import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledDashBoardItem = styled('div')`
    box-sizing: border-box;
    display: block;
`;

export const StyledHeader = styled('header')`
    border-bottom: unset !important;
    margin-top: unset !important;
    padding-bottom: unset !important;
    margin-left: 20px;
    cursor: default !important;
`;

export const StyledDashBoardContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    border: ${props =>
        props.theme.palette.mode === 'dark'
            ? `1px solid ${constants.COLOR_GENERAL_28}`
            : `1px solid ${constants.COLOR_GENERAL_52}`};
    border-width: 1;
    border-radius: 4px !important;
`;

export const StyledCard = styled('div')`
    flex: 1 1 0%;
    width: 0px;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? '#251E33'
            : constants.COLOR_WHITE};
    height: 385px;

    .empty-placeholder {
        svg {
            height: 100px;
        }
    }
`;

export const StyledDiv = styled('div')`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    padding: 24px;
    overflow: auto;
`;

export const StyledEmptyContainer = styled('div')`
    display: flex;
    height: 100%;
    justify-content: center;
    background: ${props =>
        props.theme.palette.mode === 'dark' ? '' : '#ffffff'};
    align-items: center;
    border: ${props =>
        props.theme.palette.mode === 'dark'
            ? `1px solid ${constants.COLOR_GENERAL_28}`
            : `1px solid ${constants.COLOR_GENERAL_52}`};
`;

export const StyledField = styled('div')`
    padding-bottom: 8px;

    .MuiFormLabel-root {
        font-family: Proxima Nova Bld;
        margin-bottom: 4px;
        font-size: 14px;
        display: block;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
    .modal-text-field {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_DARK_3
                : constants.COLOR_WHITE};

        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};

        border-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_9};
    }
`;
