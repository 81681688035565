import CheckBoxSection from './CheckBoxSection';
import { StyledField } from '../CreateReport.style';
import { ReportElementErrorType } from '../CreateReport.types';
import { EDITOR_MAX_LIMIT_ERROR } from '../utils';
import EditorSection from './EditorSection';

type ConditionalDescriptionSectionProps = {
    id: string;
    itemKey: string;
    title: string;
    isChecked: boolean | undefined;
    value: string;
    reportElementError: ReportElementErrorType;
    handleError: (error: string) => void;
    handleCheckboxChange: (value: boolean) => void;
    handleChange: (value: string) => void;
};

const ConditionalDescriptionSection = ({
    id,
    itemKey,
    title,
    isChecked,
    value,
    reportElementError,
    handleError,
    handleCheckboxChange,
    handleChange
}: ConditionalDescriptionSectionProps) => {
    const handleEditorChange = (val: string) => {
        if (val.length > 30_000) {
            handleError(EDITOR_MAX_LIMIT_ERROR);
        } else {
            handleError('');
        }
        handleChange(val);
    };

    return (
        <StyledField>
            <CheckBoxSection
                handleChange={handleCheckboxChange}
                isChecked={isChecked}
                title={title}
            />
            <EditorSection
                id={id}
                itemKey={itemKey}
                onChange={handleEditorChange}
                reportElementError={reportElementError}
                value={value}
            />
        </StyledField>
    );
};

export default ConditionalDescriptionSection;
