import { FC } from 'react';

import { constants } from '@armis/armis-ui-library';
import { FormHelperText } from '@mui/material';
import { EditorConfig } from 'ckeditor5';
import { Editor } from 'src/pages/components/Editor';

import { StyledContainer } from './EditorSection.style';
import { ReportElementErrorType } from '../CreateReport.types';
import { getFieldErrorMessage, isFieldHavingError } from '../utils';

type Props = {
    id: string;
    itemKey: string;
    value: string;
    onChange: (value: string) => void;
    reportElementError: ReportElementErrorType;
    editorConfig?: EditorConfig;
};

const EditorSection: FC<Props> = ({
    id,
    value,
    itemKey,
    onChange,
    reportElementError,
    editorConfig
}) => {
    const hasError = isFieldHavingError(reportElementError, id, itemKey);

    return (
        <StyledContainer hasError={hasError}>
            <Editor
                editorConfig={editorConfig}
                onChange={onChange}
                value={value}
            />
            {hasError && (
                <FormHelperText sx={{ color: constants.COLOR_5 }}>
                    {getFieldErrorMessage(reportElementError, id, itemKey)}
                </FormHelperText>
            )}
        </StyledContainer>
    );
};

export default EditorSection;
