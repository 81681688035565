import React, { useState, useEffect, useRef } from 'react';

import { CHECKBOX_STATUS, MultiSelect, Select } from '@armis/armis-ui-library';
import { FormHelperText, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import {
    ADMIN,
    ALL,
    OPTIONAL,
    REQUIRED,
    SELECT_ROLES,
    SELECT_TAGS,
    SELECT_TENANTS,
    TOTAL_SELECTED
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    validateValues,
    isFieldHavingError
} from 'src/helpers/utility';
import { EditField } from 'src/pages/components/UserModalContainer/EditField/EditField';
import {
    OptionalSpanStyle,
    StyledDiv,
    StyledSpan
} from 'src/pages/components/UserModalContainer/EditField/EditField.style';
import {
    ACTION_TYPE,
    PROPERTY_FIELD_KEY_MAP,
    UserFieldMetaData
} from 'src/pages/containers/UserManagement/UserConstants';
import {
    getRoles,
    getTenants,
    getUserAssignedTags,
    getUserDetails
} from 'src/services/api.service';
import { FieldValidationType, FilterItems } from 'src/types/CommonTypes';

const updateSelectedItems = (
    selectedItems: string[],
    items: FilterItems[],
    setItemsCount: React.Dispatch<React.SetStateAction<string[]>>,
    setItems: React.Dispatch<React.SetStateAction<FilterItems[]>>,
    onAddItems: (value: string[]) => void,
    selectText: string,
    allText: string,
    totalSelectedText: string
) => {
    const totalSelected = selectedItems.length;

    if (totalSelected === 0) {
        setItemsCount([selectText]);
    } else if (totalSelected === items.length) {
        setItemsCount([allText]);
    } else {
        setItemsCount([
            totalSelectedText.replace('%s', totalSelected.toString())
        ]);
    }

    onAddItems(selectedItems);

    const updatedItems = items?.map(item =>
        selectedItems.find(uItem => uItem === item.id)
            ? { ...item, checkStatus: CHECKBOX_STATUS.CHECKED }
            : { ...item, checkStatus: CHECKBOX_STATUS.UNCHECKED }
    );

    setItems(updatedItems);
};

const optionalFieldComponent = (
    <OptionalSpanStyle>{OPTIONAL}</OptionalSpanStyle>
);

const AddUserModalContainer = ({
    usersData,
    items,
    onUserAdd,
    customError,
    onAddRoles,
    onAddTags,
    onAddTenants,
    setIsLoading,
    actionType,
    selectedRole
}: {
    usersData: any;
    items: string[];
    onUserAdd: any;
    customError: any;
    onAddRoles: (value: string[]) => void;
    onAddTags: (value: string[]) => void;
    onAddTenants: (value: string[]) => void;
    setIsLoading: any;
    actionType: string;
    selectedRole: string[];
}) => {
    const [roles, setRoles] = useState<FilterItems[]>([]);
    const [tenants, setTenants] = useState<FilterItems[]>([]);
    const [tags, setTags] = useState<FilterItems[]>([]);
    const [user, setUser] = useState(usersData);
    const [userErrors, setUserErrors] = useState<any>(customError);
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [rolesCount, setRolesCount] = useState([SELECT_ROLES]);
    const [tenantsCount, setTenantsCount] = useState([SELECT_TENANTS]);
    const [tagCount, setTagsCount] = useState([SELECT_TAGS]);
    const firstRender = useRef(true);
    const isAdminRoleSelected = !!selectedRoles.find(role =>
        roles.find(uRole => uRole.id === role && uRole.label === ADMIN)
    );

    useEffect(() => {
        updateSelectedItems(
            selectedRoles,
            roles,
            setRolesCount,
            setRoles,
            onAddRoles,
            SELECT_ROLES,
            ALL,
            TOTAL_SELECTED
        );
    }, [selectedRoles]);

    useEffect(() => {
        updateSelectedItems(
            selectedTenants,
            tenants,
            setTenantsCount,
            setTenants,
            onAddTenants,
            SELECT_TENANTS,
            ALL,
            TOTAL_SELECTED
        );
    }, [selectedTenants]);

    useEffect(() => {
        updateSelectedItems(
            selectedTags,
            tags,
            setTagsCount,
            setTags,
            onAddTags,
            SELECT_TAGS,
            ALL,
            TOTAL_SELECTED
        );
    }, [selectedTags]);

    useEffect(() => {
        onUserAdd(user);
    }, [user]);

    useEffect(() => {
        const doApiCall = async () => {
            if (firstRender.current)
                try {
                    setIsLoading(true);
                    const getRole = await getRoles('');
                    const getTenantsList = await getTenants('', false);
                    const getTagsList = await getUserAssignedTags();

                    const getTotalRoles = getRole.data.map((item: any) => ({
                        label: item.name,
                        id: item.id,
                        checkStatus: CHECKBOX_STATUS.UNCHECKED,
                        labelRender: <span>{item.name}</span>
                    }));
                    setRoles(getTotalRoles);
                    setSelectedRoles(selectedRole.map((item: any) => item));

                    const getTenentList = getTenantsList.data.content.map(
                        (item: any) => ({
                            label: item.name,
                            id: item.id,
                            checkStatus: CHECKBOX_STATUS.UNCHECKED,
                            labelRender: <span>{item.name}</span>
                        })
                    );

                    setTenants(getTenentList);
                    setSelectedTenants(
                        selectedTenants.map((item: any) => item)
                    );

                    const getTagList = getTagsList.data?.map((item: any) => ({
                        label: item,
                        id: item,
                        checkStatus: CHECKBOX_STATUS.UNCHECKED,
                        labelRender: <span>{item}</span>
                    }));

                    setTags(getTagList);
                    setSelectedTags(selectedTenants.map((item: any) => item));

                    if (actionType === ACTION_TYPE.EDIT) {
                        const userDetails = await getUserDetails(
                            items.toString()
                        );
                        const userDetailsData = {
                            firstname: userDetails.data.firstname,
                            lastname: userDetails.data.lastname,
                            username: userDetails.data.username,
                            phonenumber: userDetails.data.phonenumber
                        };
                        setUser(userDetailsData);
                        setSelectedRoles(
                            userDetails.data.roles.map((item: any) => item.id)
                        );

                        setSelectedTenants(
                            userDetails.data.tenants.map((item: any) => item.id)
                        );
                        setSelectedTags(
                            userDetails.data.tags.map((item: any) => item)
                        );
                    }
                } catch (err: any) {
                    displayErrorMessage(err);
                } finally {
                    setIsLoading(false);
                }
        };
        doApiCall();
        firstRender.current = false;
    }, []);

    useEffect(() => {
        setUserErrors(customError);
    }, [customError]);

    const getValue = (name: string) => {
        const currentValue = user[name];
        return currentValue || '';
    };
    const fieldOnChangeHandler = (
        name: string,
        value: string,
        type: string,
        validations: FieldValidationType
    ) => {
        let fieldValidationErrorArray = {};
        setUser((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        const validationErrorObject = validateValues(value, validations, type);
        if (validationErrorObject.error) {
            fieldValidationErrorArray = {
                ...userErrors,
                [name]: {
                    ...validationErrorObject
                }
            };
        }
        if (Object.keys(fieldValidationErrorArray).length > 0) {
            setUserErrors(fieldValidationErrorArray);
        } else {
            const usersCopy = cloneDeep(userErrors);
            delete usersCopy[name];
            setUserErrors(usersCopy);
        }
    };
    const getErrorHelperText = (field: string) =>
        userErrors?.[field]?.helperText ?? '';
    return (
        <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} container item>
            <>
                {Object.keys(UserFieldMetaData).map(
                    field =>
                        field !== PROPERTY_FIELD_KEY_MAP.ROLES && (
                            <EditField
                                key={UserFieldMetaData[field].key}
                                disable={
                                    !!(
                                        UserFieldMetaData[field].key ===
                                        PROPERTY_FIELD_KEY_MAP.USERNAME &&
                                        actionType === ACTION_TYPE.EDIT
                                    )
                                }
                                error={isFieldHavingError(field, userErrors)}
                                gridSize={UserFieldMetaData[field].gridSize}
                                helperText={getErrorHelperText(field)}
                                id={UserFieldMetaData[field].key}
                                label={UserFieldMetaData[field].label}
                                onChange={value => {
                                    fieldOnChangeHandler(
                                        UserFieldMetaData[field].key,
                                        value,
                                        UserFieldMetaData[field].type,
                                        UserFieldMetaData[field].validations
                                    );
                                }}
                                optional={UserFieldMetaData[field].optional}
                                type={UserFieldMetaData[field].type}
                                value={getValue(UserFieldMetaData[field].key)}
                            />
                        )
                )}
                <Grid item xs={12}>
                    <StyledDiv>
                        <StyledSpan>Roles</StyledSpan>
                        <Select
                            defaultValue={SELECT_ROLES}
                            MenuProps={{
                                sx: { zIndex: 10002 },
                                PaperProps: {
                                    sx: {
                                        minWidth: '500px !important'
                                    }
                                },
                                MenuListProps: {
                                    sx: {
                                        '.multiselect-filtered-div': {
                                            maxHeight: '194px'
                                        }
                                    }
                                }
                            }}
                            renderValue={() => rolesCount}
                            sx={{
                                border: isFieldHavingError(
                                    PROPERTY_FIELD_KEY_MAP.ROLES,
                                    userErrors
                                )
                                    ? '1px solid red'
                                    : ''
                            }}
                            value={rolesCount}
                            variant="outlined"
                        >
                            <MultiSelect
                                isAllSelected={false}
                                items={roles}
                                onSelectionChanged={selectedItems => {
                                    setSelectedRoles(
                                        selectedItems.map(element => element.id)
                                    );
                                    if (selectedItems.length === 0) {
                                        let fieldValidationErrorArray = {};
                                        fieldValidationErrorArray = {
                                            ...userErrors,
                                            roles: {
                                                error: true,
                                                helperText: REQUIRED
                                            }
                                        };
                                        setUserErrors(
                                            fieldValidationErrorArray
                                        );
                                    } else {
                                        const usersCopy = cloneDeep(userErrors);
                                        delete usersCopy[
                                            PROPERTY_FIELD_KEY_MAP.ROLES
                                        ];
                                        setUserErrors(usersCopy);
                                    }
                                }}
                                showSelectAllOption
                            />
                        </Select>
                        {isFieldHavingError(
                            PROPERTY_FIELD_KEY_MAP.ROLES,
                            userErrors
                        ) && (
                            <FormHelperText error>
                                {getErrorHelperText(
                                    PROPERTY_FIELD_KEY_MAP.ROLES
                                )}
                            </FormHelperText>
                        )}
                    </StyledDiv>
                </Grid>
                {!isAdminRoleSelected && (
                    <>
                        <Grid item xs={6}>
                            <StyledDiv>
                                <StyledSpan>
                                    By Tenants {optionalFieldComponent}
                                </StyledSpan>
                                <Select
                                    defaultValue={SELECT_TENANTS}
                                    MenuProps={{
                                        sx: { zIndex: 10002 },
                                        PaperProps: {
                                            sx: {
                                                minWidth: '500px !important'
                                            }
                                        },
                                        MenuListProps: {
                                            sx: {
                                                '.multiselect-filtered-div': {
                                                    maxHeight: '194px'
                                                }
                                            }
                                        }
                                    }}
                                    renderValue={() => tenantsCount}
                                    value={tenantsCount}
                                    variant="outlined"
                                >
                                    <MultiSelect
                                        isAllSelected={false}
                                        items={tenants}
                                        onSelectionChanged={selectedItems => {
                                            setSelectedTenants(
                                                selectedItems.map(
                                                    element => element.id
                                                )
                                            );
                                        }}
                                        showSelectAllOption
                                    />
                                </Select>
                            </StyledDiv>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledDiv>
                                <StyledSpan>
                                    By Tags {optionalFieldComponent}
                                </StyledSpan>
                                <Select
                                    defaultValue={SELECT_TAGS}
                                    MenuProps={{
                                        sx: { zIndex: 10002 },
                                        PaperProps: {
                                            sx: {
                                                minWidth: '500px !important'
                                            }
                                        },
                                        MenuListProps: {
                                            sx: {
                                                '.multiselect-filtered-div': {
                                                    maxHeight: '194px'
                                                }
                                            }
                                        }
                                    }}
                                    renderValue={() => tagCount}
                                    value={tagCount}
                                    variant="outlined"
                                >
                                    <MultiSelect
                                        isAllSelected={false}
                                        items={tags}
                                        onSelectionChanged={selectedItems => {
                                            setSelectedTags(
                                                selectedItems.map(
                                                    element => element.id
                                                )
                                            );
                                        }}
                                        showSelectAllOption
                                    />
                                </Select>
                            </StyledDiv>
                        </Grid>
                    </>
                )}
            </>
        </Grid>
    );
};

export default AddUserModalContainer;
