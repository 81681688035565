import { useState } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';

import { StyledField } from '../CreateReport.style';

type TransparencySelectionProps = {
    title: string;
    value: string;
    error: string;
    handleChange: (value: number) => void;
    handleError: (error: string) => void;
};

const TransparencySection = ({
    title,
    value,
    error,
    handleChange,
    handleError
}: TransparencySelectionProps) => {
    const [data, setData] = useState<string>(value.toString());

    const handleNumberError = (val: string) => {
        const numberValue = parseInt(val, 10);
        if (Object.is(numberValue, NaN)) {
            handleError('Enter a valid number');
        } else if (numberValue < 0 || numberValue > 100) {
            handleError('Enter a number between 0 to 100');
        } else {
            handleError('');
            handleChange(numberValue);
        }
    };

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <TextBox
                autoComplete="off"
                error={!!error}
                fullWidth
                helperText={error}
                hiddenLabel
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" variant="outlined">
                            %
                        </InputAdornment>
                    )
                }}
                onChange={e => {
                    setData(e.target.value);
                    handleNumberError(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === '.') {
                        e.preventDefault(); // Prevents input of decimal points
                    }
                }}
                size="small"
                type="number"
                value={data}
                variant="outlined"
            />
        </StyledField>
    );
};

export default TransparencySection;
