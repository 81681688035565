import { Dispatch, FC, SetStateAction } from 'react';

import { Button, constants } from '@armis/armis-ui-library';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import { FormHelperText, FormLabel, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import { PAGE_SIZE } from 'src/constants/APIConstants';
import { DASHLET_REQUIRED_ERROR } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { DashletDataType } from 'src/pages/components/DashletPanel/DashletPanel.types';
import { getTenantDashlets } from 'src/services/api.service';
import { v4 as uuid } from 'uuid';

import {
    StyledFormFields,
    StyledDashletContainer
} from './CreateEditDashboard.styles';
import DashletPreview from './DashletPreview';
import { DashletPanel } from '../../../../components/DashletPanel';
import { StyledPreviewWrapper } from '../../../../components/DashletPanel/DashletPanel.style';
import { DroppableSourceAndDestinationType } from '../../../CreateReport/CreateReport.types';
import { Dashlets, HOCLoading } from '../../DashboardTemplate.types';
import { reorder, State } from '../../utils';

type Props = {
    dashletData: DashletDataType;
    items: Dashlets[];
    setDashletData: Dispatch<SetStateAction<DashletDataType>>;
    setIsLoading: HOCLoading;
    dashletsError: string;
    setDashletsError: Dispatch<SetStateAction<string>>;
    setState: Dispatch<SetStateAction<State>>;
};

const DashletPanelContainer: FC<Props> = ({
    dashletData,
    items,
    setDashletData,
    setIsLoading,
    dashletsError,
    setDashletsError,
    setState
}) => {
    const copy = (
        destination: Dashlets[],
        droppableDestination: DroppableSourceAndDestinationType,
        draggableId: string
    ) => {
        const clone = Array.from(destination);
        const index = dashletData.content.findIndex(
            ({ id }) => id === draggableId
        );
        clone.splice(droppableDestination.index, 0, {
            id: uuid(),
            dashletJson: dashletData.content[index].dashletJson
        });

        return clone;
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case 'reportlet-preview':
                setState(prevState => ({
                    ...prevState,
                    items: reorder(
                        prevState.items,
                        source.index,
                        destination.index
                    )
                }));
                break;
            case 'dashlets':
                setDashletsError('');
                setState(prevState => ({
                    ...prevState,
                    items: copy(prevState.items, destination, draggableId)
                }));
                break;
            default:
                break;
        }
    };

    const deleteItem = (id: string) => {
        const updatedItems = items.filter(item => item.id !== id);
        setState(prevState => ({ ...prevState, items: updatedItems }));
        if (updatedItems.length === 0) {
            setDashletsError(DASHLET_REQUIRED_ERROR);
        }
    };

    const handleMoveAll = async (
        selectedTenantId: string,
        selectedDashboards: number[]
    ) => {
        try {
            setIsLoading(true);
            const response = await getTenantDashlets(
                selectedTenantId,
                0,
                dashletData.totalElements || PAGE_SIZE,
                selectedDashboards.join()
            );

            const copyData = response.data.content.map((dashlet: any) => ({
                id: uuid(),
                dashletJson: dashlet.dashletJson
            }));
            setState(prevState => ({
                ...prevState,
                items: [...cloneDeep(items), ...copyData]
            }));
            setDashletsError('');
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <StyledFormFields sx={{ overflow: 'hidden' }}>
            <FormLabel htmlFor="dashlets">
                Dashlets ({dashletData.totalElements ?? 0})
            </FormLabel>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid
                    container
                    spacing="20px"
                    sx={{ marginTop: 0, overflow: 'hidden' }}
                >
                    <StyledDashletContainer item xs={8}>
                        <DashletPanel
                            dashletData={dashletData}
                            handleMoveAll={handleMoveAll}
                            setDashletData={setDashletData}
                        />
                    </StyledDashletContainer>
                    <StyledPreviewWrapper item xs={4}>
                        <div
                            style={{
                                marginBottom: 12,
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>Preview</span>
                            <Button
                                disabled={items.length === 0}
                                onClick={() => {
                                    setDashletsError(DASHLET_REQUIRED_ERROR);
                                    setState(prevState => ({
                                        ...prevState,
                                        items: []
                                    }));
                                }}
                                variant="text"
                            >
                                Clear all
                            </Button>
                        </div>
                        <DashletPreview deleteItem={deleteItem} items={items} />
                    </StyledPreviewWrapper>
                </Grid>
            </DragDropContext>
            {dashletsError && (
                <FormHelperText sx={{ color: constants.COLOR_5 }}>
                    {dashletsError}
                </FormHelperText>
            )}
        </StyledFormFields>
    );
};

export default DashletPanelContainer;
