import { constants, IconButton, Select } from '@armis/armis-ui-library';
import {
    Typography,
    CardContent,
    Grid,
    List,
    Skeleton,
    styled
} from '@mui/material';

export const StyledDiv = styled('div')`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 10px;

    & .MuiGrid-item {
        padding-right: 8px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const StyledField = styled('div')`
    &:not(:last-child, :first-child) {
        padding-bottom: 10pt;
    }
    .MuiFormLabel-root {
        font-family: Proxima Nova Bld;
        margin-bottom: 4px;
        font-size: 14px;
        display: block;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
`;

export const StyledSelectGrid = styled(Grid)`
    position: sticky;
    top: 0;
    background: ${props =>
        props.theme.palette.mode === 'light'
            ? constants.COLOR_GENERAL_36
            : constants.COLOR_GENERAL_35};
    padding-bottom: 8px;
    margin-bottom: 8px;
    z-index: 1000;
`;

export const StyledSelect = styled(Select)`
    width: 100%;
`;

export const StyledDashboardLabel = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const StyledNoData = styled('div')`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

export const StyledSkeleton = styled(Skeleton)`
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : 'rgb(219, 219, 219)'};
`;

export const NoDataText = styled('p')`
    color: ${props =>
        props.theme.palette.mode === 'dark' ? 'lightgrey' : '#797979'};
    font-size: 14px;
    margin: 0;
    text-align: center;
`;

export const StyledPreviewWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding-top: 0 !important;
    height: 100%;
`;

export const StyledPreviewContainer = styled(List)`
    height: 100%;
    overflow-y: auto;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ffffff'};

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const StyledPreviewCardContainer = styled('div')`
    padding: 15px;
    padding-bottom: 15px;
    padding-top: 10px;
`;

export const StyledPreviewCardContent = styled(CardContent)`
    background-color: ${props =>
        props.theme.palette.mode === 'dark' ? 'transparent' : '#F2F2F4'};
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const StyledCardRemoveButton = styled(IconButton)`
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_35
            : '#ddd2e8'};
`;

export const EllipsisTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${props => props.theme.typography.body2.fontSize};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const StyledTypography = styled(Typography)`
    font-family: Proxima Nova W08 Reg;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;
