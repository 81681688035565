import { Dispatch, SetStateAction } from 'react';

import { cloneDeep } from 'lodash';
import { PAGE_SIZE } from 'src/constants/APIConstants';
import { displayErrorMessage } from 'src/helpers/utility';
import { DashletDataType } from 'src/pages/components/DashletPanel/DashletPanel.types';
import { getTenantDashlets } from 'src/services/api.service';
import { v4 as uuid } from 'uuid';

import { ReportFieldsDataType } from './CreateReport.types';
import { DashletPanel } from '../../components/DashletPanel';

type Props = {
    dashletData: DashletDataType;
    reportFieldsData: ReportFieldsDataType;
    setReportFieldsData: Dispatch<SetStateAction<ReportFieldsDataType>>;
    setDashletData: Dispatch<SetStateAction<DashletDataType>>;
    setIsLoading: (flag: boolean) => void;
};

const DashletPanelContainer = ({
    dashletData,
    reportFieldsData,
    setReportFieldsData,
    setDashletData,
    setIsLoading
}: Props) => {
    const handleMoveAll = async (
        selectedTenantId: string,
        selectedDashboards: number[]
    ) => {
        try {
            setIsLoading(true);

            const response = await getTenantDashlets(
                selectedTenantId,
                0,
                dashletData.totalElements || PAGE_SIZE,
                selectedDashboards.join()
            );

            const copyData = response.data.content.map((dashlet: any) => ({
                dragAndDropId: uuid(),
                previewDragAndDropId: uuid(),
                isReportlet: true,
                reportlet: {
                    layout: 'LEFT',
                    recommendations: '',
                    recommendationsCheckbox: false,
                    benchmarkCheckbox: false,
                    benchmark: '',
                    hideSection: false,
                    reportDashlet: {
                        title: dashlet.title || '',
                        dashletDescription: '',
                        dashletJson: dashlet.dashletJson
                    }
                }
            }));

            const copyReportFieldData = cloneDeep(reportFieldsData);
            copyReportFieldData.reportlet_data = [
                ...copyReportFieldData.reportlet_data,
                ...copyData
            ];

            setReportFieldsData(copyReportFieldData);
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ height: '42vh' }}>
            <DashletPanel
                dashletData={dashletData}
                handleMoveAll={handleMoveAll}
                setDashletData={setDashletData}
            />
        </div>
    );
};

export default DashletPanelContainer;
