import React, { useCallback, useMemo, useState, useTransition } from 'react';

import { TagsWithInputBox, constants } from '@armis/armis-ui-library';
import { debounce, FormLabel, Typography } from '@mui/material';
import { Privileges, Resources, TagTypes } from 'src/constants/CommonConstants';
import { CREATE_TAG_INFO, OPTIONAL, TAGS } from 'src/constants/LabelText';
import { displayErrorMessage, onFieldChangeHandler } from 'src/helpers/utility';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { StyledField } from 'src/pages/components/ModalField/ModalField.style';
import { getTags } from 'src/services/api.service';
import { ModalFieldType } from 'src/types/CommonTypes';

import { actionFormMetaData } from './constants';

export interface EditTenantProps {
    modalFields: ModalFieldType;
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>;
}

export const EditTenant = ({
    modalFields,
    setModalFields
}:
EditTenantProps) => {
    const [, startTransition] = useTransition();
    const [tags, setTags] = useState<string[]>([]);
    const selectedTags = modalFields[TAGS].value as string[];

    const getSearchedTags = useCallback(
        (tagSearch: string) => {
            getTags(TagTypes.tenant, tagSearch)
                .then(res => {
                    setTags(
                        res.data.content
                            .map((tag: any) => tag.name)
                            .filter(
                                (tag: string) => !selectedTags.includes(tag)
                            )
                    );
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        },
        [selectedTags]
    );

    const onTagsSearchInputChangeHandler = useMemo(
        () =>
            debounce((searchValue: string) => {
                getSearchedTags(searchValue);
            }, 500),
        [getSearchedTags]
    );

    return (
        <div>
            {actionFormMetaData.map(
                ({ labelName, type, inputType, placeHolder }, index) => (
                    <ProtectedAction
                        key={index}
                        hasAnyPermission={[Privileges.edittenant]}
                        resource={Resources.tenant}
                    >
                        <ModalField
                            key={index}
                            index={index}
                            inputType={inputType}
                            {...modalFields[labelName]}
                            labelName={labelName}
                            onChange={fieldValue =>
                                onFieldChangeHandler(
                                    fieldValue,
                                    labelName,
                                    type,
                                    actionFormMetaData,
                                    setModalFields,
                                    modalFields,
                                    startTransition
                                )
                            }
                            placeHolder={placeHolder}
                            type={type}
                        />
                    </ProtectedAction>
                )
            )}

            <ProtectedAction
                hasAnyPermission={[Privileges.tags]}
                resource={Resources.tenant}
            >
                <StyledField key={`partner-name-input-${TAGS}`}>
                    <FormLabel htmlFor={`partner-name-input-${TAGS}`}>
                        {TAGS}{' '}
                        <span
                            style={{
                                fontSize: '1rem',
                                fontFamily:
                                    constants.PROXIMANOVA_W08_THIN_REGULAR
                            }}
                        >
                            {OPTIONAL}
                        </span>
                    </FormLabel>
                    <TagsWithInputBox
                        className="content"
                        componentsProps={{
                            popper: {
                                sx: { zIndex: 10002 }
                            }
                        }}
                        data-testid="tags-autocomplete"
                        defaultValue={selectedTags}
                        id={`partner-name-input-${TAGS}`}
                        onInputChange={(_, tagString) =>
                            tagString.trim().length &&
                            onTagsSearchInputChangeHandler(tagString)
                        }
                        onTagsChangeEmit={(tagList: string[]) => {
                            setModalFields({
                                ...modalFields,
                                [TAGS]: {
                                    ...modalFields[TAGS],
                                    value: tagList
                                }
                            });
                            setTags([]);
                        }}
                        options={tags}
                        placeholder="Search tags..."
                        readOnlyOptions={[]}
                    />
                    <Typography
                        className="content"
                        sx={{ color: constants.COLOR_1 }}
                        variant="h6"
                    >
                        {CREATE_TAG_INFO}
                    </Typography>
                </StyledField>
            </ProtectedAction>
        </div>
    );
};
