import { FC } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

import { StyledField } from '../../DashBoard.style';

type Props = {
    label: string;
    value: string;
    error?: string;
    handleChange: (value: string) => void;
};

const VariableValue: FC<Props> = ({ label, value, error, handleChange }) => (
    <StyledField sx={{ flex: 1, paddingBottom: '0 !important' }}>
        <FormLabel>{label}</FormLabel>
        <TextBox
            autoComplete="off"
            error={Boolean(error)}
            fullWidth
            helperText={error}
            hiddenLabel
            InputProps={{
                endAdornment: value && (
                    <InputAdornment
                        onClick={() => {
                            handleChange('');
                        }}
                        position="end"
                        variant="outlined"
                    >
                        <IoIosClose />
                    </InputAdornment>
                )
            }}
            onChange={e => {
                handleChange(e.target.value);
            }}
            size="small"
            value={value}
            variant="outlined"
        />
    </StyledField>
);

export default VariableValue;
