import { Select } from '@armis/armis-ui-library';
import { FormLabel, MenuItem } from '@mui/material';

import { StyledField } from '../CreateReport.style';
import { MenuItemDataType } from '../CreateReport.types';

type SelectSectionProps = {
    title: string;
    value: string;
    items: MenuItemDataType[];
    fullWidth?: boolean;
    handleChange: (color: string) => void;
};

const SelectSection = ({
    title,
    value,
    items,
    fullWidth,
    handleChange
}: SelectSectionProps) => (
    <StyledField>
        <FormLabel>{title}</FormLabel>

        <Select
            defaultValue={value}
            fullWidth={fullWidth}
            MenuProps={{ sx: { zIndex: 10002 } }}
            onChange={e => {
                handleChange(e.target.value as string);
            }}
            variant="outlined"
        >
            {items.map(item => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Select>
    </StyledField>
);

export default SelectSection;
