import { Button, constants } from '@armis/armis-ui-library';
import { List, ListItem, ListSubheader, styled } from '@mui/material';

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

export const StyledPopper = styled('div')`
    border: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_WHITE};
    border-radius: 8px;
    width: 330px;
    padding: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const StyledPopperArrowContainer = styled('div')`
    width: 8px;
    height: 8px;
    z-index: 1;
    position: absolute;
    left: 15px;
    top: -3px;
`;

export const StyledPopperArrow = styled('div')`
    transform: rotate(45deg);
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_WHITE};
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: inherit;
    box-shadow: -1px -1px 1px 0 ${props => (props.theme.palette.mode === 'dark' ? constants.COLOR_GENERAL_28 : constants.COLOR_GENERAL_52)};
`;

export const StyledPopperButton = styled(Button)`
    justify-content: flex-start;
    font-size: 16px;
    height: 32px;
`;

export const StyledListSubheader = styled(ListSubheader)`
    font-family: 'Proxima Nova W08 Reg';
    font-size: 13px;
    line-height: 18px;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_WHITE};
    padding: 5px 0px;
    z-index: 1;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : '#6E6E6E'};
    border-bottom: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
`;

export const StyledList = styled(List)`
    width: 100%;
    position: relative;
    overflow-y: auto;
    max-height: 200px;
    margin-top: 12px;
    background-color: inherit;

    & ul {
        padding: 0;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const StyledListItem = styled(ListItem)`
    & .list-title > span {
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_26
                : '#6E6E6E'};
        font-size: 14px;
    }
`;

export const StyledFormField = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 6px;
    .MuiFormLabel-root {
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
`;
