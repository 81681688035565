import { constants } from '@armis/armis-ui-library';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    styled
} from '@mui/material';

import { StyledField } from '../../DashBoard.style';

export const StyledDrawer = styled(Drawer)`
    & .MuiDrawer-paper {
        border: 1px solid
            ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_28
                    : constants.COLOR_GENERAL_52};
        background: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_30
                : constants.COLOR_WHITE};
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_26
                : '#6E6E6E'};
        width: 550px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const StyledTitle = styled(DialogTitle)`
    font-size: 16px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 16px 8px 16px;

    & .dashboard-title {
        font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
        color: #8338ec;
    }
`;

export const StyledContent = styled(DialogContent)`
    padding: 8px 16px;
    margin: 0;
`;

export const StyledSpan = styled('span')`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
`;

export const StyledColumnTitle = styled(StyledField)`
    display: flex;
    align-items: center;
    gap: 8px;

    & button {
        padding-left: 8px;
        padding-right: 16px;
        height: 28px;
    }

    & .MuiFormLabel-root {
        margin-bottom: 0;
        flex-grow: 1;
    }
`;

export const StyledActions = styled(DialogActions)``;

export const StyledNoInputsLabel = styled('p')`
    margin: 16px 0;
    color: ${props =>
        props.theme.palette.mode === 'dark' ? 'white' : '#797979'};
    font-size: 14px;
    width: 100%;
    text-align: center;
`;
