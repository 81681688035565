import { FC } from 'react';

import { Tooltip } from '@armis/armis-ui-library';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, CardMedia, useTheme } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

import {
    EllipsisTypography,
    NoDataText,
    StyledCardRemoveButton,
    StyledPreviewCardContainer,
    StyledPreviewCardContent,
    StyledPreviewContainer
} from '../../../../components/DashletPanel/DashletPanel.style';
import {
    DragableDiv,
    RightPaneContainerTwo
} from '../../../CreateReport/CreateReport.style';
import NoData from '../../../CreateReport/NoData';
import { renderChartSvg } from '../../../CreateReport/utils';
import { Dashlets } from '../../DashboardTemplate.types';

type Props = {
    items: Dashlets[];
    deleteItem: (id: string) => void;
};

const DashletPreview: FC<Props> = ({ items, deleteItem }) => {
    const { palette } = useTheme();

    const renderSymbol = (item: Dashlets) => {
        const title = item.dashletJson.title ?? '';
        const image = renderChartSvg(
            item?.dashletJson?.dashlet?.visualizationConfig?.type
        );

        return (
            <Card sx={{ overflow: 'visible', position: 'relative' }}>
                <StyledCardRemoveButton
                    aria-label="remove-dashlet"
                    onClick={() => deleteItem(item.id)}
                >
                    <IoIosClose height={20} width={20} />
                </StyledCardRemoveButton>

                <CardMedia
                    alt="Dashlet image"
                    component="img"
                    height={80}
                    image={image}
                    sx={{
                        padding: '1em',
                        objectFit: 'contain',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: palette.mode === 'dark' ? '' : '#F2F2F4',
                        boxShadow: 'none',
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px'
                    }}
                    width={30}
                />

                <StyledPreviewCardContent>
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            <div className="tooltip-arrow-text">{title}</div>
                        }
                    >
                        <EllipsisTypography>{title}</EllipsisTypography>
                    </Tooltip>
                </StyledPreviewCardContent>
            </Card>
        );
    };

    return (
        <Droppable droppableId="reportlet-preview" type="report-items">
            {provided =>
                items.length > 0 ? (
                    <StyledPreviewContainer ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {dragProvided => (
                                    <DragableDiv
                                        ref={dragProvided.innerRef}
                                        id={item.id}
                                        {...dragProvided.draggableProps}
                                        style={
                                            dragProvided.draggableProps.style
                                        }
                                        {...dragProvided.dragHandleProps}
                                    >
                                        <StyledPreviewCardContainer>
                                            {renderSymbol(item)}
                                        </StyledPreviewCardContainer>
                                    </DragableDiv>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </StyledPreviewContainer>
                ) : (
                    <RightPaneContainerTwo ref={provided.innerRef}>
                        <NoData>
                            <NoDataText>Drag & drop dashlets here</NoDataText>
                        </NoData>
                    </RightPaneContainerTwo>
                )
            }
        </Droppable>
    );
};

export default DashletPreview;
