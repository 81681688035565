import { FC, ReactNode } from 'react';

import { DragDropLine } from 'src/assets/images';

import { NoDataComponent } from './CreateReport.style';

const NoData: FC<{ children: ReactNode }> = ({ children }) => (
    <NoDataComponent>
        <img alt="no-data" src={DragDropLine} />
        {children}
    </NoDataComponent>
);

export default NoData;
