import {
    useEffect,
    useState,
    useRef,
    useCallback,
    Dispatch,
    SetStateAction,
    FC
} from 'react';

import {
    IconButton,
    MenuItemProps,
    MultiSelect,
    Tooltip
} from '@armis/armis-ui-library';
import { Droppable } from '@hello-pangea/dnd';
import { FormLabel, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { AxiosResponse } from 'axios';
import { differenceWith, isEqual } from 'lodash';
import { FaArrowRight } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PAGE_SIZE } from 'src/constants/APIConstants';
import { DASHBOARD, TENANT } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import {
    getDashlets,
    getTenantDashlets,
    getTenants
} from 'src/services/api.service';

import {
    StyledDashboardLabel,
    StyledDiv,
    StyledField,
    StyledSelect,
    StyledSelectGrid
} from './DashletPanel.style';
import { DashletDataType, TenantDataType } from './DashletPanel.types';
import {
    DraggableCard,
    NextLoader,
    NoData,
    renderCardSkeleton
} from './helperUtils';
import {
    getDashboardList,
    getDashboardSelectLabel,
    selectMenuProps,
    updateDashboardList,
    updateDashletContent
} from './utils';

const ALL = 'All';
const moveTooltipContent = (
    <div className="tooltip-arrow-text">Move All Dashlets</div>
);

type Props = {
    dashletData: DashletDataType;
    setDashletData: Dispatch<SetStateAction<DashletDataType>>;
    handleMoveAll: (
        selectedTenantId: string,
        selectedDashboards: number[]
    ) => void;
};

export const DashletPanel: FC<Props> = ({
    dashletData,
    setDashletData,
    handleMoveAll
}) => {
    const [tenantData, setTenantData] = useState<TenantDataType[]>([]);
    const [selectedTenantId, setSelectedTenantId] = useState(ALL);
    const [dashboardList, setDashboardList] = useState<MenuItemProps[]>([]);
    const [selectedDashboards, setSelectedDashboards] = useState<number[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextFetching, setIsNextFetching] = useState(false);
    const topRef = useRef<HTMLDivElement>(null);
    const dashboardSelectLabel = getDashboardSelectLabel(
        dashboardList,
        selectedDashboards
    );
    const dashlets = dashletData?.content ?? [];
    const showNoData = !isLoading && dashlets.length === 0;
    const hasMoreData =
        dashletData?.totalElements !== 0 &&
        dashlets.length !== dashletData?.totalElements;

    const isMoveEnabled =
        selectedTenantId !== ALL &&
        selectedDashboards.length === 1 &&
        dashlets.length;

    /**
     * Ensures the scroll position is reset to the top when the user interacts with the select box.
     *
     * This prevents the scroll from being stuck in the middle due to the sticky element.
     */
    const scrollToTop = useCallback(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        const getInitialData = async () => {
            try {
                setIsLoading(true);
                const tenantsResponse = await getTenants('', false);
                const dashletsResponse = await getDashlets(0, PAGE_SIZE);
                setTenantData(tenantsResponse.data.content);
                setDashletData(dashletsResponse.data);
            } catch (error: any) {
                displayErrorMessage(error);
            } finally {
                setIsLoading(false);
            }
        };
        getInitialData();
    }, [setDashletData]);

    const handleTenantChange = async (event: SelectChangeEvent<unknown>) => {
        const tenantId = event.target.value as string;

        try {
            setIsLoading(true);
            scrollToTop();
            setPageNumber(0);
            setSelectedTenantId(tenantId);
            setDashletData({} as DashletDataType);

            let response: AxiosResponse;

            if (tenantId !== ALL) {
                const { dashboardData, selectedDashboard } =
                    await getDashboardList(tenantId);

                setSelectedDashboards(selectedDashboard);
                setDashboardList(dashboardData);

                response = await getTenantDashlets(
                    tenantId,
                    0,
                    PAGE_SIZE,
                    selectedDashboard.join()
                );
            } else {
                setSelectedDashboards([]);
                response = await getDashlets(0, PAGE_SIZE);
            }
            setDashletData(response.data);
        } catch (error) {
            displayErrorMessage(error as any);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDashboardChange = async () => {
        const updatedDashboardList = updateDashboardList(
            dashboardList,
            selectedDashboards
        );
        const differences = differenceWith(
            dashboardList,
            updatedDashboardList,
            isEqual
        );

        if (differences.length === 0) return;

        try {
            setIsLoading(true);
            scrollToTop();
            setPageNumber(0);
            setDashletData({} as DashletDataType);

            let response: AxiosResponse;

            if (selectedTenantId !== ALL) {
                setDashboardList(updatedDashboardList);
                response = await getTenantDashlets(
                    selectedTenantId,
                    0,
                    PAGE_SIZE,
                    selectedDashboards.join()
                );
            } else {
                response = await getDashlets(0, PAGE_SIZE);
            }
            setDashletData(response.data);
        } catch (error) {
            displayErrorMessage(error as any);
        } finally {
            setIsLoading(false);
        }
    };

    const nextScroll = async () => {
        const shouldFetch = dashletData.totalPages >= pageNumber + 2;
        if (!shouldFetch) return;

        try {
            const newPageNumber = pageNumber + 1;
            setPageNumber(newPageNumber);
            setIsNextFetching(true);

            let response: AxiosResponse;

            if (selectedTenantId !== ALL) {
                response = await getTenantDashlets(
                    selectedTenantId,
                    newPageNumber,
                    PAGE_SIZE,
                    selectedDashboards.join()
                );
            } else {
                response = await getDashlets(newPageNumber, PAGE_SIZE);
            }
            setDashletData(previousDashlet =>
                updateDashletContent(previousDashlet, response.data)
            );
        } catch (error) {
            displayErrorMessage(error as any);
        } finally {
            setIsNextFetching(false);
        }
    };

    const tenantSelect = (
        <>
            <FormLabel>{TENANT}</FormLabel>
            <StyledSelect
                defaultValue="All"
                MenuProps={selectMenuProps}
                onChange={handleTenantChange}
                variant="outlined"
            >
                <MenuItem value="All">All</MenuItem>
                {tenantData.map(tenant => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                        {tenant.name}
                    </MenuItem>
                ))}
            </StyledSelect>
        </>
    );

    const dashboardSelect = (
        <>
            <FormLabel>
                <StyledDashboardLabel>
                    {DASHBOARD}
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={isMoveEnabled ? moveTooltipContent : null}
                    >
                        <span>
                            <IconButton
                                disabled={!isMoveEnabled}
                                onClick={() =>
                                    handleMoveAll(
                                        selectedTenantId,
                                        selectedDashboards
                                    )
                                }
                            >
                                <FaArrowRight fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                </StyledDashboardLabel>
            </FormLabel>
            <StyledSelect
                defaultValue="All Dashboards"
                disabled={selectedTenantId === ALL}
                MenuProps={selectMenuProps}
                onClose={handleDashboardChange}
                renderValue={() => dashboardSelectLabel}
                variant="outlined"
            >
                <MultiSelect
                    isAllSelected
                    items={dashboardList}
                    onSelectionChanged={items => {
                        setSelectedDashboards(items.map(({ id }) => +id));
                    }}
                    showSelectAllOption
                />
            </StyledSelect>
        </>
    );

    return (
        <StyledDiv ref={topRef} id="dashletScrollableDiv">
            <InfiniteScroll
                dataLength={dashlets.length}
                hasMore={hasMoreData}
                loader={isNextFetching && <NextLoader />}
                next={nextScroll}
                scrollableTarget="dashletScrollableDiv"
                style={{ overflow: 'revert' }}
            >
                <Droppable
                    droppableId="dashlets"
                    isDropDisabled
                    type="report-items"
                >
                    {({ innerRef }) => (
                        <div ref={innerRef}>
                            <StyledSelectGrid container spacing={2}>
                                <Grid item style={{ paddingTop: 0 }} xs={6}>
                                    <StyledField>{tenantSelect}</StyledField>
                                </Grid>
                                <Grid item style={{ paddingTop: 0 }} xs={6}>
                                    <StyledField>{dashboardSelect}</StyledField>
                                </Grid>
                            </StyledSelectGrid>
                            <Grid container spacing={2} sx={{ marginTop: 0 }}>
                                {isLoading && renderCardSkeleton(4)}
                                {showNoData && (
                                    <Grid item xs={12}>
                                        <NoData />
                                    </Grid>
                                )}
                                {dashlets.map((dashlet, index) => (
                                    <DraggableCard
                                        key={dashlet.id}
                                        id={dashlet.id}
                                        index={index}
                                        title={dashlet.title ?? ''}
                                        type={
                                            dashlet?.dashletJson?.dashlet
                                                ?.visualizationConfig?.type
                                        }
                                    />
                                ))}
                            </Grid>
                        </div>
                    )}
                </Droppable>
            </InfiniteScroll>
        </StyledDiv>
    );
};
