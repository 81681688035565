import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    Button,
    CustomHeader,
    Delete,
    IconButton,
    MultiSelect,
    Pagination,
    Push,
    Refresh,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip,
    WhitePlus
} from '@armis/armis-ui-library';
import { Menu } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { Privileges, Resources, TagTypes } from 'src/constants/CommonConstants';
import { REFRESH, SEARCH_DASHBOARD_PLACEHOLDER } from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    templateDashboardAPIMapping
} from 'src/constants/TableConstants';
import { templateDashboardColumnConfig } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import {
    getAllDashboardTemplates,
    getDashboardTemplate,
    getTags
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { Dashboard, HOCLoading } from './DashboardTemplate.types';
import { StyledMenuItem } from '../AggregatedView/AggregatedView.style';
import { StyledOption } from '../UserManagement/UserManagement.style';
import CreateEditDashboard from './Modals/CreateEditDashboard/CreateEditDashboard';
import DeleteDashboardModal from './Modals/DeleteDashboard';
import PushDashboardModal from './Modals/PushDashboard';
import ViewJsonModal from './Modals/ViewJson';
import {
    actionOptions,
    availableActions,
    getUpdatedTags,
    renderDashboardActions
} from './utils';

const templateDashboardSortOrder: Map<number> = {};
const templateDashboardSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: Record<string, FilterItems[]> = {
    tags: []
};
const queryPropertiesObj: Record<string, string> = {
    tags: ''
};

createRelatedObject(
    templateDashboardAPIMapping,
    templateDashboardSortOrder,
    templateDashboardSortStatus,
    columnsFilterItems
);

type Props = {
    setIsLoading: HOCLoading;
};

const DashboardTemplateComponent: FC<Props> = ({ setIsLoading }) => {
    const {
        tableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        queryProperties,
        columnFilterData,
        setTableLoading,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        setQueryProperties,
        onFilterChanged,
        setColumnFilterData
    } = useTable({
        sortOrderObj: templateDashboardSortOrder,
        sortStatusObj: templateDashboardSortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });
    const currentUser = useSelector(selectUser);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [selectedDashboards, setSelectedDashboards] = useState<Dashboard[]>(
        []
    );
    const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
    const [isViewJsonModalOpen, setIsViewJsonModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPushModalOpen, setIsPushModalOpen] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState({
        refresh: false,
        refreshFilterData: false
    });
    const [totalRows, setTotalRows] = useState(0);
    const firstRender = useRef(true);
    const paginationRef = useRef<ChildRefProp>();
    const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | null>(
        null
    );
    const selectedDashboard = useRef<Dashboard | null>(null);
    const disableHeaderActionButtons =
        !selectedDashboards.length || tableLoading;

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (dashboards.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [dashboards, tableLoading, gridRef]);

    useEffect(() => {
        const getDashboards = async () => {
            try {
                setTableLoading(true);
                const response = await getAllDashboardTemplates(
                    convertQueryObjectToParams(queryProperties)
                );
                setDashboards(response.data.content);
                setTotalRows(response.data.totalElements ?? 0);
            } catch (error: any) {
                displayErrorMessage(error);
                setDashboards([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                setSelectedDashboards([]);
                selectedDashboard.current = null;
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                ) {
                    paginationRef.current?.resetPagination();
                }
            }
        };
        getDashboards();
    }, [gridRef, queryProperties, setTableLoading, shouldRefresh.refresh]);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await getTags(TagTypes.dashboardTemplate);
                setColumnFilterData(prevValue => ({
                    ...prevValue,
                    tags: getUpdatedTags(prevValue.tags, response.data.content)
                }));
            } catch (error: any) {
                displayErrorMessage(error);
            }
        };
        fetchTags();
    }, [setColumnFilterData, shouldRefresh.refreshFilterData]);

    const handleEdit = useCallback(
        async (id: string) => {
            try {
                setIsLoading(true);
                const response = await getDashboardTemplate(id);
                selectedDashboard.current = response.data;
            } catch (error: any) {
                displayErrorMessage(error);
                selectedDashboard.current = null;
            } finally {
                setIsLoading(false);
                setIsCreateEditModalOpen(true);
            }
        },
        [setIsLoading]
    );

    const handleMore = useCallback((target: any, data: Dashboard) => {
        setActionsAnchor(target);
        selectedDashboard.current = data;
    }, []);

    const columnConfig = useMemo(() => {
        const hasAccess = isActionHasPermissions(
            currentUser,
            Resources.dashboardTemplate,
            [Privileges.delete, Privileges.push]
        );
        const columnInfo = hasAccess
            ? templateDashboardColumnConfig
            : templateDashboardColumnConfig.slice(1);

        return [
            ...columnInfo,
            {
                field: 'actions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                width: 120,
                sortable: false,
                cellRenderer: ({ data }: { data: Dashboard }) =>
                    renderDashboardActions(
                        currentUser,
                        () => handleEdit(data?.id ?? ''),
                        ({ currentTarget }) => handleMore(currentTarget, data)
                    )
            } as ColDef
        ];
    }, [currentUser, handleEdit, handleMore]);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnFilterData,
            onFilterChanged,
            columnSortStatus
        ]
    );

    const searchTenantContainer = useMemo(
        () => (
            <SearchBar
                onChange={newValue => {
                    setQueryProperties(prevValue => ({
                        ...prevValue,
                        searchBy: newValue,
                        page: DEFAULT_PAGE,
                        size: DEFAULT_PAGESIZE
                    }));
                }}
                placeholder={SEARCH_DASHBOARD_PLACEHOLDER}
                searchValue={queryProperties.searchBy as string}
            />
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const onCloseActionMenu = () => {
        setActionsAnchor(null);
    };

    const handleSubmit = () => {
        setIsCreateEditModalOpen(false);
        setShouldRefresh(prevValue => ({
            refresh: !prevValue.refresh,
            refreshFilterData: !prevValue.refreshFilterData
        }));
    };

    const handleViewJson = async () => {
        try {
            setIsLoading(true);
            const id = selectedDashboard.current?.id ?? '';
            const response = await getDashboardTemplate(id);
            selectedDashboard.current = response.data;
            setIsViewJsonModalOpen(true);
        } catch (error: any) {
            displayErrorMessage(error);
            selectedDashboard.current = null;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Header title="Dashboard Templates">
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.dashboardTemplate}
                >
                    <Button
                        className="header-add-button"
                        onClick={() => {
                            setIsCreateEditModalOpen(true);
                        }}
                        startIcon={<WhitePlus />}
                        variant="contained"
                    >
                        Add Dashboard
                    </Button>
                </ProtectedAction>
            </Header>
            <div className="control table">
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={{
                        component: StyledMenuPaper
                    }}
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Menu
                    anchorEl={actionsAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onCloseActionMenu}
                    open={Boolean(actionsAnchor)}
                    PaperProps={{
                        component: StyledMenuPaper,
                        sx: {
                            marginTop: '0px !important'
                        }
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {actionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.dashboardTemplate}
                        >
                            <StyledMenuItem
                                key={action.type}
                                onClick={() => {
                                    if (
                                        action.type ===
                                        availableActions.VIEW_JSON
                                    ) {
                                        handleViewJson();
                                    } else {
                                        setIsDeleteModalOpen(true);
                                    }
                                    onCloseActionMenu();
                                }}
                            >
                                {action.icon}
                                <StyledOption>{action.label}</StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>
                <TableHeader
                    childrenLeft={
                        <>
                            {searchTenantContainer}
                            <div>
                                <ProtectedAction
                                    hasPermissions={[Privileges.push]}
                                    resource={Resources.dashboardTemplate}
                                >
                                    <Button
                                        disabled={disableHeaderActionButtons}
                                        onClick={() => {
                                            setIsPushModalOpen(true);
                                        }}
                                        startIcon={<Push />}
                                    >
                                        Push Dashboards
                                    </Button>
                                </ProtectedAction>
                                <ProtectedAction
                                    hasPermissions={[Privileges.delete]}
                                    resource={Resources.dashboardTemplate}
                                >
                                    <Button
                                        disabled={disableHeaderActionButtons}
                                        onClick={() => {
                                            setIsDeleteModalOpen(true);
                                        }}
                                        startIcon={<Delete />}
                                    >
                                        Delete Dashboards
                                    </Button>
                                </ProtectedAction>
                            </div>
                        </>
                    }
                    childrenRight={
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <span>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    color="primary"
                                    disabled={tableLoading}
                                    onClick={() => {
                                        setShouldRefresh(prevValue => ({
                                            refreshFilterData:
                                                !prevValue.refreshFilterData,
                                            refresh: !prevValue.refresh
                                        }));
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedDashboards.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? 'Dashboard' : 'Dashboards'
                    }`}
                />
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={event => {
                        setSelectedDashboards(event.api.getSelectedRows());
                    }}
                    rowData={dashboards}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>

            {isCreateEditModalOpen && (
                <CreateEditDashboard
                    isModalOpen
                    onClose={() => {
                        setIsCreateEditModalOpen(false);
                        selectedDashboard.current = null;
                    }}
                    onSubmit={handleSubmit}
                    selectedDashboard={selectedDashboard.current}
                    setIsLoading={setIsLoading}
                />
            )}

            {isViewJsonModalOpen && (
                <ViewJsonModal
                    data={selectedDashboard.current as Dashboard}
                    isModalOpen
                    onClose={() => {
                        selectedDashboard.current = null;
                        setIsViewJsonModalOpen(false);
                    }}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteDashboardModal
                    isModalOpen
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                        selectedDashboard.current = null;
                    }}
                    onSuccess={() => {
                        setShouldRefresh(prevValue => ({
                            refresh: !prevValue.refresh,
                            refreshFilterData: !prevValue.refreshFilterData
                        }));
                        setIsDeleteModalOpen(false);
                        selectedDashboard.current = null;
                    }}
                    selectedDashboards={
                        selectedDashboard.current
                            ? [selectedDashboard.current]
                            : selectedDashboards
                    }
                    setIsLoading={setIsLoading}
                />
            )}

            {isPushModalOpen && (
                <PushDashboardModal
                    isModalOpen
                    onClose={() => {
                        selectedDashboard.current = null;
                        setIsPushModalOpen(false);
                    }}
                    onSuccess={() => {
                        selectedDashboard.current = null;
                        setIsPushModalOpen(false);
                    }}
                    selectedDashboards={
                        selectedDashboard.current
                            ? [selectedDashboard.current]
                            : selectedDashboards
                    }
                    setIsLoading={setIsLoading}
                />
            )}
        </>
    );
};

export const DashboardTemplate = IsLoadingHOC(DashboardTemplateComponent);
