import { FC, useState, MouseEventHandler } from 'react';

import { Button, ErrorTri, Plus, TabGroup } from '@armis/armis-ui-library';
import { Divider, useTheme } from '@mui/material';
import { Mention, MentionFeedObjectItem } from 'ckeditor5';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    VARIABLE_USED_IN_EDITOR,
    VARIABLE_USED_IN_EQUATION,
    INVALID_EXPRESSION,
    UNDECLARED_VARIABLE_ERROR,
    NAME_EXISTS,
    MAX_INPUT_ERROR,
    VALID_EXPRESSION
} from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';

import {
    ReportElementErrorType,
    ReportFieldDataContentType,
    TextWithVariablesReportDataContent
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';
import VariableInput from './components/VariableInput';
import { StyledNoInputsLabel } from './components/VariableInput.style';
import {
    StyledButtonContainer,
    StyledContainer,
    StyledEditorContainer
} from './TextWithVariables.style.';
import EditorSection from '../common/EditorSection';
import { VariableInputType } from './components/VariableInput.types';
import {
    getDefaultVariable,
    getNameValidationError,
    getTabErrorState,
    getValueValidationError,
    getVariableStatus,
    highlightVariableError,
    removeVariableError,
    supportedNameRegex,
    validateExpression,
    VARIABLE_PREFIX
} from './utils';
import { getFieldErrorMessage } from '../utils';

type Props = {
    item: TextWithVariablesReportDataContent;
    selectedDashboardIds: any;
    deleteItem: (id: string | undefined) => void;
    reportElementError: ReportElementErrorType;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleError: (
        error: string,
        key: string,
        id: string,
        isPristine?: boolean
    ) => void;
};
type TabKey = 'variables' | 'equations';

const MAX_INPUT = 4;
const errorIcon = <ErrorTri height={16} width={16} />;

const TextWithVariables: FC<Props> = ({
    item,
    selectedDashboardIds,
    deleteItem,
    handleError,
    handleReportElementChange,
    reportElementError
}) => {
    const theme = useTheme();
    const { mode } = theme.palette;
    const [selectedTab, setSelectedTab] = useState<TabKey>('variables');
    const visibleInputs = item.reportElement?.configuration[selectedTab];
    const editorKey = `editor-${item.dragAndDropId}`;
    const valueLabel = selectedTab === 'variables' ? 'ASQ' : 'Expression';
    const itemId = item.dragAndDropId ?? '';
    const inputs = [
        ...item.reportElement.configuration.variables,
        ...item.reportElement.configuration.equations
    ];
    const { isEquationTabError, isVariableTabError } = getTabErrorState(
        item,
        reportElementError
    );

    const getSuggestions = async (
        query: string
    ): Promise<MentionFeedObjectItem[]> => {
        const suggestedList = [
            ...item.reportElement.configuration.variables,
            ...item.reportElement.configuration.equations
        ]
            .filter(
                ({ key }) =>
                    key.length > 0 &&
                    supportedNameRegex.test(key) &&
                    key.includes(query)
            )
            .map(({ key }) => ({
                id: `${VARIABLE_PREFIX}${key}`
            }));

        return new Promise(resolve => {
            resolve(suggestedList);
        });
    };

    const handleAddInput: MouseEventHandler<HTMLButtonElement> = () => {
        const tempState = { ...item };
        const { configuration } = tempState.reportElement;

        if (configuration[selectedTab].length === MAX_INPUT) {
            showToast(
                MAX_INPUT_ERROR.replace('%s', selectedTab),
                TOAST_TYPE.WARNING,
                TOAST_ID
            );
            return;
        }

        configuration[selectedTab] = [
            ...configuration[selectedTab],
            getDefaultVariable()
        ];
        handleReportElementChange(tempState);
    };

    const handleEditorChange = (data: string) => {
        const tempState = { ...item };
        const { usedVariables, undeclaredVariables } = getVariableStatus(
            data,
            inputs.map(input => input.key)
        );
        const errors = reportElementError[itemId] ?? [];

        if (undeclaredVariables.length === 0) {
            // Remove the unused variable/equation error
            errors.forEach(({ colErrorMessage }) => {
                if (colErrorMessage === UNDECLARED_VARIABLE_ERROR) {
                    handleError('', editorKey, itemId);
                }
            });
        }

        // Check for variable/equation used in editor error
        errors.forEach(({ colErrorMessage, colName }) => {
            const usedEditorSnippet = VARIABLE_USED_IN_EDITOR.substring(
                VARIABLE_USED_IN_EDITOR.indexOf('%s') + 2
            );
            const usedEquationSnippet = VARIABLE_USED_IN_EQUATION.substring(
                VARIABLE_USED_IN_EQUATION.indexOf('%s') + 2
            );
            if (colErrorMessage.includes(usedEditorSnippet)) {
                // Check if error still persists
                const name = colErrorMessage.split(' ')[0];
                const index = usedVariables.findIndex(
                    usedVariable => `${VARIABLE_PREFIX}${usedVariable}` === name
                );

                if (index !== -1) {
                    removeVariableError(usedVariables);
                    highlightVariableError(undeclaredVariables);
                    handleError('', editorKey, itemId);
                }
            }
            if (colErrorMessage.includes(usedEquationSnippet)) {
                handleError('', colName, itemId);
            }
        });

        tempState.reportElement.value = data;
        handleReportElementChange(tempState);
    };

    const handleRemoveInput = (inputId: string) => {
        const tempState = { ...item };
        const { configuration } = tempState.reportElement;
        const editorContent = tempState.reportElement.value;
        const inputToBeDeleted = configuration[selectedTab].find(
            ({ id }) => id === inputId
        ) as VariableInputType;
        const inputKey = `${VARIABLE_PREFIX}${inputToBeDeleted.key}`;
        const inputNames = inputs.map(inp => inp.key);
        const { usedVariables } = getVariableStatus(editorContent, inputNames);
        const isUsedInEditor = usedVariables.includes(inputToBeDeleted.key);
        let hasError = false;

        if (isUsedInEditor && inputKey !== VARIABLE_PREFIX) {
            handleError(
                VARIABLE_USED_IN_EDITOR.replace('%s', inputKey),
                editorKey,
                itemId
            );
            highlightVariableError([inputToBeDeleted.key]);
            hasError = true;
        }

        // For variable, it can be used in the equation so validate
        if (selectedTab === 'variables' && inputKey !== VARIABLE_PREFIX) {
            const usedInAnyEquation = configuration.equations.find(
                ({ value }) => value.includes(inputKey)
            );

            if (usedInAnyEquation?.id) {
                handleError(
                    VARIABLE_USED_IN_EQUATION.replace('%s', inputKey),
                    `value-${usedInAnyEquation.id}`,
                    itemId
                );
                hasError = true;
            }
        }

        if (hasError) return;

        // Safe to delete
        configuration[selectedTab] = configuration[selectedTab].filter(
            ({ id }) => id !== inputId
        );

        // Remove errors of deleted inputs
        handleError('', `name-${inputId}`, itemId);
        handleError('', `value-${inputId}`, itemId);

        // Remove name exists error of other inputs
        const errors = reportElementError[itemId] ?? [];
        errors.forEach(error => {
            if (error.colErrorMessage === NAME_EXISTS) {
                const errorElement = inputs.find(
                    inp => `name-${inp.id}` === error.colName
                );
                const updatedInputNames = inputs
                    .filter(inp => inp.id !== inputId)
                    .map(({ key }) => key);
                const occurrenceCount = updatedInputNames.filter(
                    name => name === errorElement?.key
                ).length;

                if (occurrenceCount === 1) {
                    handleError('', `name-${errorElement?.id}`, itemId);
                }
            }
        });

        handleReportElementChange(tempState);
    };

    const handleNameChangeError = (element: VariableInputType) => {
        const valueKey = `value-${element.id}`;
        const nameKey = `name-${element.id}`;
        const valueError = getFieldErrorMessage(
            reportElementError,
            itemId,
            valueKey
        );
        // Check basic error validation
        const errorMessage = getNameValidationError(element, inputs);
        handleError(errorMessage, nameKey, itemId);

        // Set pristine error for it's value if length is zero
        if (element.value.length === 0 && valueError.length === 0) {
            handleError(
                getValueValidationError(element, valueLabel),
                valueKey,
                itemId,
                true
            );
        }

        // Handle errors for variable rename
        const content = item.reportElement.value;
        const inputNames = inputs.map(inp =>
            inp.id === element.id ? element.key : inp.key
        );
        const { usedVariables, undeclaredVariables } = getVariableStatus(
            content,
            inputNames
        );

        if (undeclaredVariables.length) {
            // highlight the variable references in the editor
            removeVariableError(usedVariables);
            highlightVariableError(undeclaredVariables);
            handleError(UNDECLARED_VARIABLE_ERROR, editorKey, itemId);
        }

        // Syntax checking for expressions
        const updatedVariables = item.reportElement.configuration.variables.map(
            variable => (variable.id === element.id ? element : variable)
        );
        const { equations } = item.reportElement.configuration;
        equations.forEach(equation => {
            const validationResult = validateExpression(
                equation.value,
                updatedVariables
            );

            if (validationResult.startsWith(INVALID_EXPRESSION)) {
                // There is an error in the expression as variable name is changed
                handleError(validationResult, `value-${equation.id}`, itemId);
            }
        });

        // Remove errors from other inputs when not required
        const errors = reportElementError[itemId] ?? [];
        errors.forEach(error => {
            if (error.colErrorMessage === NAME_EXISTS) {
                const errorElement = inputs.find(
                    inp => `name-${inp.id}` === error.colName
                );
                // Only remove other inputs error
                if (errorElement?.id !== element.id) {
                    const occurrenceCount = inputNames.filter(
                        name => name === errorElement?.key
                    ).length;

                    if (occurrenceCount === 1) {
                        handleError('', `name-${errorElement?.id}`, itemId);
                    }
                }
            }
            if (error.colErrorMessage === UNDECLARED_VARIABLE_ERROR) {
                if (
                    error.colName === editorKey &&
                    undeclaredVariables.length === 0
                ) {
                    handleError('', editorKey, itemId);
                    removeVariableError(usedVariables);
                }
            }
            if (error.colErrorMessage.startsWith(INVALID_EXPRESSION)) {
                const errorElement = inputs.find(
                    inp => `value-${inp.id}` === error.colName
                );
                if (errorElement && errorElement?.id !== element.id) {
                    const validationResult = validateExpression(
                        errorElement.value,
                        updatedVariables
                    );

                    if (validationResult === VALID_EXPRESSION) {
                        handleError('', `value-${errorElement.id}`, itemId);
                    }
                }
            }
        });
    };

    const handleValueChangeError = (element: VariableInputType) => {
        const valueKey = `value-${element.id}`;
        const nameKey = `name-${element.id}`;
        const nameError = getFieldErrorMessage(
            reportElementError,
            itemId,
            nameKey
        );
        // Check basic error validation
        const errorMessage = getValueValidationError(element, valueLabel);
        handleError(errorMessage, valueKey, itemId);

        // Set pristine error for key if length is zero
        if (element.key.length === 0 && nameError.length === 0) {
            handleError(
                getNameValidationError(element, inputs),
                nameKey,
                itemId,
                true
            );
        }

        // Check for syntax error
        if (selectedTab === 'equations' && element.value.trim().length) {
            const validationResult = validateExpression(
                element.value,
                item.reportElement.configuration.variables
            );

            if (validationResult.startsWith(INVALID_EXPRESSION)) {
                handleError(validationResult, `value-${element?.id}`, itemId);
            } else if (element.value) {
                handleError('', `value-${element?.id}`, itemId);
            }
        }
    };

    const handleInputChange = (
        inputType: 'key' | 'value',
        input: VariableInputType
    ) => {
        // Validations
        if (inputType === 'key') {
            handleNameChangeError(input);
        } else {
            handleValueChangeError(input);
        }

        const tempState = { ...item };
        tempState.reportElement.configuration[selectedTab] =
            tempState.reportElement.configuration[selectedTab].map(element =>
                element.id === input.id ? input : element
            );

        handleReportElementChange(tempState);
    };

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Text With Variables"
        >
            <StyledContainer>
                <TabGroup
                    onChange={(_, value) => {
                        setSelectedTab(value);
                    }}
                    scrollButtons={false}
                    tabsItems={[
                        {
                            label: 'Variables',
                            tabValue: 'variables',
                            ...(isVariableTabError && {
                                icon: errorIcon,
                                iconPosition: 'start'
                            })
                        },
                        {
                            label: 'Equations',
                            tabValue: 'equations',
                            ...(isEquationTabError && {
                                icon: errorIcon,
                                iconPosition: 'start'
                            })
                        }
                    ]}
                    value={selectedTab}
                />
                <StyledButtonContainer>
                    <Button
                        onClick={handleAddInput}
                        startIcon={<Plus className="plus-icon" />}
                        style={{ background: mode === 'light' ? 'white' : '' }}
                        variant="outlined"
                    >
                        Add
                    </Button>
                </StyledButtonContainer>
            </StyledContainer>

            {visibleInputs.length === 0 && (
                <StyledNoInputsLabel>
                    No {selectedTab} declared.
                </StyledNoInputsLabel>
            )}

            {visibleInputs.map(input => (
                <VariableInput
                    key={input.id}
                    handleChange={handleInputChange}
                    handleDelete={handleRemoveInput}
                    input={input}
                    item={item}
                    keyLabel="Name"
                    reportElementError={reportElementError}
                    valueLabel={valueLabel}
                />
            ))}

            <Divider style={{ marginTop: 20 }} />

            <StyledEditorContainer>
                <EditorSection
                    editorConfig={{
                        extraPlugins: [Mention],
                        mention: {
                            feeds: [
                                {
                                    marker: VARIABLE_PREFIX,
                                    feed: getSuggestions
                                }
                            ]
                        }
                    }}
                    id={itemId}
                    itemKey={editorKey}
                    onChange={handleEditorChange}
                    reportElementError={reportElementError}
                    value={item.reportElement?.value ?? ''}
                />
            </StyledEditorContainer>
        </ReportElementContainer>
    );
};

export default TextWithVariables;
