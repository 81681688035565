import { Controller } from 'react-hook-form';

import { inValidNumberError, showAsList } from './utils';
import NumberSection from '../../common/NumberSection';
import SelectSection from '../../common/SelectSection';
import TextAreaSection from '../../common/TextAreaSection';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';

const NumberColumn = () => {
    const { activeColumn, formData } = useDashboardConfigContext();
    const { control, setError } = formData;

    return (
        <>
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.showAs`}
                render={({ field, fieldState: { error } }) => (
                    <SelectSection
                        error={error?.message}
                        fullWidth
                        handleChange={field.onChange}
                        items={showAsList}
                        placeholder="Select value"
                        title="Show As"
                        value={field.value || ''}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.mediumThreshold`}
                render={({ field, fieldState: { error } }) => (
                    <NumberSection
                        error={error?.message}
                        handleChange={val => {
                            const numberValue = parseInt(val, 10);
                            if (Object.is(numberValue, NaN)) {
                                setError(
                                    `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.mediumThreshold`,
                                    inValidNumberError
                                );
                            } else {
                                field.onChange(numberValue);
                            }
                        }}
                        title="Yellow Threshold"
                        value={field.value}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.highThreshold`}
                render={({ field, fieldState: { error } }) => (
                    <NumberSection
                        error={error?.message}
                        handleChange={val => {
                            const numberValue = parseInt(val, 10);
                            if (Object.is(numberValue, NaN)) {
                                setError(
                                    `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.highThreshold`,
                                    inValidNumberError
                                );
                            } else {
                                field.onChange(numberValue);
                            }
                        }}
                        title="Red Threshold"
                        value={field.value}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto.asq`}
                render={({ field, fieldState: { error } }) => (
                    <TextAreaSection
                        error={error?.message}
                        handleChange={field.onChange}
                        title="ASQ"
                        value={field.value}
                    />
                )}
            />
        </>
    );
};

export default NumberColumn;
