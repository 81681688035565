import { Tooltip } from '@armis/armis-ui-library';

import ConfigDrawer from './ConfigDrawer';
import {
    StyledEllipsisTypography,
    StyledHeader,
    StyledPanelTitle
} from './PanelHeader.style';
import { DashboardConfigProvider } from '../../context/DashboardConfigContext';
import { useDashboardContext } from '../../context/DashboardContext';

const PanelHeader = () => {
    const { selectedTab } = useDashboardContext();

    return (
        <DashboardConfigProvider>
            <StyledHeader>
                <StyledPanelTitle className="content" variant="h6">
                    <Tooltip
                        arrow
                        placement="bottom"
                        title={
                            <div className="tooltip-arrow-text">
                                {selectedTab?.name}
                            </div>
                        }
                    >
                        <StyledEllipsisTypography>
                            {selectedTab?.name}
                        </StyledEllipsisTypography>
                    </Tooltip>
                </StyledPanelTitle>
                <ConfigDrawer />
            </StyledHeader>
        </DashboardConfigProvider>
    );
};

export default PanelHeader;
