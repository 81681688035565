import { FormHelperText } from '@mui/material';
import { ColDef, RowDragEvent } from 'ag-grid-community';

import { defaultTableColDefs } from '../data';
import { StyledTable, StyledTableContainer } from './DraggableTable.style';

type DraggableTableProps<T> = {
    rowsData: T[];
    columnDefs: ColDef[];
    error: string;
    handleTableChange: (rowsData: T[]) => void;
};

const DraggableTable = <T,>({
    rowsData,
    columnDefs,
    error,
    handleTableChange
}: DraggableTableProps<T>) => {
    const onRowDragEnd = (event: RowDragEvent<any>) => {
        const updatedData: T[] = [];
        event.api.forEachNode(node => {
            updatedData.push(node.data);
        });

        handleTableChange(updatedData);
    };

    return (
        <StyledTableContainer contentEditable>
            <StyledTableContainer contentEditable={false} hasError={!!error}>
                <StyledTable
                    ref={null}
                    animateRows
                    columnDefs={columnDefs}
                    defaultColDef={defaultTableColDefs}
                    enableCellTextSelection={false}
                    ensureDomOrder={false}
                    onRowDragEnd={onRowDragEnd}
                    onRowDragLeave={onRowDragEnd}
                    rowData={rowsData}
                    rowDragManaged
                    suppressMovableColumns
                />
                {!!error && (
                    <FormHelperText sx={{ color: '#e31c3d' }}>
                        {error}
                    </FormHelperText>
                )}
            </StyledTableContainer>
        </StyledTableContainer>
    );
};

export default DraggableTable;
