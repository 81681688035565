/* eslint-disable no-useless-escape */
import React from 'react';

import {
    toastHook,
    updateToastHook,
    originalToast,
    CHECKBOX_STATUS
} from '@armis/armis-ui-library';
import { AxiosError } from 'axios';
import cronstrue from 'cronstrue';
import moment from 'moment-timezone';
import { TypeOptions } from 'react-toastify';
import { TOAST_ID } from 'src/constants/APIConstants';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    CRON_RANGE_INVALID_SECONDS,
    CRON_RANGE_INVALID_MINUTES,
    CRON_RANGE_INVALID_HOURS,
    REQUIRED,
    MIN_VALUE_INVALID,
    MAX_VALUE_INVALID,
    INVALID_VALUE_INSERT,
    MAX_LENGTH_INVALID,
    TIMEOUT_ERROR,
    INVALID_URL,
    RETENTION_RANGE_INVALID_COUNT,
    RETENTION_RANGE_INVALID_DAYS
} from 'src/constants/LabelText';
import { REPEAT_OPTIONS_MAP } from 'src/pages/containers/ReportTemplate/constants';
import { ErrorResponse, User } from 'src/types/APIResponseTypes';
import {
    APICallType,
    FieldType,
    FieldValidationError,
    FieldValidationType,
    FilterItems,
    Map,
    ModalFieldType
} from 'src/types/CommonTypes';

import { Validators } from './Validators';

export interface DecodeReturnType {
    value: string;
    scale: string;
}
/**
 * Code decode strucutre interface type
 */
export interface CronDecodeStructure {
    scale: string;
    pattern: string;
    getValueAndScale: (cronScheduler: string) => DecodeReturnType;
}
/**
 * Toast type mapping
 */
export const TOAST_TYPE = {
    SUCCESS: 'success' as 'success',
    ERROR: 'error' as 'error',
    INFO: 'info' as 'info',
    WARNING: 'warning' as 'warning',
    DEFAULT: 'default' as 'default'
};

/**
 * Cron scales mapping
 */
export const CRON_SCALES = {
    SECONDS: 'seconds',
    MINUTES: 'minutes',
    HOURS: 'hours'
};

export const RETENTION_TYPES = {
    COUNT: 'count',
    DAYS: 'days'
};

export const RETENTION_HELPER_TEXT = {
    [RETENTION_TYPES.COUNT]: RETENTION_RANGE_INVALID_COUNT,
    [RETENTION_TYPES.DAYS]: RETENTION_RANGE_INVALID_DAYS
};

/**
 * Cron decode regex mapping with function.
 */
export const CRON_DECODE_REGEX: CronDecodeStructure[] = [
    {
        scale: CRON_SCALES.SECONDS,
        pattern: `Every[ ][\\d][\\d]? second[s]?`,
        getValueAndScale: (cronScheduler: string) => ({
            value: cronScheduler,
            scale: CRON_SCALES.SECONDS
        })
    },
    {
        scale: CRON_SCALES.MINUTES,
        pattern: `Every[ ]?[\\d]?[\\d]? minute[s]?`,
        getValueAndScale: (cronScheduler: string) => ({
            value: cronScheduler,
            scale: CRON_SCALES.MINUTES
        })
    },
    {
        scale: CRON_SCALES.HOURS,
        pattern: `[E-e]very[ ]?[\\d]?[\\d]? hour[s]?`,
        getValueAndScale: (cronScheduler: string) => ({
            value: cronScheduler,
            scale: CRON_SCALES.HOURS
        })
    }
];

let toastIdValue: string | null = null;

export const showToast = (
    content: string,
    type: TypeOptions,
    toastId: string
) => {
    if (toastIdValue === null || !originalToast.isActive(toastIdValue)) {
        toastHook({
            content,
            options: {
                type,
                toastId
            }
        });
    } else {
        updateToastHook({
            toastId,
            options: {
                render: content,
                type
            }
        });
    }
    toastIdValue = toastId;
};

export const getDirectionsAndProperties = (
    columnSortOrder: Map<number>,
    columnSortStatus: Map<string>
) => {
    let directions = '';
    let properties = '';
    const keysSorted = Object.keys(columnSortOrder).sort(
        (a, b) => columnSortOrder[a] - columnSortOrder[b]
    );
    keysSorted.forEach(element => {
        if (columnSortOrder[element] >= 0) {
            directions += `${columnSortStatus[element]},`;
            properties += `${element},`;
        }
    });
    directions = directions.slice(0, -1);
    properties = properties.slice(0, -1);
    return {
        directions,
        properties
    };
};

export const displayErrorMessage = (err: AxiosError<ErrorResponse>) => {
    if (['ERR_CANCELED'].includes(err?.code || '')) {
        return;
    }

    if (err.response) {
        showToast(err.response.data?.message!, TOAST_TYPE.ERROR, TOAST_ID);
    } else if (err.code === 'ECONNABORTED') {
        showToast(TIMEOUT_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
    } else if (err.message) {
        showToast(err.message, TOAST_TYPE.ERROR, TOAST_ID);
    }
};

export const convertQueryObjectToParams = (queryObj: APICallType) => {
    let queryParams = '';
    Object.entries(queryObj).forEach(([key, value], index, array) => {
        queryParams += `${key}=${encodeURIComponent(value)}${
            index === array.length - 1 ? '' : '&'
        }`;
    });
    return queryParams;
};

/**
 * This function creates cron expression based on the value and scale selected.
 * @param value Cron string value
 * @param scaleType Cron scale type
 * @returns string
 */
export const cronGenerator = (
    value: string,
    scaleType: string | undefined | unknown
) => {
    switch (scaleType) {
        case CRON_SCALES.SECONDS:
            return `0/${value} * * * * ? *`;
        case CRON_SCALES.MINUTES:
            return `0 */${value} * * * ? *`;
        case CRON_SCALES.HOURS:
            return value === '1'
                ? `0 0 0/${value} ? * * *`
                : `* * 0/${value} ? * * *`;
        default:
            return '* * * * * ?';
    }
};

/**
 * This function decodes cron expression from params and returs object of value and scale.
 * @param schedulerString cron expression as string
 * @returns {value: string, scale: string}
 */
export const cronDecoder = (schedulerString: string) => {
    if (schedulerString) {
        try {
            const readableCron = cronstrue.toString(schedulerString);
            const selectedCronScale = CRON_DECODE_REGEX.find(
                cronScale => readableCron.match(cronScale.pattern)?.length! > 0
            );
            if (selectedCronScale)
                return selectedCronScale.getValueAndScale(
                    readableCron.match(/\d[\d]?/)?.toString() || '1'
                );
            return {
                value: '',
                scale: ''
            };
        } catch (_) {
            return {
                value: '',
                scale: ''
            };
        }
    }
    return {
        value: '',
        scale: ''
    };
};

export const sortPropertyObject = (a: any, b: any) =>
    a.name > b.name ? 1 : -1;

export const SCALE_HELPER_TEXT = {
    [CRON_SCALES.SECONDS]: CRON_RANGE_INVALID_SECONDS,
    [CRON_SCALES.MINUTES]: CRON_RANGE_INVALID_MINUTES,
    [CRON_SCALES.HOURS]: CRON_RANGE_INVALID_HOURS
};

/**
 * This function will check the validations for the input fields from params and return object of error and helptext.
 * @param value value as string
 * @param validations validations as a FieldValidationType
 * @param fieldType as a string
 * @returns {error: boolean, helptext: string}
 */
export const validateValues = (
    value: string,
    validations: FieldValidationType,
    fieldType: string
) => {
    const cronObject = cronDecoder(value);
    if (validations.required && !Validators.validateNotEmpty(value))
        return {
            error: true,
            helperText: REQUIRED
        } as FieldValidationError;
    if (
        validations.minValue &&
        Validators.validateMinValue(+value, Number(validations.minValue)) &&
        fieldType !== FIELD_TYPE_MAP.SCHEDULER
    )
        return {
            error: true,
            helperText: MIN_VALUE_INVALID.replace(
                '%s',
                validations.minValue.toString()
            )
        } as FieldValidationError;
    if (
        validations.maxValue &&
        Validators.validateMaxValue(+value, validations.maxValue) &&
        fieldType !== FIELD_TYPE_MAP.SCHEDULER
    )
        return {
            error: true,
            helperText: MAX_VALUE_INVALID.replace(
                '%s',
                validations.maxValue.toString()
            )
        } as FieldValidationError;
    if (
        validations.regex &&
        !Validators.validateAgainstFormat(value, validations.regex)
    )
        return {
            error: true,
            helperText: INVALID_VALUE_INSERT
        } as FieldValidationError;
    if (
        validations.scaleMinValue &&
        validations.scaleMaxValue &&
        !Validators.validateNumberRange(
            validations.scaleMinValue(cronObject.scale),
            validations.scaleMaxValue(cronObject.scale),
            Number(cronObject.value)
        ) &&
        fieldType === FIELD_TYPE_MAP.SCHEDULER
    ) {
        return {
            error: true,
            helperText: SCALE_HELPER_TEXT[cronObject?.scale]
        } as FieldValidationError;
    }
    if (
        value &&
        validations.maxLength &&
        !Validators.validateMaxLength(value, validations.maxLength)
    ) {
        return {
            error: true,
            helperText: MAX_LENGTH_INVALID.replace(
                '%s',
                validations.maxLength.toString()
            )
        } as FieldValidationError;
    }
    if (value && validations.validUrl && !Validators.validateUrl(value)) {
        return {
            error: true,
            helperText: INVALID_URL
        };
    }
    return {
        error: false,
        helperText: ''
    } as FieldValidationError;
};

export const isFieldHavingError = (field: string, error: any) =>
    error?.[field]?.error ?? false;

export const isActionHasPermissions = (
    user: User,
    resource: string,
    hasPermissions: string[]
) => {
    const findResource = user.resources.find(r => r.name === resource);
    return hasPermissions.some(permission =>
        findResource?.privilegeNames.includes(permission)
    );
};

export const onFieldChangeHandler = (
    fieldValue: string,
    labelName: string,
    type: string,
    actionFormMetaData: FieldType[],
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>,
    modalFields: ModalFieldType,
    startTransition: React.TransitionStartFunction
) => {
    if (type === FIELD_TYPE_MAP.TEXT || type === FIELD_TYPE_MAP.TEXTAREA) {
        setModalFields({
            ...modalFields,
            [labelName]: {
                ...modalFields[labelName],
                value: fieldValue
            }
        });
        const fieldObject = actionFormMetaData.find(
            (element: any) => element.labelName === labelName
        )!;
        const { error, helperText } = validateValues(
            fieldValue,
            fieldObject.validations,
            fieldObject.type
        );
        startTransition(() => {
            setModalFields({
                ...modalFields,
                [labelName]: {
                    ...modalFields[labelName],
                    value: fieldValue,
                    error,
                    helperText
                }
            });
        });
    } else if (type === FIELD_TYPE_MAP.DROPDOWN) {
        setModalFields({
            ...modalFields,
            [labelName]: {
                ...modalFields[labelName],
                value: Number(fieldValue)
            }
        });
    }
};

export const validateFormFields = (
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>,
    modalFields: ModalFieldType,
    actionFormMetaData: FieldType[]
) => {
    let thereIsError = false;
    let fieldsObject = {};
    actionFormMetaData.forEach(({ labelName, type, validations }) => {
        if (type === FIELD_TYPE_MAP.TEXT || type === FIELD_TYPE_MAP.TEXTAREA) {
            const fieldValue = modalFields[labelName].value;
            const { error, helperText } = validateValues(
                fieldValue as string,
                validations!,
                type
            );
            if (error) {
                thereIsError = true;
                fieldsObject = {
                    ...fieldsObject,
                    [labelName]: {
                        value: fieldValue,
                        error,
                        helperText
                    }
                };
            }
        }
    });
    if (thereIsError) {
        setModalFields({
            ...modalFields,
            ...fieldsObject
        });
    }
    return thereIsError;
};

type Interval = 'Days' | 'Weeks' | 'Months';
type Time = { hour: number; minute: number };

// Day mapping for both generator and decoder
const daysMap: { [key: string]: string } = {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday'
};

/**
 * Create Cron based on input given
 * @param repeatEvery Number
 * @param interval interval e.g. 'Days' | 'Weeks' | 'Months'
 * @param weekDays Array of string - e.g. ["Monday", "Wednesday"]
 * @param monthDay Month day as number
 * @param time time as object { hour: 0, minute:0}
 * @returns
 */

export const generateCronWithConfigration = (
    repeatEvery: number,
    interval: Interval,
    weekDays: string[] = [],
    monthDay: number | null = null,
    time: Time = { hour: 0, minute: 0 }
): string => {
    // Convert the user's local time to UTC
    const localMoment = moment.tz(
        { hour: time.hour, minute: time.minute },
        moment.tz.guess()
    );
    const utcMoment = localMoment.clone().utc(); // Convert to UTC

    // Default cron fields
    const cronMinute = utcMoment.minute().toString();
    const cronHour = utcMoment.hour().toString();
    let cronDayOfMonth = '*';
    let cronMonth = '*';
    let cronDayOfWeek = '*';

    if (interval === 'Days') {
        // Repeat every X days
        cronDayOfMonth = `*/${repeatEvery}`;
    } else if (interval === 'Weeks') {
        // Repeat every X weeks on specified days
        cronDayOfWeek = weekDays
            .map(day => Object.keys(daysMap).find(key => daysMap[key] === day))
            .join(',');
        cronDayOfMonth = `*/${repeatEvery * 7}`; // Set day interval in terms of days
    } else if (interval === 'Months') {
        // Repeat every X months on specified day
        cronDayOfMonth = monthDay !== null ? monthDay.toString() : '*';
        cronMonth = `*/${repeatEvery}`;
    }

    // Construct cron expression
    return `${cronMinute} ${cronHour} ${cronDayOfMonth} ${cronMonth} ${cronDayOfWeek}`;
};

/**
 * Decode to retrive original values from cron
 * @param cronExpression
 * @returns
 */
export const decodeCronWithConfigration = (
    cronExpression: string
): {
    repeatEvery: number;
    interval: Interval;
    weekDays?: string[];
    monthDay?: number | null;
    time: Time;
} | null => {
    const [, minute, hour, dayOfMonth, month, dayOfWeek] =
        cronExpression.split(' ');
    // Convert UTC cron time back to local time
    const utcMoment = moment.utc().set({
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
        second: 0
    });
    const localMoment = utcMoment.clone().tz(moment.tz.guess());

    let interval: Interval;
    let repeatEvery = 1;
    let weekDays: string[] | undefined;
    let monthDay: number | null = null;

    // Determine the interval based on fields
    if (dayOfMonth.startsWith('*/') && month === '*' && dayOfWeek === '*') {
        interval = 'Days';
        repeatEvery = parseInt(dayOfMonth.slice(2), 10);
    } else if (month.startsWith('*/') && dayOfMonth !== '*') {
        interval = 'Months';
        repeatEvery = parseInt(month.slice(2), 10);
        monthDay = parseInt(dayOfMonth, 10);
    } else if (dayOfWeek !== '*') {
        interval = 'Weeks';
        weekDays = dayOfWeek.split(',').map(day => daysMap[day] || day);
        repeatEvery = dayOfMonth.startsWith('*/')
            ? parseInt(dayOfMonth.slice(2), 10) / 7
            : 1;
    } else {
        // For invalid cron pattern, it will be reverted to default values.
        return {
            repeatEvery: 1,
            interval: REPEAT_OPTIONS_MAP.WEEKS,
            weekDays: [],
            monthDay: null,
            time: { hour: 9, minute: 0 }
        };
    }

    // Parse time fields
    const time: Time = {
        hour: localMoment.hour(),
        minute: localMoment.minute()
    };

    // Return decoded arguments
    return {
        repeatEvery,
        interval,
        ...(weekDays && { weekDays }),
        monthDay,
        time
    };
};

export const updateSelectedItems = (
    selectedItems: string[],
    items: FilterItems[],
    setItemsCount: React.Dispatch<React.SetStateAction<string[]>>,
    setItems: React.Dispatch<React.SetStateAction<FilterItems[]>>,
    onUpdateParent: (value: string[]) => void,
    selectText: string,
    allText: string,
    totalSelectedText: string
) => {
    const totalSelected = selectedItems.length;

    if (totalSelected === 0) {
        setItemsCount([selectText]);
    } else if (totalSelected === items.length) {
        setItemsCount([allText]);
    } else {
        setItemsCount([
            totalSelectedText.replace('%s', totalSelected.toString())
        ]);
    }

    onUpdateParent(selectedItems);

    const updatedItems = items.map(item =>
        selectedItems.find(uItem => uItem === item.id)
            ? { ...item, checkStatus: CHECKBOX_STATUS.CHECKED }
            : { ...item, checkStatus: CHECKBOX_STATUS.UNCHECKED }
    );

    setItems(updatedItems);
};
