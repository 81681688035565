import {
    Button,
    constants,
    CrossIcon,
    IconButton,
    Upload
} from '@armis/armis-ui-library';
import { FormHelperText, FormLabel } from '@mui/material';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    ONLY_FILE_ALLOWED,
    SIZE_UNDER_2MB,
    UPLOAD_FILE,
    IMAGE_REQUIRED
} from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { axiosConfig } from 'src/services/axiosInstance';

import {
    StyledField,
    SupportedImageLabel,
    VisuallyHiddenInput
} from '../CreateReport.style';
import { supportedImageTypes } from '../utils';
import { StyledContainer } from './UploadImageSection.style';

type UploadImageSectionProps = {
    title: string;
    fileName: string;
    file: File | undefined;
    isRequired?: boolean;
    handleError?: (error: string) => void;
    handleChange: (value: File | undefined) => void;
    error?: string;
};

const UploadImageSection = ({
    title,
    fileName,
    file,
    isRequired,
    handleError,
    handleChange,
    error = ''
}: UploadImageSectionProps) => {
    const handleImageChange = (value: File | undefined) => {
        if (isRequired && !value) {
            handleError?.(IMAGE_REQUIRED);
        }
        if (!value) return;

        const { type, size } = value;

        if (supportedImageTypes.includes(type)) {
            if (size / 1024 ** 2 > 2) {
                showToast(SIZE_UNDER_2MB, TOAST_TYPE.ERROR, TOAST_ID);
            } else {
                handleChange(value);
                handleError?.('');
            }
        } else {
            showToast(ONLY_FILE_ALLOWED, TOAST_TYPE.ERROR, TOAST_ID);
        }
    };

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <div
                style={{
                    display: 'flex'
                }}
            >
                <IconButton className="Icon-Hover-Effect">
                    <Button
                        className="control svg-symbol-button transition theme-5"
                        color="primary"
                        component="label"
                        startIcon={<Upload />}
                        variant="text"
                    >
                        {UPLOAD_FILE}
                        <VisuallyHiddenInput
                            accept=".png,.jpg,.jpeg"
                            onChange={e => {
                                handleImageChange(e.target.files?.[0]);
                                e.target.value = '';
                            }}
                            type="file"
                        />
                    </Button>
                </IconButton>
                <StyledContainer>
                    {fileName && (
                        <a
                            className="file-name-box"
                            href={`${
                                axiosConfig.baseURL
                            }/reports/logo/download?name=${encodeURIComponent(
                                fileName
                            )}`}
                        >
                            {fileName}
                        </a>
                    )}
                    {!fileName && file && (
                        <div className="file-name-box">{file.name}</div>
                    )}
                    {(fileName || file) && (
                        <IconButton
                            className="Icon-Hover-Effect"
                            onClick={() => {
                                handleChange(undefined);
                                if (isRequired) {
                                    handleError?.(IMAGE_REQUIRED);
                                }
                            }}
                        >
                            <CrossIcon />
                        </IconButton>
                    )}
                </StyledContainer>
            </div>
            <SupportedImageLabel>
                File Support: JPG, JPEG and PNG upto 2 MB
            </SupportedImageLabel>
            {error && (
                <FormHelperText
                    sx={{ color: constants.COLOR_5, marginLeft: '10px' }}
                >
                    {error}
                </FormHelperText>
            )}
        </StyledField>
    );
};

export default UploadImageSection;
