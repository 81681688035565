import React, { useEffect, useState } from 'react';

import { constants } from '@armis/armis-ui-library';
import { css, styled } from '@mui/material';
import moment from 'moment';
import InputMask from 'react-input-mask';

const STRING_FORMAT = 'hh:mm';

const StyledDivAMPM = styled('div')`
    display: flex;
    width: 74px;
    height: 24px;
    border: 1px solid ${constants.COLOR_PURPLE};
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 1.4rem;
`;

const StyledContainer = styled('div')`
    font-size: 14px;
    display: flex;
    margin-top: 8px;
`;

const StyledPicker = styled(InputMask)`
    width: 60px;
    height: 24px;
    margin-right: 8px;
    border: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_4
                : constants.COLOR_GENERAL_7};
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    box-shadow: none;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_29
            : constants.COLOR_WHITE};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_WHITE
            : constants.COLOR_GENERAL_29};
`;

const StyledItem = styled('span')<{ selected?: boolean }>`
    flex: 1;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${props =>
        props.selected &&
        css`
            color: ${props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_25
                : constants.COLOR_WHITE};
            background-color: ${constants.COLOR_PURPLE};
            pointer-events: none;
        `}
    &:active {
        color: ${constants.COLOR_PURPLE};
        background-color: ${constants.COLOR_1_LIGHT};
    }
`;
export const TimePicker = ({
    value,
    onChange
}: {
    value: any;
    onChange: any;
}) => {
    const time = moment(value);

    const [timeString, setTimeString] = useState(time.format(STRING_FORMAT));
    const isPm = moment.localeData().isPM(time.format('a'));

    const mask = '12:34';
    const formatChars = { 1: '[0-2]', 2: '[0-9]', 3: '[0-5]', 4: '[0-9]' };
    const beforeMaskedValueChange = (newState: any) => {
        formatChars['2'] = newState.value.startsWith('2') ? '[0-3]' : '[0-9]'; // To block 24, 25, etc.
        return { value: newState.value, selection: newState.selection };
    };

    const resetTimeString = () => {
        setTimeString(time.format(STRING_FORMAT));
    };

    const reportTimeChange = (newTime: moment.Moment) => {
        const { hours: hour, minutes: minute } = newTime.toObject();
        onChange({ hour, minute });
    };

    const reportTimeStringChange = () => {
        let newTime = moment(timeString, STRING_FORMAT);
        if (newTime.isValid()) {
            const { hours: hour } = newTime.toObject();
            if (isPm && hour < 12) {
                newTime = newTime.add(12, 'hours');
            }
            reportTimeChange(newTime);
        }
    };

    const onAmClick = () => {
        reportTimeChange(time.subtract(12, 'hours'));
    };

    const onPmClick = () => {
        reportTimeChange(time.add(12, 'hours'));
    };

    useEffect(resetTimeString, [value]);

    return (
        <StyledContainer>
            <StyledPicker
                beforeMaskedValueChange={beforeMaskedValueChange}
                formatChars={formatChars}
                mask={mask}
                onBlur={reportTimeStringChange}
                onChange={(e: any) => setTimeString(e.target.value)}
                value={timeString}
            />
            <StyledDivAMPM>
                <StyledItem onClick={onAmClick} selected={!isPm}>
                    AM
                </StyledItem>
                <StyledItem onClick={onPmClick} selected={isPm}>
                    PM
                </StyledItem>
            </StyledDivAMPM>
        </StyledContainer>
    );
};
