import { MutableRefObject } from 'react';

import { cloneDeep } from 'lodash';

import TableSection from './components/TableSection';
import { RowData, TableCheckBoxField, TableTextField } from './utils';
import CheckBoxSection from '../common/CheckBoxSection';
import ConditionalDescriptionSection from '../common/ConditionalDescriptionSection';
import DescriptionSection from '../common/DescriptionSection';
import TextFieldSection from '../common/TextFieldSection';
import {
    ReportElementErrorType,
    ReportFieldDataContentType,
    TableReportDataContentType
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';
import { getFieldErrorMessage, EMPTY_TABLE_ERROR } from '../utils';

type TableReportProps = {
    item: TableReportDataContentType;
    selectedDashboardIds: any;
    reportElementError: ReportElementErrorType;
    hasOtherTableInclude: boolean;
    tableSummaryDragIdRef: MutableRefObject<string>;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleTableTitleChange: (value: string) => void;
    handleError: (error: string, key: string, id: string) => void;
    deleteItem: (id: string | undefined) => void;
};

const TableReport = ({
    item,
    selectedDashboardIds,
    reportElementError,
    hasOtherTableInclude,
    tableSummaryDragIdRef,
    handleTableTitleChange,
    handleReportElementChange,
    handleError,
    deleteItem
}: TableReportProps) => {
    const id = item.dragAndDropId ?? '';

    const tableTitleError = getFieldErrorMessage(
        reportElementError,
        id,
        'table-exist'
    );

    const handleCheckBoxChange = (key: TableCheckBoxField, value: boolean) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration[key] = value;
        handleReportElementChange(updatedItem);
    };

    const handleTextChange = (key: TableTextField, value: string) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration[key] = value;
        handleReportElementChange(updatedItem);
    };

    const handleTableChange = (rowData: RowData[]) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration.table = rowData;
        handleReportElementChange(updatedItem);
    };

    const handleTableReportElementUpdate = (value: boolean) => {
        const hasTableEntry = value || hasOtherTableInclude;

        if (tableSummaryDragIdRef.current) {
            if (hasTableEntry) {
                handleError('', 'tables', tableSummaryDragIdRef.current);
            } else {
                handleError(
                    EMPTY_TABLE_ERROR,
                    'tables',
                    tableSummaryDragIdRef.current
                );
            }
        }
    };

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Table"
        >
            <CheckBoxSection
                handleChange={value =>
                    handleCheckBoxChange(TableCheckBoxField.HIDE, value)
                }
                isChecked={item.reportElement.configuration.hideIfSumZero}
                title="Hide the table if sum count is zero"
            />
            <TextFieldSection
                errorMessage={tableTitleError}
                handleChange={value => {
                    handleTextChange(TableTextField.TITLE, value);
                    handleTableTitleChange(value);
                }}
                handleError={error => {
                    handleError(error, TableTextField.TITLE, id);
                }}
                id={id}
                itemKey={TableTextField.TITLE}
                reportElementError={reportElementError}
                title="Title"
                value={item.reportElement.configuration.title}
            />
            <DescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.BEFORE, value)
                }
                handleError={error =>
                    handleError(error, TableTextField.BEFORE, id)
                }
                id={id}
                itemKey={TableTextField.BEFORE}
                reportElementError={reportElementError}
                title="Pre Description"
                value={item.reportElement.configuration.before}
            />
            <TableSection
                handleError={error => handleError(error, 'table', id)}
                handleTableChange={handleTableChange}
                id={id}
                itemKey="table"
                reportElementError={reportElementError}
                rowsData={item.reportElement.configuration.table}
            />
            <DescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.AFTER, value)
                }
                handleError={error =>
                    handleError(error, TableTextField.AFTER, id)
                }
                id={id}
                itemKey={TableTextField.AFTER}
                reportElementError={reportElementError}
                title="Post Description"
                value={item.reportElement.configuration.after}
            />
            <ConditionalDescriptionSection
                handleChange={value =>
                    handleTextChange(TableTextField.RECOMMENDATIONS, value)
                }
                handleCheckboxChange={value =>
                    handleCheckBoxChange(
                        TableCheckBoxField.RECOMMENDATIONS,
                        value
                    )
                }
                handleError={error =>
                    handleError(error, TableTextField.RECOMMENDATIONS, id)
                }
                id={id}
                isChecked={
                    item.reportElement?.configuration?.recommendationsCheckbox
                }
                itemKey={TableTextField.RECOMMENDATIONS}
                reportElementError={reportElementError}
                title="Recommendations"
                value={item.reportElement.configuration.recommendations}
            />
            <CheckBoxSection
                handleChange={value => {
                    handleCheckBoxChange(
                        TableCheckBoxField.INCLUDE_IN_SUMMARY,
                        value
                    );
                    handleTableReportElementUpdate(value);
                }}
                isChecked={item.reportElement.configuration.includeInSummary}
                title="Include in the table summary report element"
            />
        </ReportElementContainer>
    );
};

export default TableReport;
