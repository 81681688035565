import { useState } from 'react';

import { Tooltip } from '@armis/armis-ui-library';
import { IoIosClose } from 'react-icons/io';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';

import {
    StyledTab,
    StyledTabButton,
    StyledTabName,
    StyledWarningText
} from './TabsContainer.style';
import { TabType } from './TabsContainer.types';

type TabProps = {
    tab: TabType;
    selectedTab: boolean;
    handleTabChange: (id: string) => void;
    handleRemoveTab: (id: string) => void;
};

const Tab = ({
    tab,
    selectedTab,
    handleTabChange,
    handleRemoveTab
}: TabProps) => {
    const [hoveredTab, setHoveredTab] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDelete = () => {
        handleRemoveTab(tab.id);
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <StyledTab
                aria-selected={selectedTab}
                className={selectedTab ? 'selected' : ''}
                onClick={() => handleTabChange(tab.id)}
                onMouseEnter={() => setHoveredTab(true)}
                onMouseLeave={() => setHoveredTab(false)}
            >
                <Tooltip
                    arrow
                    placement="bottom"
                    title={<div className="tooltip-arrow-text">{tab.name}</div>}
                >
                    <StyledTabName>{tab.name}</StyledTabName>
                </Tooltip>
                {hoveredTab && (
                    <StyledTabButton
                        className="Icon-Hover-Effect"
                        onClick={e => {
                            e.stopPropagation();
                            setIsDeleteModalOpen(true);
                        }}
                        size="large"
                    >
                        <IoIosClose style={{ fill: 'currentcolor' }} />
                    </StyledTabButton>
                )}
            </StyledTab>
            <Modal
                isModalOpen={isDeleteModalOpen}
                onCancel={() => setIsDeleteModalOpen(false)}
                onSubmit={handleDelete}
                submitBtnLabel="Delete"
                title="Delete Dashboard"
            >
                <WarningModalContainer text="">
                    <StyledWarningText>
                        You are about to delete the {tab.name} dashboard. Are
                        you sure?
                    </StyledWarningText>
                </WarningModalContainer>
            </Modal>
        </>
    );
};

export default Tab;
