import { FC } from 'react';

import { Editor } from '@monaco-editor/react';
import { useTheme } from '@mui/material';

import { Dashboard } from '../DashboardTemplate.types';
import { StyledModal } from './CreateEditDashboard/CreateEditDashboard.styles';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    data: Dashboard;
};

const ViewJsonModal: FC<Props> = ({ data, isModalOpen, onClose }) => {
    const { palette } = useTheme();
    const content = JSON.stringify(data, null, 2);
    const theme = palette.mode === 'dark' ? 'vs-dark' : '';

    return (
        <StyledModal
            displayBtn="cancel"
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={() => {}}
            paperProps={{
                style: {
                    width: '100%',
                    maxWidth: 1000
                }
            }}
            submitBtnLabel=""
            title={data.name}
        >
            <Editor
                defaultLanguage="json"
                defaultValue={content}
                height="50vh"
                options={{
                    readOnly: true
                }}
                theme={theme}
            />
        </StyledModal>
    );
};

export default ViewJsonModal;
