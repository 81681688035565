import {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
    Dispatch,
    SetStateAction
} from 'react';

import { MenuItemProps, MultiSelect } from '@armis/armis-ui-library';
import { Droppable } from '@hello-pangea/dnd';
import { FormLabel, Grid } from '@mui/material';
import { differenceWith, isEqual } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PAGE_SIZE } from 'src/constants/APIConstants';
import { REPORT } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { getAllReportlets } from 'src/services/api.service';

import { ReportletDataType } from './ReportletPanel.types';
import {
    getReportletSelectLabel,
    getReportsList,
    updateReportList
} from './utils';
import {
    StyledDashboardLabel,
    StyledSelect,
    StyledDiv,
    StyledSelectGrid,
    StyledField
} from '../DashletPanel/DashletPanel.style';
import {
    DraggableCard,
    NextLoader,
    NoData,
    renderCardSkeleton
} from '../DashletPanel/helperUtils';
import { selectMenuProps } from '../DashletPanel/utils';

type Props = {
    reportletData: ReportletDataType;
    setReportletData: Dispatch<SetStateAction<ReportletDataType>>;
};

export const ReportletPanel: FC<Props> = ({
    reportletData,
    setReportletData
}) => {
    const [reportList, setReportList] = useState<MenuItemProps[]>([]);
    const [selectedReports, setSelectedReports] = useState<string[]>([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isNextFetching, setIsNextFetching] = useState(false);
    const topRef = useRef<HTMLDivElement>(null);
    const reportletSelectLabel = getReportletSelectLabel(
        reportList,
        selectedReports
    );
    const reportlets = reportletData?.content ?? [];
    const showNoData = !isLoading && reportlets.length === 0;
    const hasMoreData =
        reportletData?.totalElements !== 0 &&
        reportlets.length !== reportletData?.totalElements;

    /**
     * Ensures the scroll position is reset to the top when the user interacts with the select box.
     *
     * This prevents the scroll from being stuck in the middle due to the sticky element.
     */
    const scrollToTop = useCallback(() => {
        if (topRef.current) {
            topRef.current.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        const getInitialData = async () => {
            try {
                setIsLoading(true);
                const { reportData, selectedReportList } =
                    await getReportsList();
                const response = await getAllReportlets(0, PAGE_SIZE, '');
                setReportList(reportData);
                setSelectedReports(selectedReportList);
                setReportletData(response.data);
            } catch (error: any) {
                displayErrorMessage(error);
            } finally {
                setIsLoading(false);
            }
        };

        getInitialData();
    }, [setReportletData]);

    const handleReportChange = async () => {
        const updatedReportList = updateReportList(reportList, selectedReports);
        const differences = differenceWith(
            reportList,
            updatedReportList,
            isEqual
        );

        if (differences.length === 0) return;

        try {
            setIsLoading(true);
            scrollToTop();
            setPageNumber(0);
            setReportletData({} as ReportletDataType);
            const response = await getAllReportlets(
                0,
                PAGE_SIZE,
                selectedReports.join()
            );
            setReportletData(response.data);
            setReportList(updatedReportList);
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    const nextScroll = async () => {
        const shouldFetch = reportletData.totalPages >= pageNumber + 2;
        if (!shouldFetch) return;

        try {
            const newPageNumber = pageNumber + 1;
            setIsNextFetching(true);
            setPageNumber(newPageNumber);

            const response = await getAllReportlets(
                newPageNumber,
                PAGE_SIZE,
                selectedReports.join()
            );
            setReportletData(prev => ({
                ...prev,
                content: [...prev.content, ...response.data.content]
            }));
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsNextFetching(false);
        }
    };

    const reportSelect = (
        <>
            <FormLabel>
                <StyledDashboardLabel>{REPORT}</StyledDashboardLabel>
            </FormLabel>
            <StyledSelect
                defaultValue="All Reports"
                MenuProps={selectMenuProps}
                onClose={handleReportChange}
                renderValue={() => reportletSelectLabel}
                variant="outlined"
            >
                <MultiSelect
                    isAllSelected
                    items={reportList}
                    onSelectionChanged={items => {
                        setSelectedReports(items.map(({ id }) => id));
                    }}
                    showSelectAllOption
                />
            </StyledSelect>
        </>
    );

    return (
        <StyledDiv ref={topRef} id="reportletScrollableDiv">
            <InfiniteScroll
                dataLength={reportlets.length}
                hasMore={hasMoreData}
                loader={isNextFetching && <NextLoader />}
                next={nextScroll}
                scrollableTarget="reportletScrollableDiv"
                style={{ overflow: 'revert' }}
            >
                <Droppable
                    droppableId="reportlets"
                    isDropDisabled
                    type="report-items"
                >
                    {({ innerRef }) => (
                        <div ref={innerRef}>
                            <StyledSelectGrid container spacing={2}>
                                <Grid item style={{ paddingTop: 0 }} xs={6}>
                                    <StyledField>{reportSelect}</StyledField>
                                </Grid>
                            </StyledSelectGrid>
                            <Grid container spacing={2} sx={{ marginTop: 0 }}>
                                {isLoading && renderCardSkeleton(4)}
                                {showNoData && (
                                    <Grid item xs={12}>
                                        <NoData />
                                    </Grid>
                                )}
                                {reportlets.map((reportlet, index) => (
                                    <DraggableCard
                                        key={reportlet.id}
                                        id={reportlet.id}
                                        index={index}
                                        title={
                                            reportlet.reportDashlet.title ?? ''
                                        }
                                        type={
                                            reportlet.reportDashlet.dashletJson
                                                .dashlet.visualizationConfig
                                                .type
                                        }
                                    />
                                ))}
                            </Grid>
                        </div>
                    )}
                </Droppable>
            </InfiniteScroll>
        </StyledDiv>
    );
};
