import * as Yup from 'yup';

import {
    BarAPICallConfigType,
    CalculationAPICallConfigType,
    ColumnConfigType,
    ColumnType,
    DashboardConfigType
} from './DashBoard.types';
import { getCalculationExpressionError, getLengthErrorMessage } from './utils';

export const MAX_COLUMN_LENGTH = 20;
export const MAX_API_CALLS = 4;

export const defaultDashboardConfig: DashboardConfigType = {
    ampDashboardTenantDtos: [],
    ampDashboardColumnDtos: []
};

export const defaultBarAPICallConfig: BarAPICallConfigType = {
    asq: '',
    colorCode: '#ffffffff'
};

export const defaultCalculationAPICallConfig: CalculationAPICallConfigType = {
    asq: '',
    variable: ''
};

export const REQUIRE_FIELD_ERROR = 'This field is required';

const getDynamicColumnSchema = (columnType: ColumnType) => {
    switch (columnType) {
        case ColumnType.NUMBER:
            return Yup.object({
                ampDashboardColumnNumberDto: Yup.object({
                    asq: Yup.string()
                        .required(REQUIRE_FIELD_ERROR)
                        .max(500, getLengthErrorMessage('ASQ', 500)),
                    showAs: Yup.string().required(REQUIRE_FIELD_ERROR),
                    mediumThreshold: Yup.number().required(REQUIRE_FIELD_ERROR),
                    highThreshold: Yup.number().required(REQUIRE_FIELD_ERROR)
                }).required()
            });
        case ColumnType.CALCULATION:
            return Yup.object({
                ampDashboardColumnCalculations: Yup.object({
                    showAs: Yup.string().required(REQUIRE_FIELD_ERROR),
                    mediumThreshold: Yup.number().required(REQUIRE_FIELD_ERROR),
                    highThreshold: Yup.number().required(REQUIRE_FIELD_ERROR),
                    calculationFunction: Yup.string()
                        .required(REQUIRE_FIELD_ERROR)
                        .max(500, getLengthErrorMessage('Operation', 500))
                        .test({
                            name: 'is-valid-operation',
                            test(value, context) {
                                const { ampDashboardColumnApiCallsDtos } =
                                    context.parent;

                                const error = getCalculationExpressionError(
                                    value,
                                    ampDashboardColumnApiCallsDtos || []
                                );
                                if (error) {
                                    return context.createError({
                                        message: error
                                    });
                                }

                                return true;
                            }
                        }),
                    ampDashboardColumnApiCallsDtos: Yup.array()
                        .of(
                            Yup.object({
                                asq: Yup.string()
                                    .required('ASQ is required')
                                    .max(
                                        500,
                                        getLengthErrorMessage('ASQ', 500)
                                    ),
                                variable: Yup.string()
                                    .required('Variable is required')
                                    .matches(
                                        /^[A-Za-z_]*$/,
                                        'Only a-z, _ allowed'
                                    )
                                    .max(10, 'Max 10 Characters')
                            })
                        )
                        .test({
                            name: 'unique-variable-names',
                            test(list, context) {
                                const set = [
                                    ...new Set(list?.map(data => data.variable))
                                ];
                                const isUnique = list?.length === set.length;
                                if (isUnique) {
                                    return true;
                                }

                                const idx = list?.findIndex(
                                    (data, i) => data.variable !== set[i]
                                );
                                if (idx === -1) {
                                    return true;
                                }
                                return context.createError({
                                    path: `${context.path}[${idx}].variable`,
                                    message: 'Name already exist'
                                });
                            }
                        })
                        .min(1, 'Minimum one API call is required')
                        .required(REQUIRE_FIELD_ERROR)
                }).required()
            });
        case ColumnType.BAR:
            return Yup.object({
                ampDashboardColumnBarDtos: Yup.array()
                    .of(
                        Yup.object({
                            asq: Yup.string()
                                .required('ASQ is required')
                                .max(500, getLengthErrorMessage('ASQ', 500)),
                            colorCode: Yup.string().required(
                                'Color code is required'
                            )
                        })
                    )
                    .min(1, 'Minimum one API Call is required')
                    .required(REQUIRE_FIELD_ERROR)
            });
        case ColumnType.REPORT_LINK:
            return Yup.object({
                reportId: Yup.string().required(REQUIRE_FIELD_ERROR)
            });

        default:
            return Yup.object();
    }
};

export const dashboardConfigSchema: Yup.ObjectSchema<DashboardConfigType> =
    Yup.object({
        ampDashboardTenantDtos: Yup.array()
            .of(
                Yup.object({
                    tenantDto: Yup.object({
                        id: Yup.string().required()
                    })
                })
            )
            .required(REQUIRE_FIELD_ERROR)
            .min(1, 'At least one tenant must be selected'),
        ampDashboardColumnDtos: Yup.array()
            .of(
                Yup.lazy((value: ColumnConfigType) =>
                    Yup.object({
                        title: Yup.string()
                            .required(REQUIRE_FIELD_ERROR)
                            .max(255, getLengthErrorMessage('Name', 255)),
                        columnType: Yup.string()
                            .oneOf(Object.values(ColumnType))
                            .required(),
                        orderNumber: Yup.number().required()
                    }).concat(getDynamicColumnSchema(value?.columnType || ''))
                )
            )
            .required()
    });
