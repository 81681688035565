import React, { useState } from 'react';

import { IconButton, Upload } from '@armis/armis-ui-library';
import {
    Box,
    ClickAwayListener,
    Divider,
    ListItemText,
    Popper
} from '@mui/material';
import { IoIosAdd } from 'react-icons/io';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';

import {
    StyledList,
    StyledListItem,
    StyledListSubheader,
    StyledPopper,
    StyledPopperArrow,
    StyledPopperArrowContainer,
    StyledPopperButton,
    VisuallyHiddenInput
} from './AddPopper.style';
import CreateDashboardModal from './CreateDashboardModal';
import { useDashboardContext } from '../../context/DashboardContext';
import DashboardCreateModal from '../../modal/DashboardCreateModal';

const AddPopper: React.FC = () => {
    const [popperAnchor, setPopperAnchor] = useState<null | HTMLElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const { dashboards } = useDashboardContext();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const togglePopper = (event: React.MouseEvent<HTMLElement>) => {
        setPopperAnchor(popperAnchor ? null : event.currentTarget);
    };

    const filteredDashboards = dashboards.filter(dashboard =>
        dashboard.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <DashboardCreateModal
                onCancel={() => setOpenCreateModal(false)}
                open={openCreateModal}
            />
            <IconButton
                className="Icon-Hover-Effect"
                onClick={togglePopper}
                size="large"
                style={{ fontSize: '32px', padding: '4px', height: '40px' }}
            >
                <IoIosAdd />
            </IconButton>

            <Popper
                anchorEl={popperAnchor}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        }
                    }
                ]}
                open={Boolean(popperAnchor)}
                placement="bottom-start"
            >
                <ClickAwayListener onClickAway={() => setPopperAnchor(null)}>
                    <StyledPopper>
                        <StyledPopperArrowContainer>
                            <StyledPopperArrow />
                        </StyledPopperArrowContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px',
                                marginBottom: '8px'
                            }}
                        >
                            <StyledPopperButton
                                color="primary"
                                component="label"
                                onClick={() => {
                                    setIsCreateModalOpen(true);
                                }}
                                startIcon={<IoIosAdd />}
                                variant="text"
                            >
                                Create New Dashboard
                            </StyledPopperButton>
                            <StyledPopperButton
                                color="primary"
                                component="label"
                                startIcon={
                                    <Upload
                                        style={{
                                            marginInline: '2px'
                                        }}
                                    />
                                }
                                variant="text"
                            >
                                Load from JSON
                                <VisuallyHiddenInput
                                    accept=".png,.jpg,.jpeg"
                                    onChange={e => {
                                        e.target.value = '';
                                    }}
                                    type="file"
                                />
                            </StyledPopperButton>
                        </Box>

                        <Divider style={{ marginBottom: '16px' }} />

                        <SearchBar
                            onChange={newValue => setSearchQuery(newValue)}
                            placeholder="Search Dashboards"
                            searchValue={searchQuery}
                            sx={{ width: '100%' }}
                        />

                        <StyledList subheader={<li />}>
                            <li>
                                <ul>
                                    <StyledListSubheader>
                                        Shared
                                    </StyledListSubheader>
                                    {filteredDashboards.map(dashboard => (
                                        <StyledListItem
                                            key={`shared-dashboard-${dashboard.id}`}
                                        >
                                            <ListItemText
                                                className="list-title"
                                                primary={`Item ${dashboard.name}`}
                                            />
                                        </StyledListItem>
                                    ))}
                                </ul>
                            </li>
                        </StyledList>
                    </StyledPopper>
                </ClickAwayListener>
            </Popper>

            {isCreateModalOpen && (
                <CreateDashboardModal
                    isModalOpen
                    onClose={() => {
                        setIsCreateModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default AddPopper;
