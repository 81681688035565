import { Tooltip } from '@armis/armis-ui-library';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, CardContent, CardMedia, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { EllipsisTypography, StyledDiv } from './CreateReport.style';
import { ReportletElementType } from './CreateReport.types';
import { renderChartSvg } from './utils';

type ReportElementsPanelType = {
    items: ReportletElementType[];
};

const ReportElementsPanel = ({ items }: ReportElementsPanelType) => {
    const theme = useTheme();

    return (
        <StyledDiv
            id="scrollableDashletDiv"
            style={{
                height: '42vh',
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Droppable
                droppableId="report-elements"
                isDropDisabled
                type="report-items"
            >
                {provided => (
                    <div
                        ref={provided.innerRef}
                        // @ts-ignore
                        // isDraggingOver={snapshot.isDraggingOver}
                    >
                        <Grid container spacing={2}>
                            {items.map((element, index) => {
                                if (element.hide) return null;

                                const cardImage = renderChartSvg(
                                    element.type,
                                    theme.palette.mode
                                );

                                return (
                                    <Draggable
                                        key={element.id}
                                        draggableId={element.id}
                                        index={index}
                                    >
                                        {dragProvided => (
                                            <Grid
                                                ref={dragProvided.innerRef}
                                                // isDragging={
                                                //     snapshot.isDragging
                                                // }
                                                item
                                                style={
                                                    dragProvided.draggableProps
                                                        .style
                                                }
                                                xs={6}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}
                                                // @ts-ignore
                                            >
                                                <Card>
                                                    <CardMedia
                                                        alt="Your image"
                                                        component="img"
                                                        height="80"
                                                        image={cardImage}
                                                        sx={{
                                                            padding: '1em',
                                                            objectFit:
                                                                'contain',
                                                            alignContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center'
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Tooltip
                                                            arrow
                                                            placement="bottom"
                                                            sx={{
                                                                padding: '1em',
                                                                objectFit:
                                                                    'contain',
                                                                alignContent:
                                                                    'center',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center'
                                                            }}
                                                            title={
                                                                <div className="tooltip-arrow-text">
                                                                    {
                                                                        element.label
                                                                    }
                                                                </div>
                                                            }
                                                        >
                                                            <EllipsisTypography>
                                                                {element.label}
                                                            </EllipsisTypography>
                                                        </Tooltip>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </Grid>
                    </div>
                )}
            </Droppable>
        </StyledDiv>
    );
};

export default ReportElementsPanel;
