import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    margin: 16px 0;
    display: flex;
    gap: 5px;
`;

export const StyledNoInputsLabel = styled('p')`
    margin: 16px 0;
    color: ${props =>
        props.theme.palette.mode === 'dark' ? 'white' : '#797979'};
    font-size: 14px;
    width: 100%;
    text-align: center;
`;

export const StyledTooltipContainer = styled('div')`
    height: 14px;
    cursor: pointer;
    .fill {
        fill: ${constants.COLOR_GENERAL_4} !important;
    }
`;

export const StyledVariablePrefix = styled('div')`
    width: 34px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    color: ${({ theme }) =>
        theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.5)'};
    border-right: ${({ theme }) =>
        theme.palette.mode === 'dark'
            ? `1px solid ${constants.COLOR_GENERAL_28}`
            : `1px solid ${constants.COLOR_GENERAL_6}`};
`;

export const StyledOperatorContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledOperator = styled('span', {
    shouldForwardProp: prop => prop !== 'hasError'
})<{ hasError?: boolean }>`
    margin-top: ${({ hasError }) => (hasError ? 0 : '25px')};
    color: ${({ theme }) =>
        theme.palette.mode === 'dark' ? 'white' : 'black'};
`;

export const StyledButtonContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'hasError'
})<{ hasError?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${({ hasError }) => (hasError ? 0 : '25px')};
`;
