import { useEffect, useState } from 'react';

import { Divider } from '@mui/material';
import { cloneDeep } from 'lodash';

import UploadImageSection from '../common/UploadImageSection';
import {
    ReportSettingsType,
    ReportSettingsErrorType
} from '../CreateReport.types';
import { ImageField } from './utils';
import SelectSection from '../common/SelectSection';
import NumberSection from '../common/TransparencySection';
import { StyledVerticalContainer } from '../CreateReport.style';
import { backgroundAppearanceItems } from '../data';
import { StyledModal } from './ReportSettingsModal.style';

interface ReportSettingsModalProps {
    isModalOpen: boolean;
    onSubmit: (dataObject: any) => void;
    onCancel: () => void;
    modalFields: ReportSettingsType;
}

const ReportSettingsModal = ({
    isModalOpen,
    onSubmit,
    onCancel,
    modalFields
}: ReportSettingsModalProps) => {
    const [data, setData] = useState(modalFields);
    const [error, setError] = useState<ReportSettingsErrorType>({});

    useEffect(
        () => () => {
            setData(modalFields);
            setError({});
        },
        [modalFields]
    );

    const handleFileChange = (
        file: File | undefined,
        key: ImageField.LOGO | ImageField.BACKGROUND,
        fileNameKey: ImageField.LOGO_NAME | ImageField.BACKGROUND_NAME
    ) => {
        const updatedData = cloneDeep(data);
        updatedData[fileNameKey] = '';
        updatedData[key] = file;
        setData(updatedData);
    };

    function handleChange<Key extends keyof ReportSettingsType>(
        key: Key,
        value: ReportSettingsType[Key]
    ) {
        const updatedData = cloneDeep(data);
        updatedData[key] = value;
        setData(updatedData);
    }

    const handleError = (key: keyof ReportSettingsType, err: string) => {
        setError(prev => ({ ...prev, [key]: err }));
    };

    return (
        <StyledModal
            isModalOpen={isModalOpen}
            onCancel={onCancel}
            onSubmit={() => onSubmit(data)}
            submitBtnLabel="Apply"
            title="Report Settings"
        >
            <UploadImageSection
                file={data.logo}
                fileName={data.logoPath}
                handleChange={file =>
                    handleFileChange(
                        file,
                        ImageField.LOGO,
                        ImageField.LOGO_NAME
                    )
                }
                title="Logo"
            />
            <Divider flexItem style={{ marginBottom: '8px' }} />
            <UploadImageSection
                file={data.watermarkImage}
                fileName={data.watermarkImageName}
                handleChange={file =>
                    handleFileChange(
                        file,
                        ImageField.BACKGROUND,
                        ImageField.BACKGROUND_NAME
                    )
                }
                title="Background Image"
            />
            <StyledVerticalContainer>
                <NumberSection
                    error={error.watermarkOpacity || ''}
                    handleChange={val => handleChange('watermarkOpacity', val)}
                    handleError={err => handleError('watermarkOpacity', err)}
                    title="Transparency"
                    value={data.watermarkOpacity.toString()}
                />
                <SelectSection
                    fullWidth
                    handleChange={val =>
                        handleChange('watermarkAppearance', val)
                    }
                    items={backgroundAppearanceItems}
                    title="Appearance"
                    value={data.watermarkAppearance}
                />
            </StyledVerticalContainer>
        </StyledModal>
    );
};

export default ReportSettingsModal;
