import { FC } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import { IoIosClose } from 'react-icons/io';

import { StyledField } from '../../CreateReport.style';

type Props = {
    id: string;
    label: string;
    value: string;
    error: string;
    handleChange: (value: string) => void;
};

const VariableValue: FC<Props> = ({
    id,
    label,
    value,
    error,
    handleChange
}) => {
    const debouncedOnChange = debounce(handleChange, 500);

    return (
        <StyledField sx={{ flex: 1, paddingBottom: '0 !important' }}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <TextBox
                autoComplete="off"
                error={Boolean(error)}
                fullWidth
                helperText={error}
                hiddenLabel
                id={id}
                InputProps={{
                    endAdornment: value && (
                        <InputAdornment
                            onClick={() => {
                                handleChange('');
                            }}
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    )
                }}
                onChange={e => {
                    debouncedOnChange(e.target.value);
                }}
                size="small"
                value={value}
                variant="outlined"
            />
        </StyledField>
    );
};

export default VariableValue;
