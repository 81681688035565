import { useCallback, useMemo, useState } from 'react';

import { Button, Plus } from '@armis/armis-ui-library';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { ModalFieldType } from 'src/types/CommonTypes';

import DraggableTable from '../../common/DraggableTable';
import { StyledField } from '../../CreateReport.style';
import { ASQAction, ReportElementErrorType } from '../../CreateReport.types';
import { ConfigASQModal } from '../../modal/ConfigASQModal';
import { getFieldErrorMessage } from '../../utils';
import { ActionCellRenderer, initialData, RowData } from '../utils';

type TableSectionProps = {
    id: string;
    itemKey: string;
    rowsData: RowData[];
    reportElementError: ReportElementErrorType;
    handleError: (error: string) => void;
    handleTableChange: (rowData: RowData[]) => void;
};

const TableSection = ({
    id,
    itemKey,
    rowsData,
    reportElementError,
    handleError,
    handleTableChange
}: TableSectionProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [actionType, setActionType] = useState<ASQAction>(ASQAction.ADD);
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

    const error = getFieldErrorMessage(reportElementError, id, itemKey);

    const handleTableError = (length: number) => {
        if (!length) {
            handleError('Require at least one data to add');
        } else {
            handleError('');
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalFields(initialData);
        setActionType(ASQAction.ADD);
    };

    const updateData = (dataObject: RowData) => {
        const updatedRowsData: RowData[] = [...rowsData];
        if (actionType === 'add') {
            updatedRowsData.push(dataObject);
        } else if (actionType === 'edit') {
            updatedRowsData[selectedRowIndex] = dataObject;
        }
        handleTableChange(updatedRowsData);
        handleTableError(updatedRowsData.length);
        closeModal();
    };

    const handleDeleteRow = () => {
        const updatedRowsData = rowsData.filter(
            (_, index) => index !== selectedRowIndex
        );
        handleTableChange(updatedRowsData);
        handleTableError(updatedRowsData.length);
        closeModal();
    };

    const updateModalFields = (data: RowData) => {
        const updatedModalFields = cloneDeep(initialData);
        updatedModalFields.title.value = data.title;
        updatedModalFields.asq.value = data.asq;
        setModalFields(updatedModalFields);
    };

    const handleEdit = useCallback(
        (data: RowData, rowIndex: number) => {
            setActionType(ASQAction.EDIT);
            setSelectedRowIndex(rowIndex);
            updateModalFields(data);
            setModalOpen(true);
        },
        [setModalOpen]
    );

    const handleDelete = useCallback(
        (data: RowData, rowIndex: number) => {
            setSelectedRowIndex(rowIndex);
            setActionType(ASQAction.DELETE);
            updateModalFields(data);
            setModalOpen(true);
        },
        [setModalOpen]
    );

    const cellRenderer = useCallback(
        (params: ICellRendererParams) => (
            <ActionCellRenderer
                onDelete={() => handleDelete(params.data, params.rowIndex)}
                onEdit={() => handleEdit(params.data, params.rowIndex)}
            />
        ),
        [handleDelete, handleEdit]
    );

    const reportASQTableConfig: ColDef[] = useMemo(
        () => [
            {
                rowDrag: true,
                maxWidth: 50,
                rowDragText: params => params.rowNode?.data?.title
            },
            {
                field: 'title',
                headerName: 'Title'
            },
            {
                field: 'asq',
                headerName: 'ASQ'
            },
            {
                headerName: 'Actions',
                maxWidth: 100,
                cellRenderer
            }
        ],
        [cellRenderer]
    );

    return (
        <StyledField className="flex-end">
            <ConfigASQModal
                actionType={actionType}
                isModalOpen={modalOpen}
                modalFields={modalFields}
                onCancel={closeModal}
                onDelete={handleDeleteRow}
                onSubmit={updateData}
                setModalFields={setModalFields}
            />
            <Button
                className="header-add-button"
                color="primary"
                onClick={e => {
                    e.currentTarget.blur();
                    setModalOpen(true);
                }}
                startIcon={<Plus className="plus-icon" />}
                style={{
                    width: '125px',
                    paddingLeft: '15px',
                    height: '32px'
                }}
                variant="outlined"
            >
                Add Row
            </Button>
            <DraggableTable
                columnDefs={reportASQTableConfig}
                error={error}
                handleTableChange={handleTableChange}
                rowsData={rowsData}
            />
        </StyledField>
    );
};

export default TableSection;
