import { constants, Select } from '@armis/armis-ui-library';
import { FormHelperText, FormLabel, MenuItem } from '@mui/material';

import { StyledField } from '../DashBoard.style';
import { MenuItemDataType } from '../DashBoard.types';

type SelectSectionProps = {
    title: string;
    value: string;
    items: MenuItemDataType[];
    error?: string;
    placeholder?: string;
    fullWidth?: boolean;
    handleChange: (value: string) => void;
};

const SelectSection = ({
    title,
    value,
    items,
    error,
    placeholder,
    fullWidth,
    handleChange
}: SelectSectionProps) => {
    const selectedValue = items.find(item => item.value === value)?.label;

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>

            <Select
                displayEmpty
                fullWidth={fullWidth}
                MenuProps={{ sx: { zIndex: 10002 } }}
                onChange={e => {
                    handleChange(e.target.value as string);
                }}
                renderValue={() => {
                    if (!value) {
                        return placeholder;
                    }
                    return selectedValue;
                }}
                sx={{
                    border: error ? `1px solid ${constants.COLOR_5}` : ''
                }}
                value={value}
                variant="outlined"
            >
                {items.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </StyledField>
    );
};

export default SelectSection;
