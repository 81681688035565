import { useState } from 'react';

import { IconButton, IconDelete } from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';
import { MuiColorInputColors, MuiColorInputFormat } from 'mui-color-input';

import { StyledField } from '../DashBoard.style';
import { StyledColorLabel, StyledMuiColorInput } from './Common.style';
import TextAreaSection from './TextAreaSection';
import { BarAPICallConfigType } from '../DashBoard.types';

enum ColorASQInputKey {
    COLOR_CODE = 'colorCode',
    ASQ = 'asq'
}

type ColorASQSectionProps = {
    input: BarAPICallConfigType;
    colorError?: string;
    asqError?: string;
    handleChange: (key: ColorASQInputKey, color: string) => void;
    handleDelete: () => void;
};

const ColorASQSection = ({
    input,
    colorError,
    asqError,
    handleChange,
    handleDelete
}: ColorASQSectionProps) => {
    const { colorCode, asq } = input;
    const [colorValue, setColorValue] = useState<string>(colorCode);

    const handleColorChange = (
        newValue: string,
        colors: MuiColorInputColors
    ) => {
        setColorValue(newValue);
        if (colors.hex8) {
            handleChange(ColorASQInputKey.COLOR_CODE, colors.hex8);
        }
    };

    const format: MuiColorInputFormat = 'hex8';

    return (
        <>
            <StyledField>
                <StyledColorLabel>
                    <FormLabel>Color Code</FormLabel>
                    <IconButton
                        className="Icon-Hover-Effect"
                        onClick={handleDelete}
                    >
                        <IconDelete />
                    </IconButton>
                </StyledColorLabel>
                <StyledMuiColorInput
                    error={!!colorError}
                    format={format}
                    helperText={colorError}
                    onChange={handleColorChange}
                    value={colorValue}
                />
            </StyledField>
            <TextAreaSection
                error={asqError}
                handleChange={val => handleChange(ColorASQInputKey.ASQ, val)}
                title="ASQ"
                value={asq}
            />
        </>
    );
};

export default ColorASQSection;
