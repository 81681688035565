import React, { ReactNode } from 'react';

import { CrossIcon, IconButton } from '@armis/armis-ui-library';
import { Card, FormLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { StyledField } from './CreateReport.style';
import { ReportFieldDataContentType } from './CreateReport.types';

type ReportElementContainerProps = {
    item: ReportFieldDataContentType;
    title: string;
    selectedDashboardIds: any;
    deleteItem: (id: string | undefined) => void;
    children: ReactNode;
};

const ReportElementContainer = ({
    item,
    title,
    selectedDashboardIds,
    deleteItem,
    children
}: ReportElementContainerProps) => {
    const theme = useTheme();

    const boxShadowString = `${
        theme.palette.mode === 'dark'
            ? '#756d81 0px 0px 20px 2px'
            : 'rgba(0, 0, 0, 0.35) 0px 0px 20px 2px'
    }`;

    return (
        <Card
            sx={{
                background:
                    theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, .05)'
                        : '#F2F2F4',
                padding: '10px',
                boxShadow:
                    item.dragAndDropId === selectedDashboardIds.dragAndDropId
                        ? boxShadowString
                        : '',
                overflow: 'visible'
            }}
        >
            <StyledField>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        height: '30px'
                    }}
                >
                    <FormLabel
                        htmlFor={`modal-field-input-${
                            item.dragAndDropId as string
                        }`}
                    >
                        {title}
                    </FormLabel>
                    <IconButton
                        className="Icon-Hover-Effect"
                        onClick={() => deleteItem(item.dragAndDropId)}
                        sx={{
                            float: 'right',
                            top: '-5px',
                            height: '24px'
                        }}
                    >
                        <CrossIcon />
                    </IconButton>
                </div>
                {children}
            </StyledField>
        </Card>
    );
};

export default ReportElementContainer;
