import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'hasError'
})<{ hasError?: boolean }>`
    & .ck-editor__editable,
    .ck-editor__editable:focus {
        border-color: ${({ hasError }) =>
            hasError ? constants.COLOR_5 : ''} !important;
    }
`;
