import { IconPencil, Sync } from '@armis/armis-ui-library';
import { PiUserSwitchFill } from 'react-icons/pi';
import { FIELD_TYPE_MAP, Privileges } from 'src/constants/CommonConstants';
import {
    ASSIGN_UNASSIGN_USER,
    EDIT,
    PLACEHOLDER_PASSWORD,
    SECRET_KEY,
    SYNC,
    TAGS,
    TENANT_NAME,
    TENANT_URL,
    USERNAME
} from 'src/constants/LabelText';
import { FieldType } from 'src/types/CommonTypes';

import { TenantActions } from './TenantManagement.types';

export const tenantActionOptions = [
    {
        type: TenantActions.EDIT,
        icon: <IconPencil className="fill-dropdown-svg" width={18} />,
        label: EDIT,
        privileges: [Privileges.edittenant, Privileges.tags]
    },
    {
        type: TenantActions.SYNC,
        icon: <Sync className="fill-dropdown-svg" height={18} width={18} />,
        label: SYNC,
        privileges: [Privileges.read]
    },
    {
        type: TenantActions.ASSIGN_UNASSIGN_USER,
        icon: <PiUserSwitchFill size={18} />,
        label: ASSIGN_UNASSIGN_USER,
        privileges: []
    }
];

export const actionFormMetaData: FieldType[] = [
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: TENANT_NAME,
        validations: {
            required: true,
            maxLength: 100
        }
    },
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: TENANT_URL,
        validations: {
            required: true,
            maxLength: 500,
            validUrl: true
        }
    },
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: USERNAME,
        validations: {
            required: false,
            maxLength: 100
        }
    },
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: SECRET_KEY,
        inputType: FIELD_TYPE_MAP.PASSWORD,
        validations: {
            required: false,
            maxLength: 500
        },
        placeHolder: PLACEHOLDER_PASSWORD
    }
];

export const initialData = {
    [TENANT_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [TENANT_URL]: {
        value: '',
        error: false,
        helperText: '',
        disable: true
    },
    [SECRET_KEY]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [USERNAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [TAGS]: {
        value: [],
        error: false,
        helperText: '',
        disable: false,
        optional: true
    }
};
