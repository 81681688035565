import React, { useEffect, useRef, useState } from 'react';

import {
    Button,
    CHECKBOX_STATUS,
    MultiSelect,
    Select,
    Switch
} from '@armis/armis-ui-library';
import {
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField
} from '@mui/material';
import { cloneDeep, debounce } from 'lodash';
import { useSelector } from 'react-redux';
import {
    ALL,
    EMAIL_INVALID,
    MULTI_EMAIL_INVALID,
    REQUIRED,
    TOTAL_SELECTED
} from 'src/constants/LabelText';
import {
    decodeCronWithConfigration,
    isFieldHavingError,
    validateValues
} from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import { StyledField } from 'src/pages/components/ModalField/ModalField.style';
import { EditField } from 'src/pages/components/UserModalContainer/EditField/EditField';
import { getScheduleReports } from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import {
    DropDownWithKeyValue,
    FieldValidationError,
    FieldValidationType,
    FilterItems
} from 'src/types/CommonTypes';

import {
    DAYS_OPTIONS,
    SCHEDULE_MODAL_TIME_FIELDS,
    PROPERTY_FIELD_KEY_MAP,
    REPEAT_EVERY,
    REPEAT_EVERY_OPTIONS,
    REPEAT_OPTIONS_MAP,
    REPORT_MAIL_FIELDS,
    TIME_FRAME_ENUM,
    OUTPUT_FORMAT_ENUM,
    SCHEDULE_REPORT_FORM_FIELDS
} from '../constants';
import { ConfigFields } from './ConfigFields';
import { Report } from '../ReportTemplate.types';
import { TimePicker } from '../TimePicker/TimePicker';

export interface ScheduleReportSettingsType {
    repeatEvery: number;
    repeatUnit: 'Days' | 'Weeks' | 'Months';
    days: FilterItems[];
    daysCount: string[];
    selectedDays: string[];
    onDayNumber: null | number;
    time: { hour: number; minute: number };
    email_subject: string;
    email: string;
    selectedTenant: { label: string; id: string } | null;
    boundaries: any;
    timeFrame: string;
    outputFormat: string;
    isActive: boolean;
}

const ScheduleReport = ({
    selectedReport,
    error,
    modalData,
    setModalData,
    setIsLoading,
    tenants,
    updateEditMode
}: {
    error: any;
    modalData: any;
    selectedReport: React.MutableRefObject<Report | null>;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
    setModalData: (data: any) => void;
    tenants: DropDownWithKeyValue[];
    updateEditMode: (data: any) => void;
}) => {
    const [scheduleReportSettings, setScheduleReportSettings] =
        useState<ScheduleReportSettingsType>(modalData);
    const [isToggleEnabled, setIsToggleEnabled] = useState(false);
    const [modalFieldErrors, setModalFieldErrors] = useState<any>(error);
    const [outputFormat, setoutputFormat] = useState(0);
    const [timeFrame, settimeFrame] = useState(0);
    const [selectedTenant, setselectedTenant] = useState(0);
    const currentUser = useSelector(selectUser);
    const firstRender = useRef(true);
    const defaultValues = {
        repeatEvery: 1,
        repeatUnit: REPEAT_OPTIONS_MAP.WEEKS,
        days: DAYS_OPTIONS,
        daysCount: [],
        selectedDays: [],
        onDayNumber: null,
        time: { hour: 9, minute: 0 },
        email_subject: '',
        email: '',
        selectedTenant: tenants[0],
        boundaries: [],
        timeFrame: TIME_FRAME_ENUM[0],
        outputFormat: OUTPUT_FORMAT_ENUM[0],
        isActive: true,
        tenantId: tenants[0]
    };

    const updateRepeatSettings = (field: string, value: unknown) => {
        setScheduleReportSettings(prevSettings => ({
            ...prevSettings,
            [field]: value
        }));
    };

    const updateSelectedTenant = (updatedTenant: number) => {
        setselectedTenant(updatedTenant);
        updateRepeatSettings(
            SCHEDULE_REPORT_FORM_FIELDS.selectedTenant,
            tenants[updatedTenant]
        );
        // reseting boundaries as tenant has changed.
        updateRepeatSettings(SCHEDULE_REPORT_FORM_FIELDS.boundaries, []);
    };

    const updateOutputFormat = (formate: number) => {
        setoutputFormat(formate);
        updateRepeatSettings(
            SCHEDULE_REPORT_FORM_FIELDS.outputFormat,
            OUTPUT_FORMAT_ENUM[formate]
        );
    };

    const updateTimeFrame = (time: number) => {
        settimeFrame(time);
        updateRepeatSettings(
            SCHEDULE_REPORT_FORM_FIELDS.timeFrame,
            TIME_FRAME_ENUM[time]
        );
    };

    useEffect(() => {
        const doApiCall = async () => {
            if (firstRender.current)
                try {
                    setIsLoading(true);
                    const scheduledReports = await getScheduleReports(
                        selectedReport?.current!.id!
                    );

                    if (scheduledReports) {
                        updateEditMode({ id: scheduledReports.data.id });
                    }

                    // @ts-ignore
                    const { repeatEvery, interval, weekDays, monthDay, time } =
                        decodeCronWithConfigration(
                            scheduledReports.data.cronExp
                        );

                    const tenantIndex = tenants.findIndex(
                        (tenant: any) =>
                            scheduledReports.data.tenantId === tenant.id
                    );
                    setselectedTenant(
                        tenantIndex && tenantIndex !== -1 ? tenantIndex : 0
                    );

                    const selectedTenants = tenants.find(
                        (tenant: any) =>
                            scheduledReports.data.tenantId === tenant.id
                    );

                    const mapping = {
                        repeatEvery,
                        repeatUnit: interval,
                        selectedDays: weekDays || [],
                        onDayNumber: monthDay,
                        time,
                        email_subject: scheduledReports.data.emailSubject,
                        email: scheduledReports.data.emailTo,
                        isActive: scheduledReports.data.isactive,
                        boundaries: [...scheduledReports.data.boundaries],
                        timeFrame: scheduledReports.data.timeFrame,
                        outputFormat: scheduledReports.data.outputFormat,
                        selectedTenant: selectedTenants
                    };
                    setScheduleReportSettings((prevSettings: any) => ({
                        ...prevSettings,
                        ...mapping
                    }));
                    setIsToggleEnabled(scheduledReports.data.isactive);
                    updateTimeFrame(
                        TIME_FRAME_ENUM.indexOf(scheduledReports.data.timeFrame)
                    );
                    updateOutputFormat(
                        OUTPUT_FORMAT_ENUM.indexOf(
                            scheduledReports.data.outputFormat
                        )
                    );
                } catch (err: any) {
                    setScheduleReportSettings(prevSettings => ({
                        ...prevSettings,
                        ...defaultValues
                    }));
                } finally {
                    setIsLoading(false);
                }
        };
        doApiCall();
        firstRender.current = false;
    }, []);

    useEffect(() => {
        setModalFieldErrors(error);
    }, [error]);

    useEffect(() => {
        setModalFieldErrors(error);
    }, [error]);

    useEffect(() => {
        setModalData(scheduleReportSettings);
    }, [scheduleReportSettings]);

    useEffect(() => {
        const totalDaysSelected =
            scheduleReportSettings?.selectedDays?.length || 0;
        if (totalDaysSelected === 0) {
            updateRepeatSettings(SCHEDULE_REPORT_FORM_FIELDS.daysCount, [
                'Select'
            ]);
        } else if (totalDaysSelected === DAYS_OPTIONS.length) {
            updateRepeatSettings(SCHEDULE_REPORT_FORM_FIELDS.daysCount, [ALL]);
        } else {
            updateRepeatSettings(SCHEDULE_REPORT_FORM_FIELDS.daysCount, [
                TOTAL_SELECTED.replace('%s', totalDaysSelected.toString())
            ]);
        }
        const updatedDay = scheduleReportSettings.days.map(day =>
            scheduleReportSettings.selectedDays.find(uDay => uDay === day.id)
                ? { ...day, checkStatus: CHECKBOX_STATUS.CHECKED }
                : { ...day, checkStatus: CHECKBOX_STATUS.UNCHECKED }
        );

        updateRepeatSettings(SCHEDULE_REPORT_FORM_FIELDS.days, updatedDay);
    }, [scheduleReportSettings.selectedDays]);

    const fieldOnChangeHandler = (
        name: string,
        value: string,
        type: string,
        validations: FieldValidationType,
        setFunction: React.Dispatch<React.SetStateAction<any>>
    ) => {
        let fieldValidationErrorArray = {};

        setFunction((prevState: any) => ({
            ...prevState,
            [name]: value
        }));

        if (name === PROPERTY_FIELD_KEY_MAP.EMAIL) {
            const totalEmails = value
                .split(',')
                .map(email => email.split(' ')).length;
            const isValid = new RegExp(validations?.regex!).test(value);
            if (!isValid && totalEmails > 1) {
                fieldValidationErrorArray = {
                    ...modalFieldErrors,
                    [name]: {
                        error: true,
                        helperText: MULTI_EMAIL_INVALID
                    } as FieldValidationError
                };
            } else if (!isValid) {
                fieldValidationErrorArray = {
                    ...modalFieldErrors,
                    [name]: {
                        error: true,
                        helperText: EMAIL_INVALID
                    } as FieldValidationError
                };
            }

            if (validations.required && !Validators.validateNotEmpty(value))
                fieldValidationErrorArray = {
                    ...modalFieldErrors,
                    [name]: {
                        error: true,
                        helperText: REQUIRED
                    }
                } as FieldValidationError;
        } else {
            const validationErrorObject = validateValues(
                value,
                validations,
                type
            );
            if (validationErrorObject.error) {
                fieldValidationErrorArray = {
                    ...modalFieldErrors,
                    [name]: {
                        ...validationErrorObject
                    }
                };
            }
        }
        if (Object.keys(fieldValidationErrorArray).length > 0) {
            setModalFieldErrors(fieldValidationErrorArray);
        } else {
            const modalFieldErrorsCopy = cloneDeep(modalFieldErrors);
            delete modalFieldErrorsCopy[name];
            setModalFieldErrors(modalFieldErrorsCopy);
        }
    };

    const getErrorHelperText = (field: string) =>
        modalFieldErrors?.[field]?.helperText ?? '';

    const resetError = () =>
        setModalFieldErrors((prevError: { [x: string]: any }) =>
            prevError[PROPERTY_FIELD_KEY_MAP.REPEAT]
                ? {
                      [PROPERTY_FIELD_KEY_MAP.REPEAT]: {
                          ...prevError[PROPERTY_FIELD_KEY_MAP.REPEAT]
                      }
                  }
                : {}
        );

    const getValue = (name: string) => {
        const currentValue =
            scheduleReportSettings[name as keyof ScheduleReportSettingsType];
        return currentValue || '';
    };

    return (
        <>
            <Grid
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                container
                item
                style={{ marginBottom: isToggleEnabled ? '10px' : '0px' }}
            >
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isToggleEnabled}
                                onChange={e => {
                                    setIsToggleEnabled(e.target.checked);
                                    updateRepeatSettings(
                                        SCHEDULE_REPORT_FORM_FIELDS.isActive,
                                        e.target.checked
                                    );
                                }}
                            />
                        }
                        label="Subscribe to Report"
                        style={{ margin: 0 }}
                        sx={{
                            '.MuiSwitch-root': {
                                marginRight: 1
                            }
                        }}
                    />
                </Grid>
            </Grid>

            {isToggleEnabled && (
                <>
                    <ConfigFields
                        modalFieldErrors={modalFieldErrors}
                        outputFormat={outputFormat}
                        scheduleReportSettings={scheduleReportSettings}
                        selectedTenant={selectedTenant}
                        setIsLoading={setIsLoading}
                        tenants={tenants}
                        timeFrame={timeFrame}
                        updateOutputFormat={updateOutputFormat}
                        updateRepeatSettings={updateRepeatSettings}
                        updateSelectedTenant={updateSelectedTenant}
                        updateTimeFrame={updateTimeFrame}
                    />

                    <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }} container>
                        <Grid item>
                            <StyledField>
                                <FormLabel htmlFor="repeat-every-input-field">
                                    {REPEAT_EVERY}
                                </FormLabel>
                                <TextField
                                    error={isFieldHavingError(
                                        PROPERTY_FIELD_KEY_MAP.REPEAT,
                                        modalFieldErrors
                                    )}
                                    fullWidth
                                    helperText={getErrorHelperText(
                                        PROPERTY_FIELD_KEY_MAP.REPEAT
                                    )}
                                    id="repeat-every-input-field"
                                    InputProps={{
                                        inputProps: {
                                            step: 1,
                                            pattern: '[0-9]*'
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StyledField>
                                                    <Select
                                                        MenuProps={{
                                                            sx: {
                                                                zIndex: 10002
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            updateRepeatSettings(
                                                                SCHEDULE_REPORT_FORM_FIELDS.repeatUnit,
                                                                e.target.value
                                                            );
                                                            resetError();
                                                            updateRepeatSettings(
                                                                SCHEDULE_REPORT_FORM_FIELDS.selectedDays,
                                                                []
                                                            );
                                                            updateRepeatSettings(
                                                                SCHEDULE_REPORT_FORM_FIELDS.onDayNumber,
                                                                null
                                                            );
                                                        }}
                                                        style={{
                                                            minWidth: '85px'
                                                        }}
                                                        value={
                                                            scheduleReportSettings.repeatUnit
                                                        }
                                                    >
                                                        {REPEAT_EVERY_OPTIONS.map(
                                                            option => (
                                                                <MenuItem
                                                                    key={option}
                                                                    value={
                                                                        option
                                                                    }
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </StyledField>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e => {
                                        const { value } = e.target;
                                        if (
                                            value === '' ||
                                            (Number(value) > 0 &&
                                                Number(value) <= 9999)
                                        ) {
                                            fieldOnChangeHandler(
                                                PROPERTY_FIELD_KEY_MAP.REPEAT,
                                                value,
                                                SCHEDULE_MODAL_TIME_FIELDS[
                                                    PROPERTY_FIELD_KEY_MAP
                                                        .REPEAT
                                                ].type,
                                                SCHEDULE_MODAL_TIME_FIELDS[
                                                    PROPERTY_FIELD_KEY_MAP
                                                        .REPEAT
                                                ].validations,
                                                setScheduleReportSettings
                                            );
                                        }
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === '.' || e.key === '-') {
                                            e.preventDefault(); // Prevents input of decimal points
                                        }
                                    }}
                                    sx={{
                                        '.MuiInputBase-adornedEnd': {
                                            paddingRight: 0
                                        }
                                    }}
                                    type="number"
                                    value={scheduleReportSettings.repeatEvery}
                                />
                            </StyledField>
                        </Grid>

                        {scheduleReportSettings.repeatUnit !==
                            REPEAT_OPTIONS_MAP.DAYS && (
                            <Grid item>
                                <StyledField>
                                    <FormLabel htmlFor="on-input-field">
                                        {scheduleReportSettings.repeatUnit ===
                                        REPEAT_OPTIONS_MAP.WEEKS
                                            ? 'On'
                                            : 'On Day'}
                                    </FormLabel>
                                    {scheduleReportSettings.repeatUnit ===
                                        REPEAT_OPTIONS_MAP.WEEKS && (
                                        <>
                                            <Select
                                                fullWidth
                                                id="on-input-field"
                                                MenuProps={{
                                                    sx: { zIndex: 10002 }
                                                }}
                                                renderValue={() =>
                                                    scheduleReportSettings.daysCount
                                                }
                                                size="medium"
                                                sx={{
                                                    border: isFieldHavingError(
                                                        PROPERTY_FIELD_KEY_MAP.DAYS,
                                                        modalFieldErrors
                                                    )
                                                        ? '1px solid red'
                                                        : ''
                                                }}
                                                value={
                                                    scheduleReportSettings.daysCount
                                                }
                                            >
                                                <MultiSelect
                                                    isAllSelected={false}
                                                    items={
                                                        scheduleReportSettings.days
                                                    }
                                                    onSelectionChanged={selectedItems => {
                                                        updateRepeatSettings(
                                                            SCHEDULE_REPORT_FORM_FIELDS.selectedDays,
                                                            selectedItems.map(
                                                                element =>
                                                                    element.id
                                                            )
                                                        );

                                                        if (
                                                            selectedItems.length ===
                                                            0
                                                        ) {
                                                            let fieldValidationErrorArray =
                                                                {};
                                                            fieldValidationErrorArray =
                                                                {
                                                                    ...modalFieldErrors,
                                                                    [PROPERTY_FIELD_KEY_MAP.DAYS]:
                                                                        {
                                                                            error: true,
                                                                            helperText:
                                                                                REQUIRED
                                                                        }
                                                                };
                                                            setModalFieldErrors(
                                                                fieldValidationErrorArray
                                                            );
                                                        } else {
                                                            const modalFieldErrorsCopy =
                                                                cloneDeep(
                                                                    modalFieldErrors
                                                                );
                                                            delete modalFieldErrorsCopy[
                                                                PROPERTY_FIELD_KEY_MAP
                                                                    .DAYS
                                                            ];
                                                            setModalFieldErrors(
                                                                modalFieldErrorsCopy
                                                            );
                                                        }
                                                    }}
                                                    showSelectAllOption
                                                />
                                            </Select>

                                            {isFieldHavingError(
                                                PROPERTY_FIELD_KEY_MAP.DAYS,
                                                modalFieldErrors
                                            ) && (
                                                <FormHelperText error>
                                                    {getErrorHelperText(
                                                        PROPERTY_FIELD_KEY_MAP.DAYS
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                    {scheduleReportSettings.repeatUnit ===
                                        REPEAT_OPTIONS_MAP.MONTHS && (
                                        <TextField
                                            error={isFieldHavingError(
                                                PROPERTY_FIELD_KEY_MAP.DAYS_NUMBER,
                                                modalFieldErrors
                                            )}
                                            fullWidth
                                            helperText={getErrorHelperText(
                                                PROPERTY_FIELD_KEY_MAP.DAYS_NUMBER
                                            )}
                                            id="on-input-field"
                                            onBlur={e => {
                                                const { value } = e.target;
                                                let newValue = value;
                                                if (Number(value) < 1) {
                                                    newValue = '1';
                                                }
                                                if (Number(value) > 31) {
                                                    newValue = '31';
                                                }
                                                updateRepeatSettings(
                                                    SCHEDULE_REPORT_FORM_FIELDS.onDayNumber,
                                                    newValue
                                                );
                                            }}
                                            onChange={e => {
                                                const { value } = e.target;
                                                if (
                                                    value === '' ||
                                                    (Number(value) >= 0 &&
                                                        Number(value) <= 9999)
                                                ) {
                                                    fieldOnChangeHandler(
                                                        PROPERTY_FIELD_KEY_MAP.DAYS_NUMBER,
                                                        e.target.value,
                                                        SCHEDULE_MODAL_TIME_FIELDS[
                                                            PROPERTY_FIELD_KEY_MAP
                                                                .DAYS_NUMBER
                                                        ].type,
                                                        SCHEDULE_MODAL_TIME_FIELDS[
                                                            PROPERTY_FIELD_KEY_MAP
                                                                .DAYS_NUMBER
                                                        ].validations,
                                                        setScheduleReportSettings
                                                    );
                                                }
                                            }}
                                            onKeyDown={e => {
                                                if (
                                                    e.key === '.' ||
                                                    e.key === '-'
                                                ) {
                                                    e.preventDefault(); // Prevents input of decimal points
                                                }
                                            }}
                                            type="number"
                                            value={
                                                scheduleReportSettings.onDayNumber
                                            }
                                        />
                                    )}
                                </StyledField>
                            </Grid>
                        )}
                        <Grid item>
                            <StyledField>
                                <FormLabel>At</FormLabel>
                                <TimePicker
                                    onChange={(value: any) =>
                                        updateRepeatSettings('time', value)
                                    }
                                    value={scheduleReportSettings.time}
                                />
                            </StyledField>
                        </Grid>

                        {Object.keys(REPORT_MAIL_FIELDS).map(field => (
                            <EditField
                                key={REPORT_MAIL_FIELDS[field].key}
                                disable={false}
                                error={isFieldHavingError(
                                    field,
                                    modalFieldErrors
                                )}
                                gridSize={REPORT_MAIL_FIELDS[field].gridSize}
                                helperText={getErrorHelperText(field)}
                                id={REPORT_MAIL_FIELDS[field].key}
                                isFirstElement={
                                    REPORT_MAIL_FIELDS[field].isFirstElement
                                }
                                label={REPORT_MAIL_FIELDS[field].label}
                                onChange={debounce(value => {
                                    fieldOnChangeHandler(
                                        REPORT_MAIL_FIELDS[field].key,
                                        value,
                                        REPORT_MAIL_FIELDS[field].type,
                                        REPORT_MAIL_FIELDS[field].validations,
                                        setScheduleReportSettings
                                    );
                                }, 500)}
                                optional={REPORT_MAIL_FIELDS[field].optional}
                                type={REPORT_MAIL_FIELDS[field].type}
                                value={
                                    getValue(
                                        REPORT_MAIL_FIELDS[field].key
                                    ) as string
                                }
                            />
                        ))}

                        {!scheduleReportSettings.email
                            .split(',')
                            .includes(currentUser.username) && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        const { email: scheduledEmail } =
                                            scheduleReportSettings;
                                        const email = scheduledEmail
                                            .trim()
                                            .replace(/,+$/, '')
                                            ? [
                                                  ...scheduledEmail
                                                      .trim()
                                                      .replace(/,+$/, '')
                                                      .split(','),
                                                  currentUser.username
                                              ].join(',')
                                            : currentUser.username;

                                        updateRepeatSettings(
                                            SCHEDULE_REPORT_FORM_FIELDS.email,
                                            email
                                        );
                                        const modalFieldErrorsCopy =
                                            cloneDeep(modalFieldErrors);
                                        delete modalFieldErrorsCopy[
                                            PROPERTY_FIELD_KEY_MAP.EMAIL
                                        ];
                                        setModalFieldErrors(
                                            modalFieldErrorsCopy
                                        );
                                    }}
                                    size="medium"
                                    style={{ marginBottom: '10px' }}
                                    variant="text"
                                >
                                    Insert my email
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

export default ScheduleReport;
