import { DraggableLocation } from '@hello-pangea/dnd';
import { PaletteMode } from '@mui/material';
import { compact, has, mapValues, omit, pick, some } from 'lodash';
import {
    columnchart,
    gaugechart,
    linechart,
    overviewText,
    piechart,
    segmentDivider,
    segmentOverviewTitle,
    segmentTitle,
    singlevaluechart,
    tablechart,
    PageBreak,
    TableIcon,
    TableSummaryIcon,
    TextWithVariablesDark,
    TextWithVariablesLight,
    ImageElement,
    CustomSummaryGraphic
} from 'src/assets/images';
import { IMAGE_REQUIRED } from 'src/constants/LabelText';

import {
    CustomSummaryGraphicDataContentType,
    ImageElementType,
    ImageReportDataContent,
    ReportElementErrorType,
    ReportFieldDataContentType,
    ReportFieldDataContentWithConfigurationType,
    ReportletElementType,
    TableReportDataContentType,
    TableSummaryElementType,
    TableSummaryReportDataContentType,
    TextWithVariablesReportDataContent
} from './CreateReport.types';
import { defaultReportSettingsData } from './data';
import { RowData } from './TableSummary/utils';
import {
    sanitizeTextVariableElement,
    sanitizeTextVariableElementResponse
} from './TextWithVariables/utils';

export const supportedImageTypes = ['image/png', 'image/jpeg'];

export const isFieldHavingError = (
    reportElementError: ReportElementErrorType,
    id: string | undefined,
    colName: string
) => {
    const colId = id ?? '';

    if (reportElementError[colId]) {
        const errorElement = reportElementError[colId].find(
            single => single.colName === colName
        );

        if (errorElement?.isPristine) return false;
        return errorElement?.error || false;
    }

    return false;
};

export const getFieldErrorMessage = (
    reportElementError: ReportElementErrorType,
    id: string | undefined,
    colName: string
) => {
    const colId = id ?? '';

    if (reportElementError[colId]) {
        const errorElement = reportElementError[colId].find(
            single => single.colName === colName
        );

        if (errorElement?.isPristine) return '';
        if (errorElement?.error) {
            return errorElement.colErrorMessage;
        }
    }

    return '';
};

export const readableErrorTerms = {
    segment_title: 'segment title',
    segment_overview_title: 'segment overview title',
    title: 'title'
};

export const getLengthErrorMessage = (key: string, length = 150) => {
    const readableTerm =
        readableErrorTerms?.[key as keyof typeof readableErrorTerms] ?? key;
    return `Max ${length} characters are allowed in ${readableTerm}`;
};

export const EDITOR_MAX_LIMIT_ERROR = "You've reached the max character limit.";
export const REQUIRE_FIELD_ERROR = 'This field is required';
export const EMPTY_TABLE_ERROR = 'Require at least one data to add';
export const ALREADY_EXIST_TABLE_ERROR = 'Table is already exist';

/**
 * Moves an item from one list to another list.
 */
export const move = (
    source: string,
    destination: string,
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation
) => {
    if (!destination) return {};

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {} as Record<string, string[]>;
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export const renderChartSvg = (type: string, theme?: PaletteMode) => {
    switch (type) {
        case 'COLUMN':
            return columnchart;
        case 'TABLE':
            return tablechart;
        case 'GAUGE':
            return gaugechart;
        case 'LINE':
            return linechart;
        case 'DONUT':
            return piechart;
        case 'COUNT':
            return singlevaluechart;
        case 'segment_title':
            return segmentTitle;
        case 'segment_overview_title':
            return segmentOverviewTitle;
        case 'overview_text':
            return overviewText;
        case 'line_break':
            return segmentDivider;
        case 'page_break':
            return PageBreak;
        case 'table':
            return TableIcon;
        case 'table_summary':
            return TableSummaryIcon;
        case 'text_api_variables':
            if (theme === 'dark') {
                return TextWithVariablesDark;
            }
            return TextWithVariablesLight;
        case 'image':
            return ImageElement;
        case 'custom_summary_graphics':
            return CustomSummaryGraphic;
        default:
            return '';
    }
};

export const makeReportFieldDirty = (
    reportElementError: ReportElementErrorType
): ReportElementErrorType =>
    mapValues(reportElementError, arr =>
        arr.map(item => omit(item, 'isPristine'))
    );

export const hasReportFieldError = (
    reportElementError: ReportElementErrorType
): boolean =>
    some(reportElementError, arr =>
        arr.some(item => !(has(item, 'isPristine') && item.isPristine))
    );

type ConfigurationWithImage = TableSummaryElementType | ImageElementType;

const sanitizeImageElement = (
    report: ReportFieldDataContentWithConfigurationType<ConfigurationWithImage>,
    elements: File[],
    order: number
) => {
    if (report.reportElement?.configuration?.image) {
        const uploadImage = report.reportElement?.configuration?.image;
        const newFileName = `element_${order}_${uploadImage.name}`;
        const renamedImage = new File([uploadImage], newFileName, {
            type: uploadImage.type
        });
        elements.push(renamedImage);

        delete report.reportElement.configuration.image;
    }
};

const sanitizeCustomSummaryGraphicsImage = (
    report: CustomSummaryGraphicDataContentType,
    elements: File[],
    order: number
) => {
    if (report.reportElement?.configuration?.sections) {
        report.reportElement?.configuration?.sections.forEach(
            (section, index) => {
                if (section?.image) {
                    const uploadImage = section?.image;
                    const newFileName = `element_${order}_section_${section.sectionId}_${uploadImage.name}`;
                    const renamedImage = new File([uploadImage], newFileName, {
                        type: uploadImage.type
                    });
                    elements.push(renamedImage);
                    report.reportElement.configuration.sections[
                        index
                    ].imageFileName = newFileName;
                    delete report.reportElement.configuration.sections[index]
                        .image;
                }
            }
        );
    }
};

const sanitizeTables = (
    report: TableSummaryReportDataContentType,
    tableData: RowData[]
) => {
    report.reportElement.configuration.tables = tableData;
};

export const sanitizePayload = (
    report: ReportFieldDataContentType,
    elements: File[],
    order: number,
    tableData: RowData[]
) => {
    switch (report?.reportElement?.elementType ?? '') {
        case 'table_summary':
            sanitizeImageElement(
                report as TableSummaryReportDataContentType,
                elements,
                order
            );
            sanitizeTables(
                report as TableSummaryReportDataContentType,
                tableData
            );
            break;
        case 'text_api_variables':
            sanitizeTextVariableElement(
                report as TextWithVariablesReportDataContent
            );
            break;
        case 'image':
            sanitizeImageElement(
                report as ImageReportDataContent,
                elements,
                order
            );
            break;
        case 'custom_summary_graphics':
            sanitizeCustomSummaryGraphicsImage(
                report as CustomSummaryGraphicDataContentType,
                elements,
                order
            );
            break;
        default:
        // pass
    }
};

export const sanitizeResponse = (report: ReportFieldDataContentType) => {
    switch (report.reportElement?.elementType ?? '') {
        case 'text_api_variables':
            return sanitizeTextVariableElementResponse(
                report as TextWithVariablesReportDataContent
            );
        default:
            return report;
    }
};

export const generateReportSettingsPostData = (data: Record<string, any>) => {
    const postData: Record<string, string | number> = {};
    postData.watermarkOpacity = data.watermarkOpacity;
    postData.watermarkAppearance = data.watermarkAppearance;

    if (data.logoPath) {
        postData.logoPath = data.logoPath;
    }
    if (data.watermarkImageName) {
        postData.watermarkImageName = data.watermarkImageName;
    }
    return postData;
};

const reportSettingsKey = [
    'logoPath',
    'watermarkImageName',
    'watermarkOpacity',
    'watermarkAppearance'
];

export const getReportSettingsData = (data: any) => {
    const updatedData = pick(data, reportSettingsKey);
    return { ...defaultReportSettingsData, ...updatedData };
};

export const changeReportElementVisibility = (
    items: ReportletElementType[],
    key: string,
    hide: boolean
) => items.map(item => (item.id === key ? { ...item, hide } : item));

export const getDefaultTableSummaryError = (hasTableEntry: boolean) => {
    const error = [
        {
            colName: 'title',
            error: true,
            isPristine: true,
            colErrorMessage: REQUIRE_FIELD_ERROR
        },
        {
            colName: 'image',
            error: true,
            isPristine: true,
            colErrorMessage: IMAGE_REQUIRED
        }
    ];

    if (!hasTableEntry) {
        error.push({
            colName: 'tables',
            error: true,
            isPristine: true,
            colErrorMessage: EMPTY_TABLE_ERROR
        });
    }

    return error;
};

export const getSummaryTableRows = (fieldsData: ReportFieldDataContentType[]) =>
    fieldsData
        .filter(
            data =>
                data?.reportElement?.elementType === 'table' &&
                (data as TableReportDataContentType).reportElement.configuration
                    .includeInSummary
        )
        .map(data => ({
            title: (data as TableReportDataContentType).reportElement
                .configuration.title
        }));

export const getTableIndex = (
    fieldsData: ReportFieldDataContentType[],
    rowData: RowData
) =>
    fieldsData.findIndex(data => {
        if (data?.reportElement?.elementType === 'table') {
            return (
                (data as TableReportDataContentType).reportElement.configuration
                    .title === rowData.title
            );
        }
        return false;
    });

export const reorderTableSummaryData = (
    fieldData: ReportFieldDataContentType[],
    data: RowData[]
): ReportFieldDataContentType[] => {
    const reorderedTables = compact(
        data.map((row: RowData) =>
            fieldData.find(item => {
                if (item?.reportElement?.elementType === 'table') {
                    return (item as TableReportDataContentType).reportElement
                        .configuration.title === row.title
                        ? item
                        : undefined;
                }
                return undefined;
            })
        )
    );

    const updatedFieldData = fieldData.map(item =>
        item?.reportElement?.elementType === 'table' &&
        (item as TableReportDataContentType).reportElement.configuration
            .includeInSummary
            ? reorderedTables.shift() || item
            : item
    );

    return updatedFieldData;
};

export const checkTableKeyExist = (
    fieldsData: ReportFieldDataContentType[],
    id: number,
    key: 'title' | 'includeInSummary',
    value: string | boolean
) =>
    fieldsData.some((data, index) => {
        if (data?.reportElement?.elementType === 'table' && index !== id) {
            return (
                (data as TableReportDataContentType).reportElement
                    .configuration[key] === value
            );
        }
        return false;
    });
