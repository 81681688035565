import { useState } from 'react';

import {
    Button,
    constants,
    CrossIcon,
    ErrorTri,
    IconButton,
    Loader,
    TabGroup,
    Tooltip
} from '@armis/armis-ui-library';
import { Divider, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
import { GrConfigure } from 'react-icons/gr';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';

import ConfigColumn from './ConfigColumn';
import {
    StyledActions,
    StyledColumnTitle,
    StyledContent,
    StyledDrawer,
    StyledNoInputsLabel,
    StyledSpan,
    StyledTitle
} from './ConfigDrawer.style';
import {
    discardConfigMessage,
    getDeleteColumnMessage,
    tenantTypeList
} from './utils';
import MultiSelectSection from '../../common/MultiSelectSection';
import SelectSection from '../../common/SelectSection';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';
import { ColumnType } from '../../DashBoard.types';
import { MAX_COLUMN_LENGTH } from '../../data';
import { DASHBOARD_CONFIGURE_TOAST_ID, getMaxEntryError } from '../../utils';

const errorIcon = <ErrorTri height={16} width={16} />;

const ConfigDrawer = () => {
    const [isColumnRemoveModalOpen, setIsColumnRemoveModalOpen] =
        useState(false);
    const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
    const {
        open,
        isLoading,
        isSaveDisabled,
        handleOpen,
        formData,
        handleClose,
        handleTenantChange,
        tenantsLabel,
        tenants,
        columnData,
        activeColumn,
        setActiveColumn,
        columnHasError,
        handleSave
    } = useDashboardConfigContext();

    const {
        control,
        watch,
        handleSubmit,
        formState: { isDirty, errors }
    } = formData;

    const {
        fields: columnFields,
        append: addColumn,
        remove: removeColumn
    } = columnData;

    const columns = watch(`ampDashboardColumnDtos`);

    const handleAddColumn = () => {
        const { length } = columnFields;

        if (length === MAX_COLUMN_LENGTH) {
            showToast(
                getMaxEntryError('Columns', MAX_COLUMN_LENGTH),
                TOAST_TYPE.WARNING,
                DASHBOARD_CONFIGURE_TOAST_ID
            );
            return;
        }
        addColumn({
            title: `Column ${length + 1}`,
            columnType: ColumnType.NUMBER,
            orderNumber: 0
        });
        setActiveColumn(length);
    };

    return (
        <>
            <Modal
                isModalOpen={isColumnRemoveModalOpen}
                onCancel={() => setIsColumnRemoveModalOpen(false)}
                onSubmit={() => {
                    removeColumn(activeColumn);
                    if (activeColumn === columnFields.length - 1) {
                        setActiveColumn(activeColumn - 1);
                    }
                    setIsColumnRemoveModalOpen(false);
                }}
                submitBtnLabel="Delete"
                title="Delete Column"
            >
                <WarningModalContainer
                    text={getDeleteColumnMessage(
                        columns?.[activeColumn]?.title
                    )}
                />
            </Modal>
            <Modal
                isModalOpen={isCloseModalOpen}
                onCancel={() => setIsCloseModalOpen(false)}
                onSubmit={() => {
                    handleClose();
                    setIsCloseModalOpen(false);
                }}
                submitBtnLabel="Continue"
                title="Discard Changes"
            >
                <WarningModalContainer text={discardConfigMessage} />
            </Modal>
            <Button
                className="header-add-button"
                disabled={isLoading}
                onClick={handleOpen}
                startIcon={
                    isLoading ? (
                        <Loader
                            fade={false}
                            loaderPosition={{ right: 16 }}
                            loaderType={{
                                name: 'spinner',
                                size: 16
                            }}
                            show
                        >
                            <div className="circle" data-testid="loader-dots" />
                        </Loader>
                    ) : (
                        <GrConfigure height={16} width={16} />
                    )
                }
                style={{ paddingInline: '16px', height: '32px' }}
                variant="outlined"
            >
                Configure
            </Button>
            <StyledDrawer anchor="right" open={open}>
                <StyledTitle>
                    <div>
                        Configure{' '}
                        <span className="dashboard-title">Asset Inventory</span>
                    </div>
                    <IconButton
                        className="Icon-Hover-Effect"
                        onClick={() => {
                            if (isDirty) {
                                setIsCloseModalOpen(true);
                            } else {
                                handleClose();
                            }
                        }}
                    >
                        <CrossIcon />
                    </IconButton>
                </StyledTitle>
                <Divider style={{ marginBottom: '4px' }} />
                <StyledContent>
                    <SelectSection
                        fullWidth
                        handleChange={() => {}}
                        items={tenantTypeList}
                        title="Type of Tenant"
                        value="centrix"
                    />
                    <Controller
                        control={control}
                        name="ampDashboardTenantDtos"
                        render={({ field, fieldState: { error } }) => (
                            <MultiSelectSection
                                error={error?.message}
                                fullWidth
                                handleChange={selectedItems => {
                                    handleTenantChange(selectedItems);
                                    field.onChange(
                                        selectedItems.map(({ id }) => ({
                                            tenantDto: { id }
                                        }))
                                    );
                                }}
                                items={tenants}
                                title="Tenants"
                                value={tenantsLabel}
                            />
                        )}
                    />
                    <Divider style={{ marginBottom: '8px' }} />
                    <StyledColumnTitle>
                        <FormLabel>Column</FormLabel>
                        <Button
                            className="header-add-button"
                            onClick={handleAddColumn}
                            startIcon={<IoIosAdd height={16} width={16} />}
                            variant="outlined"
                        >
                            Add
                        </Button>
                        {columnFields.length > 0 && (
                            <Button
                                className="header-add-button"
                                onClick={() => setIsColumnRemoveModalOpen(true)}
                                startIcon={<IoMdTrash height={16} width={16} />}
                                style={{
                                    border: `1px solid ${constants.COLOR_10}`,
                                    color: constants.COLOR_10
                                }}
                                variant="outlined"
                            >
                                Remove
                            </Button>
                        )}
                    </StyledColumnTitle>
                    <TabGroup
                        onChange={(_, value) => {
                            setActiveColumn(value);
                        }}
                        sx={{
                            paddingBottom: '4px',
                            height: '36px',
                            '.tab': {
                                padding: '7px 8px !important',
                                marginInline: '4px !important',
                                height: '32px !important',
                                lineHeight: '1 !important'
                            }
                        }}
                        tabsItems={columnFields.map((_, index) => ({
                            label: (
                                <Tooltip
                                    arrow
                                    placement="bottom"
                                    title={
                                        <div className="tooltip-arrow-text">
                                            {columns?.[index].title}
                                        </div>
                                    }
                                >
                                    <StyledSpan>
                                        {columns?.[index].title}
                                    </StyledSpan>
                                </Tooltip>
                            ),
                            tabValue: index,
                            ...(columnHasError(index) && {
                                icon: errorIcon,
                                iconPosition: 'start'
                            })
                        }))}
                        value={activeColumn}
                    />
                    {columnFields.length > 0 && (
                        <ConfigColumn key={activeColumn} />
                    )}
                    {columnFields.length === 0 && (
                        <StyledNoInputsLabel>
                            No Column added.
                        </StyledNoInputsLabel>
                    )}
                </StyledContent>
                <Divider />
                <StyledActions>
                    <Button
                        className="header-add-button"
                        color="primary"
                        disabled={
                            !columnFields.length ||
                            Object.keys(errors).length > 0 ||
                            isSaveDisabled
                        }
                        onClick={handleSubmit(handleSave)}
                        startIcon={
                            isSaveDisabled ? (
                                <Loader
                                    fade={false}
                                    loaderPosition={{ right: 16 }}
                                    loaderType={{
                                        name: 'spinner',
                                        size: 16
                                    }}
                                    show
                                >
                                    <div
                                        className="circle"
                                        data-testid="loader-dots"
                                    />
                                </Loader>
                            ) : null
                        }
                        style={{ paddingInline: '24px', height: '32px' }}
                        variant="contained"
                    >
                        Save
                    </Button>
                </StyledActions>
            </StyledDrawer>
        </>
    );
};

export default ConfigDrawer;
