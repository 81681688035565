import { styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const StyledModal = styled(Modal)`
    & .MuiDialogContent-root {
        line-height: 1;

        > div {
            padding-bottom: 10pt;
        }
    }
`;
