import { FC } from 'react';

import { Button, Plus } from '@armis/armis-ui-library';
import { cloneDeep } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import { MAX_SECTIONS_ERROR } from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';

import {
    CustomSummaryGraphicDataContentType,
    ReportElementErrorType,
    ReportFieldDataContentType
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';
import { SummaryTextField } from './utils';
import TextFieldSection from '../common/TextFieldSection';
import { StyledField } from '../CreateReport.style';
import { TableTextField } from '../Table/utils';
import Section from './components/Section';

type CustomSummaryProps = {
    item: CustomSummaryGraphicDataContentType;
    selectedDashboardIds: any;
    reportElementError: ReportElementErrorType;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleError: (error: string, key: string, id: string) => void;
    deleteItem: (id: string | undefined) => void;
    addErrorForNewSection: (
        item: ReportFieldDataContentType,
        key: string
    ) => void;
    handleRemoveErrorForDeletedSection: (
        item: ReportFieldDataContentType,
        key: string[]
    ) => void;
};

const CustomSummaryGraphicElement: FC<CustomSummaryProps> = ({
    item,
    selectedDashboardIds,
    deleteItem,
    handleReportElementChange,
    handleError,
    reportElementError,
    addErrorForNewSection,
    handleRemoveErrorForDeletedSection
}) => {
    const id = item.dragAndDropId ?? '';

    const handleTextChange = (key: SummaryTextField, value: string) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration[key] = value;
        handleReportElementChange(updatedItem);
    };
    const addSection = () => {
        if (item.reportElement.configuration.sections.length >= 3) {
            showToast(MAX_SECTIONS_ERROR, TOAST_TYPE.WARNING, TOAST_ID);
            return;
        }
        const updatedItem = cloneDeep(item);
        const sectionId =
            updatedItem.reportElement.configuration.sections.length + 1;
        updatedItem.reportElement.configuration.sections.push({
            sectionId,
            table: [],
            imageFileName: ''
        });
        addErrorForNewSection(item, sectionId.toString());
        handleReportElementChange(updatedItem);
    };

    const sectionsToRender = item.reportElement.configuration.sections.map(
        section => (
            <Section
                key={section.sectionId}
                currentSection={section}
                handleError={handleError}
                handleRemoveErrorForDeletedSection={
                    handleRemoveErrorForDeletedSection
                }
                handleReportElementChange={handleReportElementChange}
                id={id}
                item={item}
                itemKey={section.sectionId.toString()}
                reportElementError={reportElementError}
                rowsData={section.table}
            />
        )
    );

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Custom Summary Graphic"
        >
            <TextFieldSection
                handleChange={value => {
                    handleTextChange(SummaryTextField.TITLE, value);
                }}
                handleError={error => {
                    handleError(error, TableTextField.TITLE, id);
                }}
                id={id}
                itemKey={TableTextField.TITLE}
                reportElementError={reportElementError}
                title="Title"
                value={item.reportElement.configuration.title}
            />

            <StyledField className="flex-end">
                <Button
                    className="header-add-button"
                    color="primary"
                    onClick={e => {
                        e.currentTarget.blur();
                        addSection();
                    }}
                    startIcon={<Plus className="plus-icon" />}
                    style={{
                        paddingLeft: '15px',
                        height: '32px'
                    }}
                    variant="outlined"
                >
                    Add Section
                </Button>
            </StyledField>

            {sectionsToRender}
        </ReportElementContainer>
    );
};

export default CustomSummaryGraphicElement;
