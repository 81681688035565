import React, { useState } from 'react';

import {
    constants,
    IconButton,
    Select,
    TextBox,
    EyeIcon,
    EyeHideIcon
} from '@armis/armis-ui-library';
import {
    FormHelperText,
    FormLabel,
    InputAdornment,
    MenuItem
} from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { OPTIONAL, SECRET_KEY } from 'src/constants/LabelText';
import {
    StyledField,
    StyledTextArea
} from 'src/pages/components/ModalField/ModalField.style';

type ModalFieldTypes = {
    index: number;
    labelName?: string;
    onChange: (fieldValue: string, labelName?: string, type?: string) => void;
    value?: string | number | string[];
    error: boolean;
    helperText: string;
    type?: string;
    items?: string[];
    disable?: boolean;
    optional?: boolean;
    inputType?: string;
    placeHolder?: string;
};

export const ModalField = ({
    index,
    labelName,
    onChange,
    value,
    error,
    disable,
    helperText,
    type = FIELD_TYPE_MAP.TEXT,
    items = [],
    optional,
    inputType = 'text',
    placeHolder
}: ModalFieldTypes) => {
    let elementType;
    const [passwordType, setPasswordType] = useState(inputType);
    if (type === FIELD_TYPE_MAP.TEXT) {
        elementType = (
            <TextBox
                autoComplete="off"
                disabled={disable}
                error={error}
                fullWidth
                helperText={helperText}
                hiddenLabel
                id={`partner-name-input-${labelName}`}
                InputProps={{
                    endAdornment:
                        labelName === SECRET_KEY ? (
                            <InputAdornment
                                onClick={() => {
                                    setPasswordType(prevValue =>
                                        prevValue === FIELD_TYPE_MAP.TEXT
                                            ? FIELD_TYPE_MAP.PASSWORD
                                            : FIELD_TYPE_MAP.TEXT
                                    );
                                }}
                                position="end"
                                variant="outlined"
                            >
                                <IconButton className="Icon-Hover-Effect">
                                    {passwordType ===
                                    FIELD_TYPE_MAP.PASSWORD ? (
                                        <EyeIcon />
                                    ) : (
                                        <EyeHideIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            !disable &&
                            value && (
                                <InputAdornment
                                    onClick={() =>
                                        onChange('', labelName, type)
                                    }
                                    position="end"
                                    variant="outlined"
                                >
                                    <IoIosClose />
                                </InputAdornment>
                            )
                        )
                }}
                onChange={e => onChange(e.target.value, labelName, type)}
                placeholder={placeHolder}
                size="small"
                type={labelName === SECRET_KEY ? passwordType : inputType}
                value={value as string}
                variant="outlined"
            />
        );
    } else if (type === FIELD_TYPE_MAP.DROPDOWN) {
        elementType = (
            <Select
                disabled={disable}
                error={error}
                MenuProps={{
                    sx: { zIndex: 10002 }
                }}
                onChange={e =>
                    onChange(e.target.value as string, labelName, type)
                }
                placeholder={placeHolder}
                sx={{
                    width: '100%'
                }}
                value={value}
                variant="outlined"
            >
                {items.map((element, index1) => (
                    <MenuItem key={index1} value={index1}>
                        {element}
                    </MenuItem>
                ))}
            </Select>
        );
    } else if (type === FIELD_TYPE_MAP.TEXTAREA) {
        elementType = (
            <>
                <StyledTextArea
                    className="modal-text-field"
                    hasError={!!helperText}
                    id={`modal-field-input-${labelName}`}
                    maxRows="5"
                    minRows="5"
                    onChange={e => {
                        onChange(e.target.value as string, labelName, type);
                    }}
                    value={value as string}
                />
                {helperText && (
                    <FormHelperText error>{helperText}</FormHelperText>
                )}
            </>
        );
    }
    return (
        <StyledField key={index}>
            {labelName && (
                <FormLabel htmlFor={`partner-name-input-${labelName}`}>
                    {labelName}{' '}
                    {optional ? (
                        <span
                            style={{
                                fontSize: '1rem',
                                fontFamily:
                                    constants.PROXIMANOVA_W08_THIN_REGULAR
                            }}
                        >
                            {OPTIONAL}
                        </span>
                    ) : null}
                </FormLabel>
            )}
            {elementType || null}
        </StyledField>
    );
};
