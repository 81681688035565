import { FC, useEffect, useRef, useState } from 'react';

import { Button } from '@armis/armis-ui-library';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { LuLayoutDashboard } from 'react-icons/lu';

import AddPopper from './AddPopper';
import Tab from './Tab';
import {
    StyledIconButton,
    StyledTabs,
    StyledTabsContainer
} from './TabsContainer.style';
import { useDashboardContext } from '../../context/DashboardContext';
import ReorderModal from '../ReorderModal';

const TabsContainer: FC = () => {
    const { tabs, removeTab, selectedTab, handleTabChange } =
        useDashboardContext();
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const tabContainerRef = useRef<HTMLDivElement | null>(null);
    const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);

    const updateScrollButtons = () => {
        if (tabContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } =
                tabContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft + clientWidth + 1 < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();

        const container = tabContainerRef.current;
        if (container) {
            container.addEventListener('scroll', updateScrollButtons);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', updateScrollButtons);
            }
        };
    }, [tabs]);

    const scrollTabs = (direction: 'left' | 'right') => {
        if (tabContainerRef.current) {
            const scrollAmount = 188;
            tabContainerRef.current.scrollBy({
                left: direction === 'left' ? -scrollAmount : scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <>
            <StyledTabsContainer>
                <AddPopper />
                <StyledTabs ref={tabContainerRef}>
                    {tabs.map(tab => (
                        <Tab
                            key={tab.id}
                            handleRemoveTab={removeTab}
                            handleTabChange={() => handleTabChange(tab)}
                            selectedTab={tab.id === selectedTab?.id}
                            tab={tab}
                        />
                    ))}
                </StyledTabs>
                <StyledIconButton
                    className="Icon-Hover-Effect"
                    disabled={!canScrollLeft}
                    onClick={() => scrollTabs('left')}
                    size="small"
                    sx={{ fontSize: '16px' }}
                >
                    <IoIosArrowBack />
                </StyledIconButton>

                <StyledIconButton
                    className="Icon-Hover-Effect"
                    disabled={!canScrollRight}
                    onClick={() => scrollTabs('right')}
                    size="small"
                    sx={{ fontSize: '16px' }}
                >
                    <IoIosArrowForward />
                </StyledIconButton>
                <Button
                    className="header-add-button"
                    onClick={() => {
                        setIsReorderModalOpen(true);
                    }}
                    startIcon={<LuLayoutDashboard />}
                    style={{ paddingInline: '16px' }}
                    variant="text"
                >
                    Reorder
                </Button>
            </StyledTabsContainer>

            {isReorderModalOpen && (
                <ReorderModal
                    isModalOpen
                    onClose={() => {
                        setIsReorderModalOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default TabsContainer;
