import { FC } from 'react';

import { IconButton, IconDelete, IconPencil } from '@armis/armis-ui-library';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { FieldType } from 'src/types/CommonTypes';

export interface RowData {
    title: string;
    asq: string;
}

export enum TableCheckBoxField {
    HIDE = 'hideIfSumZero',
    RECOMMENDATIONS = 'recommendationsCheckbox',
    INCLUDE_IN_SUMMARY = 'includeInSummary'
}

export enum TableTextField {
    TITLE = 'title',
    BEFORE = 'before',
    AFTER = 'after',
    RECOMMENDATIONS = 'recommendations'
}

interface ActionCellRendererProps {
    onEdit: () => void;
    onDelete: () => void;
}

export const ActionCellRenderer: FC<ActionCellRendererProps> = ({
    onEdit,
    onDelete
}) => (
    <div>
        <IconButton
            aria-label="edit"
            className="Icon-Hover-Effect"
            onClick={e => {
                e.currentTarget.blur();
                onEdit();
            }}
        >
            <IconPencil />
        </IconButton>
        <IconButton
            aria-label="delete"
            className="Icon-Hover-Effect"
            onClick={e => {
                e.currentTarget.blur();
                onDelete();
            }}
        >
            <IconDelete />
        </IconButton>
    </div>
);

export const asqFormMetaData: FieldType[] = [
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: 'title',
        title: 'Title',
        validations: {
            required: true,
            maxLength: 250
        }
    },
    {
        type: FIELD_TYPE_MAP.TEXTAREA,
        labelName: 'asq',
        title: 'ASQ',
        validations: {
            required: true
        }
    }
];

export const initialData = {
    title: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    asq: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    }
};
