import { Controller } from 'react-hook-form';

import SelectSection from '../../common/SelectSection';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';

const ReportColumn = () => {
    const { activeColumn, formData, reportList } = useDashboardConfigContext();
    const { control } = formData;

    return (
        <Controller
            control={control}
            name={`ampDashboardColumnDtos.${activeColumn}.reportId`}
            render={({ field, fieldState: { error } }) => (
                <SelectSection
                    error={error?.message}
                    fullWidth
                    handleChange={field.onChange}
                    items={reportList}
                    placeholder="Select Report"
                    title="Report"
                    value={field.value || ''}
                />
            )}
        />
    );
};

export default ReportColumn;
