import { FC } from 'react';

import { IconDelete } from '@armis/armis-ui-library';
import { IconButton } from '@mui/material';

import {
    StyledButtonContainer,
    StyledContainer,
    StyledOperator,
    StyledOperatorContainer
} from './VariableInput.style';
import { VariableInputType } from './VariableInput.types';
import VariableName from './VariableName';
import VariableValue from './VariableValue';
import {
    ReportElementErrorType,
    TextWithVariablesReportDataContent
} from '../../CreateReport.types';
import { getFieldErrorMessage } from '../../utils';

type Props = {
    input: VariableInputType;
    item: TextWithVariablesReportDataContent;
    reportElementError: ReportElementErrorType;
    keyLabel: string;
    valueLabel: string;
    handleChange: (
        inputType: 'key' | 'value',
        input: VariableInputType
    ) => void;
    handleDelete: (id: string) => void;
};

const VariableInput: FC<Props> = ({
    item,
    input,
    handleChange,
    handleDelete,
    keyLabel,
    valueLabel,
    reportElementError
}) => {
    const itemId = item.dragAndDropId ?? '';
    const { id, key, value } = input;
    const valueKey = `value-${id}`;
    const nameKey = `name-${id}`;
    const valueError = getFieldErrorMessage(
        reportElementError,
        itemId,
        valueKey
    );
    const nameError = getFieldErrorMessage(reportElementError, itemId, nameKey);
    const hasError = Boolean(nameError) || Boolean(valueError);

    return (
        <StyledContainer>
            <VariableName
                error={nameError}
                handleChange={val => {
                    handleChange('key', { id, key: val, value });
                }}
                id={nameKey}
                label={keyLabel}
                value={key}
            />
            <StyledOperatorContainer>
                <StyledOperator hasError={hasError}>=</StyledOperator>
            </StyledOperatorContainer>
            <VariableValue
                error={valueError}
                handleChange={val => {
                    handleChange('value', { id, key, value: val });
                }}
                id={valueKey}
                label={valueLabel}
                value={value}
            />
            <StyledButtonContainer hasError={hasError}>
                <IconButton color="primary" onClick={() => handleDelete(id)}>
                    <IconDelete style={{ width: 20, height: 20 }} />
                </IconButton>
            </StyledButtonContainer>
        </StyledContainer>
    );
};

export default VariableInput;
