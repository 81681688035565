import { cloneDeep } from 'lodash';

import {
    CalculationAPICallConfigType,
    ColumnType,
    DashboardConfigType
} from './DashBoard.types';

export const REQUIRE_FIELD_ERROR = 'This field is required';
export const DASHBOARD_CONFIGURE_SUCCESSFUL =
    'Successfully configured the dashboard.';
export const DASHBOARD_CREATE_SUCCESSFUL = 'Dashboard created successfully';
export const DASHBOARD_CONFIGURE_TOAST_ID = 'dashboard-config-toast';

export const getLengthErrorMessage = (label: string, length = 150) =>
    `Max ${length} characters are allowed in ${label}`;

export const getMaxEntryError = (label: string, length: number) =>
    `You can only add up to ${length} ${label}`;

const CALCULATION_ERROR = 'CalculationExpressionError';

const calculationError = (message: string) => {
    const error = new Error(message);
    error.name = CALCULATION_ERROR;
    return error;
};

export const getDashboardNameError = (name: string) => {
    if (!name) {
        return REQUIRE_FIELD_ERROR;
    }
    if (name.length > 100) {
        return getLengthErrorMessage('name', 100);
    }
    return '';
};

export const getCalculationExpressionError = (
    expression: string,
    variables: CalculationAPICallConfigType[]
) => {
    try {
        // Create a map of variables to replace in the expression
        const variableMap = Object.fromEntries(
            variables.map(variable => [variable.variable, 1])
        );

        // Replace $variable references with 1 to parse the equation
        const parsedExpression = expression.replace(/\$(\w+)/g, (_, name) => {
            if (name in variableMap) return `${variableMap[name]}`;
            throw calculationError(`Variable '${name}' is not defined`);
        });

        const validPattern = /^[\d+\-*/%().\s$]+$/;
        if (!validPattern.test(parsedExpression)) {
            throw calculationError('Expression contains invalid characters');
        }

        // Validate the syntax of the parsed expression
        // The `Function` constructor here parses the expression for syntax errors
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        new Function(`return ${parsedExpression}`)();

        return false;
    } catch (error) {
        if (error instanceof Error && error.name === CALCULATION_ERROR) {
            return error.message;
        }
        return 'Expression is Invalid';
    }
};

export const getSanitizedConfigurationData = (data: DashboardConfigType) => {
    const cloneConfigData = cloneDeep(data);
    cloneConfigData.ampDashboardColumnDtos.forEach((column, index) => {
        column.orderNumber = index + 1;

        switch (column.columnType) {
            case ColumnType.NUMBER:
                delete column.ampDashboardColumnCalculations;
                delete column.ampDashboardColumnBarDtos;
                delete column.reportId;
                break;
            case ColumnType.CALCULATION:
                delete column.ampDashboardColumnNumberDto;
                delete column.ampDashboardColumnBarDtos;
                delete column.reportId;
                break;
            case ColumnType.BAR:
                delete column.ampDashboardColumnNumberDto;
                delete column.ampDashboardColumnCalculations;
                delete column.reportId;
                break;
            case ColumnType.REPORT_LINK:
                delete column.ampDashboardColumnNumberDto;
                delete column.ampDashboardColumnCalculations;
                delete column.ampDashboardColumnBarDtos;
                break;
            default:
            // pass
        }
    });

    return cloneConfigData;
};
