import { FC } from 'react';

import { FormLabel } from '@mui/material';
import { cloneDeep } from 'lodash';

import EditorSection from '../common/EditorSection';
import SelectSection from '../common/SelectSection';
import UploadImageSection from '../common/UploadImageSection';
import { StyledField, StyledVerticalContainer } from '../CreateReport.style';
import {
    ImageReportDataContent,
    ReportElementErrorType,
    ReportFieldDataContentType
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';
import { EDITOR_MAX_LIMIT_ERROR, getFieldErrorMessage } from '../utils';

type Props = {
    item: ImageReportDataContent;
    selectedDashboardIds: any;
    reportElementError: ReportElementErrorType;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleError: (error: string, key: string, id: string) => void;
    deleteItem: (id: string | undefined) => void;
};

enum Layout {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    ABOVE = 'ABOVE',
    BELOW = 'BELOW'
}

const layoutOptions = [
    {
        value: Layout.LEFT,
        label: Layout.LEFT
    },
    {
        value: Layout.RIGHT,
        label: Layout.RIGHT
    },
    {
        value: Layout.ABOVE,
        label: Layout.ABOVE
    },
    {
        value: Layout.BELOW,
        label: Layout.BELOW
    }
];

const ImageElement: FC<Props> = ({
    deleteItem,
    item,
    handleError,
    handleReportElementChange,
    reportElementError,
    selectedDashboardIds
}) => {
    const itemId = item.dragAndDropId ?? '';

    const handleFileChange = (file: File | undefined) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration.imageFileName = '';
        updatedItem.reportElement.configuration.image = file;
        handleReportElementChange(updatedItem);
    };

    const handleTextChange = (value: string) => {
        const tempState = { ...item };
        tempState.reportElement.configuration.description = value;
        if (value.length > 30_000) {
            handleError(EDITOR_MAX_LIMIT_ERROR, 'description', itemId);
        } else {
            handleError('', 'description', itemId);
        }
        handleReportElementChange(tempState);
    };

    const handleLayoutChange = (value: string) => {
        const tempState = { ...item };
        tempState.reportElement.configuration.layout = value;
        handleReportElementChange(tempState);
    };

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Image"
        >
            <StyledVerticalContainer>
                <UploadImageSection
                    error={getFieldErrorMessage(
                        reportElementError,
                        itemId,
                        'image'
                    )}
                    file={item.reportElement.configuration.image}
                    fileName={item.reportElement.configuration.imageFileName}
                    handleChange={handleFileChange}
                    handleError={error => handleError(error, 'image', itemId)}
                    isRequired
                    title=""
                />
                <SelectSection
                    handleChange={handleLayoutChange}
                    items={layoutOptions}
                    title="Layout"
                    value={item.reportElement.configuration.layout}
                />
            </StyledVerticalContainer>
            <StyledField>
                <FormLabel>Description</FormLabel>
                <EditorSection
                    id={itemId}
                    itemKey="description"
                    onChange={handleTextChange}
                    reportElementError={reportElementError}
                    value={item.reportElement.configuration.description}
                />
            </StyledField>
        </ReportElementContainer>
    );
};

export default ImageElement;
