import { constants, TextArea } from '@armis/armis-ui-library';
import { styled } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';

export const StyledTextArea = styled(TextArea, {
    shouldForwardProp: prop => prop !== 'hasError'
})<{ hasError?: boolean }>`
    width: 100%;
    border: ${({ hasError }) =>
        hasError && `1px solid ${constants.COLOR_5} !important`};
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 1.4rem;
`;

export const StyledMuiColorInput = styled(MuiColorInput)`
    & .MuiInputBase-input {
        padding-left: 8px;
    }
`;

export const StyledColorLabel = styled('div')`
    display: flex;
    justify-content: space-between;
`;
