import { Checkbox, FormLabel } from '@mui/material';

type CheckBoxSectionProps = {
    title: string;
    isChecked: boolean | undefined;
    handleChange: (value: boolean) => void;
};

const CheckBoxSection = ({
    title,
    isChecked,
    handleChange
}: CheckBoxSectionProps) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '10pt'
        }}
    >
        <Checkbox
            checked={isChecked}
            onChange={e => handleChange(e.target.checked)}
            style={{
                transform: 'scale(0.8)'
            }}
            sx={{
                width: '20px',
                height: '20px',
                marginRight: '5px'
            }}
        />
        <FormLabel>{title}</FormLabel>
    </div>
);

export default CheckBoxSection;
