import { FC } from 'react';

import { cloneDeep } from 'lodash';

import ColorPickerSection from '../common/ColorPickerSection';
import DescriptionSection from '../common/DescriptionSection';
import DraggableTable from '../common/DraggableTable';
import SelectSection from '../common/SelectSection';
import TextFieldSection from '../common/TextFieldSection';
import {
    ReportElementErrorType,
    ReportFieldDataContentType,
    TableSummaryRows,
    TableSummaryReportDataContentType
} from '../CreateReport.types';
import ReportElementContainer from '../ReportElementContainer';
import {
    RowData,
    summaryTableColumnDefs,
    TableSummaryTextField
} from './utils';
import UploadImageSection from '../common/UploadImageSection';
import { StyledVerticalContainer } from '../CreateReport.style';
import { layouts } from '../data';
import { getFieldErrorMessage } from '../utils';

type TableSummaryProps = {
    item: TableSummaryReportDataContentType;
    selectedDashboardIds: any;
    reportElementError: ReportElementErrorType;
    tableRows: TableSummaryRows;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    handleTableChange: (data: RowData[]) => void;
    handleError: (error: string, key: string, id: string) => void;
    deleteItem: (id: string | undefined) => void;
};

const TableSummary: FC<TableSummaryProps> = ({
    item,
    selectedDashboardIds,
    reportElementError,
    tableRows,
    handleReportElementChange,
    handleTableChange,
    handleError,
    deleteItem
}) => {
    const id = item.dragAndDropId ?? '';

    const handleTextChange = (key: TableSummaryTextField, value: string) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration[key] = value;
        handleReportElementChange(updatedItem);
    };

    const handleFileChange = (file: File | undefined) => {
        const updatedItem = cloneDeep(item);
        updatedItem.reportElement.configuration.imageFileName = '';
        updatedItem.reportElement.configuration.image = file;
        handleReportElementChange(updatedItem);
    };

    return (
        <ReportElementContainer
            deleteItem={deleteItem}
            item={item}
            selectedDashboardIds={selectedDashboardIds}
            title="Table Summary"
        >
            <DescriptionSection
                handleChange={value =>
                    handleTextChange(TableSummaryTextField.DESCRIPTION, value)
                }
                handleError={error =>
                    handleError(error, TableSummaryTextField.DESCRIPTION, id)
                }
                id={id}
                itemKey={TableSummaryTextField.DESCRIPTION}
                reportElementError={reportElementError}
                title="Description"
                value={item.reportElement.configuration.description}
            />
            <TextFieldSection
                handleChange={value =>
                    handleTextChange(TableSummaryTextField.TITLE, value)
                }
                handleError={error => {
                    handleError(error, TableSummaryTextField.TITLE, id);
                }}
                id={id}
                itemKey={TableSummaryTextField.TITLE}
                reportElementError={reportElementError}
                title="Title"
                value={item.reportElement.configuration.title}
            />
            <StyledVerticalContainer>
                <UploadImageSection
                    error={getFieldErrorMessage(
                        reportElementError,
                        id,
                        'image'
                    )}
                    file={item.reportElement.configuration.image}
                    fileName={item.reportElement.configuration.imageFileName}
                    handleChange={handleFileChange}
                    handleError={error => handleError(error, 'image', id)}
                    isRequired
                    title="Image"
                />
                <SelectSection
                    handleChange={value =>
                        handleTextChange(TableSummaryTextField.LAYOUT, value)
                    }
                    items={layouts}
                    title="Layout"
                    value={item.reportElement.configuration.layout}
                />
            </StyledVerticalContainer>
            <StyledVerticalContainer>
                <ColorPickerSection
                    handleChange={value =>
                        handleTextChange(
                            TableSummaryTextField.BACKGROUND_COLOR,
                            value
                        )
                    }
                    handleError={error => {
                        handleError(
                            error,
                            TableSummaryTextField.BACKGROUND_COLOR,
                            id
                        );
                    }}
                    hexColor={item.reportElement.configuration.backgroundColor}
                    id={id}
                    itemKey={TableSummaryTextField.BACKGROUND_COLOR}
                    reportElementError={reportElementError}
                    title="Background Color"
                />
                <ColorPickerSection
                    handleChange={value =>
                        handleTextChange(
                            TableSummaryTextField.TEXT_COLOR,
                            value
                        )
                    }
                    handleError={error => {
                        handleError(
                            error,
                            TableSummaryTextField.TEXT_COLOR,
                            id
                        );
                    }}
                    hexColor={item.reportElement.configuration.textColor}
                    id={id}
                    itemKey={TableSummaryTextField.TEXT_COLOR}
                    reportElementError={reportElementError}
                    title="Text Color"
                />
            </StyledVerticalContainer>
            <DraggableTable
                columnDefs={summaryTableColumnDefs}
                error={getFieldErrorMessage(reportElementError, id, 'tables')}
                handleTableChange={handleTableChange}
                rowsData={tableRows}
            />
        </ReportElementContainer>
    );
};

export default TableSummary;
