import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

import { StyledField } from '../DashBoard.style';

type TextFieldSelectionProps = {
    title: string;
    value: string;
    error?: string;
    placeholder?: string;
    handleChange: (value: string) => void;
};

const TextFieldSection = ({
    title,
    value,
    error,
    placeholder,
    handleChange
}: TextFieldSelectionProps) => (
    <StyledField>
        <FormLabel>{title}</FormLabel>
        <TextBox
            autoComplete="off"
            error={!!error}
            fullWidth
            helperText={error}
            hiddenLabel
            InputProps={{
                endAdornment: value && (
                    <InputAdornment
                        onClick={() => {
                            handleChange('');
                        }}
                        position="end"
                        variant="outlined"
                    >
                        <IoIosClose />
                    </InputAdornment>
                )
            }}
            onChange={e => {
                handleChange(e.target.value);
            }}
            placeholder={placeholder}
            size="small"
            value={value}
            variant="outlined"
        />
    </StyledField>
);

export default TextFieldSection;
