import { FC } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useTheme } from '@mui/material';
import { ClassicEditor, EditorConfig } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import './editor.css';

import { defaultEditorConfig } from './utils';

type EditorProps = {
    value: string;
    onChange: (value: string) => void;
    editorConfig?: EditorConfig;
};

export const Editor: FC<EditorProps> = ({ value, onChange, editorConfig }) => {
    const theme = useTheme();
    const { mode } = theme.palette;
    const isDarkTheme = mode === 'dark';

    const onReady = () => {
        // To apply dark theme on portal elements of the toolbar
        const wrapperDiv = document.querySelector('.ck-body-wrapper');
        wrapperDiv?.classList.add(mode);
        if (isDarkTheme) {
            wrapperDiv?.classList.remove('light');
        } else {
            wrapperDiv?.classList.remove('dark');
        }
    };

    return (
        <div className={isDarkTheme ? mode : ''}>
            <CKEditor
                key={mode}
                config={{
                    ...defaultEditorConfig,
                    ...editorConfig
                }}
                data={value}
                editor={ClassicEditor}
                onChange={(_, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
                onReady={onReady}
            />
        </div>
    );
};
