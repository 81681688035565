import { ChangeEvent, FC, useMemo, useState } from 'react';

import { constants, TagsWithInputBox, TextBox } from '@armis/armis-ui-library';
import { Editor, OnMount } from '@monaco-editor/react';
import {
    Box,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Switch,
    Typography,
    useTheme
} from '@mui/material';
import { AxiosResponse } from 'axios';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { IoIosClose } from 'react-icons/io';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    ADD_TAGS_PLACEHOLDER,
    ADVANCED_JSON_EDIT,
    CREATE_TAG_INFO,
    DASHBOARD_CREATED_SUCCESS,
    DASHBOARD_UPDATED_SUCCESS,
    DASHLET_REQUIRED_ERROR,
    POLICY_TEMPLATE_JSON_EDIT_INFO
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { DashletDataType } from 'src/pages/components/DashletPanel/DashletPanel.types';
import {
    createDashboardTemplate,
    updateDashboardTemplate
} from 'src/services/api.service';

import {
    StyledFormFieldContainer,
    StyledFormFields,
    StyledModal
} from './CreateEditDashboard.styles';
import DashletPanelContainer from './DashletPanelContainer';
import { Dashboard, HOCLoading } from '../../DashboardTemplate.types';
import {
    defaultDashboardStructure,
    defaultState,
    getDashboardNameError,
    getInitialState,
    getSearchedTags,
    parseDashboardError,
    parseDashboardJson,
    readonlyTags
} from '../../utils';
import DiscardChangesModal from '../DiscardChanges';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    setIsLoading: HOCLoading;
    onSubmit: () => void;
    selectedDashboard: Dashboard | null;
};

const CreateDashboardModal: FC<Props> = ({
    isModalOpen,
    onClose,
    setIsLoading,
    onSubmit,
    selectedDashboard
}) => {
    const parsedDashboardJson = parseDashboardJson(
        selectedDashboard
    ) as Dashboard | null;
    const theme = useTheme();
    const [nameError, setNameError] = useState('');
    const [tags, setTags] = useState<string[]>([]);
    const [isJsonEnabled, setIsJsonEnabled] = useState(false);
    const [dashletsError, setDashletsError] = useState('');
    const [dashletData, setDashletData] = useState({} as DashletDataType);
    const [dashboardJson, setDashboardJson] = useState(
        parsedDashboardJson ??
            (defaultDashboardStructure as unknown as Dashboard)
    );
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
    const [isValidJson, setIsValidJson] = useState(true);
    const hasValidationError = Boolean(nameError) || Boolean(dashletsError);
    const [state, setState] = useState(() =>
        getInitialState(selectedDashboard)
    );
    const isInputsChanged = !isEqual(
        state,
        selectedDashboard?.id
            ? {
                  name: selectedDashboard.name,
                  tags: selectedDashboard.tags,
                  items: selectedDashboard.dashlets
              }
            : defaultState
    );
    const isJsonChanged = !isEqual(
        parsedDashboardJson ?? defaultDashboardStructure,
        dashboardJson
    );

    const isDisabledSubmitBtn =
        hasValidationError ||
        (!isJsonEnabled && !isInputsChanged) ||
        (isJsonEnabled && (!isJsonChanged || !isValidJson));

    const onTagsSearchInputChangeHandler = useMemo(
        () =>
            debounce(
                (search: string) => getSearchedTags(search, state.tags),
                500
            ),
        [state.tags]
    );

    const makeAPIRequest = async (
        dashboard: Dashboard,
        shouldParseError = false
    ) => {
        try {
            setIsLoading(true);
            let response = {} as AxiosResponse<any, any>;

            if (selectedDashboard?.id) {
                response = await updateDashboardTemplate(
                    selectedDashboard.id,
                    dashboard
                );
            } else {
                response = await createDashboardTemplate(dashboard);
            }
            if (response.status === 200) {
                showToast(
                    selectedDashboard?.id
                        ? DASHBOARD_UPDATED_SUCCESS
                        : DASHBOARD_CREATED_SUCCESS,
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
                onSubmit();
            }
        } catch (error: any) {
            displayErrorMessage(
                shouldParseError ? parseDashboardError(error) : error
            );
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (isJsonEnabled) {
            await makeAPIRequest(dashboardJson, true);
            return;
        }

        // Validations
        let hasError = false;
        const nameErrorMessage = getDashboardNameError(state.name);

        if (nameErrorMessage) {
            hasError = true;
            setNameError(nameErrorMessage);
        }

        if (state.items.length === 0) {
            hasError = true;
            setDashletsError(DASHLET_REQUIRED_ERROR);
        }

        if (hasError) return;

        const dashboardDashlet = state.items.map((item, index) => ({
            order: index + 1,
            dashletJson: item.dashletJson
        }));
        const object = {
            name: state.name,
            tags: state.tags,
            dashlets: dashboardDashlet
        };

        await makeAPIRequest(object as unknown as Dashboard);
    };

    const onEditorMount: OnMount = editor => {
        editor.onDidChangeModelContent(
            debounce(() => {
                try {
                    const updatedDashboardJson = JSON.parse(editor.getValue());
                    if (
                        typeof updatedDashboardJson !== 'object' ||
                        updatedDashboardJson === null
                    ) {
                        setIsValidJson(false);
                        return;
                    }
                    setDashboardJson(updatedDashboardJson);
                    setIsValidJson(true);
                } catch {
                    setIsValidJson(false);
                }
            }, 500)
        );
    };

    const handleAdvanceJsonSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;

        if (isJsonEnabled && (isJsonChanged || !isValidJson)) {
            setIsDiscardModalOpen(true);
            return;
        }
        if (isInputsChanged || hasValidationError) {
            setIsDiscardModalOpen(true);
            return;
        }

        setIsJsonEnabled(checked);
    };

    const handleDiscardContinue = () => {
        if (isJsonEnabled) {
            const json = (selectedDashboard?.id
                ? parsedDashboardJson
                : defaultDashboardStructure) as unknown as Dashboard;
            setDashboardJson(json);
            setIsValidJson(true);
        } else {
            if (selectedDashboard?.id) {
                setState({
                    name: selectedDashboard.name,
                    tags: selectedDashboard.tags,
                    items: selectedDashboard.dashlets
                });
            } else {
                setState(cloneDeep(defaultState));
            }
            setNameError('');
            setDashletsError('');
        }

        setIsJsonEnabled(prev => !prev);
        setIsDiscardModalOpen(false);
    };

    return (
        <div>
            <StyledModal
                displayBtn="all"
                isDisabledSubmitBtn={isDisabledSubmitBtn}
                isModalOpen={isModalOpen}
                onCancel={onClose}
                onSubmit={handleSubmit}
                paperProps={{
                    style: {
                        width: '100%',
                        maxWidth: 1000,
                        margin: 10
                    }
                }}
                preventCloseOnOutsideClick
                submitBtnLabel="Save"
                title="Create Dashboard"
            >
                <Box
                    sx={{
                        justifyContent: 'flex-end',
                        display: 'flex'
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isJsonEnabled}
                                onChange={handleAdvanceJsonSwitch}
                            />
                        }
                        label={ADVANCED_JSON_EDIT}
                        sx={{
                            '.MuiTypography-root': {
                                fontWeight: 'bold'
                            },
                            '.MuiSwitch-root': {
                                marginRight: 1
                            }
                        }}
                    />
                </Box>
                {isJsonEnabled ? (
                    <Box>
                        <Typography
                            className="content"
                            sx={{
                                color: constants.COLOR_1,
                                marginBottom: 1
                            }}
                            variant="h5"
                        >
                            {POLICY_TEMPLATE_JSON_EDIT_INFO}
                        </Typography>
                        <Editor
                            defaultLanguage="json"
                            defaultValue={JSON.stringify(
                                dashboardJson,
                                null,
                                2
                            )}
                            height="50vh"
                            onMount={onEditorMount}
                            theme={
                                theme.palette.mode === 'dark' ? 'vs-dark' : ''
                            }
                        />
                    </Box>
                ) : (
                    <StyledFormFieldContainer>
                        <StyledFormFields>
                            <FormLabel htmlFor="name">
                                Dashboard Title
                            </FormLabel>
                            <TextBox
                                className="content"
                                disabled={false}
                                error={!!nameError}
                                helperText={nameError}
                                id="name"
                                InputProps={{
                                    endAdornment: state.name && (
                                        <InputAdornment
                                            className="clear"
                                            onClick={() => {
                                                setNameError(
                                                    getDashboardNameError('')
                                                );
                                                setState(prev => ({
                                                    ...prev,
                                                    name: ''
                                                }));
                                            }}
                                            position="end"
                                            variant="outlined"
                                        >
                                            <IoIosClose />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={({ target }) => {
                                    setNameError(
                                        getDashboardNameError(target.value)
                                    );
                                    setState(prev => ({
                                        ...prev,
                                        name: target.value
                                    }));
                                }}
                                placeholder="Enter a dashboard name"
                                sx={{
                                    width: '100%'
                                }}
                                value={state.name}
                            />
                        </StyledFormFields>

                        <StyledFormFields>
                            <FormLabel htmlFor="tags">Tags</FormLabel>
                            <TagsWithInputBox
                                className="content"
                                componentsProps={{
                                    popper: {
                                        sx: { zIndex: 10002 }
                                    }
                                }}
                                data-testid="tags-autocomplete"
                                defaultValue={
                                    selectedDashboard?.id
                                        ? state.tags
                                        : readonlyTags
                                }
                                id="template-tags-input"
                                onInputChange={(_, value) =>
                                    value.trim().length &&
                                    onTagsSearchInputChangeHandler(value)
                                }
                                onTagsChangeEmit={value => {
                                    setTags([]);
                                    setState(prev => ({
                                        ...prev,
                                        tags: value
                                    }));
                                }}
                                options={tags}
                                placeholder={ADD_TAGS_PLACEHOLDER}
                                readOnlyOptions={readonlyTags}
                            />
                            <Typography
                                className="content"
                                sx={{ color: constants.COLOR_1 }}
                                variant="h6"
                            >
                                {CREATE_TAG_INFO}
                            </Typography>
                        </StyledFormFields>

                        <DashletPanelContainer
                            dashletData={dashletData}
                            dashletsError={dashletsError}
                            items={state.items}
                            setDashletData={setDashletData}
                            setDashletsError={setDashletsError}
                            setIsLoading={setIsLoading}
                            setState={setState}
                        />
                    </StyledFormFieldContainer>
                )}
            </StyledModal>
            {isDiscardModalOpen && (
                <DiscardChangesModal
                    isModalOpen
                    onClose={() => setIsDiscardModalOpen(false)}
                    onSubmit={handleDiscardContinue}
                />
            )}
        </div>
    );
};

export default CreateDashboardModal;
