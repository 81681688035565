import { CHECKBOX_STATUS, MenuItemProps } from '@armis/armis-ui-library';
import { getAllReports } from 'src/services/api.service';

import { ReportDataMenuProp } from './ReportletPanel.types';

export const getReportletSelectLabel = (
    reports: MenuItemProps[],
    selectedReports: string[]
) => {
    const totalReports = selectedReports.length;
    if (totalReports === 0) {
        return 'Select Reports';
    }
    if (totalReports === 1) {
        const dashboardName = reports.find(
            element => element.id === selectedReports[0]
        );
        return dashboardName?.label;
    }
    if (totalReports === reports.length) {
        return 'All Reports';
    }
    return '%s Reports'.replace('%s', totalReports.toString());
};

export const updateReportList = (
    reports: MenuItemProps[],
    selectedReportIds: string[]
): MenuItemProps[] =>
    reports.map(dashboard => {
        const checked = selectedReportIds.includes(dashboard.id);
        return {
            ...dashboard,
            checkStatus: checked
                ? CHECKBOX_STATUS.CHECKED
                : CHECKBOX_STATUS.UNCHECKED
        };
    });

export const getReportsList = async () => {
    const reportData: MenuItemProps[] = [];
    const selectedReportList: string[] = [];

    const response = await getAllReports();
    response.data.content.forEach((report: any) => {
        const obj = {} as ReportDataMenuProp;
        obj.id = report.id;
        obj.label = report.name;
        obj.labelRender = report.name;
        obj.checkStatus = CHECKBOX_STATUS.CHECKED;
        obj.tags = report.tags;
        obj.name = report.name;
        reportData.push(obj);
        selectedReportList.push(report.id);
    });

    return {
        reportData,
        selectedReportList
    };
};
