import { FormHelperText, FormLabel } from '@mui/material';

import { StyledField } from '../DashBoard.style';
import { StyledTextArea } from './Common.style';

type TextAreaSelectionProps = {
    title: string;
    value: string;
    error?: string;
    handleChange: (value: string) => void;
};

const TextAreaSection = ({
    title,
    value,
    error,
    handleChange
}: TextAreaSelectionProps) => (
    <StyledField>
        <FormLabel>{title}</FormLabel>
        <StyledTextArea
            className="modal-text-field"
            hasError={!!error}
            maxRows="3"
            minRows="3"
            onChange={e => {
                handleChange(e.target.value);
            }}
            value={value}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
    </StyledField>
);

export default TextAreaSection;
