import { CSSProperties } from 'react';

import { constants } from '@armis/armis-ui-library';
import { map } from 'lodash';
import uuid from 'react-uuid';
import {
    INVALID_EXPRESSION,
    NAME_EXISTS,
    VALID_EXPRESSION
} from 'src/constants/LabelText';

import {
    ReportElementErrorType,
    TextWithVariablesReportDataContent
} from '../CreateReport.types';
import { VariableInputType } from './components/VariableInput.types';

export const VARIABLE_PREFIX = '$';

export const getDefaultVariable = () => ({
    id: uuid(),
    key: '',
    value: ''
});

export const defaultTextWithVariableConfiguration = {
    type: 'text_api_variables',
    variables: [getDefaultVariable()],
    equations: [getDefaultVariable()]
};

export const supportedNameRegex = /^[A-Za-z_]*$/;

export const getNameValidationError = (
    input: VariableInputType,
    inputs: VariableInputType[]
) => {
    let errorMessage = '';

    if (!input.key.trim()) {
        errorMessage = 'Name is required';
    } else if (!supportedNameRegex.test(input.key)) {
        errorMessage = 'Only A-Z, _ allowed';
    } else if (input.key.length > 32) {
        errorMessage = 'Max 32 characters';
    } else if (
        inputs.some(({ id, key }) => id !== input.id && key === input.key)
    ) {
        errorMessage = NAME_EXISTS;
    } else {
        errorMessage = '';
    }
    return errorMessage;
};

export const getValueValidationError = (
    input: VariableInputType,
    label: string
) => {
    let errorMessage = '';

    if (!input.value.trim()) {
        errorMessage = `${label} is required`;
    } else {
        errorMessage = '';
    }
    return errorMessage;
};

/**
 *
 * @param content Text content to search for
 * @param inputs Variable list to check in the content
 * @returns Used and undeclared variables list
 */
export const getVariableStatus = (content: string, variableNames: string[]) => {
    const matches = content.match(/data-mention="\$(\w+)"/g);
    const usedVariables = map(matches, match => match.match(/\$(\w+)/)?.[1]);

    const undeclaredVariables = usedVariables.filter(name => {
        const index = variableNames.findIndex(
            variableName => variableName === name
        );
        return index === -1;
    });

    return { usedVariables, undeclaredVariables };
};

/**
 * @param expression Expression to be parsed
 * @param variables List of declared variables
 * @returns Status of error
 */
export const validateExpression = (
    expression: string,
    variables: VariableInputType[]
) => {
    try {
        // Create a map of variables to replace in the expression
        const variableMap = Object.fromEntries(
            variables.map(variable => [variable.key, 1])
        );

        // Replace $variable references with 1 to parse the equation
        const parsedExpression = expression.replace(/\$(\w+)/g, (_, name) => {
            if (name in variableMap) return `${variableMap[name]}`;
            throw new Error(`Variable '${name}' is not defined.`);
        });

        // invalidate other characters
        const validPattern = /^[\d+\-*/%().\s$]+$/;
        if (!validPattern.test(parsedExpression)) {
            throw new Error('Expression contains invalid characters');
        }

        // Validate the syntax of the parsed expression
        // The `Function` constructor here parses the expression for syntax errors
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        new Function(`return ${parsedExpression}`)();

        return VALID_EXPRESSION;
    } catch (error) {
        if (error instanceof Error) {
            return `${INVALID_EXPRESSION}: ${error.message}`;
        }
        return `${INVALID_EXPRESSION}: ${error}`;
    }
};

const DEFAULT_MENTION_SELECTOR = 'span[data-mention="\\$%s"]';

export const highlightVariableError = (variables: (string | undefined)[]) => {
    variables.forEach((variable = '') => {
        document
            .querySelectorAll(DEFAULT_MENTION_SELECTOR.replace('%s', variable))
            .forEach(element => {
                const { style } = element as Element & { style: CSSProperties };
                style.border = `1px solid ${constants.COLOR_5}`;
            });
    });
};

export const removeVariableError = (variables: (string | undefined)[]) => {
    variables.forEach((variable = '') => {
        document
            .querySelectorAll(DEFAULT_MENTION_SELECTOR.replace('%s', variable))
            .forEach(element => {
                const { style } = element as Element & { style: CSSProperties };
                style.border = 'none';
            });
    });
};

/**
 * Used to get the error status of tabs to highlight
 */
export const getTabErrorState = (
    item: TextWithVariablesReportDataContent,
    elementError: ReportElementErrorType
) => {
    const itemId = item.dragAndDropId ?? '';
    const errors = elementError[itemId] ?? [];
    const { variables, equations } = item.reportElement.configuration;

    const isEquationTabError = errors.some(({ colName, isPristine }) =>
        equations.some(({ id }) => colName.includes(id) && isPristine !== true)
    );
    const isVariableTabError = errors.some(({ colName, isPristine }) =>
        variables.some(({ id }) => colName.includes(id) && isPristine !== true)
    );

    return {
        isEquationTabError,
        isVariableTabError
    };
};

const emptyInputFilterFunction = ({ key, value }: VariableInputType) =>
    key !== '' && value !== '';

const encodeVariable = (key: string) =>
    key.startsWith(VARIABLE_PREFIX) ? key : `${VARIABLE_PREFIX}${key}`;

const decodeVariable = (key: string) =>
    key.startsWith(VARIABLE_PREFIX) ? key.substring(1) : key;

export const sanitizeTextVariableElement = (
    element: TextWithVariablesReportDataContent
) => {
    element.reportElement.configuration.variables =
        element.reportElement.configuration.variables
            .filter(emptyInputFilterFunction)
            .map(variable => ({
                id: variable.id,
                key: encodeVariable(variable.key),
                asq: variable.value
            })) as unknown as VariableInputType[];

    element.reportElement.configuration.equations =
        element.reportElement.configuration.equations
            .filter(emptyInputFilterFunction)
            .map(equation => ({
                ...equation,
                key: encodeVariable(equation.key)
            }));
};

export const sanitizeTextVariableElementResponse = (
    element: TextWithVariablesReportDataContent
) => {
    const { variables, equations } = element.reportElement.configuration;
    const sanitizedVariables =
        variables.length === 0
            ? [getDefaultVariable()]
            : variables.map((variable: any) => ({
                  id: variable.id,
                  key: decodeVariable(variable.key),
                  value: variable.asq
              }));

    const sanitizedEquations =
        equations.length === 0
            ? [getDefaultVariable()]
            : equations.map(equation => ({
                  id: equation.id,
                  key: decodeVariable(equation.key),
                  value: equation.value
              }));

    return {
        ...element,
        reportElement: {
            ...element.reportElement,
            configuration: {
                ...element.reportElement.configuration,
                variables: sanitizedVariables,
                equations: sanitizedEquations
            }
        }
    };
};
