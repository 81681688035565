import { FormLabel } from '@mui/material';

import { StyledField } from '../CreateReport.style';
import { ReportElementErrorType } from '../CreateReport.types';
import EditorSection from './EditorSection';
import { EDITOR_MAX_LIMIT_ERROR } from '../utils';

type DescriptionSectionProps = {
    id: string;
    itemKey: string;
    title: string;
    value: string;
    reportElementError: ReportElementErrorType;
    handleError: (error: string) => void;
    handleChange: (value: string) => void;
};

const DescriptionSection = ({
    id,
    itemKey,
    title,
    value,
    reportElementError,
    handleError,
    handleChange
}: DescriptionSectionProps) => {
    const handleEditorChange = (val: string) => {
        if (val.length > 30_000) {
            handleError(EDITOR_MAX_LIMIT_ERROR);
        } else {
            handleError('');
        }
        handleChange(val);
    };

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <EditorSection
                id={id}
                itemKey={itemKey}
                onChange={handleEditorChange}
                reportElementError={reportElementError}
                value={value}
            />
        </StyledField>
    );
};

export default DescriptionSection;
