import { Modal } from 'src/pages/components/Modal';

type DashboardCreateModalProps = {
    open: boolean;
    onCancel: () => void;
};

const DashboardCreateModal = ({
    open,
    onCancel
}: DashboardCreateModalProps) => {
    const handleSubmit = () => {};

    return (
        <Modal
            isModalOpen={open}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            submitBtnLabel="Add"
            title="Create New Dashboard"
        >
            <div>Name</div>
        </Modal>
    );
};

export default DashboardCreateModal;
