import { FC, useState, memo } from 'react';

import {
    DragDropContext,
    Draggable,
    Droppable,
    DroppableProvided,
    DropResult
} from '@hello-pangea/dnd';
import { MdDragHandle } from 'react-icons/md';
import { Modal } from 'src/pages/components/Modal';

import {
    StyledTabItem,
    StyledTabIndex,
    StyledTabName
} from './ReorderModal.styles';
import { useDashboardContext } from '../context/DashboardContext';
import { TabType } from './TabsContainer/TabsContainer.types';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
};

type TabListProps = {
    tabs: TabType[];
};

const TabList: FC<TabListProps> = ({ tabs }) => (
    <>
        {tabs.map((tab, index) => (
            <Draggable key={tab.id} draggableId={tab.id} index={index}>
                {provided => (
                    <StyledTabItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <MdDragHandle height={20} width={20} />
                        <StyledTabIndex>{index + 1}</StyledTabIndex>
                        <StyledTabName>{tab.name}</StyledTabName>
                    </StyledTabItem>
                )}
            </Draggable>
        ))}
    </>
);

const TabsListMemo = memo<TabListProps>(TabList);

const ReorderModal: FC<Props> = ({ isModalOpen, onClose }) => {
    const { tabs, handleTabsOrderChange } = useDashboardContext();
    const [orderTabs, setOrderTabs] = useState(tabs);

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination || destination.index === source.index) {
            return;
        }

        const result = Array.from(orderTabs);
        const [removed] = result.splice(source.index, 1);
        result.splice(destination.index, 0, removed);
        setOrderTabs(result);
    };

    return (
        <Modal
            displayBtn="all"
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={() => {
                handleTabsOrderChange(orderTabs);
                onClose();
            }}
            paperProps={{
                sx: {
                    '.MuiDialogContent-root': {
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }
                },
                style: {
                    width: '600px'
                }
            }}
            submitBtnLabel="Ok"
            title="Reorder Dashboards"
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided: DroppableProvided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <TabsListMemo tabs={orderTabs} />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>
    );
};

export default ReorderModal;
