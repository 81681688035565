import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { FieldType } from 'src/types/CommonTypes';

export enum SummaryTextField {
    TITLE = 'title'
}

export const asqFormMetaDataForCustomSummaryGraphic: FieldType[] = [
    {
        type: FIELD_TYPE_MAP.TEXT,
        labelName: 'title',
        title: 'Title',
        validations: {
            required: true,
            maxLength: 32
        }
    },
    {
        type: FIELD_TYPE_MAP.TEXTAREA,
        labelName: 'asq',
        title: 'ASQ',
        validations: {
            required: true
        }
    }
];
