export interface DashBoardProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

export interface TableRowData {
    results: number;
    name: string;
    tenantUrl: string;
}

export interface MenuItemDataType {
    value: string;
    label: string;
}

export type TenantConfigType = {
    tenantDto: {
        id: string;
    };
};

export enum ColumnType {
    NUMBER = 'number',
    CALCULATION = 'calculation',
    BAR = 'bar',
    REPORT_LINK = 'report_link'
}

export type CommonColumnConfigType = {
    title: string; // Max length 255
    columnType: ColumnType; // Max length 50
    orderNumber: number;
};

export type NumberColumnConfigType = {
    asq: string; // Max length 500
    showAs: string; // Max length 255
    mediumThreshold: number;
    highThreshold: number;
};

export type CalculationAPICallConfigType = {
    asq: string;
    variable: string;
};

export type CalculationColumnConfigType = {
    showAs: string; // Max length 500
    mediumThreshold: number;
    highThreshold: number;
    calculationFunction: string; // Max length 500
    ampDashboardColumnApiCallsDtos: CalculationAPICallConfigType[];
};

export type BarAPICallConfigType = {
    asq: string;
    colorCode: string;
};

export type BarColumnConfigType = BarAPICallConfigType[];

export type ColumnConfigType = CommonColumnConfigType & {
    ampDashboardColumnNumberDto?: NumberColumnConfigType;
    ampDashboardColumnCalculations?: CalculationColumnConfigType;
    ampDashboardColumnBarDtos?: BarColumnConfigType;
    reportId?: string;
};

export type DashboardConfigType = {
    ampDashboardTenantDtos: TenantConfigType[];
    ampDashboardColumnDtos: ColumnConfigType[];
};

export type Dashboard = {
    id: string;
    ampDashboardDto: {
        id: string;
        name: string;
        isAmmShared: boolean;
        createdUserId: string;
    };
    userId: string;
    orderNumber: boolean;
    isShared: boolean;
    isAdded: boolean;
    createdby: string;
    updateddate: number;
    updatedby: string;
};
