import { FC } from 'react';

import { IconDelete } from '@armis/armis-ui-library';
import { IconButton } from '@mui/material';

import {
    StyledButtonContainer,
    StyledContainer,
    StyledOperator,
    StyledOperatorContainer
} from './VariableInput.style';
import VariableName from './VariableName';
import VariableValue from './VariableValue';
import { CalculationAPICallConfigType } from '../../DashBoard.types';

enum InputKey {
    VARIABLE = 'variable',
    ASQ = 'asq'
}

type Props = {
    input: CalculationAPICallConfigType;
    keyLabel: string;
    valueLabel: string;
    nameError?: string;
    valueError?: string;
    handleChange: (inputType: InputKey, value: string) => void;
    handleDelete: () => void;
};

const VariableInput: FC<Props> = ({
    input,
    handleChange,
    handleDelete,
    keyLabel,
    valueLabel,
    nameError,
    valueError
}) => {
    const { variable, asq } = input;
    const hasError = Boolean(nameError || valueError);

    return (
        <StyledContainer>
            <VariableName
                error={nameError}
                handleChange={val => {
                    handleChange(InputKey.VARIABLE, val);
                }}
                label={keyLabel}
                value={variable}
            />
            <StyledOperatorContainer>
                <StyledOperator hasError={hasError}>=</StyledOperator>
            </StyledOperatorContainer>
            <VariableValue
                error={valueError}
                handleChange={val => {
                    handleChange(InputKey.ASQ, val);
                }}
                label={valueLabel}
                value={asq}
            />
            <StyledButtonContainer hasError={hasError}>
                <IconButton
                    className="Icon-Hover-Effect"
                    onClick={handleDelete}
                >
                    <IconDelete style={{ width: 20, height: 20 }} />
                </IconButton>
            </StyledButtonContainer>
        </StyledContainer>
    );
};

export default VariableInput;
