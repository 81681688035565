import { DOWNLOAD_JSON } from 'src/constants/LabelText';

import {
    CustomSummaryGraphic,
    Layout,
    MenuItemDataType,
    ReportletElementType,
    ReportSettingsType,
    TableElementType,
    TableSummaryElementType
} from './CreateReport.types';

export const ITEMS: ReportletElementType[] = [
    {
        id: 'segment_title',
        type: 'segment_title',
        label: 'Segment Title'
    },
    {
        id: 'segment_overview_title',
        type: 'segment_overview_title',
        label: 'Segment Overview Title'
    },
    {
        id: 'overview_text',
        type: 'overview_text',
        label: 'Overview Text'
    },
    {
        id: 'line_break',
        type: 'line_break',
        label: 'Segment Divider'
    },
    {
        id: 'page_break',
        type: 'page_break',
        label: 'Page Break'
    },
    {
        id: 'table',
        type: 'table',
        label: 'Table'
    },
    {
        id: 'table_summary',
        type: 'table_summary',
        label: 'Table Summary'
    },
    {
        id: 'text_api_variables',
        type: 'text_api_variables',
        label: 'Text with Variables'
    },
    {
        id: 'image',
        type: 'image',
        label: 'Image'
    },
    {
        id: 'custom_summary_graphics',
        type: 'custom_summary_graphics',
        label: 'Custom Summary Graphic'
    }
];

export const menuItems: MenuItemDataType[] = [
    { value: 'save_as', label: 'Save As' },
    { value: 'download_json', label: DOWNLOAD_JSON }
];

export const layouts: MenuItemDataType[] = [
    { value: Layout.LEFT, label: Layout.LEFT },
    { value: Layout.RIGHT, label: Layout.RIGHT }
];

export const backgroundAppearanceItems: MenuItemDataType[] = [
    { value: 'all_pages', label: 'Apply to All Pages' },
    { value: 'first_page', label: 'Apply to First Page' }
];

export const defaultTableConfiguration: TableElementType = {
    type: 'table',
    title: '',
    table: [],
    before: '',
    after: '',
    recommendations: '',
    recommendationsCheckbox: false,
    hideIfSumZero: false,
    includeInSummary: false
};

export const defaultTableSummaryConfiguration: TableSummaryElementType = {
    type: 'table_summary',
    title: '',
    description: '',
    backgroundColor: '#ffffffff',
    textColor: '#000000ff',
    imageFileName: '',
    layout: Layout.LEFT,
    tables: []
};

export const defaultReportSettingsData: ReportSettingsType = {
    logoPath: '',
    watermarkImageName: '',
    watermarkOpacity: 30,
    watermarkAppearance: 'all_pages'
};

export const defaultCustomSummaryGraphicConfiguration: CustomSummaryGraphic = {
    type: 'custom_summary_graphics',
    title: '',
    sections: [
        {
            sectionId: 1,
            table: [],
            imageFileName: ''
        }
    ]
};

export const defaultTableColDefs = {
    sortable: false,
    resizable: false,
    flex: 1,
    autoHeight: true,
    suppressHeaderKeyboardEvent: () => true
};

export const emptyTablesError = {
    colName: 'tables',
    error: true,
    colErrorMessage: 'Require at least one data to add'
};
