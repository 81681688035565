import { ColumnType, MenuItemDataType } from '../../DashBoard.types';

export const discardConfigMessage =
    'Your changes are unsaved and it will be lost. Do you want to continue?';

export const tenantTypeList: MenuItemDataType[] = [
    { label: 'Centrix', value: 'centrix' },
    { label: 'Vipr', value: 'vipr' }
];

export const dataTypeList: MenuItemDataType[] = [
    { label: 'Number', value: ColumnType.NUMBER },
    { label: 'Calculation', value: ColumnType.CALCULATION },
    { label: 'Bar', value: ColumnType.BAR },
    { label: 'Report', value: ColumnType.REPORT_LINK }
];

export const showAsList: MenuItemDataType[] = [
    { label: 'Icon', value: 'icon' },
    { label: 'Number', value: 'number' }
];

export const inValidNumberError = {
    type: 'manual',
    message: 'Enter a valid number'
};

export const getDeleteColumnMessage = (columnName: string) =>
    `You are about to delete the '${columnName}' column. Are you sure?`;
