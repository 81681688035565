import { constants } from '@armis/armis-ui-library';
import { Grid, styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const StyledModal = styled(Modal)`
    & .MuiDialogContent-root {
        padding: 10px 30px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
`;

export const StyledFormFieldContainer = styled('div')`
    display: grid;
    gap: 16px;
    overflow: hidden;
`;

export const StyledFormFields = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 6px;
    .MuiFormLabel-root {
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
`;

export const StyledDashletContainer = styled(Grid)`
    display: grid;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    padding-top: 0 !important;
`;
