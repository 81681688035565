import { FC } from 'react';

import {
    CONFIRM_DISCARD_CHANGES,
    EDIT_RESET_WARNING_MSG
} from 'src/constants/LabelText';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
};

const DiscardChangesModal: FC<Props> = ({ isModalOpen, onClose, onSubmit }) => (
    <Modal
        isModalOpen={isModalOpen}
        onCancel={onClose}
        onSubmit={onSubmit}
        preventCloseOnOutsideClick
        submitBtnLabel="Continue"
        title={CONFIRM_DISCARD_CHANGES}
    >
        <WarningModalContainer text={EDIT_RESET_WARNING_MSG} />
    </Modal>
);

export default DiscardChangesModal;
