import { useState } from 'react';

import { FormLabel } from '@mui/material';
import { MuiColorInputColors, MuiColorInputFormat } from 'mui-color-input';

import { StyledField } from '../CreateReport.style';
import { ReportElementErrorType } from '../CreateReport.types';
import { getFieldErrorMessage, REQUIRE_FIELD_ERROR } from '../utils';
import { StyledMuiColorInput } from './ColorPickerSection.style';

type ColorPickerSectionProps = {
    id: string;
    itemKey: string;
    title: string;
    hexColor: string;
    reportElementError: ReportElementErrorType;
    handleChange: (color: string) => void;
    handleError: (error: string) => void;
};

const ColorPickerSection = ({
    id,
    itemKey,
    title,
    hexColor,
    reportElementError,
    handleChange,
    handleError
}: ColorPickerSectionProps) => {
    const [value, setValue] = useState<string>(hexColor);

    const error = getFieldErrorMessage(reportElementError, id, itemKey);

    const handleColorChange = (
        newValue: string,
        colors: MuiColorInputColors
    ) => {
        if (!newValue) {
            handleError(REQUIRE_FIELD_ERROR);
        } else if (!colors.hex8) {
            handleError('Invalid Color Value');
        } else {
            handleError('');
        }

        setValue(newValue);
        if (colors.hex8) {
            handleChange(colors.hex8);
        }
    };

    const format: MuiColorInputFormat = 'hex8';

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <StyledMuiColorInput
                error={!!error}
                format={format}
                helperText={error}
                onChange={handleColorChange}
                value={value}
            />
        </StyledField>
    );
};

export default ColorPickerSection;
