import { useState } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';

import { StyledField } from '../DashBoard.style';

type NumberSelectionProps = {
    title: string;
    value: number;
    error?: string;
    handleChange: (value: string) => void;
};

const NumberSection = ({
    title,
    value,
    error,
    handleChange
}: NumberSelectionProps) => {
    const [data, setData] = useState<string>((value || '').toString());

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <TextBox
                autoComplete="off"
                error={!!error}
                fullWidth
                helperText={error}
                hiddenLabel
                onChange={e => {
                    setData(e.target.value);
                    handleChange(e.target.value);
                }}
                size="small"
                type="number"
                value={data}
                variant="outlined"
            />
        </StyledField>
    );
};

export default NumberSection;
