import { Map } from 'src/types/CommonTypes';

import {
    OIDC_SP_AUTHORIZED_REDIRECT_URI,
    SAML_SP_ENTITY_ID,
    SAML_SP_SSO_URL,
    SECRET_KEY,
    TAGS,
    TENANT_NAME,
    TENANT_URL,
    USERNAME
} from './LabelText';

export const SAML_SP_MAPPING = {
    singleSignOnUrl: SAML_SP_SSO_URL,
    entityId: SAML_SP_ENTITY_ID
};

export const OIDC_SP_MAPPING = {
    authorizationEndpoint: OIDC_SP_AUTHORIZED_REDIRECT_URI
};

export const SYNC_STATUS = {
    SUCCESS: 'SUCCESS',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED'
} as const;

export const PARTNER_TENANTS_API_MAPPING: Map<string> = {
    [TENANT_NAME]: 'name',
    [TENANT_URL]: 'tenantUrl',
    [SECRET_KEY]: 'secretKey',
    [USERNAME]: 'username',
    [TAGS]: 'tags'
};
