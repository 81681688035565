import { FC, useState } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { createDashboard } from 'src/services/api.service';

import { StyledFormField } from './AddPopper.style';
import { useDashboardContext } from '../../context/DashboardContext';
import {
    DASHBOARD_CONFIGURE_TOAST_ID,
    DASHBOARD_CREATE_SUCCESSFUL,
    getDashboardNameError
} from '../../utils';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
};

const CreateDashboardModal: FC<Props> = ({ isModalOpen, onClose }) => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const { refreshDashboards, setIsLoading } = useDashboardContext();
    const disableSubmitButton = !name || Boolean(nameError);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            await createDashboard(name);
            showToast(
                DASHBOARD_CREATE_SUCCESSFUL,
                TOAST_TYPE.SUCCESS,
                DASHBOARD_CONFIGURE_TOAST_ID
            );
            onClose();
            refreshDashboards();
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            displayBtn="all"
            isDisabledSubmitBtn={disableSubmitButton}
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={handleSubmit}
            submitBtnLabel="Create"
            title="Create New Dashboard"
        >
            <StyledFormField>
                <FormLabel htmlFor="name">Dashboard Name</FormLabel>
                <TextBox
                    className="content"
                    disabled={false}
                    error={!!nameError}
                    helperText={nameError}
                    id="name"
                    InputProps={{
                        endAdornment: name && (
                            <InputAdornment
                                className="clear"
                                onClick={() => {
                                    setNameError(getDashboardNameError(''));
                                    setName('');
                                }}
                                position="end"
                                variant="outlined"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    }}
                    onChange={({ target }) => {
                        setNameError(getDashboardNameError(target.value));
                        setName(target.value);
                    }}
                    placeholder="Enter a dashboard name"
                    sx={{
                        width: '100%'
                    }}
                    value={name}
                />
            </StyledFormField>
        </Modal>
    );
};

export default CreateDashboardModal;
