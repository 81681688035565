import { CHECKBOX_STATUS, MenuItemProps } from '@armis/armis-ui-library';
import { MenuProps } from '@mui/material';
import { getTenantDashboards } from 'src/services/api.service';

import { DashletDataType } from './DashletPanel.types';

export const getDashboardList = async (tenantId: string) => {
    const dashboardData: MenuItemProps[] = [];
    const selectedDashboard: number[] = [];

    const response = await getTenantDashboards(tenantId);
    response.data.forEach((dashboard: any) => {
        const obj = {} as MenuItemProps;
        obj.id = dashboard.tenantDashboardId;
        obj.label = dashboard.name;
        obj.labelRender = dashboard.name;
        obj.checkStatus = CHECKBOX_STATUS.CHECKED;

        dashboardData.push(obj);
        selectedDashboard.push(dashboard.tenantDashboardId);
    });

    return {
        dashboardData,
        selectedDashboard
    };
};

export const getDashboardSelectLabel = (
    dashboards: MenuItemProps[],
    selectedDashboardIds: number[]
) => {
    const totalDashboardSelected = selectedDashboardIds.length;
    if (totalDashboardSelected === 0) {
        return 'Select Dashboards';
    }
    if (totalDashboardSelected === 1) {
        const dashboardName = dashboards.find(
            element => +element.id === selectedDashboardIds[0]
        );
        return dashboardName?.label!;
    }
    if (totalDashboardSelected === dashboards.length) {
        return 'All Dashboards';
    }
    return '%s Dashboards'.replace('%s', totalDashboardSelected.toString());
};

export const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
    },
    sx: {
        zIndex: 10002
    }
};

export const updateDashletContent = (
    oldDashlet: DashletDataType,
    newDashlet: DashletDataType
) => {
    if (Object.keys(oldDashlet).length === 0) {
        return newDashlet;
    }
    return {
        ...oldDashlet,
        content: [...oldDashlet.content, ...newDashlet.content]
    };
};

export const updateDashboardList = (
    dashboards: MenuItemProps[],
    selectedDashboardIds: number[]
): MenuItemProps[] =>
    dashboards.map(dashboard => {
        const checked = selectedDashboardIds.includes(+dashboard.id);
        return {
            ...dashboard,
            checkStatus: checked
                ? CHECKBOX_STATUS.CHECKED
                : CHECKBOX_STATUS.UNCHECKED
        };
    });
