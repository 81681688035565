import { useEffect, useState } from 'react';

import {
    CHECKBOX_STATUS,
    MenuItemProps,
    MultiSelect,
    Select
} from '@armis/armis-ui-library';
import { FormHelperText, FormLabel } from '@mui/material';
import { AxiosResponse } from 'axios';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    ALL_BOUNDARIES,
    SELECT_BOUNDARIES,
    TOTAL_SELECTED_BOUNDARIES
} from 'src/constants/LabelText';
import { displayErrorMessage, isFieldHavingError } from 'src/helpers/utility';
import { Boundary } from 'src/pages/components/GenerateReport/GenerateReport.types';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { getBoundaries } from 'src/services/api.service';
import { DropDownWithKeyValue, FilterItems } from 'src/types/CommonTypes';

import { StyledField } from '../../CreateReport/CreateReport.style';
import {
    OUTPUT_FORMAT_ENUM,
    PROPERTY_FIELD_KEY_MAP,
    SCHEDULE_REPORT_FORM_FIELDS
} from '../constants';

export const ConfigFields = ({
    setIsLoading,
    updateRepeatSettings,
    tenants,
    selectedTenant,
    updateSelectedTenant,
    scheduleReportSettings,
    outputFormat,
    timeFrame,
    updateOutputFormat,
    updateTimeFrame,
    modalFieldErrors
}: {
    selectedTenant: number;
    updateRepeatSettings: (field: string, value: unknown) => void;
    updateSelectedTenant: any;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
    scheduleReportSettings: any;
    outputFormat: number;
    timeFrame: number;
    tenants: DropDownWithKeyValue[];
    updateOutputFormat: any;
    updateTimeFrame: any;
    modalFieldErrors: any;
}) => {
    const [boundaries, setBoundaries] = useState<FilterItems[]>([]);
    const [boundariescount, setBoundariescount] = useState(ALL_BOUNDARIES);
    const [selectedBoundaries, setselectedBoundaries] = useState<string[]>([]);

    useEffect(() => {
        setselectedBoundaries(
            scheduleReportSettings.boundaries.map((element: any) => element)
        );
    }, [selectedTenant]);
    useEffect(() => {
        setIsLoading(true);
        const getBoundariesbyTenants = async (tenantId: string) => {
            try {
                const res: AxiosResponse<Boundary[]> = await getBoundaries(
                    tenantId
                );

                const getAllBoundaries = res.data.map((item: any) => ({
                    label: item.name,
                    id: item.id,
                    checkStatus: CHECKBOX_STATUS.CHECKED,
                    labelRender: <span>{item.name}</span>
                }));
                setBoundaries(getAllBoundaries);
                setselectedBoundaries(
                    scheduleReportSettings.boundaries.length === 0
                        ? getAllBoundaries.map((element: any) => element.id)
                        : [...scheduleReportSettings.boundaries]
                );
            } catch (err: any) {
                displayErrorMessage(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };
        if (tenants[selectedTenant]?.id) {
            getBoundariesbyTenants(tenants[selectedTenant]?.id);
        }
    }, [selectedTenant]);

    useEffect(() => {
        const totalBoundariesSelected = selectedBoundaries.length;

        if (totalBoundariesSelected === 0) {
            setBoundariescount(SELECT_BOUNDARIES);
        } else if (totalBoundariesSelected === boundaries.length) {
            setBoundariescount(ALL_BOUNDARIES);
        } else if (totalBoundariesSelected === 1) {
            const boundaryName = boundaries.find(
                element => element.id === selectedBoundaries[0]
            );
            setBoundariescount(boundaryName?.label!);
        } else {
            setBoundariescount(
                TOTAL_SELECTED_BOUNDARIES.replace(
                    '%s',
                    totalBoundariesSelected.toString()
                )
            );
        }
        updateRepeatSettings(
            SCHEDULE_REPORT_FORM_FIELDS.boundaries,
            selectedBoundaries
        );

        const updatedBoundaries = boundaries.map(boundary => {
            const isSelected =
                scheduleReportSettings.boundaries.length === 0 ||
                selectedBoundaries.includes(boundary.id);
            return {
                ...boundary,
                checkStatus: isSelected
                    ? CHECKBOX_STATUS.CHECKED
                    : CHECKBOX_STATUS.UNCHECKED
            };
        });

        setBoundaries(updatedBoundaries);
    }, [selectedBoundaries]);

    return (
        <>
            <ModalField
                key={1}
                error={false}
                helperText=""
                index={1}
                items={tenants.map(
                    (singleTenant: { label: any }) => singleTenant.label
                )}
                labelName="Tenant"
                onChange={updateSelectedTenant}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={selectedTenant}
            />
            <StyledField key={2}>
                <FormLabel htmlFor="select-boundaries">Boundaries</FormLabel>

                <Select
                    defaultValue={ALL_BOUNDARIES}
                    id="select-boundaries"
                    MenuProps={{
                        sx: { zIndex: 10002 }
                    }}
                    renderValue={() => boundariescount}
                    sx={{
                        width: '100%',
                        border: isFieldHavingError(
                            PROPERTY_FIELD_KEY_MAP.BOUNDARIES,
                            modalFieldErrors
                        )
                            ? '1px solid red'
                            : ''
                    }}
                    value={boundariescount}
                    variant="outlined"
                >
                    <MultiSelect
                        isAllSelected={false}
                        items={boundaries}
                        onSelectionChanged={(
                            selectedItems: MenuItemProps[]
                        ) => {
                            setselectedBoundaries(
                                selectedItems.map(element => element.id)
                            );
                            updateRepeatSettings(
                                SCHEDULE_REPORT_FORM_FIELDS.boundaries,
                                selectedItems.map(element => element.id)
                            );
                        }}
                        showSelectAllOption
                    />
                </Select>
                {isFieldHavingError(
                    PROPERTY_FIELD_KEY_MAP.BOUNDARIES,
                    modalFieldErrors
                ) && (
                    <FormHelperText error>
                        {modalFieldErrors?.[PROPERTY_FIELD_KEY_MAP.BOUNDARIES]
                            ?.helperText ?? ''}
                    </FormHelperText>
                )}
            </StyledField>
            <ModalField
                key={3}
                error={false}
                helperText=""
                index={3}
                items={[
                    'Last Day',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days'
                ]}
                labelName="Time Frame"
                onChange={updateTimeFrame}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={timeFrame}
            />
            <ModalField
                key={4}
                error={false}
                helperText=""
                index={4}
                items={OUTPUT_FORMAT_ENUM}
                labelName="Output Format"
                onChange={updateOutputFormat}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={outputFormat}
            />
        </>
    );
};
