import {
    createContext,
    useContext,
    useState,
    ReactNode,
    FC,
    useMemo,
    useCallback,
    Dispatch,
    SetStateAction
} from 'react';

import { CHECKBOX_STATUS, MenuItemProps } from '@armis/armis-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import {
    useFieldArray,
    useForm,
    UseFormReturn,
    UseFieldArrayReturn
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ALL_TENANTS,
    SELECT_TENANTS,
    TOTAL_SELECTED_TENANTS
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import {
    getAllReports,
    getTenants,
    postDashboardConfig
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ErrorResponse } from 'src/types/APIResponseTypes';

import { DashboardConfigType, MenuItemDataType } from '../DashBoard.types';
import { dashboardConfigSchema, defaultDashboardConfig } from '../data';
import {
    DASHBOARD_CONFIGURE_SUCCESSFUL,
    DASHBOARD_CONFIGURE_TOAST_ID,
    getSanitizedConfigurationData
} from '../utils';

type DashboardConfigContextType = {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    isLoading: boolean;
    isSaveDisabled: boolean;
    tenantsLabel: string;
    tenants: MenuItemProps[];
    reportList: MenuItemDataType[];
    columnData: UseFieldArrayReturn<
        DashboardConfigType,
        'ampDashboardColumnDtos',
        'id'
    >;
    formData: UseFormReturn<DashboardConfigType, any, undefined>;
    handleTenantChange: (selectedTenants: MenuItemProps[]) => void;
    columnHasError: (index: number) => boolean | undefined;
    activeColumn: number;
    setActiveColumn: Dispatch<SetStateAction<number>>;
    handleSave: (data: DashboardConfigType) => void;
};

const DashboardConfigContext = createContext<
    DashboardConfigContextType | undefined
>(undefined);

export const DashboardConfigProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [open, setOpen] = useState(false);
    const user = useSelector(selectUser);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveDisabled, setSaveDisabled] = useState(false);
    const [tenantsLabel, setTenantsLabel] = useState(SELECT_TENANTS);
    const [tenants, setTenants] = useState<MenuItemProps[]>([]);
    const [reportList, setReportList] = useState<MenuItemDataType[]>([]);
    const [activeColumn, setActiveColumn] = useState(-1);

    const formData = useForm<DashboardConfigType>({
        resolver: yupResolver(dashboardConfigSchema),
        mode: 'onChange',
        defaultValues: defaultDashboardConfig
    });

    const {
        control,
        reset,
        formState: { errors }
    } = formData;

    const columnData = useFieldArray({
        control,
        name: 'ampDashboardColumnDtos'
    });

    const makeTenantApiCall = async () => {
        try {
            const tenantsResponse = await getTenants('', false);
            const getTotalTenants = tenantsResponse.data.content.map(
                (item: any) => ({
                    label: item.name,
                    id: item.id,
                    checkStatus: CHECKBOX_STATUS.UNCHECKED,
                    labelRender: <span>{item.name}</span>
                })
            );

            setTenants(getTotalTenants);
        } catch (err: any) {
            displayErrorMessage(err);
        }
    };

    const makeReportApiCall = async () => {
        try {
            const reportResponse = await getAllReports();
            const reportData = reportResponse.data.content.map(
                (report: any) => ({
                    value: report.id,
                    label: report.name
                })
            );

            setReportList(reportData);
        } catch (err: any) {
            displayErrorMessage(err);
        }
    };

    const handleTenantChange = useCallback(
        (selectedTenants: MenuItemProps[]) => {
            const totalRolesSelected = selectedTenants.length;
            if (totalRolesSelected === 0) {
                setTenantsLabel(SELECT_TENANTS);
            } else if (totalRolesSelected === tenants.length) {
                setTenantsLabel(ALL_TENANTS);
            } else if (totalRolesSelected === 1) {
                const tenantName = tenants.find(
                    ({ id }) => id === selectedTenants[0].id
                );
                setTenantsLabel(tenantName?.label!);
            } else {
                setTenantsLabel(
                    TOTAL_SELECTED_TENANTS.replace(
                        '%s',
                        totalRolesSelected.toString()
                    )
                );
            }

            const updatedTenants = tenants.map(tenant =>
                selectedTenants.find(({ id }) => id === tenant.id)
                    ? { ...tenant, checkStatus: CHECKBOX_STATUS.CHECKED }
                    : { ...tenant, checkStatus: CHECKBOX_STATUS.UNCHECKED }
            );
            setTenants(updatedTenants);
        },
        [tenants]
    );

    const handleOpen = useCallback(async () => {
        setIsLoading(true);
        if (isActionHasPermissions(user, Resources.policy, [Privileges.read])) {
            await makeTenantApiCall();
        }
        if (
            isActionHasPermissions(user, Resources.reports, [Privileges.read])
        ) {
            await makeReportApiCall();
        }
        setIsLoading(false);
        setOpen(true);
    }, [user]);

    const handleClose = useCallback(() => {
        setOpen(false);
        reset();
        setTenantsLabel(SELECT_TENANTS);
        setActiveColumn(-1);
    }, [reset]);

    const columnHasError = useCallback(
        (index: number): boolean | undefined =>
            errors.ampDashboardColumnDtos &&
            Boolean(errors.ampDashboardColumnDtos[index]),
        [errors.ampDashboardColumnDtos]
    );

    const handleSave = useCallback((data: DashboardConfigType) => {
        setSaveDisabled(true);
        const sanitizedData = getSanitizedConfigurationData(data);
        const dashboardId = '84db9efd-ac4f-445e-9971-6778d9f03e27';
        postDashboardConfig(dashboardId, sanitizedData)
            .then(() => {
                showToast(
                    DASHBOARD_CONFIGURE_SUCCESSFUL,
                    TOAST_TYPE.SUCCESS,
                    DASHBOARD_CONFIGURE_TOAST_ID
                );
                handleClose();
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setSaveDisabled(false);
            });
    }, []);

    const contextValue: DashboardConfigContextType = useMemo(
        () => ({
            open,
            handleOpen,
            handleClose,
            isLoading,
            isSaveDisabled,
            tenantsLabel,
            tenants,
            reportList,
            formData,
            columnData,
            handleTenantChange,
            columnHasError,
            activeColumn,
            setActiveColumn,
            handleSave
        }),
        [
            open,
            handleOpen,
            handleClose,
            isLoading,
            isSaveDisabled,
            tenantsLabel,
            tenants,
            reportList,
            formData,
            columnData,
            handleTenantChange,
            columnHasError,
            activeColumn,
            handleSave
        ]
    );

    return (
        <DashboardConfigContext.Provider value={contextValue}>
            {children}
        </DashboardConfigContext.Provider>
    );
};

export const useDashboardConfigContext = (): DashboardConfigContextType => {
    const context = useContext(DashboardConfigContext);
    if (!context) {
        throw new Error(
            'useDashboardConfigContext must be used within a DashboardConfigProvider'
        );
    }
    return context;
};
