import { constants, Table } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTable = styled(Table)`
    margin-top: 0px;
    border-top: ${props =>
        props.theme.palette.mode === 'dark'
            ? `1px solid #5c5c5c`
            : `1px solid #e3e3e8`};

    .ag-root-wrapper {
        border-radius: 8px;
    }

    & .ag-theme-alpine {
        border-radius: 8px;
        overflow: hidden;
        outline: 1px solid #e8e8e8;
    }

    & .ag-grid-table-container,
    .ag-header-group-cell,
    .ag-grid-table-container,
    .ag-header-cell {
        border-right: ${props =>
            props.theme.palette.mode === 'dark'
                ? `1px solid #5c5c5c`
                : `1px solid #e8e8e8`};
    }

    .ag-header-cell-resize {
        border-left: none !important;
    }

    & .row-separator {
        border-bottom: ${props =>
            props.theme.palette.mode === 'dark'
                ? `1px solid #5c5c5c`
                : `1px solid #e8e8e8`};
        & .ag-header-group-cell-label {
            justify-content: center;
        }
    }

    .ag-header-row {
        border-bottom: ${props =>
            props.theme.palette.mode === 'dark'
                ? `1px solid #5c5c5c`
                : `1px solid #e8e8e8`};
    }

    & .row-table-border-top:first-child {
        border-top: none;
    }

    .ag-header-row:not(:first-child),
    .ag-header-cell,
    .ag-header-row:not(:first-child),
    .ag-header-group-cell,
    .ag-header-group-cell-with-group {
        border-top: none !important;
    }
`;

export const StyledTableContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'hasError'
})<{ hasError?: boolean }>`
    width: 100%;
    outline: none;

    & .empty-table {
        min-height: fit-content;
    }

    & .ag-theme-alpine {
        border-radius: 8px;
        overflow: hidden;
        outline: ${({ theme, hasError }) =>
            hasError
                ? `1px solid ${constants.COLOR_5}`
                : theme.palette.mode === 'dark'
                ? `1px solid #5c5c5c`
                : `1px solid #e3e3e8`};
    }
`;
