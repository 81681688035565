import { FC, useEffect, useMemo, useRef, useState } from 'react';

import {
    StyledMenuPaper,
    MultiSelect,
    TableSkeleton,
    TableNoData,
    CustomHeader,
    Table,
    Pagination
} from '@armis/armis-ui-library';
import { Menu } from '@mui/material';
import { SelectionChangedEvent } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    PUSH_DASHBOARD_TEMPLATE,
    SEARCH_TENANT_PLACEHOLDER,
    TENANT,
    TENANTS
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    templatePushSelectTenantAPIMapping
} from 'src/constants/TableConstants';
import { templatePushSelectTenantColumnConfig } from 'src/helpers/ColumnsConfig';
import {
    getDirectionsAndProperties,
    convertQueryObjectToParams,
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { useTable } from 'src/hooks/useTable';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { getTenants, pushDashboardTemplate } from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { StyledModal } from './CreateEditDashboard/CreateEditDashboard.styles';
import { Tenant } from '../../TenantManagement/TenantManagement.types';
import { Dashboard, HOCLoading } from '../DashboardTemplate.types';

const tenantSortOrder: Map<number> = {};
const tenantSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    templatePushSelectTenantAPIMapping,
    tenantSortOrder,
    tenantSortStatus,
    columnsFilterItems
);

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    setIsLoading: HOCLoading;
    selectedDashboards: Dashboard[];
};

const PushDashboardModal: FC<Props> = ({
    isModalOpen,
    onClose,
    onSuccess,
    setIsLoading,
    selectedDashboards
}) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        queryProperties,
        setQueryProperties,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged
    } = useTable({
        sortOrderObj: tenantSortOrder,
        sortStatusObj: tenantSortStatus,
        columnsFilterItems
    });
    const currentUser = useSelector(selectUser);
    const [tenantList, setTenantList] = useState<Tenant[]>([]);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const isArmisUser = currentUser.username.includes('@armis.com');

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        const getInitialData = async () => {
            try {
                setTableLoading(true);
                const response = await getTenants(
                    convertQueryObjectToParams(queryProperties)
                );
                setTenantList(response.data.content);
                setTotalRows(response.data.totalElements);
            } catch (error: any) {
                displayErrorMessage(error);
                setTenantList([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                setSelectedTenants([]);
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            }
        };
        getInitialData();
    }, [gridRef, queryProperties, setTableLoading]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (tenantList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [tenantList.length, tableLoading, gridRef]);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const searchTenantContainer = useMemo(
        () => (
            <SearchBar
                onChange={newValue => {
                    setQueryProperties(prevValue => ({
                        ...prevValue,
                        searchBy: newValue,
                        page: DEFAULT_PAGE,
                        size: DEFAULT_PAGESIZE
                    }));
                }}
                placeholder={SEARCH_TENANT_PLACEHOLDER}
                searchValue={queryProperties.searchBy as string}
            />
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Tenant>
    ) => {
        setSelectedTenants(
            event.api.getSelectedRows().map(tenant => tenant.id)
        );
    };

    const handlePush = async () => {
        try {
            setIsLoading(true);
            const dashboardTemplateIds = selectedDashboards.map(({ id }) => id);
            const response = await pushDashboardTemplate({
                dashboardTemplateIds,
                tenantIds: selectedTenants
            });
            if (response.status === 200) {
                showToast(PUSH_DASHBOARD_TEMPLATE, TOAST_TYPE.INFO, TOAST_ID);
            }
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            setIsLoading(false);
            onSuccess();
        }
    };

    return (
        <StyledModal
            displayBtn="all"
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={handlePush}
            paperProps={{
                style: {
                    width: '100%',
                    maxWidth: '1000px'
                }
            }}
            submitBtnLabel="Push"
            title="Push Dashboards"
        >
            <div
                className="control table"
                style={{ marginTop: 20, padding: 0, height: '65vh' }}
            >
                <TableHeader
                    childrenLeft={searchTenantContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedTenants.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${TENANT}` : `${TENANTS}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={{
                        component: StyledMenuPaper
                    }}
                    sx={{
                        zIndex: '10002 !important'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={templatePushSelectTenantColumnConfig}
                    context={{ isArmisUser }}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={tenantList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </StyledModal>
    );
};

export default PushDashboardModal;
