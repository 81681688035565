import { DashletJson } from 'src/pages/components/DashletPanel/DashletPanel.types';

import { VariableInputType } from './TextWithVariables/components/VariableInput.types';

export interface CreateReportProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

export interface ReportTitleAndNameType {
    title: string;
    name: string;
    tags: string[];
}

export enum Layout {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export type TableElementType = {
    type: 'table';
    title: string; // max length 150, not null
    table: Array<{
        title: string; // not null , max length 150
        asq: string; // not null
    }>;
    before: string; // max length 30000
    after: string; // max length 30000
    recommendations: string; // max length 30000
    recommendationsCheckbox: boolean; // default false
    hideIfSumZero: boolean;
    includeInSummary: boolean;
};

export type TableSummaryElementType = {
    type: 'table_summary';
    title: string;
    description: string;
    tables: Array<{
        title: string;
    }>;
    backgroundColor: string;
    textColor: string;
    imageFileName: string;
    image?: File;
    layout: string;
};
export interface ImageElementType {
    type: string;
    description: string;
    layout: string;
    imageFileName: string;
    image?: File;
}

export type TableReportDataContent =
    ReportFieldDataContentWithConfigurationType<TableElementType>;

export type ImageReportDataContent =
    ReportFieldDataContentWithConfigurationType<ImageElementType>;

export type CustomSummaryGraphic = {
    type: 'custom_summary_graphics';
    title: string;
    sections: Array<{
        sectionId: number;
        table: Array<{
            title: string;
            asq: string;
        }>;
        image?: File;
        imageFileName: string;
    }>;
};

export interface TextWithVariableElementType {
    type: string;
    variables: VariableInputType[];
    equations: VariableInputType[];
}

export type TextWithVariablesReportDataContent =
    ReportFieldDataContentWithConfigurationType<TextWithVariableElementType>;

export interface ReportFieldDataContentType {
    dragAndDropId?: string;
    previewDragAndDropId?: string;
    isReportlet: boolean;
    length?: number;
    value?: string;
    order?: number;
    reportlet?: {
        id?: string;
        reportId?: string;
        partnerId?: string;
        hideSection?: boolean;
        layout: string;
        recommendations: string;
        recommendationsCheckbox: boolean;
        benchmarkCheckbox: boolean;
        benchmark: string;
        reportDashlet: {
            id?: string;
            partnerId?: string;
            createdby?: string;
            updateddate?: number;
            title: string;
            dashletDescription: string;
            dashletJson: DashletJson;
        };
    };
    reportElement?: {
        id?: string;
        reportId?: string;
        partnerId?: string;
        updateddate?: string;
        createdby?: string;
        elementType: string;
        value: string;
    };
}

export type ReportFieldDataContentWithConfigurationType<T = never> =
    ReportFieldDataContentType & {
        reportElement: ReportFieldDataContentType['reportElement'] & {
            configuration: T;
        };
    };

export type TableReportDataContentType =
    ReportFieldDataContentWithConfigurationType<TableElementType>;

export type TableSummaryReportDataContentType =
    ReportFieldDataContentWithConfigurationType<TableSummaryElementType>;

export type CustomSummaryGraphicDataContentType =
    ReportFieldDataContentWithConfigurationType<CustomSummaryGraphic>;

export interface ReportFieldsDataType {
    reportlet_data: ReportFieldDataContentType[];
}

export interface MenuItemDataType {
    value: string;
    label: string;
}

export interface ReportFieldErrorType {
    titleError: boolean;
    titleHelperText: string;
    templateNameError: boolean;
    templateHelperText: string;
    existingTemplateError: boolean;
    existingTemplateHelperText: string;
}

export interface ReportletElementType {
    id: string;
    type: string;
    label: string;
    hide?: boolean;
}

export interface DroppableSourceAndDestinationType {
    index: number;
    droppableId: string;
}

export interface DraggableProps {
    destination: DroppableSourceAndDestinationType;
    source: DroppableSourceAndDestinationType;
    draggableId: string;
}

export interface ReportElementErrorType {
    [key: string]: {
        error: boolean;
        isPristine?: boolean;
        colName: string;
        colErrorMessage: string;
    }[];
}

export type ReportSettingsType = {
    logo?: File;
    logoPath: string;
    watermarkImage?: File;
    watermarkImageName: string;
    watermarkOpacity: number;
    watermarkAppearance: string;
};

export type ReportSettingsErrorType = Partial<
    Record<keyof ReportSettingsType, string>
>;

export type TableSummaryRows = Array<{
    title: string;
}>;

export enum ASQAction {
    ADD = 'add',
    EDIT = 'edit',
    DELETE = 'delete'
}
