import { FC, ReactNode } from 'react';

import { TableNoDataIcon, Tooltip } from '@armis/armis-ui-library';
import { Draggable } from '@hello-pangea/dnd';
import { Card, CardContent, CardMedia, Grid, useTheme } from '@mui/material';
import { NO_DATA_FOUND } from 'src/constants/LabelText';
import { renderChartSvg } from 'src/pages/containers/CreateReport/utils';

import {
    EllipsisTypography,
    StyledNoData,
    StyledSkeleton,
    StyledTypography
} from './DashletPanel.style';

export const NoData = () => (
    <StyledNoData>
        <TableNoDataIcon
            style={{
                height: '150px',
                width: '150px'
            }}
        />
        <StyledTypography variant="h4">{NO_DATA_FOUND}</StyledTypography>
    </StyledNoData>
);

export const renderCardSkeleton = (length: number) => (
    <>
        {Array.from({ length }, (_, i) => (
            <Grid key={`card-skeleton-${i}`} item xs={6}>
                <StyledSkeleton height={120} variant="rounded" />
            </Grid>
        ))}
    </>
);

export const NextLoader = () => (
    <Grid container spacing={2} sx={{ marginTop: 0 }}>
        {renderCardSkeleton(2)}
    </Grid>
);

export const TooltipWrapper: FC<{ children: ReactNode }> = ({ children }) => (
    <Tooltip
        arrow
        placement="bottom"
        title={<div className="tooltip-arrow-text">{children}</div>}
    >
        <EllipsisTypography>{children}</EllipsisTypography>
    </Tooltip>
);

type DraggableCardProps = {
    id: string;
    index: number;
    title: string;
    type: string;
};

export const DraggableCard: FC<DraggableCardProps> = ({
    id,
    index,
    title,
    type
}) => {
    const { palette } = useTheme();
    const cardImage = renderChartSvg(type);

    return (
        <Draggable draggableId={id} index={index}>
            {dragprovided => (
                <Grid item xs={6}>
                    <Card
                        ref={dragprovided.innerRef}
                        {...dragprovided.draggableProps}
                        {...dragprovided.dragHandleProps}
                        style={dragprovided.draggableProps.style}
                        sx={{
                            backgroundImage:
                                palette.mode === 'light' ? 'none' : ''
                        }}
                    >
                        <CardMedia
                            alt="Card image"
                            component="img"
                            height="80"
                            image={cardImage}
                            sx={{
                                padding: '1em',
                                objectFit: 'contain',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        />
                        <CardContent>
                            <TooltipWrapper>{title}</TooltipWrapper>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Draggable>
    );
};
