import React from 'react';

import { Button, constants } from '@armis/armis-ui-library';
import { Divider, FormLabel } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { IoIosAdd } from 'react-icons/io';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';

import { StyledColumnTitle, StyledNoInputsLabel } from './ConfigDrawer.style';
import ColorASQSection from '../../common/ColorASQSection';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';
import { defaultBarAPICallConfig, MAX_API_CALLS } from '../../data';
import { DASHBOARD_CONFIGURE_TOAST_ID, getMaxEntryError } from '../../utils';

const BarColumn = () => {
    const { activeColumn, formData } = useDashboardConfigContext();

    const {
        control,
        setValue,
        watch,
        formState: { errors }
    } = formData;

    const {
        fields: apiFields,
        append: addAPICall,
        remove: removeAPICall
    } = useFieldArray({
        control,
        name: `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnBarDtos`
    });

    const apiFieldsWatch = watch(
        `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnBarDtos`
    );
    const apiFieldsError =
        errors.ampDashboardColumnDtos?.[activeColumn]?.ampDashboardColumnBarDtos
            ?.message;

    const handleAddAPICalls = () => {
        const { length } = apiFields;

        if (length === MAX_API_CALLS) {
            showToast(
                getMaxEntryError('API Calls', MAX_API_CALLS),
                TOAST_TYPE.WARNING,
                DASHBOARD_CONFIGURE_TOAST_ID
            );
            return;
        }
        addAPICall(defaultBarAPICallConfig);
    };

    return (
        <>
            <StyledColumnTitle>
                <FormLabel>API Calls</FormLabel>
                <Button
                    className="header-add-button"
                    onClick={handleAddAPICalls}
                    startIcon={<IoIosAdd height={16} width={16} />}
                    variant="outlined"
                >
                    Add
                </Button>
            </StyledColumnTitle>
            {apiFields.length === 0 && (
                <StyledNoInputsLabel
                    style={apiFieldsError ? { color: constants.COLOR_10 } : {}}
                >
                    {apiFieldsError || 'No API calls declared'}
                </StyledNoInputsLabel>
            )}
            {apiFields.map((field, index) => (
                <React.Fragment key={field.id}>
                    <Divider style={{ marginBottom: '8px' }} />
                    <ColorASQSection
                        asqError={
                            errors.ampDashboardColumnDtos?.[activeColumn]
                                ?.ampDashboardColumnBarDtos?.[index]?.asq
                                ?.message
                        }
                        colorError={
                            errors.ampDashboardColumnDtos?.[activeColumn]
                                ?.ampDashboardColumnBarDtos?.[index]?.colorCode
                                ?.message
                        }
                        handleChange={(inputType, value) => {
                            setValue(
                                `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnBarDtos.${index}.${inputType}`,
                                value,
                                { shouldDirty: true, shouldValidate: true }
                            );
                        }}
                        handleDelete={() => removeAPICall(index)}
                        input={
                            apiFieldsWatch?.[index] || defaultBarAPICallConfig
                        }
                    />
                </React.Fragment>
            ))}
        </>
    );
};

export default BarColumn;
