import { constants } from '@armis/armis-ui-library';
import { styled, Typography } from '@mui/material';

export const StyledHeader = styled('div')`
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 4px;
    padding: 0 64px 16px 64px;

    @media (max-width: 1360px) {
        padding: 0 32px 16px 32px;
    }
`;

export const StyledPanelTitle = styled(Typography)`
    width: fit-content;
    max-width: 500px;
`;

export const StyledEllipsisTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    font-size: 24px;
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
`;
