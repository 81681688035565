import { CHECKBOX_STATUS } from '@armis/armis-ui-library';
import { merge } from 'lodash';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    DELETE,
    VIEW_JSON,
    EDIT,
    PUSH_POLICY,
    EMAIL,
    EMAIL_SUBJECT,
    MULTI_EMAIL_REGEX
} from 'src/constants/LabelText';
import { FieldValidationType } from 'src/types/CommonTypes';

export const AvailableTemplatePolicyActions = {
    DELETE,
    VIEW_JSON,
    EDIT,
    PUSH_POLICY
};

// Schedule Reports
export const REPEAT_EVERY = 'Repeat Every';
export const REPEAT_EVERY_OPTIONS = ['Days', 'Weeks', 'Months'];
export const REPEAT_OPTIONS_MAP = {
    DAYS: REPEAT_EVERY_OPTIONS[0] as 'Days',
    WEEKS: REPEAT_EVERY_OPTIONS[1] as 'Weeks',
    MONTHS: REPEAT_EVERY_OPTIONS[2] as 'Months'
};
export const DAYS_OPTIONS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
].map((item: any) => ({
    label: item,
    id: item,
    checkStatus: CHECKBOX_STATUS.UNCHECKED,
    labelRender: <span>{item}</span>
}));

export const PROPERTY_FIELD_KEY_MAP = {
    REPEAT: 'repeatEvery',
    DAYS: 'days',
    DAYS_NUMBER: 'onDayNumber',
    EMAIL: 'email',
    EMAIL_SUBJECT: 'email_subject',
    BOUNDARIES: 'boundaries'
};

export const REPORT_MAIL_FIELDS = {
    [PROPERTY_FIELD_KEY_MAP.EMAIL_SUBJECT]: {
        label: EMAIL_SUBJECT,
        key: PROPERTY_FIELD_KEY_MAP.EMAIL_SUBJECT,
        type: FIELD_TYPE_MAP.TEXT,
        gridSize: 12,
        optional: true,
        validations: {
            maxLength: 1000
        } as FieldValidationType,
        isFirstElement: true
    },
    [PROPERTY_FIELD_KEY_MAP.EMAIL]: {
        label: EMAIL,
        key: PROPERTY_FIELD_KEY_MAP.EMAIL,
        type: FIELD_TYPE_MAP.TEXT,
        gridSize: 12,
        validations: {
            required: true,
            regex: MULTI_EMAIL_REGEX as unknown as string,
            maxLength: 254
        } as FieldValidationType
    }
};
export const SCHEDULE_MODAL_TIME_FIELDS = {
    [PROPERTY_FIELD_KEY_MAP.REPEAT]: {
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            required: true
        } as FieldValidationType
    },

    [PROPERTY_FIELD_KEY_MAP.DAYS]: {
        type: FIELD_TYPE_MAP.DROPDOWN,
        validations: {
            required: true
        } as FieldValidationType
    },

    [PROPERTY_FIELD_KEY_MAP.DAYS_NUMBER]: {
        type: FIELD_TYPE_MAP.TEXT,
        validations: {
            required: true
        } as FieldValidationType
    }
};

export const COMBINE_FIELDS = merge(
    {},
    SCHEDULE_MODAL_TIME_FIELDS,
    REPORT_MAIL_FIELDS
);

export const SCHEDULE_REPORT_FORM_FIELDS = {
    repeatEvery: 'repeatEvery',
    repeatUnit: 'repeatUnit',
    days: 'days',
    daysCount: 'daysCount',
    selectedDays: 'selectedDays',
    onDayNumber: 'onDayNumber',
    time: 'time',
    email_subject: 'email_subject',
    email: 'email',
    selectedTenant: 'selectedTenant',
    boundaries: 'boundaries',
    timeFrame: 'timeFrame',
    outputFormat: 'outputFormat',
    isActive: 'isActive'
};

export const TIME_FRAME_ENUM = [
    'LAST_DAY',
    'LAST_7_DAY',
    'LAST_14_DAY',
    'LAST_30_DAY'
];

export const OUTPUT_FORMAT_ENUM = ['PDF', 'DOC', 'PPT', 'JSON'];
