import {
    Alignment,
    Autoformat,
    AutoLink,
    Bold,
    EditorConfig,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontSize,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    Paragraph,
    RemoveFormat,
    Strikethrough,
    Subscript,
    Superscript,
    TextTransformation,
    Underline,
    Undo,
    ColorOption
} from 'ckeditor5';

export const colorPalate: ColorOption[] = [
    {
        color: '#000000',
        label: 'Black'
    },
    {
        color: '#696969',
        label: 'Dim Grey'
    },
    {
        color: '#808080',
        label: 'Grey'
    },
    {
        color: '#D3D3D3',
        label: 'Light Grey'
    },
    {
        color: '#FFFFFF',
        label: 'White',
        hasBorder: true
    },
    {
        color: '#FF0000',
        label: 'Red'
    },
    {
        color: '#FFA500',
        label: 'Orange'
    },
    {
        color: '#FFFF00',
        label: 'Yellow'
    },
    {
        color: '#90EE90',
        label: 'Light Green'
    },
    {
        color: '#008000',
        label: 'Green'
    },
    {
        color: '#7FFFD4',
        label: 'Aquamarine'
    },
    {
        color: '#40E0D0',
        label: 'Turquoise'
    },
    {
        color: '#ADD8E6',
        label: 'Light Blue'
    },
    {
        color: '#0000FF',
        label: 'Blue'
    },
    {
        color: '#800080',
        label: 'Purple'
    }
];

export const defaultEditorConfig: EditorConfig = {
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            '|',
            'alignment',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'link',
            'removeFormat'
        ],
        shouldNotGroupWhenFull: true
    },
    plugins: [
        Alignment,
        Autoformat,
        AutoLink,
        Bold,
        Essentials,
        FontBackgroundColor,
        FontColor,
        FontSize,
        Indent,
        IndentBlock,
        Italic,
        Link,
        List,
        Paragraph,
        RemoveFormat,
        Strikethrough,
        Subscript,
        Superscript,
        TextTransformation,
        Underline,
        Undo
    ],
    fontSize: {
        options: [10, 12, 14, 16, 18, 20],
        supportAllValues: true
    },
    link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://'
    },
    list: {
        properties: {
            styles: false
        }
    },
    fontColor: {
        colorPicker: {
            format: 'hex'
        },
        colors: colorPalate
    },
    fontBackgroundColor: {
        colorPicker: {
            format: 'hex'
        },
        colors: colorPalate
    }
};
