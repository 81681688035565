// Login
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgotpassword';
export const USER = '/user';
export const VERIFY_PASSWORD = '/verifypassword';
export const CHANGE_PASSWORD = '/changepassword';
export const LOGOUT = '/logout';
export const GET_SSOID = '/ssoid';
export const SAML2_ENDPOINT = '/saml2/authenticate/';
export const SSO = '/sso';
export const QUERY_PREFIX = '?r=';
export const TOAST_ID = 'a98db973kwl8';

// RBAC
export const GET_ROLES = '/roles';
export const CREATE_ROLE = '/roles';
export const DELETE_ROLES = '/roles/_bulk';
export const UPDATE_ROLE = '/roles';
export const GET_ROLE_STRUCTURE = '/roles/definition';

// AggregateView
export const GET_AGGREGATED_DATA = 'aggregations/';
export const POST_PIN_DATA = 'aggregations/pin';
export const SYNC_AGGREGATION = '/aggregations/sync';
export const TILE = 'tile';
export const TABLE = 'table';

// Tenant Management
export const GET_TENANTS = '/tenants';
export const SEARCH_WITHIN_TENANTS = '/tenants/resources';
export const SYNC_TENANTS = '/tenants/sync';
export const CONFIGURATIONS = '/configurations';
export const BOUNDARIES = '/boundaries';
export const TENANT_USERS= 'users';
export const TENANT_TAG= 'tags';

// Reports
export const RUN_REPORT = '/reports/run';
export const UNIQUE_PROPS_VALUES = 'uniquePropsValues';

// TenantPolicies
export const TENANT_POLICIES = '/tenantpolicies';
export const GET_TENANT_POLICIES = TENANT_POLICIES;
export const UPDATE_RESOURCE = TENANT_POLICIES;

// Report History
export const ALL_REPORTS_RUN = '/reports/run';
export const TENANT_REPORTS_RUN = '/reports/run/tenants';

// AuditLog
export const GET_AUDIT_LOGS = '/auditlogs';
export const EXPORT_AUDIT_LOGS = '/auditlogs/export';
export const FILTER_AUDIT_LOGS = '/auditlogs/uniquePropsValues';

// Tags
export const GET_TAGS = '/tags';
export const POST_TAGS = '/tags';

// Policy Template
export const POLICY_TEMPLATE_ENDPOINT = '/policytemplates';
export const GET_POLICY_TEMPLATE = POLICY_TEMPLATE_ENDPOINT;
export const CREATE_POLICY_TEMPLATE = POLICY_TEMPLATE_ENDPOINT;
export const UPDATE_POLICY_TEMPLATE = POLICY_TEMPLATE_ENDPOINT;
export const DELETE_POLICY_TEMPLATE = `${POLICY_TEMPLATE_ENDPOINT}/_bulk`;
export const GET_TENANTS_BY_POLICY_TEMPLATE = `${POLICY_TEMPLATE_ENDPOINT}/%s${GET_TENANTS}`;
export const VALIDATE_PUSH = '/policies/push/validate';
export const PUSH_POLICY = '/policies/push';

// Dashboard Templates
export const DASHBOARD_TEMPLATE_ENDPOINT = '/dashboardtemplate';

// User Management
export const USERS = '/users';
export const DELETE_BULK_USER = '/users/_bulk';

// Get SP Details
export const SP_DETAILS = '/sso';
export const IDP_DETAILS = '/sso/saml';
export const OIDC_DETAILS = '/sso/oidc';

// Set Password
export const SET_PASSWORD = '/setpassword?token=';

// Get Dashboards
export const GET_DASHBOARD = '/dashboards';
export const GET_RECOMMENDED_ASQ = '/dashboards/recommendedasq';
export const GET_DASHBOARD_CONFIG = '/ampDashboards';

// Push History
export const GET_ALL_PUSH_HISTORY = '/policies/push';
export const GET_PUSH_HISTORY_FILTER_COLUMNS = `${GET_ALL_PUSH_HISTORY}/uniquePropsValues`;

// Report Template
export const GET_REPORT_TEMPLATE = '/reports';
export const DUPLICATE = '/duplicate';
export const DELETE_BULK_REPORT_TEMPLATE = `${GET_REPORT_TEMPLATE}/_bulk`;
export const GET_TENANTS_BY_REPORT_TEMPLATE = `${RUN_REPORT}/%s${GET_TENANTS}`;

// Reportlets
export const TENANT_DASHLETS = '/tenantdashlets';
export const TENANT_DASHBOARDS = '/tenantdashboards';
export const REPORTLETS = '/reportlets';
export const REPORTS = '/reports';
export const LOGO = '/logo';
export const PREVIEW = '/preview';

// Scheduled reports
export const SCHEDULED_REPORTS = '/reports/scheduleReport';

// Default page size
export const PAGE_SIZE = 10;
