import { constants, IconButton } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTabsContainer = styled('div')`
    display: grid;
    grid-template-columns: auto 1fr auto auto auto;
    align-items: center;
    gap: 4px;
    padding: 0 64px 16px 64px;

    @media (max-width: 1360px) {
        padding: 0 32px 16px 32px;
    }
`;

export const StyledTabs = styled('div')`
    display: flex;
    align-items: center;
    overflow-x: hidden;
    flex-grow: 1;
    gap: 8px;
    height: 48px;
    padding-inline: 8px;
    white-space: nowrap;
    border: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_WHITE};
    border-radius: 8px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledTab = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition-property: background-color;
    transition-duration: 200ms;
    font-family: 'Proxima Nova W08 Reg';
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : '#6E6E6E'};
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_35
            : constants.COLOR_GENERAL_36};
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    min-width: 180px;
    max-width: 180px;
    height: 32px;
    margin: 8px 0;
    padding: 8px 8px 8px 12px;
    cursor: pointer;

    &.selected {
        font-family: 'Proxima Nova W08 Smbd';
        background: #8338ec;
        color: #ffffff;
    }

    :hover {
        background: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        padding-right: 8px;

        &.selected {
            background: ${constants.COLOR_PURPLE_DARK};
        }
    }
`;

export const StyledTabName = styled('span')`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const StyledIconButton = styled(IconButton)`
    &.Mui-disabled {
        cursor: not-allowed;
        pointer-events: auto;
    }
`;

export const StyledTabButton = styled(IconButton)`
    height: 24px !important;

    .selected & {
        color: #ffffff;
        &:hover {
            background-color: #5821a6;
        }
    }
`;

export const StyledWarningText = styled('div')`
    word-break: break-word;
`;
