import { MouseEventHandler } from 'react';

import {
    CHECKBOX_STATUS,
    IconButton,
    IconDelete,
    IconPencil,
    MoreAction,
    Tooltip,
    View
} from '@armis/armis-ui-library';
import { cloneDeep } from 'lodash';
import { Privileges, Resources, TagTypes } from 'src/constants/CommonConstants';
import {
    DASHBOARD_REQUIRED,
    DELETE,
    EDIT,
    GENERIC_DASHBOARD_ERROR,
    MAX_DASHBOARD_LIMIT,
    VIEW_JSON
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    isActionHasPermissions
} from 'src/helpers/utility';
import { getTags } from 'src/services/api.service';
import { User } from 'src/types/APIResponseTypes';
import { FilterItems } from 'src/types/CommonTypes';

import { Dashboard, Dashlets } from './DashboardTemplate.types';
import { StyledActions } from '../PolicyTemplate/PolicyTemplate.style';

export const readonlyTags = ['AMP Managed'];

export const availableActions = {
    DELETE,
    VIEW_JSON
};

export const reorder = (
    list: Dashlets[],
    startIndex: number,
    endIndex: number
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const actionOptions = [
    {
        type: availableActions.DELETE,
        icon: <IconDelete />,
        label: DELETE,
        privileges: [Privileges.delete]
    },
    {
        type: availableActions.VIEW_JSON,
        icon: <View height={14} width={14} />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    }
];

export const renderDashboardActions = (
    currentUser: User,
    handleEdit: () => void,
    handleMore: MouseEventHandler
) => {
    const hasEditAccess = isActionHasPermissions(
        currentUser,
        Resources.dashboardTemplate,
        [Privileges.edit]
    );
    const hasMoreAccess = isActionHasPermissions(
        currentUser,
        Resources.dashboardTemplate,
        [Privileges.delete, Privileges.read]
    );

    return (
        <StyledActions>
            <Tooltip
                arrow
                placement="bottom"
                title={<div className="tooltip-arrow-text">{EDIT}</div>}
            >
                <IconButton
                    className="Icon-Hover-Effect"
                    disabled={!hasEditAccess}
                    onClick={handleEdit}
                    size="small"
                >
                    <IconPencil height={16} width={16} />
                </IconButton>
            </Tooltip>
            <IconButton
                className="Icon-Hover-Effect"
                disabled={!hasMoreAccess}
                onClick={handleMore}
                size="small"
            >
                <MoreAction />
            </IconButton>
        </StyledActions>
    );
};

export const getDashboardNameError = (name: string) => {
    if (name.trim().length === 0) {
        return DASHBOARD_REQUIRED;
    }
    if (name.trim().length > 100) {
        return MAX_DASHBOARD_LIMIT;
    }
    return '';
};

export const getSearchedTags = async (
    tagSearch: string,
    selectedTags: string[]
) => {
    try {
        const response = await getTags(TagTypes.dashboardTemplate, tagSearch);
        return response.data.content
            .map((tag: any) => tag.name)
            .filter((tag: string) => !selectedTags.includes(tag));
    } catch (error: any) {
        displayErrorMessage(error);
    }
    return [];
};

export const getUpdatedTags = (
    tags: FilterItems[],
    newTags: { name: string }[]
) =>
    newTags.map(({ name }) => {
        const hasNoTagsSelected = tags.length === 0;
        const tag = tags.find(({ id }) => id === name);
        return {
            id: name,
            label: name,
            labelRender: <span>{name}</span>,
            checkStatus:
                tag?.checkStatus === CHECKBOX_STATUS.CHECKED ||
                hasNoTagsSelected
                    ? CHECKBOX_STATUS.CHECKED
                    : CHECKBOX_STATUS.UNCHECKED
        };
    });

export type State = {
    name: string;
    tags: string[];
    items: Dashlets[];
};

export const defaultState: State = {
    name: '',
    tags: readonlyTags,
    items: []
};

export const defaultDashboardStructure = {
    name: '',
    tags: [],
    dashlets: [
        {
            order: '1',
            dashletJson: {
                title: '',
                searchString: '',
                dashlet: {
                    dashletId: '',
                    visualizationConfig: {
                        aggregateBy: '',
                        groupBy: '',
                        type: ''
                    }
                }
            }
        }
    ]
};

export const getInitialState = (dashboard: Dashboard | null) => {
    if (dashboard?.id) {
        return {
            name: dashboard.name,
            tags: dashboard.tags,
            items: dashboard.dashlets ?? []
        };
    }
    return cloneDeep(defaultState);
};

export const parseDashboardError = (error: any) => {
    if (error.response.data?.errors) {
        if (error.response.data.errors?.name) {
            return { message: error.response.data.errors.name };
        }
        if (error.response.data?.errors?.dashlets) {
            return { message: error.response.data.errors.dashlets };
        }
        return {
            message: GENERIC_DASHBOARD_ERROR
        };
    }
    return error;
};

export const parseDashboardJson = (dashboard: Dashboard | null) => {
    if (!dashboard) return null;

    const parsedDashboard = cloneDeep(dashboard) as Partial<Dashboard>;
    delete parsedDashboard.id;
    delete parsedDashboard.createdby;
    delete parsedDashboard.partnerId;
    delete parsedDashboard.updateddate;
    delete parsedDashboard.updatedby;

    parsedDashboard.dashlets?.forEach((dashlet: Partial<Dashlets>) => {
        delete dashlet.id;
        delete dashlet.createdby;
        delete dashlet.updatedby;
        delete dashlet.updateddate;
    });

    parsedDashboard.tags = parsedDashboard.tags?.filter(
        tag => !readonlyTags.includes(tag)
    );

    return parsedDashboard;
};
