import { useState, FC } from 'react';

import { Checkbox, FormLabel } from '@mui/material';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    DASHBOARDS_DELETED_SUCCESS,
    DASHBOARD_DELETED_SUCCESS,
    DELETE_DASHBOARDS_FROM_TENANT,
    DELETE_DASHBOARDS_WARNING,
    DELETE_DASHBOARD_FROM_TENANT,
    DELETE_DASHBOARD_WARNING
} from 'src/constants/LabelText';
import { FlexRow } from 'src/helpers/Common.style';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { StyledText } from 'src/pages/components/WarningModalContainer/WarningModalContainer.style';
import { deleteDashboardTemplate } from 'src/services/api.service';

import { Dashboard, HOCLoading } from '../DashboardTemplate.types';
import { StyledModal } from './CreateEditDashboard/CreateEditDashboard.styles';

type Props = {
    isModalOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    setIsLoading: HOCLoading;
    selectedDashboards: Dashboard[];
};

const DeleteDashboardModal: FC<Props> = ({
    selectedDashboards,
    isModalOpen,
    onClose,
    onSuccess,
    setIsLoading
}) => {
    const [deleteTenantDashboard, setDeleteTenantDashboard] = useState(false);
    const message =
        selectedDashboards.length === 1
            ? DELETE_DASHBOARD_WARNING.replace('%s', selectedDashboards[0].name)
            : DELETE_DASHBOARDS_WARNING.replace(
                  '%s',
                  selectedDashboards.length.toString()
              );

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const response = await deleteDashboardTemplate({
                dashboardTemplateIds: selectedDashboards.map(({ id }) => id),
                deleteTenantDashboard
            });
            if (response.status === 200) {
                showToast(
                    selectedDashboards.length === 1
                        ? DASHBOARD_DELETED_SUCCESS
                        : DASHBOARDS_DELETED_SUCCESS,
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
            }
        } catch (error: any) {
            displayErrorMessage(error);
        } finally {
            onSuccess();
            setIsLoading(false);
        }
    };

    return (
        <StyledModal
            displayBtn="all"
            isModalOpen={isModalOpen}
            onCancel={onClose}
            onSubmit={handleDelete}
            paperProps={{
                style: {
                    width: '600px'
                }
            }}
            submitBtnLabel="Delete"
            title={
                selectedDashboards.length === 1
                    ? 'Delete Dashboard'
                    : 'Delete Dashboards'
            }
        >
            <WarningModalContainer text={message}>
                <FlexRow>
                    <Checkbox
                        checked={deleteTenantDashboard}
                        id="delete-dashboard-from-tenant"
                        onChange={({ target: { checked } }) => {
                            setDeleteTenantDashboard(checked);
                        }}
                        onClick={e => e.stopPropagation()}
                        style={{
                            transform: 'scale(0.8)'
                        }}
                        sx={{
                            marginTop: '2px',
                            alignSelf: 'flex-start',
                            width: '20px',
                            height: '20px',
                            marginRight: '5px'
                        }}
                    />
                    <FormLabel htmlFor="delete-dashboard-from-tenant">
                        <StyledText>
                            {selectedDashboards.length === 1
                                ? DELETE_DASHBOARD_FROM_TENANT
                                : DELETE_DASHBOARDS_FROM_TENANT}
                        </StyledText>
                    </FormLabel>
                </FlexRow>
            </WarningModalContainer>
        </StyledModal>
    );
};

export default DeleteDashboardModal;
