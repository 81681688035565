import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    FC,
    useMemo,
    useCallback,
    useEffect
} from 'react';

import { displayErrorMessage } from 'src/helpers/utility';
import { getAMPDashboards } from 'src/services/api.service';
import { v4 as uuid } from 'uuid';

import { Dashboard } from '../DashBoard.types';

type TabType = {
    id: string;
    name: string;
};

type DashboardType = {
    id: number;
    name: string;
};

type DashboardContextType = {
    tabs: TabType[];
    selectedTab: TabType | undefined;
    dashboards: DashboardType[];
    addTab: (name: string) => void;
    removeTab: (id: string) => void;
    handleTabsOrderChange: (updatedTabs: TabType[]) => void;
    handleTabChange: (tab: TabType) => void;
    refreshDashboards: () => void;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
};

const DashboardContext = createContext<DashboardContextType | undefined>(
    undefined
);

type Props = {
    children: ReactNode;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
};

export const DashboardProvider: FC<Props> = ({ children, setIsLoading }) => {
    const [tabs, setTabs] = useState<TabType[]>([]);
    const [selectedTab, setSelectedTab] = useState<TabType>();
    const [dashboards] = useState<DashboardType[]>([
        { id: 1, name: 'Shared Dashboard 1' },
        { id: 2, name: 'Shared Dashboard 2' },
        { id: 3, name: 'Shared Dashboard 3' },
        { id: 4, name: 'Shared Dashboard 4' },
        { id: 5, name: 'Shared Dashboard 5' },
        { id: 6, name: 'Shared Dashboard 6' },
        { id: 7, name: 'Shared Dashboard 7' },
        { id: 8, name: 'Shared Dashboard 8' },
        { id: 9, name: 'Shared Dashboard 9' }
    ]);
    const [shouldRefetchDashboards, setShouldRefetchDashboards] =
        useState(false);

    useEffect(() => {
        const getDashboards = async () => {
            try {
                setIsLoading(true);
                const response = await getAMPDashboards();
                const extractedTabs = response.data?.map(
                    (dashboard: Dashboard) => ({
                        id: dashboard?.ampDashboardDto.id,
                        name: dashboard?.ampDashboardDto.name
                    })
                ) as TabType[];
                setTabs(extractedTabs);
                setSelectedTab(extractedTabs[0]);
            } catch (error: any) {
                displayErrorMessage(error);
            } finally {
                setIsLoading(false);
            }
        };
        getDashboards();
    }, [setIsLoading, shouldRefetchDashboards]);

    const addTab = useCallback(
        (name: string) => {
            const newId = uuid();
            setTabs([...tabs, { id: newId, name }]);
        },
        [tabs]
    );

    const removeTab = useCallback(
        (id: string) => {
            setTabs(tabs.filter(tab => tab.id !== id));
        },
        [tabs]
    );

    const handleTabChange = useCallback((tab: TabType) => {
        setSelectedTab(tab);
    }, []);

    // TODO: Connect with API once available
    const handleTabsOrderChange = useCallback(
        (updatedTabs: TabType[]) => setTabs(updatedTabs),
        []
    );

    const refreshDashboards = useCallback(() => {
        setShouldRefetchDashboards(prev => !prev);
    }, []);

    const contextValue = useMemo(
        () => ({
            tabs,
            selectedTab,
            dashboards,
            addTab,
            removeTab,
            handleTabsOrderChange,
            handleTabChange,
            refreshDashboards,
            setIsLoading
        }),
        [
            tabs,
            selectedTab,
            dashboards,
            addTab,
            removeTab,
            handleTabsOrderChange,
            handleTabChange,
            refreshDashboards,
            setIsLoading
        ]
    );

    return (
        <DashboardContext.Provider value={contextValue}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboardContext = (): DashboardContextType => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error(
            'useDashboardContext must be used within a DashboardProvider'
        );
    }
    return context;
};
