import { ColDef, IRowDragItem } from 'ag-grid-community';

export enum TableSummaryTextField {
    TITLE = 'title',
    DESCRIPTION = 'description',
    BACKGROUND_COLOR = 'backgroundColor',
    TEXT_COLOR = 'textColor',
    LAYOUT = 'layout'
}

export interface RowData {
    title: string;
}

export const summaryTableColumnDefs: ColDef[] = [
    {
        rowDrag: true,
        maxWidth: 50,
        rowDragText: (params: IRowDragItem) => params.rowNode?.data?.title
    },
    {
        field: 'title',
        headerName: 'Title'
    }
];
