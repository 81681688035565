import React, { useTransition } from 'react';

import { QUERY_DELETE_WARNING } from 'src/constants/LabelText';
import { onFieldChangeHandler, validateFormFields } from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { FieldType, ModalFieldType } from 'src/types/CommonTypes';

import { ASQAction } from '../CreateReport.types';
import { asqFormMetaData as asqFormMetaDataDefault } from '../Table/utils';

interface ConfigASQModalProps {
    isModalOpen: boolean;
    actionType: ASQAction;
    onSubmit: (dataObject: any) => void;
    onCancel: () => void;
    onDelete: () => void;
    modalFields: ModalFieldType;
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>;
    asqFormMetaData?: FieldType[];
}

export const ConfigASQModal = ({
    isModalOpen,
    actionType,
    onSubmit,
    onCancel,
    onDelete,
    modalFields,
    setModalFields,
    asqFormMetaData = asqFormMetaDataDefault
}: ConfigASQModalProps) => {
    const [, startTransition] = useTransition();

    const submitButtonClick = () => {
        if (!validateFormFields(setModalFields, modalFields, asqFormMetaData)) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    const modalFieldValue = modalFields[currentValue].value;
                    totalObject[currentValue] = modalFieldValue;

                    return totalObject;
                },
                {}
            );
            onSubmit({ ...dataObject });
        }
    };

    if (actionType === ASQAction.DELETE) {
        return (
            <Modal
                isModalOpen={isModalOpen}
                onCancel={onCancel}
                onSubmit={onDelete}
                submitBtnLabel="Delete"
                title="Delete Row"
            >
                <WarningModalContainer
                    text={QUERY_DELETE_WARNING.replace(
                        '%s',
                        modalFields.title.value as string
                    )}
                />
            </Modal>
        );
    }

    return (
        <Modal
            isModalOpen={isModalOpen}
            onCancel={onCancel}
            onSubmit={submitButtonClick}
            submitBtnLabel="Save"
            title={actionType === 'add' ? 'Add Row' : 'Update Row'}
        >
            <div>
                {asqFormMetaData.map(
                    (
                        { labelName, type, inputType, placeHolder, title },
                        index
                    ) => (
                        <ModalField
                            key={index}
                            index={index}
                            inputType={inputType}
                            {...modalFields[labelName]}
                            labelName={title}
                            onChange={fieldValue =>
                                onFieldChangeHandler(
                                    fieldValue,
                                    labelName,
                                    type,
                                    asqFormMetaData,
                                    setModalFields,
                                    modalFields,
                                    startTransition
                                )
                            }
                            placeHolder={placeHolder}
                            type={type}
                        />
                    )
                )}
            </div>
        </Modal>
    );
};
