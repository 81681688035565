import { useCallback, useMemo, useState } from 'react';

import { Button, CrossIcon, IconButton, Plus } from '@armis/armis-ui-library';
import { Divider, FormLabel } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import { MAX_ELEMENT_ERROR } from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { ModalFieldType } from 'src/types/CommonTypes';

import DraggableTable from '../../common/DraggableTable';
import UploadImageSection from '../../common/UploadImageSection';
import { StyledField } from '../../CreateReport.style';
import {
    ASQAction,
    CustomSummaryGraphicDataContentType,
    ReportElementErrorType,
    ReportFieldDataContentType
} from '../../CreateReport.types';
import { ConfigASQModal } from '../../modal/ConfigASQModal';
import { ActionCellRenderer, initialData, RowData } from '../../Table/utils';
import { getFieldErrorMessage } from '../../utils';
import { asqFormMetaDataForCustomSummaryGraphic } from '../utils';

type SectionProps = {
    id: string;
    item: CustomSummaryGraphicDataContentType;
    itemKey: string;
    rowsData: RowData[];
    reportElementError: ReportElementErrorType;
    handleError: (error: string, key: string, id: string) => void;
    handleReportElementChange: (item: ReportFieldDataContentType) => void;
    currentSection: {
        sectionId: number;
        table: Array<{
            title: string;
            asq: string;
        }>;
        image?: File;
        imageFileName: string;
    };
    handleRemoveErrorForDeletedSection: (
        item: ReportFieldDataContentType,
        key: string[]
    ) => void;
};

const Section = ({
    id,
    item,
    itemKey,
    rowsData,
    reportElementError,
    handleError,
    handleReportElementChange,
    currentSection,
    handleRemoveErrorForDeletedSection
}: SectionProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [actionType, setActionType] = useState<ASQAction>(ASQAction.ADD);
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

    const error = getFieldErrorMessage(reportElementError, id, itemKey);
    const totalSections = item.reportElement.configuration.sections.length;
    const totalRowForCurrentSection = currentSection.table.length;

    const handleTableError = (length: number) => {
        if (!length) {
            handleError(
                'Require at least one data to add',
                currentSection.sectionId.toString(),
                id
            );
        } else {
            handleError('', currentSection.sectionId.toString(), id);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalFields(initialData);
        setActionType(ASQAction.ADD);
    };

    const handleTableChange = (rowData: RowData[]) => {
        const updatedItem = cloneDeep(item);
        const test = updatedItem.reportElement.configuration.sections.map(
            section =>
                section.sectionId === currentSection.sectionId
                    ? { ...section, table: rowData }
                    : section
        );
        updatedItem.reportElement.configuration.sections = test;
        handleReportElementChange(updatedItem);
    };

    const updateData = (dataObject: RowData) => {
        const updatedRowsData: RowData[] = [...rowsData];
        if (actionType === 'add') {
            updatedRowsData.push(dataObject);
        } else if (actionType === 'edit') {
            updatedRowsData[selectedRowIndex] = dataObject;
        }
        handleTableChange(updatedRowsData);
        handleTableError(updatedRowsData.length);
        closeModal();
    };

    const handleDeleteRow = () => {
        const updatedRowsData = rowsData.filter(
            (_, index) => index !== selectedRowIndex
        );
        handleTableChange(updatedRowsData);
        handleTableError(updatedRowsData.length);
        closeModal();
    };

    const updateModalFields = (data: RowData) => {
        const updatedModalFields = cloneDeep(initialData);
        updatedModalFields.title.value = data.title;
        updatedModalFields.asq.value = data.asq;
        setModalFields(updatedModalFields);
    };

    const handleEdit = useCallback(
        (data: RowData, rowIndex: number) => {
            setActionType(ASQAction.EDIT);
            setSelectedRowIndex(rowIndex);
            updateModalFields(data);
            setModalOpen(true);
        },
        [setModalOpen]
    );

    const handleDelete = useCallback(
        (data: RowData, rowIndex: number) => {
            setSelectedRowIndex(rowIndex);
            setActionType(ASQAction.DELETE);
            updateModalFields(data);
            setModalOpen(true);
        },
        [setModalOpen]
    );

    const cellRenderer = useCallback(
        (params: ICellRendererParams) => (
            <ActionCellRenderer
                onDelete={() => handleDelete(params.data, params.rowIndex)}
                onEdit={() => handleEdit(params.data, params.rowIndex)}
            />
        ),
        [handleDelete, handleEdit]
    );

    const reportASQTableConfig: ColDef[] = useMemo(
        () => [
            {
                rowDrag: true,
                maxWidth: 50,
                rowDragText: params => params.rowNode?.data?.title
            },
            {
                field: 'title',
                headerName: 'Title'
            },
            {
                field: 'asq',
                headerName: 'ASQ'
            },
            {
                headerName: 'Actions',
                maxWidth: 100,
                cellRenderer
            }
        ],
        [cellRenderer]
    );

    const handleFileChange = (file: File | undefined) => {
        const updatedItem = cloneDeep(item);

        const test = updatedItem.reportElement.configuration.sections.map(
            section =>
                section.sectionId === currentSection.sectionId
                    ? { ...section, image: file, imageFileName: '' }
                    : section
        );
        updatedItem.reportElement.configuration.sections = test;
        handleReportElementChange(updatedItem);
    };

    const handleDeleteSection = () => {
        handleRemoveErrorForDeletedSection(item, [
            currentSection.sectionId.toString(),
            `image${currentSection.sectionId.toString()}`
        ]);

        const updatedItem = cloneDeep(item);

        const updatedSections =
            updatedItem.reportElement.configuration.sections.filter(
                section => section.sectionId !== currentSection.sectionId
            );

        updatedItem.reportElement.configuration.sections = updatedSections;
        handleReportElementChange(updatedItem);
    };

    return (
        <>
            <StyledField>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <FormLabel>Section {currentSection.sectionId}</FormLabel>
                    {totalSections !== 1 && (
                        <IconButton
                            className="Icon-Hover-Effect"
                            onClick={handleDeleteSection}
                            sx={{
                                float: 'right',
                                height: '24px'
                            }}
                        >
                            <CrossIcon />
                        </IconButton>
                    )}
                </div>

                <Divider />
            </StyledField>

            <UploadImageSection
                error={getFieldErrorMessage(
                    reportElementError,
                    id,
                    `image${currentSection.sectionId}`
                )}
                file={currentSection?.image}
                fileName={currentSection?.imageFileName}
                handleChange={handleFileChange}
                handleError={err =>
                    handleError(err, `image${currentSection.sectionId}`, id)
                }
                isRequired
                title="Image"
            />
            <StyledField>
                <ConfigASQModal
                    actionType={actionType}
                    asqFormMetaData={asqFormMetaDataForCustomSummaryGraphic}
                    isModalOpen={modalOpen}
                    modalFields={modalFields}
                    onCancel={closeModal}
                    onDelete={handleDeleteRow}
                    onSubmit={updateData}
                    setModalFields={setModalFields}
                />
                <StyledField className="flex-end">
                    <Button
                        className="header-add-button"
                        color="primary"
                        onClick={e => {
                            e.currentTarget.blur();
                            if (totalRowForCurrentSection >= 3) {
                                showToast(
                                    MAX_ELEMENT_ERROR,
                                    TOAST_TYPE.WARNING,
                                    TOAST_ID
                                );
                                return;
                            }
                            setModalOpen(true);
                        }}
                        startIcon={<Plus className="plus-icon" />}
                        style={{
                            paddingLeft: '15px',
                            height: '32px',
                            marginBottom: '10px'
                        }}
                        variant="outlined"
                    >
                        Add Element
                    </Button>
                </StyledField>
                <DraggableTable
                    columnDefs={reportASQTableConfig}
                    error={error}
                    handleTableChange={handleTableChange}
                    rowsData={rowsData}
                />
            </StyledField>
        </>
    );
};

export default Section;
