import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTabItem = styled('div')`
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_7};

    &:last-child {
        border: none;
    }
`;

export const StyledTabIndex = styled('span')`
    width: 2ch;
    font-size: 12px;
    color: ${constants.COLOR_GENERAL_5};
    text-align: center;
`;

export const StyledTabName = styled('span')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;
