import { Button, constants } from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import { IoIosAdd } from 'react-icons/io';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';

import { StyledColumnTitle, StyledNoInputsLabel } from './ConfigDrawer.style';
import { inValidNumberError, showAsList } from './utils';
import NumberSection from '../../common/NumberSection';
import SelectSection from '../../common/SelectSection';
import TextFieldSection from '../../common/TextFieldSection';
import VariableInput from '../../common/VariableSection/VariableInput';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';
import { defaultCalculationAPICallConfig, MAX_API_CALLS } from '../../data';
import { DASHBOARD_CONFIGURE_TOAST_ID, getMaxEntryError } from '../../utils';

const CalculationColumn = () => {
    const { activeColumn, formData } = useDashboardConfigContext();
    const {
        control,
        setValue,
        setError,
        watch,
        formState: { errors }
    } = formData;

    const {
        fields: apiFields,
        append: addAPICall,
        remove: removeAPICall
    } = useFieldArray({
        control,
        name: `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.ampDashboardColumnApiCallsDtos`
    });

    const apiFieldsWatch = watch(
        `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.ampDashboardColumnApiCallsDtos`
    );
    const apiFieldsError =
        errors.ampDashboardColumnDtos?.[activeColumn]
            ?.ampDashboardColumnCalculations?.ampDashboardColumnApiCallsDtos
            ?.message;

    const handleAddAPICalls = () => {
        const { length } = apiFields;

        if (length === MAX_API_CALLS) {
            showToast(
                getMaxEntryError('API Calls', MAX_API_CALLS),
                TOAST_TYPE.WARNING,
                DASHBOARD_CONFIGURE_TOAST_ID
            );
            return;
        }
        addAPICall(defaultCalculationAPICallConfig);
    };

    return (
        <>
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.showAs`}
                render={({ field, fieldState: { error } }) => (
                    <SelectSection
                        error={error?.message}
                        fullWidth
                        handleChange={field.onChange}
                        items={showAsList}
                        placeholder="Select value"
                        title="Show As"
                        value={field.value || ''}
                    />
                )}
            />
            <StyledColumnTitle>
                <FormLabel>API Calls</FormLabel>
                <Button
                    className="header-add-button"
                    onClick={handleAddAPICalls}
                    startIcon={<IoIosAdd height={16} width={16} />}
                    variant="outlined"
                >
                    Add
                </Button>
            </StyledColumnTitle>
            {apiFields.length === 0 && (
                <StyledNoInputsLabel
                    style={apiFieldsError ? { color: constants.COLOR_10 } : {}}
                >
                    {apiFieldsError || 'No API calls declared'}
                </StyledNoInputsLabel>
            )}
            {apiFields.map((field, index) => (
                <VariableInput
                    key={field.id}
                    handleChange={(inputType, value) => {
                        setValue(
                            `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.ampDashboardColumnApiCallsDtos.${index}.${inputType}`,
                            value,
                            { shouldDirty: true, shouldValidate: true }
                        );
                    }}
                    handleDelete={() => removeAPICall(index)}
                    input={
                        apiFieldsWatch?.[index] ||
                        defaultCalculationAPICallConfig
                    }
                    keyLabel="Name"
                    nameError={
                        errors.ampDashboardColumnDtos?.[activeColumn]
                            ?.ampDashboardColumnCalculations
                            ?.ampDashboardColumnApiCallsDtos?.[index]?.variable
                            ?.message
                    }
                    valueError={
                        errors.ampDashboardColumnDtos?.[activeColumn]
                            ?.ampDashboardColumnCalculations
                            ?.ampDashboardColumnApiCallsDtos?.[index]?.asq
                            ?.message
                    }
                    valueLabel="ASQ"
                />
            ))}
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.calculationFunction`}
                render={({ field, fieldState: { error } }) => (
                    <TextFieldSection
                        error={error?.message}
                        handleChange={field.onChange}
                        placeholder="e.g. X/Y*100"
                        title="Operation"
                        value={field.value}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.mediumThreshold`}
                render={({ field, fieldState: { error } }) => (
                    <NumberSection
                        error={error?.message}
                        handleChange={val => {
                            const numberValue = parseInt(val, 10);
                            if (Object.is(numberValue, NaN)) {
                                setError(
                                    `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.mediumThreshold`,
                                    inValidNumberError
                                );
                            } else {
                                field.onChange(numberValue);
                            }
                        }}
                        title="Yellow Threshold"
                        value={field.value}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.highThreshold`}
                render={({ field, fieldState: { error } }) => (
                    <NumberSection
                        error={error?.message}
                        handleChange={val => {
                            const numberValue = parseInt(val, 10);
                            if (Object.is(numberValue, NaN)) {
                                setError(
                                    `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations.highThreshold`,
                                    inValidNumberError
                                );
                            } else {
                                field.onChange(numberValue);
                            }
                        }}
                        title="Red Threshold"
                        value={field.value}
                    />
                )}
            />
        </>
    );
};

export default CalculationColumn;
