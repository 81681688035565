import { TextBox } from '@armis/armis-ui-library';
import { FormLabel, InputAdornment } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

import { StyledField } from '../CreateReport.style';
import { ReportElementErrorType } from '../CreateReport.types';
import {
    getFieldErrorMessage,
    getLengthErrorMessage,
    isFieldHavingError,
    REQUIRE_FIELD_ERROR
} from '../utils';

type TextFieldSelectionProps = {
    id: string;
    itemKey: string;
    title: string;
    value: string;
    errorMessage?: string;
    reportElementError: ReportElementErrorType;
    handleError: (error: string) => void;
    handleChange: (value: string) => void;
};

const TextFieldSection = ({
    id,
    itemKey,
    title,
    value,
    errorMessage,
    reportElementError,
    handleError,
    handleChange
}: TextFieldSelectionProps) => {
    const hasError =
        isFieldHavingError(reportElementError, id, itemKey) || !!errorMessage;
    const error =
        getFieldErrorMessage(reportElementError, id, itemKey) || errorMessage;

    const handleTextChangeError = (val: string) => {
        if (!val) {
            handleError(REQUIRE_FIELD_ERROR);
        } else if (val.length > 150) {
            handleError(getLengthErrorMessage('title'));
        } else {
            handleError('');
        }
    };

    return (
        <StyledField>
            <FormLabel>{title}</FormLabel>
            <TextBox
                autoComplete="off"
                error={hasError}
                fullWidth
                helperText={hasError ? error : ''}
                hiddenLabel
                InputProps={{
                    endAdornment: value && (
                        <InputAdornment
                            onClick={() => {
                                handleChange('');
                                handleTextChangeError('');
                            }}
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    )
                }}
                onChange={e => {
                    handleChange(e.target.value);
                    handleTextChangeError(e.target.value);
                }}
                size="small"
                value={value}
                variant="outlined"
            />
        </StyledField>
    );
};

export default TextFieldSection;
