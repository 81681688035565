import { FC } from 'react';

import { TextBox, Tooltip, InfoIcon } from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';
import { debounce } from 'lodash';

import {
    StyledTooltipContainer,
    StyledVariablePrefix
} from './VariableInput.style';
import { StyledField } from '../../CreateReport.style';
import { VARIABLE_PREFIX } from '../utils';

type Props = {
    id: string;
    label: string;
    value: string;
    error: string;
    handleChange: (value: string) => void;
};

const VariableName: FC<Props> = ({ id, label, value, error, handleChange }) => {
    const debouncedOnChange = debounce(handleChange, 500);

    return (
        <StyledField sx={{ width: 120 }}>
            <FormLabel
                htmlFor={id}
                sx={{
                    display: 'flex !important',
                    alignItems: 'center',
                    gap: '5px'
                }}
            >
                <span>{label}</span>
                <Tooltip
                    placement="left"
                    title={
                        <div className="tooltip-arrow-text">
                            Use $ to reference variables or equations
                        </div>
                    }
                >
                    <StyledTooltipContainer>
                        <InfoIcon />
                    </StyledTooltipContainer>
                </Tooltip>
            </FormLabel>
            <TextBox
                autoComplete="off"
                error={Boolean(error)}
                fullWidth
                helperText={error}
                hiddenLabel
                id={id}
                InputProps={{
                    startAdornment: (
                        <StyledVariablePrefix>
                            {VARIABLE_PREFIX}
                        </StyledVariablePrefix>
                    ),
                    sx: { paddingLeft: 0, overflow: 'hidden' },
                    inputProps: {
                        style: { paddingLeft: 5 }
                    }
                }}
                onChange={e => {
                    debouncedOnChange(e.target.value);
                }}
                size="small"
                value={value}
                variant="outlined"
            />
        </StyledField>
    );
};

export default VariableName;
