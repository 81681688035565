import { FC } from 'react';

import { TextBox, Tooltip, InfoIcon } from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';

import {
    StyledTooltipContainer,
    StyledVariablePrefix
} from './VariableInput.style';
import { StyledField } from '../../DashBoard.style';

type Props = {
    label: string;
    value: string;
    error?: string;
    handleChange: (value: string) => void;
};

const VariableName: FC<Props> = ({ label, value, error, handleChange }) => (
    <StyledField sx={{ width: 120 }}>
        <FormLabel
            sx={{
                display: 'flex !important',
                alignItems: 'center',
                gap: '5px'
            }}
        >
            <span>{label}</span>
            <Tooltip
                placement="left"
                title={
                    <div className="tooltip-arrow-text">
                        Use $ to reference variables
                    </div>
                }
            >
                <StyledTooltipContainer>
                    <InfoIcon />
                </StyledTooltipContainer>
            </Tooltip>
        </FormLabel>
        <TextBox
            autoComplete="off"
            error={Boolean(error)}
            fullWidth
            helperText={error}
            hiddenLabel
            InputProps={{
                startAdornment: <StyledVariablePrefix>$</StyledVariablePrefix>,
                sx: { paddingLeft: 0, overflow: 'hidden' },
                inputProps: {
                    style: { paddingLeft: 5 }
                }
            }}
            onChange={e => {
                handleChange(e.target.value);
            }}
            size="small"
            value={value}
            variant="outlined"
        />
    </StyledField>
);

export default VariableName;
