import { constants } from '@armis/armis-ui-library';
import { Typography, styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const EllipsisTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${props => props.theme.typography.body2.fontSize};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const DragableDiv = styled('div')`
    // top: auto !important;
    // left: auto !important;
`;

export const RightSideContainer = styled('div')`
    display: flex;
    width: 100%;
`;

export const RightSideFieldsContainer = styled('div')`
    height: calc(100% - 45px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const MiddleContainer = styled('div')`
    display: flex;

    & .MuiCardContent-root {
        padding: 0 10px 8px;
        background: ${props =>
            props.theme.palette.mode === 'dark' ? 'transparent' : '#F2F2F4'};
    }
`;

export const PageContainer = styled('div')`
    display: flex;
    height: calc(100% - 90px);
`;

export const StyledDiv = styled('div')`
    flexdirection: column;
    gap: 5px;
    display: flex;

    & .MuiGrid-item {
        padding-right: 8px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const StyledModal = styled(Modal)``;

export const StyledTypography = styled(Typography)`
    font-family: Proxima Nova W08 Reg;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const StyledField = styled('div')`
    &:not(:last-child, :first-child) {
        padding-bottom: 10pt;
    }
    .MuiFormLabel-root {
        font-family: Proxima Nova Bld;
        margin-bottom: 4px;
        font-size: 14px;
        display: block;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
    .text-area {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
        color: ${props =>
            props.theme.palette.mode === 'dark' ? '#FCFCFC' : '#121212'};

        &:focus {
            border-color: #8338ec !important;
        }
        border: 1px solid
            ${props =>
                props.theme.palette.mode === 'dark' ? '#5C5C5C' : '#CBCBCB'};
    }

    .plus-icon {
        height: 10px;
    }

    .flex-end {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 8px;
    }
`;

export const StyledVerticalContainer = styled(StyledField)`
    display: flex;
    gap: 8px;

    & > div {
        flex-basis: 50%;
    }
`;

export const StyledHeader = styled('div')`
    display: flex;
    justify-content: space-between;

    & .arrow {
        height: 12px;
        justify-self: center;
        transition: all 300ms;
        fill: ${constants.COLOR_1};
        overflow: hidden;
        position: relative;

        &.open {
            transform: rotate(90deg);
        }
        &.close {
            transform: rotate(270deg);
        }
    }
`;

const List = styled('div')`
    background: #fff;
    padding: 0.5rem 0.5rem 0;
    border-radius: 3px;
    flex: 0 0 150px;
    font-family: sans-serif;
`;

export const Content = styled('div')`
    padding: 10px;
    overflow-y: auto;
    height: calc(100% - 45px);
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const MiddlePaneContainer = styled(List)`
    background-color: transparent;
    min-height: 55vh;
`;

export const RightPaneContainer = styled(List)`
    flex: 1;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ffffff'};
`;

export const RightPaneContainerTwo = styled(List)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#f3f3f3'};
`;

export const SupportedImageLabel = styled('p')`
    font-size: 10px;
    margin: 0;
    margin-left: 10px;
    color: #989898;
`;

export const NoDataComponent = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 400px;
`;

export const NoDataText = styled('p')`
    color: ${props =>
        props.theme.palette.mode === 'dark' ? 'white' : '#797979'};
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    text-align: center;
`;

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});
