import {
    constants,
    MenuItemProps,
    MultiSelect,
    Select
} from '@armis/armis-ui-library';
import { FormHelperText, FormLabel } from '@mui/material';

import { StyledField } from '../DashBoard.style';

type MultiSelectSectionProps = {
    title: string;
    value: string;
    error?: string;
    items: MenuItemProps[];
    fullWidth?: boolean;
    handleChange: (value: MenuItemProps[]) => void;
};

const MultiSelectSection = ({
    title,
    value,
    error,
    items,
    fullWidth,
    handleChange
}: MultiSelectSectionProps) => (
    <StyledField>
        <FormLabel>{title}</FormLabel>
        <Select
            fullWidth={fullWidth}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }}
            renderValue={() => value}
            sx={{
                border: error ? `1px solid ${constants.COLOR_5}` : ''
            }}
            value={value}
            variant="outlined"
        >
            <MultiSelect
                items={items}
                onSelectionChanged={selectedItems => {
                    handleChange(selectedItems);
                }}
                showSelectAllOption
            />
        </Select>
        {error && <FormHelperText error>{error}</FormHelperText>}
    </StyledField>
);

export default MultiSelectSection;
