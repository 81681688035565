import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    padding-left: 20px;
    font-family: 'Proxima Nova W08 Smbd';
    display: flex;
    align-items: center;

    & .file-name-box {
        color: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : 'black'};
        text-decoration: none;
        max-width: 200px;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
