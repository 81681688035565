import { Routes, Route, Navigate } from 'react-router-dom';
import { GET_TENANTS } from 'src/constants/APIConstants';
import {
    HOME,
    LOGIN,
    MY_PROFILE,
    ROLE_MANAGEMENT_ROUTE,
    SETTINGS_ROUTE,
    ANALYTICS,
    TENANT_MANAGEMENT,
    AUDIT_LOG,
    CUSTOM_PROPERTIES,
    USER_MANAGEMENT_ROUTE,
    TEMPLATE_ROUTE,
    POLICY,
    SSO_AND_SAML_ROUTE,
    SET_PASSWORD,
    PUSH_HISTORY,
    DASHBOARDS_ROUTE,
    REPORTS,
    HISTORY,
    REPORT_ROUTE,
    CREATE_REPORT,
    EDIT_REPORT,
    DASHBOARD_ROUTE
} from 'src/constants/RouteConstants';
import { ProtectedRoute } from 'src/pages/common/ProtectedRoute';
import { AggregatedView } from 'src/pages/containers/AggregatedView';
import { AuditLog } from 'src/pages/containers/AuditLog';
import { CreateReport } from 'src/pages/containers/CreateReport';
import { CustomProperties } from 'src/pages/containers/CustomProperties';
import { Dashboard } from 'src/pages/containers/DashBoard/DashBoard';
import { DashboardTemplate } from 'src/pages/containers/DashboardTemplate';
import { Login } from 'src/pages/containers/Login';
import { SetPassword } from 'src/pages/containers/Login/SetPassword/SetPassword';
import { PolicyTemplate } from 'src/pages/containers/PolicyTemplate';
import { PolicyTemplateView } from 'src/pages/containers/PolicyTemplate/PolicyTemplateView';
import { Profile } from 'src/pages/containers/Profile';
import { PushHistoryDetailedView } from 'src/pages/containers/PushHistory/PushHistoryDetailedView';
import { PushHistoryTemplateDetailView } from 'src/pages/containers/PushHistory/PushHistoryTemplateDetailView';
import { RoleManagement } from 'src/pages/containers/RoleManagement';
import { SamlSettings } from 'src/pages/containers/SSO&SAML/SamlSettings';
import { TenantManagement } from 'src/pages/containers/TenantManagement/TenantManagement';
import { TenantView } from 'src/pages/containers/TenantView';
import { UserManagement } from 'src/pages/containers/UserManagement/UserManagement';

import { History } from './pages/containers/ReportHistory/History';
import { ReportHistoryByTenantByReport } from './pages/containers/ReportHistory/ReportHistoryByTenantByReport';
import { ReportTemplate } from './pages/containers/ReportTemplate';
import { ReportTemplateTenant } from './pages/containers/ReportTemplate/ReportTemplateTenant';

const AppRoutes = () => (
    <Routes>
        <Route element={<Login />} path={LOGIN} />
        <Route element={<SetPassword />} path={SET_PASSWORD} />
        <Route element={<ProtectedRoute />}>
            <Route element={<AggregatedView />} path={HOME} />

            <Route element={<Profile />} path={MY_PROFILE} />
            <Route
                element={<TenantView />}
                path={`${TENANT_MANAGEMENT}/:tenantId`}
            />
            <Route element={<TenantManagement />} path={TENANT_MANAGEMENT} />
            <Route path={TEMPLATE_ROUTE}>
                <Route element={<PolicyTemplate />} path={POLICY} />
                <Route element={<ReportTemplate />} path={REPORT_ROUTE} />
                <Route element={<DashboardTemplate />} path={DASHBOARD_ROUTE} />

                <Route
                    element={<CreateReport />}
                    path={`${REPORT_ROUTE}/${CREATE_REPORT}`}
                />
                <Route
                    element={<CreateReport />}
                    path={`${REPORT_ROUTE}/${EDIT_REPORT}/:reportId`}
                />
                <Route
                    element={<PolicyTemplateView />}
                    path={`${POLICY}/:policyTemplateId`}
                />
                <Route element={<History />} path={HISTORY} />

                <Route path={PUSH_HISTORY}>
                    <Route
                        element={<PushHistoryDetailedView />}
                        path=":pushHistoryId"
                    />
                    <Route
                        element={<PushHistoryTemplateDetailView />}
                        path={`:pushHistoryId${GET_TENANTS}/:tenantId`}
                    />
                </Route>
                <Route element={<ReportTemplate />} path={REPORTS} />
                <Route
                    element={<ReportTemplateTenant />}
                    path={`${REPORTS}/:reportTemplateId${TENANT_MANAGEMENT}`}
                />
                <Route
                    element={<ReportHistoryByTenantByReport />}
                    path={`${REPORTS}/:reportTemplateId${TENANT_MANAGEMENT}/:tenantId`}
                />
            </Route>
            <Route path={SETTINGS_ROUTE}>
                <Route
                    element={<RoleManagement />}
                    path={ROLE_MANAGEMENT_ROUTE}
                />
                <Route
                    element={<UserManagement />}
                    path={USER_MANAGEMENT_ROUTE}
                />
                <Route element={<AuditLog />} path={AUDIT_LOG} />
                <Route
                    element={<CustomProperties />}
                    path={CUSTOM_PROPERTIES}
                />
                <Route element={<SamlSettings />} path={SSO_AND_SAML_ROUTE} />
            </Route>
            <Route path={ANALYTICS}>
                <Route element={<Dashboard />} path={DASHBOARDS_ROUTE} />
            </Route>
        </Route>
        <Route element={<Navigate to={HOME} />} path="*" />
    </Routes>
);

export default AppRoutes;
