import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    display: flex;
    justify-content: space-between;
`;

export const StyledButtonContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledEditorContainer = styled('div')`
    margin-top: 20px;

    .ck-content .mention {
        background-color: ${({ theme }) =>
            theme.palette.mode === 'dark'
                ? '#ffffff30'
                : constants.COLOR_GENERAL_8};
        color: ${({ theme }) =>
            theme.palette.mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.7)'};
        padding: 0 4px;
        border-radius: 4px;
    }
`;
