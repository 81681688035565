import React, {
    ChangeEvent,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import {
    Accordion,
    ArrowDown,
    Button,
    constants,
    CrossIcon,
    IconButton,
    MenuIcon,
    MenuItemProps,
    Select,
    StyledMenuPaper,
    TagsWithInputBox,
    TextBox,
    Tooltip
} from '@armis/armis-ui-library';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from '@hello-pangea/dnd';
import {
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    InputAdornment,
    Menu,
    MenuItem,
    PaperProps,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Typography,
    useEventCallback
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { cloneDeep, debounce } from 'lodash';
import { FaCog } from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    GET_REPORT_TEMPLATE,
    RUN_REPORT,
    TOAST_ID
} from 'src/constants/APIConstants';
import { FIELD_TYPE_MAP, TagTypes } from 'src/constants/CommonConstants';
import {
    BENCHMARKS,
    CREATE_TAG_INFO,
    DASHLET,
    DASHLET_DESCRIPTION,
    DOWNLOAD_JSON,
    GENERATE,
    HIDE_DASHLET,
    IMAGE_REQUIRED,
    LAYOUT,
    LIBRARY,
    OVERVIEW_TEXT,
    PREVIEW,
    RECOMMENDATIONS,
    REPORT_CONFIGURATION,
    REPORT_CREATED_SUCCESSFULLY,
    REPORT_GENERATION_ERROR,
    REPORT_NO_DATA_PREVIEW,
    REPORT_PREVIEW,
    REPORT_TEMPLATE,
    REPORT_TEMPLATE_UPDATED_SUCCESSFULLY,
    REPORT_TITLE,
    REPORT_UPDATE_SUCCESSFULLY,
    REQUIRED_FIELD,
    ROLE_NAME_ERROR_EMPTY,
    SEGMENT_OVERVIEW_TITLE,
    SEGMENT_TITLE,
    SELECT_BUILT_IN_TEMPLATE,
    SUCCESSFUL_REPORT_GENERATED
} from 'src/constants/LabelText';
import { HISTORY, TEMPLATE_ROUTE } from 'src/constants/RouteConstants';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { DashletDataType } from 'src/pages/components/DashletPanel/DashletPanel.types';
import { GenerateReport } from 'src/pages/components/GenerateReport/GenerateReport';
import { GenerateReportResponse } from 'src/pages/components/GenerateReport/GenerateReport.types';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { ReportletPanel } from 'src/pages/components/ReportletPanel';
import { ReportletDataType } from 'src/pages/components/ReportletPanel/ReportletPanel.types';
import { getReportsList } from 'src/pages/components/ReportletPanel/utils';
import { ReportPreview } from 'src/pages/components/ReportPreview/ReportPreview';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    createReport,
    getReportDetail,
    getRunningReport,
    getTags,
    previewReportDownload,
    updateReportDetails
} from 'src/services/api.service';
import { axiosConfig } from 'src/services/axiosInstance';
import { v4 as uuid } from 'uuid';

import EditorSection from './common/EditorSection';
import {
    Content,
    DragableDiv,
    EllipsisTypography,
    MiddleContainer,
    MiddlePaneContainer,
    NoDataText,
    PageContainer,
    RightPaneContainer,
    RightPaneContainerTwo,
    RightSideContainer,
    RightSideFieldsContainer,
    StyledField,
    StyledHeader,
    StyledModal,
    StyledTypography
} from './CreateReport.style';
import {
    CreateReportProps,
    DroppableSourceAndDestinationType,
    ImageReportDataContent,
    ReportElementErrorType,
    ReportFieldDataContentType,
    ReportFieldErrorType,
    ReportFieldsDataType,
    ReportSettingsType,
    ReportTitleAndNameType,
    TableReportDataContentType,
    TableSummaryReportDataContentType,
    TextWithVariablesReportDataContent,
    CustomSummaryGraphicDataContentType
} from './CreateReport.types';
import CustomSummaryGraphicElement from './CustomSummaryGraphic/CustomSummaryGraphic';
import DashletPanelContainer from './DashletPanelContainer';
import {
    defaultReportSettingsData,
    defaultTableConfiguration,
    defaultTableSummaryConfiguration,
    ITEMS,
    menuItems,
    defaultCustomSummaryGraphicConfiguration,
    emptyTablesError
} from './data';
import ImageElement from './Image/ImageElement';
import ReportSettingsModal from './modal/ReportSettingsModal';
import NoData from './NoData';
import ReportElementsPanel from './ReportElementsPanel';
import TableReport from './Table/CustomTable';
import TableSummary from './TableSummary/TableSummary';
import { RowData } from './TableSummary/utils';
import TextWithVariables from './TextWithVariables/TextWithVariables';
import { getDefaultVariable } from './TextWithVariables/utils';
import {
    changeReportElementVisibility,
    EDITOR_MAX_LIMIT_ERROR,
    EMPTY_TABLE_ERROR,
    generateReportSettingsPostData,
    getFieldErrorMessage,
    getLengthErrorMessage,
    getReportSettingsData,
    hasReportFieldError,
    isFieldHavingError,
    makeReportFieldDirty,
    move,
    renderChartSvg,
    reorderTableSummaryData,
    REQUIRE_FIELD_ERROR,
    sanitizePayload,
    sanitizeResponse,
    getSummaryTableRows,
    ALREADY_EXIST_TABLE_ERROR,
    getDefaultTableSummaryError,
    checkTableKeyExist
} from './utils';

const CreateReportComponent = ({ setIsLoading }: CreateReportProps) => {
    const theme = useTheme();

    const navigate = useNavigate();

    const { state } = useLocation();

    const [expandedPanel, setExpandedPanel] = useState<string | boolean>(false);
    const [leftPanelExpanded, setleftPanelExpanded] = useState<boolean>(true);

    const [dashletData, setDashletData] = useState({} as DashletDataType);
    const [reportletData, setReportletData] = useState({} as ReportletDataType);
    const { reportId } = useParams();

    const [selectedTemplate, setSelectedTemplate] = useState<string>('');

    const [reportTitleAndName, setReportTitleAndName] =
        useState<ReportTitleAndNameType>({
            title: '',
            name: '',
            tags: ['AMP Managed']
        });
    const [reportElements, setReportElements] = useState(ITEMS);
    const [reportFieldsData, setReportFieldsData] =
        useState<ReportFieldsDataType>({
            reportlet_data: []
        });
    const [reportSettingsData, setReportSettingsData] =
        useState<ReportSettingsType>(defaultReportSettingsData);
    const [isReportSettingsModalOpen, SetIsReportSettingsModalOpen] =
        useState<boolean>(false);

    const [reportList, setReportList] = useState<MenuItemProps[]>([]);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [isPreviewModalOpen, setIsPreviewModalOpen] =
        useState<boolean>(false);
    const [isWarningModalOpen, setIsWarningModalOpen] =
        useState<boolean>(false);

    const [generateModalType, setGenerateModalType] = useState<string>('');
    const [previewFileType, setPreviewFileType] = useState<string>('');

    const [reportFieldError, setReportFieldError] =
        useState<ReportFieldErrorType>({
            titleError: false,
            titleHelperText: '',
            templateNameError: false,
            templateHelperText: '',
            existingTemplateError: false,
            existingTemplateHelperText: ''
        });

    const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

    const [previewpdfFileObj, setPreviewpdfFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewdocFileObj, setPreviewdocFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewpptFileObj, setPreviewpptFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewjsonFileObj, setPreviewjsonFileObj] = useState({
        uri: '',
        fileType: '',
        fileName: '',
        previewReportId: ''
    });

    const [previewFiles, setPreviewFiles] = useState([]);

    const [reportElementError, setReportElementError] =
        useState<ReportElementErrorType>({});

    const [selectedDashboardIds, setSelectedDashboardIds] = useState<any>({
        previewDragAndDropId: '',
        dragAndDropId: ''
    });

    const borderString = `1px solid ${
        theme.palette.mode === 'dark' ? '#5C5C5C' : '#E8E8E8'
    }`;

    const boxShadowString = `${
        theme.palette.mode === 'dark'
            ? '#756d81 0px 0px 20px 2px'
            : 'rgba(0, 0, 0, 0.35) 0px 0px 20px 2px'
    }`;

    // To-do after download api woll be available
    const [previewReportIdAndName, setPreviewReportIdAndName] = useState({
        previewId: '',
        previewName: '',
        fileType: ''
    });

    const [shouldIntervalStart, setShouldIntervalStart] = useState(false);

    const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
    const [isDownloadJSONModalOpen, setIsDownloadJSONModalOpen] =
        useState<boolean>(false);

    const [selectedRadioButton, setSelectedRadioButton] =
        useState('new_template');
    const isReportConfigurationError =
        reportFieldError.titleError || hasReportFieldError(reportElementError);

    const [isGenerateReport, setIsGenerateReport] = useState<boolean>(false);

    const [generatedReportId, setGeneratedReportId] = useState<string>('');

    const tableSummaryDragIdRef = useRef('');

    // a little function to help us with reordering the result

    const reorder = (
        list: ReportFieldDataContentType[] | string,
        startIndex: number,
        endIndex: number
    ) => {
        setIsFormDirty(true);

        // @ts-ignore
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const copy = (
        destination: ReportFieldDataContentType[],
        droppableDestination: DroppableSourceAndDestinationType,
        draggableId: string
    ) => {
        setIsFormDirty(true);
        // const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        // const item = sourceClone[droppableSource.index];

        let obj = {};

        if (expandedPanel === 'dashlets') {
            const index = dashletData.content.findIndex(
                dashlet => dashlet.id === draggableId
            );
            obj = {
                dragAndDropId: uuid(),
                previewDragAndDropId: uuid(),
                isReportlet: true,
                reportlet: {
                    layout: 'LEFT',
                    recommendations: '',
                    recommendationsCheckbox: false,
                    benchmarkCheckbox: false,
                    benchmark: '',
                    hideSection: false,
                    reportDashlet: {
                        title: dashletData.content[index].title,
                        dashletDescription: '',
                        dashletJson: dashletData.content[index].dashletJson
                    }
                }
            };
        } else if (expandedPanel === 'reportlets') {
            const index = reportletData.content.findIndex(
                object => object.id === draggableId
            );

            obj = {
                dragAndDropId: uuid(),
                previewDragAndDropId: uuid(),
                isReportlet: true,
                reportlet: {
                    layout: 'LEFT',
                    recommendations:
                        reportletData.content[index].recommendations,
                    recommendationsCheckbox:
                        reportletData.content[index].recommendationsCheckbox,
                    benchmarkCheckbox:
                        reportletData.content[index].benchmarkCheckbox,
                    benchmark: reportletData.content[index].benchmark,
                    reportDashlet: {
                        title: reportletData.content[index].reportDashlet.title,
                        dashletDescription:
                            reportletData.content[index].reportDashlet
                                .dashletDescription,
                        dashletJson:
                            reportletData.content[index].reportDashlet
                                .dashletJson
                    }
                }
            };
        } else if (expandedPanel === 'reportElements') {
            if (draggableId === 'table') {
                const dragAndDropId = uuid();
                obj = {
                    isReportlet: false,
                    dragAndDropId,
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: '',
                        configuration: defaultTableConfiguration
                    }
                };
                setReportElementError(previous => ({
                    ...previous,
                    [dragAndDropId]: [
                        {
                            colName: 'table',
                            error: true,
                            isPristine: true,
                            colErrorMessage: EMPTY_TABLE_ERROR
                        },
                        {
                            colName: 'title',
                            error: true,
                            isPristine: true,
                            colErrorMessage: REQUIRE_FIELD_ERROR
                        }
                    ]
                }));
            } else if (draggableId === 'image') {
                const dragAndDropId = uuid();
                obj = {
                    isReportlet: false,
                    dragAndDropId,
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: '',
                        configuration: {
                            type: 'image',
                            description: '',
                            layout: 'LEFT',
                            imageFileName: ''
                        }
                    }
                };
                setReportElementError(previous => ({
                    ...previous,
                    [dragAndDropId]: [
                        {
                            colName: 'image',
                            error: true,
                            isPristine: true,
                            colErrorMessage: IMAGE_REQUIRED
                        }
                    ]
                }));
            } else if (draggableId === 'table_summary') {
                const dragAndDropId = uuid();
                obj = {
                    isReportlet: false,
                    dragAndDropId,
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: '',
                        configuration: defaultTableSummaryConfiguration
                    }
                };
                setReportElements(
                    changeReportElementVisibility(
                        reportElements,
                        'table_summary',
                        true
                    )
                );

                tableSummaryDragIdRef.current = dragAndDropId;
                const tableData = getSummaryTableRows(
                    reportFieldsData.reportlet_data
                );
                setReportElementError(previous => ({
                    ...previous,
                    [dragAndDropId]: getDefaultTableSummaryError(
                        !!tableData.length
                    )
                }));
            } else if (draggableId === 'custom_summary_graphics') {
                const dragAndDropId = uuid();
                obj = {
                    isReportlet: false,
                    dragAndDropId,
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: '',
                        configuration: defaultCustomSummaryGraphicConfiguration
                    }
                };
                setReportElementError(previous => ({
                    ...previous,
                    [dragAndDropId]: [
                        {
                            colName: '1',
                            error: true,
                            isPristine: true,
                            colErrorMessage: EMPTY_TABLE_ERROR
                        },
                        {
                            colName: 'title',
                            error: true,
                            isPristine: true,
                            colErrorMessage: REQUIRE_FIELD_ERROR
                        },
                        {
                            colName: 'image1',
                            error: true,
                            isPristine: true,
                            colErrorMessage: IMAGE_REQUIRED
                        }
                    ]
                }));
            } else if (
                draggableId === 'segment_title' ||
                draggableId === 'segment_overview_title' ||
                draggableId === 'overview_text'
            ) {
                obj = {
                    isReportlet: false,
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: ''
                    }
                };
            } else if (draggableId === 'text_api_variables') {
                const dragAndDropId = uuid();
                obj = {
                    isReportlet: false,
                    dragAndDropId,
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId,
                        value: '',
                        configuration: {
                            type: 'text_api_variables',
                            variables: [getDefaultVariable()],
                            equations: [getDefaultVariable()]
                        }
                    }
                };
            } else if (
                draggableId === 'line_break' ||
                draggableId === 'page_break'
            ) {
                obj = {
                    isReportlet: false,
                    dragAndDropId: uuid(),
                    previewDragAndDropId: uuid(),
                    reportElement: {
                        elementType: draggableId
                    }
                };
            }
        }

        // @ts-ignore
        destClone.splice(droppableDestination.index, 0, {
            ...obj
        });

        return destClone;
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case 'reportlet-preview':
                setReportFieldsData((prevState: any) => ({
                    ...prevState,

                    reportlet_data: reorder(
                        reportFieldsData.reportlet_data,
                        source.index,
                        destination.index
                    )
                }));
                break;
            case destination.droppableId:
                setReportFieldsData(prevState => ({
                    ...prevState,
                    [destination.droppableId]: reorder(
                        // @ts-ignore
                        reportFieldsData[source.droppableId],
                        source.index,
                        destination.index
                    )
                }));
                break;
            case 'dashlets':
            case 'reportlets':
            case 'report-elements':
                setReportFieldsData(prevState => ({
                    ...prevState,

                    reportlet_data: copy(
                        prevState.reportlet_data,
                        destination,
                        draggableId
                    )
                }));
                break;
            default:
                setReportFieldsData((prevState: any) => ({
                    ...prevState,

                    ...move(
                        prevState[source.droppableId],
                        prevState[destination.droppableId],
                        source,
                        destination
                    )
                }));
        }
    };

    const handleReportTitleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setIsFormDirty(true);
        const { value } = event.target;
        if (!value) {
            setReportFieldError(previous => ({
                ...previous,
                titleError: true,
                titleHelperText: REQUIRE_FIELD_ERROR
            }));
        } else if (value.length > 150) {
            setReportFieldError(previous => ({
                ...previous,
                titleError: true,
                titleHelperText: getLengthErrorMessage('report title')
            }));
        } else {
            setReportFieldError(previous => ({
                ...previous,
                titleError: false,
                titleHelperText: ''
            }));
        }

        setReportTitleAndName(previous => ({
            ...previous,
            title: value
        }));
    };

    const handleReportSettingsChange = (data: ReportSettingsType) => {
        setReportSettingsData(data);
        SetIsReportSettingsModalOpen(false);
    };

    const handleLayoutChange = (
        event: SelectChangeEvent<unknown>,
        item: ReportFieldDataContentType
    ) => {
        setIsFormDirty(true);
        const tempState = { ...reportFieldsData };
        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === item.dragAndDropId
        );

        if (!(reportContent && reportContent.reportlet)) return;

        reportContent.reportlet.layout = event.target.value as string;

        setReportFieldsData(tempState);
    };

    const handleInputClear = (item: ReportFieldDataContentType) => {
        setIsFormDirty(true);
        const tempState = { ...reportFieldsData };

        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === item.dragAndDropId
        );

        if (!(reportContent && reportContent.reportElement)) return;

        reportContent.reportElement.value = '';
        setReportFieldsData(tempState);
    };

    const handleSegmentTextChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        item: ReportFieldDataContentType,
        key: string
    ) => {
        const tempState = { ...reportFieldsData };
        const id = item.dragAndDropId as string;
        const { value } = event.target;

        setIsFormDirty(true);
        if (value.length > 150) {
            setReportElementError(previous => ({
                ...previous,
                [id]: [
                    {
                        colName: key,
                        error: true,
                        colErrorMessage: getLengthErrorMessage(key)
                    }
                ]
            }));
        } else {
            const oldData = cloneDeep(reportElementError);
            delete oldData[id];
            setReportElementError(oldData);
        }

        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === id
        );

        if (!(reportContent && reportContent.reportElement)) return;

        reportContent.reportElement.value = value;

        setReportFieldsData(tempState);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: ReportFieldDataContentType,
        key: 'hideSection' | 'recommendationsCheckbox' | 'benchmarkCheckbox'
    ) => {
        const tempState = { ...reportFieldsData };

        setIsFormDirty(true);

        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === item.dragAndDropId
        );

        if (!(reportContent && reportContent.reportlet)) return;

        reportContent.reportlet[key] = event.target.checked;

        setReportFieldsData(tempState);
    };

    const deleteItem = (id: string | undefined, isTableElement?: boolean) => {
        const tempReportFieldsData = { ...reportFieldsData };

        const removeIndex = tempReportFieldsData.reportlet_data.findIndex(
            element => element.dragAndDropId === id
        );

        if (removeIndex !== -1) {
            tempReportFieldsData.reportlet_data.splice(removeIndex, 1);
        }

        if (id === tableSummaryDragIdRef.current) {
            setReportElements(
                changeReportElementVisibility(
                    reportElements,
                    'table_summary',
                    false
                )
            );
            tableSummaryDragIdRef.current = '';
        }
        setReportFieldsData(tempReportFieldsData);

        const updatedReportElementError = cloneDeep(reportElementError);

        if (isTableElement && tableSummaryDragIdRef.current) {
            const tableData = getSummaryTableRows(
                tempReportFieldsData.reportlet_data
            );
            if (!tableData.length) {
                const errorIndex = updatedReportElementError[
                    tableSummaryDragIdRef.current
                ].findIndex(({ colName }) => colName === 'tables');
                if (errorIndex < 0) {
                    updatedReportElementError[
                        tableSummaryDragIdRef.current
                    ].push(emptyTablesError);
                } else {
                    updatedReportElementError[tableSummaryDragIdRef.current][
                        errorIndex
                    ] = emptyTablesError;
                }
            }
        }
        delete updatedReportElementError[id || ''];
        setReportElementError(updatedReportElementError);
    };

    const handleOverviewTextChange = (
        item: ReportFieldDataContentType,
        value: string
    ) => {
        const tempState = { ...reportFieldsData };
        const id = item.dragAndDropId as string;

        setIsFormDirty(true);

        if (value.length > 30_000) {
            setReportElementError(previous => ({
                ...previous,
                [id]: [
                    {
                        colName: 'overview_text',
                        error: true,
                        colErrorMessage: EDITOR_MAX_LIMIT_ERROR
                    }
                ]
            }));
        } else {
            const oldData = cloneDeep(reportElementError);
            delete oldData[id];
            setReportElementError(oldData);
        }

        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === id
        );

        if (!(reportContent && reportContent.reportElement)) return;

        reportContent.reportElement.value = value;

        setReportFieldsData(tempState);
    };

    const handleReportletTextChange = (
        item: ReportFieldDataContentType,
        key: 'dashletDescription' | 'recommendations' | 'benchmark',
        value: string
    ) => {
        const tempState = { ...reportFieldsData };
        const id = item.dragAndDropId as string;
        const reportContent = tempState.reportlet_data.find(
            ({ dragAndDropId }) => dragAndDropId === id
        );

        setIsFormDirty(true);

        if (value.length > 30_000) {
            if (reportElementError[id]) {
                const oldData = cloneDeep(reportElementError);
                const errorIndex = oldData[id].findIndex(
                    ({ colName }) => colName === key
                );
                if (errorIndex < 0) {
                    oldData[id].push({
                        colName: key,
                        error: true,
                        colErrorMessage: EDITOR_MAX_LIMIT_ERROR
                    });
                }
                setReportElementError(oldData);
            } else {
                setReportElementError(previous => ({
                    ...previous,
                    [id]: [
                        {
                            colName: key,
                            error: true,
                            colErrorMessage: EDITOR_MAX_LIMIT_ERROR
                        }
                    ]
                }));
            }
        } else {
            const oldData = cloneDeep(reportElementError);
            const newArr =
                oldData[id]?.filter(({ colName }) => colName !== key) ?? [];
            if (newArr.length === 0) {
                delete oldData[id];
            } else {
                oldData[id] = newArr;
            }
            setReportElementError(oldData);
        }

        if (!(reportContent && reportContent.reportlet)) return;

        if (key === 'dashletDescription') {
            reportContent.reportlet.reportDashlet.dashletDescription = value;
        } else {
            reportContent.reportlet[key] = value;
        }

        setReportFieldsData(tempState);
    };

    const handleAddErrorForNewSection = (
        item: ReportFieldDataContentType,
        key: string
    ) => {
        const id = item.dragAndDropId as string;

        setIsFormDirty(true);
        if (reportElementError[id]) {
            const oldData = cloneDeep(reportElementError);
            const errorIndex = oldData[id].findIndex(
                ({ colName }) => colName === key
            );
            if (errorIndex < 0) {
                oldData[id].push({
                    colName: key,
                    error: true,
                    isPristine: true,
                    colErrorMessage: EMPTY_TABLE_ERROR
                });
                oldData[id].push({
                    colName: `image${key}`,
                    error: true,
                    isPristine: true,
                    colErrorMessage: IMAGE_REQUIRED
                });
            }
            setReportElementError(oldData);
        } else {
            setReportElementError(previous => ({
                ...previous,
                [id]: [
                    {
                        colName: key,
                        error: true,
                        isPristine: true,
                        colErrorMessage: EMPTY_TABLE_ERROR
                    },
                    {
                        colName: `image${key}`,
                        error: true,
                        isPristine: true,
                        colErrorMessage: IMAGE_REQUIRED
                    }
                ]
            }));
        }
    };

    const handleRemoveErrorForDeletedSection = (
        item: ReportFieldDataContentType,
        keys: string[]
    ) => {
        const id = item.dragAndDropId as string;

        setIsFormDirty(true);

        const oldData = cloneDeep(reportElementError);
        const newArr =
            oldData[id]?.filter(({ colName }) => !keys.includes(colName)) ?? [];

        if (newArr.length === 0) {
            delete oldData[id];
        } else {
            oldData[id] = newArr;
        }
        setReportElementError(oldData);
    };

    const handleError = (
        error: string,
        key: string,
        id: string,
        isPristine?: boolean
    ) => {
        if (error) {
            setReportElementError(prev => {
                if (prev[id]) {
                    const updatedData = cloneDeep(prev);
                    const errorIndex = updatedData[id].findIndex(
                        ({ colName }) => colName === key
                    );
                    if (errorIndex < 0) {
                        updatedData[id].push({
                            colName: key,
                            error: true,
                            colErrorMessage: error,
                            isPristine
                        });
                    } else {
                        updatedData[id][errorIndex] = {
                            colName: key,
                            error: true,
                            colErrorMessage: error,
                            isPristine
                        };
                    }
                    return updatedData;
                }
                return {
                    ...prev,
                    [id]: [
                        {
                            colName: key,
                            error: true,
                            colErrorMessage: error,
                            isPristine
                        }
                    ]
                };
            });
        } else {
            setReportElementError(prev => {
                const updatedData = cloneDeep(prev);
                const errorSegment =
                    updatedData[id]?.filter(({ colName }) => colName !== key) ??
                    [];
                if (errorSegment.length === 0) {
                    delete updatedData[id];
                } else {
                    updatedData[id] = errorSegment;
                }
                return updatedData;
            });
        }
    };

    const handleReportElementChange = (
        item: ReportFieldDataContentType,
        index: number
    ) => {
        const updatedReportFieldsData = { ...reportFieldsData };
        updatedReportFieldsData.reportlet_data[index] = item;
        setIsFormDirty(true);
        setReportFieldsData(updatedReportFieldsData);
    };

    const handleSummaryTableChange = (data: RowData[]) => {
        setReportFieldsData(prevState => ({
            ...prevState,

            reportlet_data: reorderTableSummaryData(
                prevState.reportlet_data,
                data
            )
        }));
    };

    const handleTableTitleChange = (
        value: string,
        index: number,
        dragId: string
    ) => {
        const nameExist = checkTableKeyExist(
            reportFieldsData.reportlet_data,
            index,
            'title',
            value
        );
        if (nameExist) {
            handleError(ALREADY_EXIST_TABLE_ERROR, 'table-exist', dragId);
        } else {
            handleError('', 'table-exist', dragId);
        }
    };

    const renderItem = (item: ReportFieldDataContentType, index: number) => {
        if (!item.isReportlet) {
            if (item.reportElement?.elementType === 'text_api_variables') {
                return (
                    <TextWithVariables
                        deleteItem={deleteItem}
                        handleError={handleError}
                        handleReportElementChange={i =>
                            handleReportElementChange(i, index)
                        }
                        item={item as TextWithVariablesReportDataContent}
                        reportElementError={reportElementError}
                        selectedDashboardIds={selectedDashboardIds}
                    />
                );
            }
            if (item.reportElement?.elementType === 'table') {
                const dragID = item?.dragAndDropId || '';
                const hasOtherTableInclude = checkTableKeyExist(
                    reportFieldsData.reportlet_data,
                    index,
                    'includeInSummary',
                    true
                );
                return (
                    <TableReport
                        deleteItem={id => deleteItem(id, true)}
                        handleError={handleError}
                        handleReportElementChange={i =>
                            handleReportElementChange(i, index)
                        }
                        handleTableTitleChange={value =>
                            handleTableTitleChange(value, index, dragID)
                        }
                        hasOtherTableInclude={hasOtherTableInclude}
                        item={item as TableReportDataContentType}
                        reportElementError={reportElementError}
                        selectedDashboardIds={selectedDashboardIds}
                        tableSummaryDragIdRef={tableSummaryDragIdRef}
                    />
                );
            }
            if (item.reportElement?.elementType === 'image') {
                return (
                    <ImageElement
                        deleteItem={deleteItem}
                        handleError={handleError}
                        handleReportElementChange={i =>
                            handleReportElementChange(i, index)
                        }
                        item={item as ImageReportDataContent}
                        reportElementError={reportElementError}
                        selectedDashboardIds={selectedDashboardIds}
                    />
                );
            }
            if (item.reportElement?.elementType === 'table_summary') {
                const tableRows = getSummaryTableRows(
                    reportFieldsData.reportlet_data
                );
                return (
                    <TableSummary
                        deleteItem={deleteItem}
                        handleError={handleError}
                        handleReportElementChange={i =>
                            handleReportElementChange(i, index)
                        }
                        handleTableChange={handleSummaryTableChange}
                        item={item as TableSummaryReportDataContentType}
                        reportElementError={reportElementError}
                        selectedDashboardIds={selectedDashboardIds}
                        tableRows={tableRows}
                    />
                );
            }

            if (item.reportElement?.elementType === 'custom_summary_graphics') {
                return (
                    <CustomSummaryGraphicElement
                        addErrorForNewSection={handleAddErrorForNewSection}
                        deleteItem={deleteItem}
                        handleError={handleError}
                        handleRemoveErrorForDeletedSection={
                            handleRemoveErrorForDeletedSection
                        }
                        handleReportElementChange={i =>
                            handleReportElementChange(i, index)
                        }
                        item={item as CustomSummaryGraphicDataContentType}
                        reportElementError={reportElementError}
                        selectedDashboardIds={selectedDashboardIds}
                    />
                );
            }
            if (item.reportElement?.elementType === 'line_break') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '15px',
                                    background: '#CBCBCB',
                                    borderRadius: '6px'
                                }}
                            />
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </Card>
                );
            }
            if (item.reportElement?.elementType === 'page_break') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '15px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '15px',
                                    // background: '#CBCBCB',
                                    borderRadius: '6px',
                                    // border: '8px dashed #CBCBCB'
                                    background:
                                        'repeating-linear-gradient(90deg, grey 0 35px, #0000 0 50px)'
                                }}
                            />
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </Card>
                );
            }
            if (item.reportElement?.elementType === 'overview_text') {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : '',
                            overflow: 'visible'
                        }}
                    >
                        <StyledField>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    height: '30px'
                                }}
                            >
                                <FormLabel
                                    htmlFor={`modal-field-input-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {OVERVIEW_TEXT}
                                </FormLabel>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    onClick={() =>
                                        deleteItem(item.dragAndDropId)
                                    }
                                    sx={{
                                        float: 'right',
                                        top: '-10px',
                                        height: '24px'
                                    }}
                                >
                                    <CrossIcon />
                                </IconButton>
                            </div>
                            <EditorSection
                                id={item.dragAndDropId ?? ''}
                                itemKey="overview_text"
                                onChange={data =>
                                    handleOverviewTextChange(item, data)
                                }
                                reportElementError={reportElementError}
                                value={item.reportElement?.value ?? ''}
                            />
                        </StyledField>
                    </Card>
                );
            }
            if (
                item.reportElement?.elementType === 'segment_overview_title' ||
                item.reportElement?.elementType === 'segment_title'
            ) {
                return (
                    <Card
                        sx={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255, 255, 255, .05)'
                                    : '#F2F2F4',
                            padding: '10px',
                            boxShadow:
                                item.dragAndDropId ===
                                selectedDashboardIds.dragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    >
                        <StyledField>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    height: '30px'
                                }}
                            >
                                <FormLabel
                                    htmlFor={`modal-field-input-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {item.reportElement.elementType ===
                                        'segment_overview_title' &&
                                        SEGMENT_OVERVIEW_TITLE}
                                    {item.reportElement.elementType ===
                                        'segment_title' && SEGMENT_TITLE}
                                </FormLabel>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    onClick={() =>
                                        deleteItem(item.dragAndDropId)
                                    }
                                    sx={{
                                        float: 'right',
                                        top: '-10px',
                                        height: '24px'
                                    }}
                                >
                                    <CrossIcon />
                                </IconButton>
                            </div>
                            <TextBox
                                autoComplete="off"
                                error={isFieldHavingError(
                                    reportElementError,
                                    item.dragAndDropId,
                                    item.reportElement.elementType
                                )}
                                fullWidth
                                helperText={getFieldErrorMessage(
                                    reportElementError,
                                    item.dragAndDropId,
                                    item.reportElement.elementType
                                )}
                                hiddenLabel
                                id={`modal-field-input-${
                                    item.dragAndDropId as string
                                }`}
                                InputProps={{
                                    endAdornment: item.reportElement.value
                                        ?.length > 0 && (
                                        <InputAdornment
                                            onClick={() =>
                                                handleInputClear(item)
                                            }
                                            position="end"
                                            variant="outlined"
                                        >
                                            <IoIosClose />
                                        </InputAdornment>
                                    )
                                }}
                                onChange={e => {
                                    handleSegmentTextChange(
                                        e,
                                        item,
                                        item.reportElement?.elementType ?? ''
                                    );
                                }}
                                size="small"
                                value={item.reportElement.value}
                                variant="outlined"
                            />
                        </StyledField>
                    </Card>
                );
            }
        }

        let layoutItems = [];

        if (
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'TABLE' ||
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'COLUMN' ||
            item.reportlet?.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type === 'LINE'
        ) {
            layoutItems = ['LEFT', 'RIGHT', 'ABOVE', 'BELOW'];
        } else {
            layoutItems = ['LEFT', 'RIGHT'];
        }
        return (
            <Card
                sx={{
                    background:
                        theme.palette.mode === 'dark'
                            ? 'rgba(255, 255, 255, .05)'
                            : '#F2F2F4',
                    padding: '10px',
                    boxShadow:
                        item.dragAndDropId ===
                        selectedDashboardIds.dragAndDropId
                            ? boxShadowString
                            : '',
                    overflow: 'visible'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Checkbox
                                    checked={item.reportlet?.hideSection}
                                    id={`modal-field-input-dashlet-hide-section-${
                                        item.dragAndDropId as string
                                    }`}
                                    onChange={e =>
                                        handleCheckboxChange(
                                            e,
                                            item,
                                            'hideSection'
                                        )
                                    }
                                    style={{
                                        transform: 'scale(0.8)'
                                    }}
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        marginRight: '5px'
                                    }}
                                />
                                <FormLabel
                                    htmlFor={`modal-field-input-dashlet-hide-section-${
                                        item.dragAndDropId as string
                                    }`}
                                >
                                    {HIDE_DASHLET}
                                </FormLabel>
                            </div>
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={() => deleteItem(item.dragAndDropId)}
                                sx={{
                                    float: 'right',
                                    top: '-10px',
                                    height: '24px'
                                }}
                            >
                                <CrossIcon />
                            </IconButton>
                        </div>
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '30px'
                            }}
                        >
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {DASHLET}
                            </FormLabel>
                        </div>
                        <TextBox
                            autoComplete="off"
                            disabled
                            fullWidth
                            hiddenLabel
                            id={`modal-field-input-dashlet-${
                                item.dragAndDropId as string
                            }`}
                            onChange={() => {}}
                            size="small"
                            value={
                                item.reportlet?.reportDashlet.title as string
                            }
                            variant="outlined"
                        />
                    </StyledField>

                    <StyledField>
                        <FormLabel
                            htmlFor={`modal-field-input-dashlet-description-${
                                item.dragAndDropId as string
                            }`}
                        >
                            {DASHLET_DESCRIPTION}
                        </FormLabel>

                        <EditorSection
                            id={item.dragAndDropId ?? ''}
                            itemKey="dashletDescription"
                            onChange={data => {
                                handleReportletTextChange(
                                    item,
                                    'dashletDescription',
                                    data
                                );
                            }}
                            reportElementError={reportElementError}
                            value={
                                item.reportlet?.reportDashlet
                                    .dashletDescription ?? ''
                            }
                        />
                    </StyledField>

                    <StyledField>
                        <FormLabel
                            htmlFor={`modal-field-input-layout-${
                                item.dragAndDropId as string
                            }`}
                        >
                            {LAYOUT}
                        </FormLabel>

                        <Select
                            defaultValue={item.reportlet?.layout || 'LEFT'}
                            onChange={e => {
                                handleLayoutChange(e, item);
                            }}
                            variant="outlined"
                        >
                            {layoutItems.map(layout => (
                                <MenuItem key={layout} value={layout}>
                                    {layout}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '30px'
                            }}
                        >
                            <Checkbox
                                checked={
                                    item.reportlet?.recommendationsCheckbox
                                }
                                id={`modal-field-input-dashlet-recommendations-${
                                    item.dragAndDropId as string
                                }`}
                                onChange={e =>
                                    handleCheckboxChange(
                                        e,
                                        item,
                                        'recommendationsCheckbox'
                                    )
                                }
                                style={{
                                    transform: 'scale(0.8)'
                                }}
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px'
                                }}
                            />
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-recommendations-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {RECOMMENDATIONS}
                            </FormLabel>
                        </div>

                        <EditorSection
                            id={item.dragAndDropId ?? ''}
                            itemKey="recommendations"
                            onChange={data => {
                                handleReportletTextChange(
                                    item,
                                    'recommendations',
                                    data
                                );
                            }}
                            reportElementError={reportElementError}
                            value={item.reportlet?.recommendations ?? ''}
                        />
                    </StyledField>

                    <StyledField>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                height: '30px'
                            }}
                        >
                            <Checkbox
                                checked={item.reportlet?.benchmarkCheckbox}
                                id={`modal-field-input-dashlet-benchmark-${
                                    item.dragAndDropId as string
                                }`}
                                onChange={e =>
                                    handleCheckboxChange(
                                        e,
                                        item,
                                        'benchmarkCheckbox'
                                    )
                                }
                                style={{
                                    transform: 'scale(0.8)'
                                }}
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px'
                                }}
                            />
                            <FormLabel
                                htmlFor={`modal-field-input-dashlet-benchmark-${
                                    item.dragAndDropId as string
                                }`}
                            >
                                {BENCHMARKS}
                            </FormLabel>
                        </div>

                        <EditorSection
                            id={item.dragAndDropId ?? ''}
                            itemKey="benchmark"
                            onChange={data => {
                                handleReportletTextChange(
                                    item,
                                    'benchmark',
                                    data
                                );
                            }}
                            reportElementError={reportElementError}
                            value={item.reportlet?.benchmark ?? ''}
                        />
                    </StyledField>
                </div>
            </Card>
        );
    };

    const renderSymbol = (item: ReportFieldDataContentType) => {
        if (!item.isReportlet) {
            const cardImage = renderChartSvg(
                item.reportElement!.elementType,
                theme.palette.mode
            );

            if (item.reportElement?.elementType === 'line_break') {
                return (
                    <div
                        style={{
                            width: '138px',
                            height: '10px',
                            borderRadius: '5px',
                            background: '#CBCBCB',
                            boxShadow:
                                item.previewDragAndDropId ===
                                selectedDashboardIds.previewDragAndDropId
                                    ? boxShadowString
                                    : ''
                        }}
                    />
                );
            }
            return (
                <Card
                    sx={{
                        width: '140px',
                        boxShadow:
                            item.previewDragAndDropId ===
                            selectedDashboardIds.previewDragAndDropId
                                ? boxShadowString
                                : ''
                    }}
                >
                    <CardMedia
                        alt="Your image"
                        component="img"
                        height="80"
                        image={cardImage}
                        sx={{
                            padding: '1em',
                            objectFit: 'contain',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background:
                                theme.palette.mode === 'dark' ? '' : '#F2F2F4',
                            boxShadow: 'none'
                        }}
                        width={30}
                    />
                </Card>
            );
        }

        const cardImage = renderChartSvg(
            item.reportlet!.reportDashlet.dashletJson.dashlet
                .visualizationConfig.type
        );

        return (
            <Card
                sx={{
                    width: '140px',
                    boxShadow:
                        item.previewDragAndDropId ===
                        selectedDashboardIds.previewDragAndDropId
                            ? boxShadowString
                            : ''
                }}
            >
                <CardMedia
                    alt="Your image"
                    component="img"
                    height="80"
                    image={cardImage}
                    sx={{
                        padding: '1em',
                        objectFit: 'contain',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background:
                            theme.palette.mode === 'dark' ? '' : '#F2F2F4',
                        boxShadow: 'none'
                    }}
                    width={30}
                />

                <CardContent>
                    <Tooltip
                        arrow
                        placement="bottom"
                        sx={{
                            '.MuiTooltip-tooltip': {
                                marginTop: '4px !important'
                            }
                        }}
                        title={
                            <div className="tooltip-arrow-text">
                                {item.reportlet!.reportDashlet.title}
                            </div>
                        }
                    >
                        <EllipsisTypography>
                            {item.reportlet!.reportDashlet.title}
                        </EllipsisTypography>
                    </Tooltip>
                </CardContent>
            </Card>
        );
    };

    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

    const accordions = [
        {
            id: 'dashlets',
            label: `All Tenant Dashlets (${dashletData.totalElements ?? 0})`,
            children: (
                <DashletPanelContainer
                    dashletData={dashletData}
                    reportFieldsData={reportFieldsData}
                    setDashletData={setDashletData}
                    setIsLoading={setIsLoading}
                    setReportFieldsData={setReportFieldsData}
                />
            )
        },
        {
            id: 'reportlets',
            label: `All Reportlets (${reportletData.totalElements ?? 0})`,
            children: (
                <div style={{ height: '42vh' }}>
                    <ReportletPanel
                        reportletData={reportletData}
                        setReportletData={setReportletData}
                    />
                </div>
            )
        },
        {
            id: 'reportElements',
            label: `Report Elements (${
                reportElements.filter(item => !item.hide).length
            })`,
            children: <ReportElementsPanel items={reportElements} />
        }
    ];

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };

    const openMenu = Boolean(anchorElMenu);

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const downloadJSON = () => {
        const reportJson = cloneDeep(reportFieldsData.reportlet_data);

        for (let i = 0; i < reportJson?.length; i++) {
            reportJson[i].order = i + 1;

            delete reportJson[i].dragAndDropId;
            delete reportJson[i].previewDragAndDropId;
            if (reportJson[i].isReportlet) {
                delete reportJson[i].reportlet!.id;
                delete reportJson[i].reportlet!.reportId;
                delete reportJson[i].reportlet!.partnerId;

                delete reportJson[i].reportlet!.reportDashlet.id;
                delete reportJson[i].reportlet!.reportDashlet.partnerId;
                delete reportJson[i].reportlet!.reportDashlet.createdby;
                delete reportJson[i].reportlet!.reportDashlet.updateddate;
            } else {
                delete reportJson[i].reportElement!.id;
                delete reportJson[i].reportElement!.reportId;
                delete reportJson[i].reportElement!.partnerId;
                delete reportJson[i].reportElement!.createdby;
                delete reportJson[i].reportElement!.updateddate;
            }
        }

        const reportDataJson = {
            title: reportTitleAndName.title,
            name: reportTitleAndName.name,
            tags: reportId ? reportTitleAndName.tags : [],
            elements: reportJson
        };

        const dataStr = `data:text/json;charset=utf-8,
            ${encodeURIComponent(JSON.stringify(reportDataJson))}`;
        const dlAnchorElem = document.getElementById('downloadAnchorElem');
        dlAnchorElem?.setAttribute('href', dataStr);
        dlAnchorElem?.setAttribute(
            'download',
            `${reportTitleAndName.name}.json`
        );
        dlAnchorElem?.click();
    };

    const handleMenuItems = (value: string) => {
        setReportElementError(prev => makeReportFieldDirty(prev));
        if (
            !reportFieldError.titleError &&
            Object.keys(reportElementError).length === 0
        ) {
            if (!reportTitleAndName.title) {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: true,
                    titleHelperText: 'This field is required'
                }));
            } else if (value === 'save_as') {
                setIsSaveModalOpen(true);
            } else {
                setReportFieldError(previous => ({
                    ...previous,
                    titleError: false,
                    titleHelperText: ''
                }));

                if (value === 'download_json') {
                    if (!reportTitleAndName.name) {
                        setIsDownloadJSONModalOpen(true);
                    } else {
                        downloadJSON();
                    }
                }
            }
        }

        handleCloseMenu();
    };

    const getSearchedTags = useEventCallback(
        (tagSearch: string) => {
            getTags(TagTypes.report, tagSearch)
                .then(res => {
                    setTags(
                        res.data.content
                            .map((tag: any) => tag.name)
                            .filter(
                                (tag: string) =>
                                    !reportTitleAndName.tags.includes(tag)
                            )
                    );
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        },
        // @ts-ignore
        [reportTitleAndName.tags]
    );

    const onTagsSearchInputChangeHandler = useMemo(
        () =>
            debounce((searchValue: string) => {
                getSearchedTags(searchValue);
            }, 500),
        [getSearchedTags]
    );

    const onClickSaveAsNewTemplate = () => {
        if (!reportTitleAndName.name) {
            setReportFieldError(previous => ({
                ...previous,
                templateNameError: true,
                templateHelperText: 'This field is required'
            }));
        } else if (reportId) {
            let order = 1;
            const elements: File[] = [];
            const cloneReport = cloneDeep(reportFieldsData.reportlet_data);
            const tableData = getSummaryTableRows(
                reportFieldsData.reportlet_data
            );

            const tempReportData = cloneReport.map(report => {
                delete report.dragAndDropId;
                delete report.previewDragAndDropId;
                report.order = order;
                if (report.isReportlet) {
                    delete report.reportlet?.id;
                    delete report.reportlet?.reportDashlet.id;
                } else {
                    delete report.reportElement?.id;
                    sanitizePayload(report, elements, order, tableData);
                }
                order++;
                return report;
            });

            const postData = {
                title: reportTitleAndName.title,
                name: reportTitleAndName.name,
                tags: reportTitleAndName.tags,
                elements: tempReportData,
                ...generateReportSettingsPostData(reportSettingsData)
            };

            const formData = new FormData();
            if (reportSettingsData.logo) {
                formData.append('file', reportSettingsData.logo);
            }

            if (reportSettingsData.watermarkImage) {
                formData.append(
                    'watermarkImage',
                    reportSettingsData.watermarkImage
                );
            }

            elements.forEach(file => {
                formData.append('elementImages', file);
            });

            // @ts-ignore
            formData.append(
                'report',
                new Blob([JSON.stringify(postData)], {
                    type: 'application/json'
                })
            );

            if (reportTitleAndName.title && reportTitleAndName.name.length) {
                createReport(formData)
                    .then(res => {
                        setAnchorElMenu(null);
                        showToast(
                            REPORT_CREATED_SUCCESSFULLY,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        if (!isGenerateReport) {
                            navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                        }
                        setIsSaveModalOpen(false);
                        setGeneratedReportId(res.data.id);
                        setIsFormDirty(false);
                        // setIsGenerateReport(false);
                        if (isGenerateReport) {
                            setIsGenerateModalOpen(true);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                    });
            } else {
                showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
            }
        } else {
            let order = 1;
            const elements: File[] = [];
            const cloneReport = cloneDeep(reportFieldsData.reportlet_data);
            const tableData = getSummaryTableRows(
                reportFieldsData.reportlet_data
            );

            const tempReportData = cloneReport.map(report => {
                delete report.dragAndDropId;
                delete report.previewDragAndDropId;
                report.order = order;
                sanitizePayload(report, elements, order, tableData);
                order++;
                return report;
            });

            const postData = {
                title: reportTitleAndName.title,
                name: reportTitleAndName.name,
                tags: reportTitleAndName.tags,
                elements: tempReportData,
                ...generateReportSettingsPostData(reportSettingsData)
            };

            const formData = new FormData();
            if (reportSettingsData.logo) {
                formData.append('file', reportSettingsData.logo);
            }

            if (reportSettingsData.watermarkImage) {
                formData.append(
                    'watermarkImage',
                    reportSettingsData.watermarkImage
                );
            }

            elements.forEach(file => {
                formData.append('elementImages', file);
            });

            // @ts-ignore
            formData.append(
                'report',
                new Blob([JSON.stringify(postData)], {
                    type: 'application/json'
                })
            );

            if (reportTitleAndName.title && reportTitleAndName.name.length) {
                createReport(formData)
                    .then(res => {
                        setAnchorElMenu(null);
                        // setIsNewTemplateModalOpen(false);
                        showToast(
                            REPORT_CREATED_SUCCESSFULLY,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        if (!isGenerateReport) {
                            navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                        }
                        setIsSaveModalOpen(false);
                        setGeneratedReportId(res.data.id);
                        setIsFormDirty(false);
                        if (isGenerateReport) {
                            setIsGenerateModalOpen(true);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                    });
            } else {
                showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
            }
        }
    };

    const onReportPreviewSubmit = (type?: string) => {
        // @ts-ignore
        // setPreviewFiles([{ ...`previewFileObj`, fileType: type?.toLowerCase() }]);

        previewReportDownload(previewpdfFileObj.previewReportId, type).then(
            res => {
                // Create blob link to download
                const url = window.URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${previewpdfFileObj.fileName}.${type}`
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode?.removeChild(link);
            }
        );
    };

    const onSaveAsExistingTemplate = () => {
        if (selectedTemplate === '') {
            setReportFieldError(previous => ({
                ...previous,
                existingTemplateError: true,
                existingTemplateHelperText: 'This field is required'
            }));
        } else {
            setReportFieldError(previous => ({
                ...previous,
                existingTemplateError: false,
                existingTemplateHelperText: ''
            }));

            let order = 1;
            const elements: File[] = [];
            const cloneReport = cloneDeep(reportFieldsData.reportlet_data);
            const tableData = getSummaryTableRows(
                reportFieldsData.reportlet_data
            );

            const tempReportData = cloneReport.map(report => {
                delete report.dragAndDropId;
                delete report.previewDragAndDropId;
                report.order = order;
                if (reportId) {
                    if (report.isReportlet) {
                        delete report.reportlet?.id;
                        delete report.reportlet?.reportDashlet.id;
                    } else {
                        delete report.reportElement?.id;
                    }
                }
                sanitizePayload(report, elements, order, tableData);
                order++;
                return report;
            });

            const filteredReportList = reportList.filter(
                report => report.id !== reportId
            );
            // @ts-ignore
            const reportData = filteredReportList[selectedTemplate];

            const postData = {
                title: reportTitleAndName.title,
                name: reportData.name,
                tags: reportData.tags,
                elements: tempReportData,
                ...generateReportSettingsPostData(reportSettingsData)
            };

            const formData = new FormData();
            if (reportSettingsData.logo) {
                formData.append('file', reportSettingsData.logo);
            }

            if (reportSettingsData.watermarkImage) {
                formData.append(
                    'watermarkImage',
                    reportSettingsData.watermarkImage
                );
            }

            elements.forEach(file => {
                formData.append('elementImages', file);
            });

            // @ts-ignore
            formData.append(
                'report',
                new Blob([JSON.stringify(postData)], {
                    type: 'application/json'
                })
            );

            if (reportTitleAndName.title) {
                updateReportDetails(reportData.id, formData)
                    .then(res => {
                        setAnchorElMenu(null);
                        showToast(
                            REPORT_TEMPLATE_UPDATED_SUCCESSFULLY,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        setGeneratedReportId(res.data.id);
                        setIsSaveModalOpen(false);
                        setIsFormDirty(false);
                        if (isGenerateReport) {
                            setIsGenerateModalOpen(true);
                        } else {
                            navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                    });
            } else {
                showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
            }
        }
    };

    useEffect(() => {
        if (state && state.data) {
            setReportTitleAndName(previous => ({
                ...previous,
                name: state.data.name,
                title: state.data.title
            }));

            const tempResponse = state.data.reportConfig.elements.map(
                (report: any) => {
                    const id = uuid();
                    report.dragAndDropId = id;
                    report.previewDragAndDropId = uuid();
                    return sanitizeResponse(report);
                }
            );

            setReportFieldsData({
                // @ts-ignore
                reportlet_data: tempResponse
            });

            setReportSettingsData(
                getReportSettingsData(state?.data?.reportConfig)
            );
        }
    }, []);

    useEffect(() => {
        const getReports = async () => {
            const { reportData } = await getReportsList();
            setReportList(reportData);
        };

        getReports();
    }, []);

    useEffect(() => {
        if (reportId) {
            getReportDetail(reportId)
                .then(res => {
                    setReportTitleAndName({
                        name: res.data.name,
                        title: res.data.title,
                        tags: res.data.tags
                    });

                    const tempResponse = res.data.elements.map(
                        (report: any) => {
                            const id = uuid();
                            report.dragAndDropId = id;
                            report.previewDragAndDropId = uuid();

                            return sanitizeResponse(report);
                        }
                    );

                    setReportFieldsData({
                        // @ts-ignore
                        reportlet_data: tempResponse
                    });

                    setReportSettingsData(getReportSettingsData(res.data));

                    setGeneratedReportId(reportId);

                    setSelectedRadioButton('save');
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        }
    }, [reportId]);

    const onPreviewClick = () => {
        setReportElementError(prev => makeReportFieldDirty(prev));
        setGenerateModalType('preview');
        if (!reportFieldError.titleError && !reportTitleAndName.title) {
            setReportFieldError(previous => ({
                ...previous,
                titleError: true,
                titleHelperText: 'This field is required'
            }));
        } else if (
            !reportFieldError.titleError &&
            Object.keys(reportElementError).length === 0
        ) {
            setIsGenerateModalOpen(true);
        }
    };

    const onSaveButtonClick = () => {
        setAnchorElMenu(null);
        let order = 1;
        const elements: File[] = [];
        const cloneReport = cloneDeep(reportFieldsData.reportlet_data);
        const tableData = getSummaryTableRows(reportFieldsData.reportlet_data);

        const tempReportData = cloneReport.map(report => {
            delete report.dragAndDropId;
            delete report.previewDragAndDropId;
            report.order = order;
            sanitizePayload(report, elements, order, tableData);
            order++;
            return report;
        });

        const postData = {
            title: reportTitleAndName.title,
            name: reportTitleAndName.name,
            tags: reportTitleAndName.tags,
            elements: tempReportData,
            ...generateReportSettingsPostData(reportSettingsData)
        };

        const formData = new FormData();
        if (reportSettingsData.logo) {
            formData.append('file', reportSettingsData.logo);
        }

        if (reportSettingsData.watermarkImage) {
            formData.append(
                'watermarkImage',
                reportSettingsData.watermarkImage
            );
        }

        elements.forEach(file => {
            formData.append('elementImages', file);
        });

        formData.append(
            'report',
            new Blob([JSON.stringify(postData)], {
                type: 'application/json'
            })
        );

        if (reportTitleAndName.title && reportTitleAndName.name) {
            updateReportDetails(reportId, formData)
                .then(res => {
                    setAnchorElMenu(null);
                    showToast(
                        REPORT_UPDATE_SUCCESSFULLY,
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    setGeneratedReportId(res.data.id);
                    setIsSaveModalOpen(false);
                    setIsFormDirty(false);
                    if (isGenerateReport) {
                        setIsGenerateModalOpen(true);
                    } else {
                        navigate(`${TEMPLATE_ROUTE}${GET_REPORT_TEMPLATE}`);
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                });
        } else {
            showToast(REQUIRED_FIELD, TOAST_TYPE.ERROR, TOAST_ID);
        }
    };

    const onSaveModalSubmit = () => {
        if (selectedRadioButton === 'new_template') {
            onClickSaveAsNewTemplate();
        } else if (selectedRadioButton === 'existing_template') {
            onSaveAsExistingTemplate();
        } else if (selectedRadioButton === 'save') {
            onSaveButtonClick();
        }
    };

    const onGenerateReportSubmitSuccess = (previewData: any) => {
        setPreviewReportIdAndName(previewData);
        setIsGenerateModalOpen(false);
        setIsLoading(false);

        if (generateModalType === 'preview') {
            setPreviewFileType(previewData.fileType);
            setShouldIntervalStart(true);
            setIsPreviewModalOpen(true);
            setIsModalLoading(true);
        }

        const pdffileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=PDF`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        const docfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=DOC&isPreview=true`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        const pptfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=PPT&isPreview=true`,
            fileType: 'pdf',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        const jsonfileObj = {
            uri: `${axiosConfig.baseURL}${RUN_REPORT}/${previewData.previewId}/download?format=JSON`,
            fileType: 'json',
            fileName: previewData.previewName,
            previewReportId: previewData.previewId
        };

        setPreviewpdfFileObj(pdffileObj);
        setPreviewdocFileObj(docfileObj);
        setPreviewpptFileObj(pptfileObj);
        setPreviewjsonFileObj(jsonfileObj);

        if (generateModalType === 'generate') {
            navigate(`${TEMPLATE_ROUTE}/${HISTORY}#report`, {
                state: { id: previewData.reportHistoryId }
            });
        }
    };

    const [intervalId, setIntervalId] =
        useState<ReturnType<typeof setInterval>>();

    // To-do after download api will be available
    useEffect(() => {
        let intervalID: ReturnType<typeof setInterval>;
        if (shouldIntervalStart) {
            intervalID = setInterval(() => {
                getRunningReport(previewReportIdAndName.previewId)
                    .then((res: AxiosResponse<GenerateReportResponse>) => {
                        if (
                            res.data.status === 'FAILED' ||
                            res.data.status === 'COMPLETED'
                        ) {
                            clearInterval(intervalID);
                            showToast(
                                res.data.status === 'FAILED'
                                    ? REPORT_GENERATION_ERROR.replace(
                                          '%s',
                                          previewReportIdAndName.previewName
                                      )
                                    : SUCCESSFUL_REPORT_GENERATED.replace(
                                          '%s',
                                          previewReportIdAndName.previewName
                                      ),
                                res.data.status === 'FAILED'
                                    ? TOAST_TYPE.ERROR
                                    : TOAST_TYPE.SUCCESS,
                                TOAST_ID
                            );
                            setShouldIntervalStart(false);
                            setIsModalLoading(false);

                            if (
                                isPreviewModalOpen &&
                                res.data.status === 'FAILED'
                            ) {
                                setIsPreviewModalOpen(prev => !prev);
                            }
                        }
                        if (res.data.status === 'COMPLETED') {
                            setPreviewFiles([
                                // @ts-ignore
                                previewpdfFileObj,
                                // @ts-ignore
                                previewdocFileObj,
                                // @ts-ignore
                                previewpptFileObj,
                                // @ts-ignore
                                previewjsonFileObj
                            ]);
                        }
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                        setIsModalLoading(false);
                    })
                    .finally(() => {
                        // setTableLoading(false);
                    });
            }, 3000);
            setIntervalId(intervalID);
            // setShouldIntervalStart(false);
        }

        return () => {
            clearInterval(intervalID);
        };
    }, [shouldIntervalStart]);

    const onRadioButtonChange = (e: any) => {
        setSelectedRadioButton(e.target.value);
    };

    return (
        <>
            <div>
                <Header title={REPORT_TEMPLATE}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={isReportConfigurationError}
                            onClick={() => onPreviewClick()}
                            style={{
                                width: '125px',
                                paddingLeft: '15px'
                            }}
                            variant="contained"
                        >
                            {PREVIEW}
                        </Button>
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={isReportConfigurationError}
                            onClick={() => {
                                setReportElementError(prev =>
                                    makeReportFieldDirty(prev)
                                );
                                if (
                                    !reportFieldError.titleError &&
                                    !reportTitleAndName.title
                                ) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        titleError: true,
                                        titleHelperText:
                                            'This field is required'
                                    }));
                                } else if (
                                    !reportFieldError.titleError &&
                                    Object.keys(reportElementError).length === 0
                                ) {
                                    if (!isFormDirty && generatedReportId) {
                                        setIsGenerateModalOpen(true);
                                        setIsGenerateReport(true);
                                    } else {
                                        setIsWarningModalOpen(true);
                                    }
                                    setGenerateModalType('generate');
                                }
                            }}
                            style={{
                                width: '125px',
                                paddingLeft: '15px',
                                height: '32px'
                            }}
                            variant="outlined"
                        >
                            {GENERATE}
                        </Button>

                        <IconButton
                            className="header-add-button"
                            onClick={handleClickMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorElMenu}
                            id="button-dropdown"
                            onClose={handleCloseMenu}
                            open={openMenu}
                            PaperProps={
                                {
                                    component: StyledMenuPaper,
                                    sx: {
                                        zIndex: 10002,
                                        maxHeight: '268px'
                                    }
                                } as Partial<PaperProps<'div', {}>> | undefined
                            }
                        >
                            {menuItems.map(menu => (
                                <MenuItem
                                    key={menu.value}
                                    disabled={isReportConfigurationError}
                                    onClick={() => handleMenuItems(menu.value)}
                                    value={menu.value}
                                >
                                    {menu.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    {/* </ProtectedAction> */}
                </Header>
                {/* @ts-ignore */}
                <a id="downloadAnchorElem" style={{ display: 'none' }}>
                    {DOWNLOAD_JSON}
                </a>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <PageContainer>
                    <div
                        style={{
                            minWidth: leftPanelExpanded ? '370px' : '30px',
                            maxWidth: leftPanelExpanded ? '370px' : '30px',
                            maxHeight: '100%',
                            transition: 'all 0.4s',
                            height: '100%',
                            borderTop: borderString,
                            // position: 'sticky',
                            top: '0',
                            overflow: 'hidden'
                        }}
                    >
                        <StyledHeader
                            style={{
                                borderBottom: borderString,
                                height: '45px'
                            }}
                        >
                            {leftPanelExpanded && (
                                <StyledTypography
                                    style={{
                                        fontFamily: 'Proxima Nova Bld',
                                        marginLeft: '25px',
                                        paddingTop: '15px'
                                    }}
                                >
                                    {LIBRARY}
                                </StyledTypography>
                            )}
                            <IconButton
                                onClick={() =>
                                    setleftPanelExpanded(
                                        prevValue => !prevValue
                                    )
                                }
                                sx={{
                                    marginRight: leftPanelExpanded
                                        ? '9px'
                                        : '0',
                                    marginLeft: leftPanelExpanded ? '0' : '9px'
                                }}
                            >
                                <ArrowDown
                                    className={
                                        leftPanelExpanded ? 'open' : 'close'
                                    }
                                />
                            </IconButton>
                        </StyledHeader>

                        {leftPanelExpanded && (
                            <Accordion
                                // @ts-ignore
                                accordions={accordions}
                                setExpandedPanel={setExpandedPanel}
                                sx={{
                                    '.MuiAccordionDetails-root': {
                                        padding: '8px 8px 10px'
                                    },
                                    minWidth: '370px'
                                }}
                            />
                        )}
                    </div>
                    <Divider
                        flexItem
                        orientation="vertical"
                        sx={{
                            position: 'sticky',
                            top: 0
                        }}
                    />
                    <MiddleContainer>
                        <div
                            style={{
                                maxWidth: '178px',
                                minWidth: '170px',
                                borderTop: borderString
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: borderString,
                                    height: '45px',
                                    textAlign: 'center'
                                }}
                            >
                                <StyledTypography
                                    style={{
                                        fontFamily: 'Proxima Nova Bld',
                                        paddingTop: '15px'
                                    }}
                                >
                                    {REPORT_PREVIEW}
                                </StyledTypography>
                            </div>
                            <Content>
                                {Object.keys(reportFieldsData).map(list => (
                                    <Droppable
                                        key="reportlet-preview"
                                        droppableId="reportlet-preview"
                                        // key={list}
                                        // droppableId={list}
                                        // type="report-thumbnail"
                                        type="report-items"
                                    >
                                        {provided => (
                                            <MiddlePaneContainer
                                                ref={provided.innerRef}

                                                // isDraggingOver={
                                                //     snapshot.isDraggingOver
                                                // }
                                            >
                                                {/* @ts-ignore */}
                                                {reportFieldsData[list].map(
                                                    // @ts-ignore
                                                    (item, index) => (
                                                        <Draggable
                                                            key={
                                                                item.previewDragAndDropId
                                                            }
                                                            draggableId={
                                                                item.previewDragAndDropId
                                                            }
                                                            index={index}
                                                        >
                                                            {dragProvided => (
                                                                <DragableDiv
                                                                    ref={
                                                                        dragProvided.innerRef
                                                                    }
                                                                    // isDragging={
                                                                    //     snapshot.isDragging
                                                                    // }
                                                                    {...dragProvided.draggableProps}
                                                                    {...dragProvided.dragHandleProps}
                                                                    id={
                                                                        item.previewDragAndDropId
                                                                    }
                                                                    onClick={() => {
                                                                        setSelectedDashboardIds(
                                                                            () => ({
                                                                                previewDragAndDropId:
                                                                                    item.previewDragAndDropId,
                                                                                dragAndDropId:
                                                                                    item.dragAndDropId
                                                                            })
                                                                        );
                                                                        document
                                                                            .getElementById(
                                                                                item.dragAndDropId
                                                                            )
                                                                            ?.scrollIntoView(
                                                                                {
                                                                                    behavior:
                                                                                        'smooth'
                                                                                }
                                                                            );
                                                                    }}
                                                                    style={
                                                                        dragProvided
                                                                            .draggableProps
                                                                            .style
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginBottom:
                                                                                '15px'
                                                                        }}
                                                                    >
                                                                        {renderSymbol(
                                                                            item
                                                                        )}
                                                                    </div>
                                                                </DragableDiv>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                            </MiddlePaneContainer>
                                        )}
                                    </Droppable>
                                ))}
                            </Content>
                        </div>
                    </MiddleContainer>
                    <RightSideContainer>
                        <Divider
                            flexItem
                            orientation="vertical"
                            sx={{
                                position: 'sticky',
                                top: 0
                            }}
                        />
                        <div
                            style={{
                                width: '100%',
                                borderTop: borderString
                                // borderLeft: borderString
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: borderString,
                                    height: '45px',
                                    paddingLeft: '16px',
                                    paddingRight: '16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <StyledTypography
                                    style={{ fontFamily: 'Proxima Nova Bld' }}
                                >
                                    {REPORT_CONFIGURATION}
                                </StyledTypography>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    onClick={() =>
                                        SetIsReportSettingsModalOpen(true)
                                    }
                                    style={{ padding: '0 4px' }}
                                >
                                    <FaCog style={{ fill: '#8338EC' }} />
                                </IconButton>
                            </div>
                            <RightSideFieldsContainer>
                                <div
                                    style={{
                                        padding: '10px 20px'
                                    }}
                                >
                                    <StyledField>
                                        <FormLabel htmlFor="modal-field-input-report-title">
                                            {REPORT_TITLE}
                                        </FormLabel>
                                        <TextBox
                                            autoComplete="off"
                                            error={reportFieldError.titleError}
                                            fullWidth
                                            helperText={
                                                reportFieldError.titleHelperText
                                            }
                                            hiddenLabel
                                            id="modal-field-input-report-title"
                                            InputProps={{
                                                endAdornment:
                                                    reportTitleAndName.title && (
                                                        <InputAdornment
                                                            onClick={() => {
                                                                setReportTitleAndName(
                                                                    previous => ({
                                                                        ...previous,
                                                                        title: ''
                                                                    })
                                                                );
                                                                setReportFieldError(
                                                                    previous => ({
                                                                        ...previous,
                                                                        titleError:
                                                                            true,
                                                                        titleHelperText:
                                                                            'This field is required'
                                                                    })
                                                                );
                                                            }}
                                                            position="end"
                                                            variant="outlined"
                                                        >
                                                            <IoIosClose />
                                                        </InputAdornment>
                                                    )
                                            }}
                                            onChange={e =>
                                                handleReportTitleChange(e)
                                            }
                                            size="small"
                                            value={
                                                reportTitleAndName.title as string
                                            }
                                            variant="outlined"
                                        />
                                    </StyledField>
                                </div>
                                {Object.keys(reportFieldsData).map(list => (
                                    <Droppable
                                        key={list}
                                        droppableId={list}
                                        type="report-items"
                                    >
                                        {provided =>
                                            reportFieldsData.reportlet_data
                                                .length > 0 ? (
                                                <RightPaneContainer
                                                    ref={provided.innerRef}
                                                >
                                                    {reportFieldsData?.[
                                                        list as keyof ReportFieldsDataType
                                                    ]?.map(
                                                        (
                                                            item: ReportFieldDataContentType,
                                                            index: number
                                                        ) => (
                                                            <Draggable
                                                                key={
                                                                    item.dragAndDropId
                                                                }
                                                                draggableId={
                                                                    item.dragAndDropId ??
                                                                    ''
                                                                }
                                                                index={index}
                                                            >
                                                                {dragProvided => (
                                                                    <DragableDiv
                                                                        ref={
                                                                            dragProvided.innerRef
                                                                        }
                                                                        id={
                                                                            item.dragAndDropId
                                                                        }
                                                                        {...dragProvided.draggableProps}
                                                                        style={
                                                                            dragProvided
                                                                                .draggableProps
                                                                                .style
                                                                        }
                                                                        {...dragProvided.dragHandleProps}
                                                                        onClick={() => {
                                                                            setSelectedDashboardIds(
                                                                                () => ({
                                                                                    dragAndDropId:
                                                                                        item.dragAndDropId,
                                                                                    previewDragAndDropId:
                                                                                        item.previewDragAndDropId
                                                                                })
                                                                            );
                                                                            document
                                                                                .getElementById(
                                                                                    item.previewDragAndDropId ??
                                                                                        ''
                                                                                )
                                                                                ?.scrollIntoView(
                                                                                    {
                                                                                        behavior:
                                                                                            'smooth'
                                                                                    }
                                                                                );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                padding:
                                                                                    '15px',
                                                                                paddingBottom:
                                                                                    '15px',
                                                                                paddingTop:
                                                                                    '10px'
                                                                            }}
                                                                        >
                                                                            {renderItem(
                                                                                item,
                                                                                index
                                                                            )}
                                                                        </div>
                                                                    </DragableDiv>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )}

                                                    {provided.placeholder}
                                                </RightPaneContainer>
                                            ) : (
                                                <RightPaneContainerTwo
                                                    ref={provided.innerRef}
                                                >
                                                    <NoData>
                                                        <NoDataText>
                                                            {
                                                                REPORT_NO_DATA_PREVIEW
                                                            }
                                                        </NoDataText>
                                                    </NoData>
                                                </RightPaneContainerTwo>
                                            )
                                        }
                                    </Droppable>
                                ))}
                            </RightSideFieldsContainer>
                        </div>
                    </RightSideContainer>
                </PageContainer>
            </DragDropContext>
            <div />
            {isGenerateModalOpen && (
                <GenerateReport
                    onClickCancel={() => {
                        setIsGenerateModalOpen(false);
                        setIsGenerateReport(false);
                    }}
                    onSubmitSuccess={previewData =>
                        onGenerateReportSubmitSuccess(previewData)
                    }
                    reportFieldsData={reportFieldsData}
                    reportId={generatedReportId || (reportId ?? '')}
                    reportSettingsData={reportSettingsData}
                    reportTemplateName={reportTitleAndName.name}
                    reportTitleAndName={reportTitleAndName}
                    setIsLoading={setIsLoading}
                    submitBtnLabel={
                        generateModalType === 'preview'
                            ? 'Preview Report'
                            : 'Generate Report'
                    }
                    title={
                        generateModalType === 'preview'
                            ? 'Preview Report'
                            : 'Generate Report'
                    }
                    type={generateModalType}
                />
            )}
            {isPreviewModalOpen && (
                <ReportPreview
                    isModalLoading={isModalLoading}
                    onClickCancel={() => {
                        setIsPreviewModalOpen(false);
                        clearInterval(intervalId);
                        setPreviewFiles([]);
                        setShouldIntervalStart(false);
                    }}
                    onSubmitSuccess={type => onReportPreviewSubmit(type)}
                    previewfiles={previewFiles}
                    previewFileType={previewFileType}
                    setIsLoading={setIsLoading}
                    setIsModalLoading={setIsModalLoading}
                />
            )}

            <StyledModal
                className="medium-width"
                isDisabledSubmitBtn={
                    (selectedRadioButton === 'new_template' &&
                        (reportFieldError.templateNameError ||
                            reportTitleAndName.name.length === 0)) ||
                    (selectedRadioButton === 'existing_template' &&
                        selectedTemplate === '')
                }
                isModalOpen={isSaveModalOpen}
                onCancel={() => {
                    setIsSaveModalOpen(false);
                    setIsGenerateReport(false);
                }}
                onSubmit={onSaveModalSubmit}
                submitBtnLabel="Save"
                title="Save As"
            >
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectedRadioButton}
                    name="radio-buttons-group"
                    onChange={onRadioButtonChange}
                    row
                >
                    {reportId && (
                        <FormControlLabel
                            control={<Radio />}
                            label="Save"
                            value="save"
                        />
                    )}
                    <FormControlLabel
                        control={<Radio />}
                        label="New Template"
                        value="new_template"
                    />
                    <FormControlLabel
                        control={<Radio />}
                        label="Existing Template"
                        value="existing_template"
                    />
                </RadioGroup>
                {(selectedRadioButton === 'new_template' ||
                    selectedRadioButton === 'save') && (
                    <div style={{ marginTop: '20px' }}>
                        <ModalField
                            key={1}
                            error={reportFieldError.templateNameError}
                            helperText={reportFieldError.templateHelperText}
                            index={1}
                            labelName="Template Name*"
                            onChange={e => {
                                if (e.length <= 100) {
                                    setReportTitleAndName(previous => ({
                                        ...previous,
                                        name: e
                                    }));
                                }

                                if (e.length === 0) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: true,
                                        templateHelperText:
                                            'This field is required'
                                    }));
                                } else if (e.length > 100) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: true,
                                        templateHelperText:
                                            'Max 100 characters are allowed in template name'
                                    }));
                                } else if (e.length <= 100) {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: false,
                                        templateHelperText: ''
                                    }));
                                } else {
                                    setReportFieldError(previous => ({
                                        ...previous,
                                        templateNameError: false,
                                        templateHelperText: ''
                                    }));
                                }
                            }}
                            type={FIELD_TYPE_MAP.TEXT}
                            value={reportTitleAndName.name}
                        />
                        <TagsWithInputBox
                            className="content"
                            componentsProps={{
                                popper: {
                                    sx: { zIndex: 10002 }
                                }
                            }}
                            data-testid="tags-autocomplete"
                            defaultValue={[...reportTitleAndName.tags]}
                            id="template-tags-input"
                            onInputChange={(_, value) =>
                                value.trim().length &&
                                onTagsSearchInputChangeHandler(value)
                            }
                            onTagsChangeEmit={(value: string[]) => {
                                setReportTitleAndName(previous => ({
                                    ...previous,
                                    tags: value
                                }));
                                setTags([]);
                            }}
                            options={tags}
                            placeholder="Search tags..."
                            readOnlyOptions={['AMP Managed']}
                        />
                        <Typography
                            className="content"
                            sx={{ color: constants.COLOR_1 }}
                            variant="h6"
                        >
                            {CREATE_TAG_INFO}
                        </Typography>
                    </div>
                )}
                {selectedRadioButton === 'existing_template' && (
                    <div style={{ marginTop: '20px' }}>
                        <StyledTypography
                            fontWeight={600}
                            marginBottom="8px"
                            variant="h5"
                        >
                            {SELECT_BUILT_IN_TEMPLATE}
                        </StyledTypography>
                        {reportList.length > 0 && (
                            <>
                                <ModalField
                                    key={1}
                                    error={
                                        reportFieldError.existingTemplateError
                                    }
                                    helperText={
                                        reportFieldError.existingTemplateHelperText
                                    }
                                    index={1}
                                    items={reportList
                                        .filter(
                                            report => report.id !== reportId
                                        )
                                        .map(report => report.label)}
                                    onChange={e => {
                                        console.log({ e });
                                        setSelectedTemplate(e);
                                    }}
                                    placeHolder="Select"
                                    type={FIELD_TYPE_MAP.DROPDOWN}
                                />
                                {reportFieldError.existingTemplateError && (
                                    <FormHelperText sx={{ color: '#e31c3d' }}>
                                        {ROLE_NAME_ERROR_EMPTY}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    </div>
                )}
            </StyledModal>

            <StyledModal
                className="medium-width"
                isDisabledSubmitBtn={
                    reportFieldError.templateNameError ||
                    reportTitleAndName.name.length === 0
                }
                isModalOpen={isDownloadJSONModalOpen}
                onCancel={() => {
                    setIsDownloadJSONModalOpen(false);
                }}
                onSubmit={() => {
                    downloadJSON();
                    setIsDownloadJSONModalOpen(false);
                }}
                submitBtnLabel={DOWNLOAD_JSON}
                title={DOWNLOAD_JSON}
            >
                <div style={{ marginTop: '20px' }}>
                    <ModalField
                        key={1}
                        error={reportFieldError.templateNameError}
                        helperText={reportFieldError.templateHelperText}
                        index={1}
                        labelName="Template Name*"
                        onChange={e => {
                            if (e.length <= 100) {
                                setReportTitleAndName(previous => ({
                                    ...previous,
                                    name: e
                                }));
                            }

                            if (e.length === 0) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: true,
                                    templateHelperText: 'This field is required'
                                }));
                            } else if (e.length > 100) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: true,
                                    templateHelperText:
                                        'Max 100 characters are allowed in template name'
                                }));
                            } else if (e.length <= 100) {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: false,
                                    templateHelperText: ''
                                }));
                            } else {
                                setReportFieldError(previous => ({
                                    ...previous,
                                    templateNameError: false,
                                    templateHelperText: ''
                                }));
                            }
                        }}
                        type={FIELD_TYPE_MAP.TEXT}
                        value={reportTitleAndName.name}
                    />
                </div>
            </StyledModal>

            <Modal
                displayBtn="all"
                isModalOpen={isWarningModalOpen}
                onCancel={() => setIsWarningModalOpen(false)}
                onSubmit={() => {
                    setIsWarningModalOpen(false);
                    setIsSaveModalOpen(true);
                    setIsGenerateReport(true);
                }}
                submitBtnLabel="Ok"
                title="Warning"
            >
                <WarningModalContainer text="Please save the unsaved changes to generate the report." />
            </Modal>

            {isReportSettingsModalOpen && (
                <ReportSettingsModal
                    isModalOpen={isReportSettingsModalOpen}
                    modalFields={reportSettingsData}
                    onCancel={() => SetIsReportSettingsModalOpen(false)}
                    onSubmit={handleReportSettingsChange}
                />
            )}
        </>
    );
};

export const CreateReport = IsLoadingHOC(CreateReportComponent);
