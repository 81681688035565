import { Controller } from 'react-hook-form';

import BarColumn from './BarColumn';
import CalculationColumn from './CalculationColumn';
import NumberColumn from './NumberColumn';
import ReportColumn from './ReportColumn';
import { dataTypeList } from './utils';
import SelectSection from '../../common/SelectSection';
import TextFieldSection from '../../common/TextFieldSection';
import { useDashboardConfigContext } from '../../context/DashboardConfigContext';
import { ColumnType } from '../../DashBoard.types';

const ConfigColumn = () => {
    const { activeColumn, formData } = useDashboardConfigContext();
    const { control, watch, clearErrors } = formData;

    const ColumnDataType = watch(
        `ampDashboardColumnDtos.${activeColumn}.columnType`
    );

    return (
        <>
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.title`}
                render={({ field, fieldState: { error } }) => (
                    <TextFieldSection
                        error={error?.message}
                        handleChange={field.onChange}
                        title="Name"
                        value={field.value}
                    />
                )}
            />
            <Controller
                control={control}
                name={`ampDashboardColumnDtos.${activeColumn}.columnType`}
                render={({ field }) => (
                    <SelectSection
                        fullWidth
                        handleChange={val => {
                            field.onChange(val);
                            clearErrors(
                                `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnNumberDto`
                            );
                            clearErrors(
                                `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnCalculations`
                            );
                            clearErrors(
                                `ampDashboardColumnDtos.${activeColumn}.ampDashboardColumnBarDtos`
                            );
                            clearErrors(
                                `ampDashboardColumnDtos.${activeColumn}.reportId`
                            );
                        }}
                        items={dataTypeList}
                        title="Data Type"
                        value={field.value}
                    />
                )}
            />
            {ColumnDataType === ColumnType.NUMBER && <NumberColumn />}
            {ColumnDataType === ColumnType.CALCULATION && <CalculationColumn />}
            {ColumnDataType === ColumnType.BAR && <BarColumn />}
            {ColumnDataType === ColumnType.REPORT_LINK && <ReportColumn />}
        </>
    );
};

export default ConfigColumn;
